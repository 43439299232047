import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { QueryParamsInterface } from '@interfaces/general.interface';
import { EndpointsService } from '@services/endpoints/endpoints.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { LoaderService } from '@services/loader/loader.service';
import { OtService } from '@services/ot/ot.service';
import { UtilsService } from '@services/utils/utils.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-refuse-confirmation',
  templateUrl: './refuse-confirmation.component.html',
  styleUrls: ['./refuse-confirmation.component.scss']
})
export class RefuseConfirmationComponent implements OnInit {

  @Input()
  urlParams: QueryParamsInterface;
  @Input()
  funcionarioAutorizador: string;
  @Input()
  cargo: string;

  // Datos de la Orden de trabajo 
  data: GetOTResponseInterface;

  // Suscripcion a OT Inicializada
  subscriptionOT: Subscription;
  
  modalTitle: string = "Solicitud de casos especiales";
  constructor(
    private utils: UtilsService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public inboundData: any,
    public dialogRef: MatDialogRef<RefuseConfirmationComponent>,
    private readonly spinner: LoaderService,
    private genericModalService: GenericModalService, 
    private otService: OtService,
    private endpointsService: EndpointsService) { 
      this.status = '';
    }

  status: string;
  displayData: any;

  ngOnInit(): void {
    // LLego la data
    this.data = this.otService.ot;

    var displayData = {};
    switch (this.inboundData.accion) {
        case 'activar':
            displayData = {
                header: "Activar",
                body: "¿Deseas activar esta OT?"
            };
            this.status = 'activada';
            break;
        case 'rechazar':
            displayData = {
                header: "Rechazar",
                body: "¿Deseas rechazar esta OT?"
            };
            this.status = 'rechazada';
            break;
    }

    this.displayData = displayData;


  }  // end init

  /**
   * Cierra el modal.
   */
   close() {
    this.dialogRef.close();
  }

  /**
   * Crear el payload y hace el request al backend. 
   */
  action() {
      this.data = this.utils.fixOT(this.data);
        var payload = {
          num_ot: this.data.num_ot,
          status: this.status,
          orden: this.data
      };
      payload = this.utils.filterNewObservations(payload);
      if (this.otService.watcherPoliza.changed) {
          payload.orden.observacionesInternas.unshift(this.otService.watcherPoliza.tatuaje())
      }
      payload = this.utils.deleteEmptyObservations(payload);

      this.spinner.show();

    this.endpointsService.turnarOrden(payload, this.inboundData.accion).subscribe(
      (response) => {
              this.spinner.hide();
              this.otService.setSalir = '';
      }, (error) =>{
              this.spinner.hide();
              if(error.error.warning != undefined || error.error.warning != null){
                this.genericModalService.showModal('generico', 'avisoErrorTurnar', { error: error.error.message });
              } else {
                this.genericModalService.showModal('generico', 'errorRechazando', { error: error.error.message });
              }
      });

      this.dialogRef.close();
  }

}
