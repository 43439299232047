import { Injectable } from '@angular/core';

import { OtData, OtTrabajo } from '../../interfaces/ot-trabajo.interface';

import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject
} from '@angular/fire/database';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { take } from 'rxjs/operators';


const baseBranch = 'WFDAs/OTsBloqueadas/OTs';
const baseActivo = 'WFDAs/activo';

@Injectable({
  providedIn: 'root'
})
export class FirebaseOtService {

  private refOts : AngularFireObject<unknown>;
  private refActivo : AngularFireObject<unknown>;

  private activo$: Observable<any>;
  
  private estaActivo : boolean;

  constructor(private db: AngularFireDatabase, private datePipe: DatePipe) { 
      this.refOts = this.db.object(baseBranch);
      this.refActivo = this.db.object(baseActivo);
      this.setearActivo();
  }

  setearActivo(){
      this.activo$ = this.refActivo.valueChanges();
      return new Promise((resolve, _) => {
        this.activo$.subscribe(
            (res) => {
                resolve(true);
                if (res != null) {
                    this.estaActivo = res;
                }
            },
            (error) => {
                console.log('Error'+ error);
                this.estaActivo = false;
                resolve(true);
            }
        );
    });
  }

  get activo(){
    return this.estaActivo;
  }


  validaActivoAgregarActualizar(enviarOrden : OtTrabajo, agregar: boolean){

    if(agregar){

      this.refActivo.valueChanges().pipe(take(1)).subscribe(
        (valor : any ) => {
           this.estaActivo = valor != undefined ? valor : false;
           console.log('Firebase esta activo ?' + this.estaActivo);
           this.agregarActualizarDataOT(enviarOrden);   
        }
      );

    } else {  

      this.agregarActualizarDataOT(enviarOrden);
    
    }

  }
  
  agregarActualizarDataOT(enviarOrden : OtTrabajo){
    
    if(this.estaActivo){

      this.refOts.update({
        [enviarOrden.numOt]: enviarOrden.data
      })
      .then((_) => {
        console.log('La OT se encuentra en proceso..');
      })
      .catch((error) => {
          console.log(`Ocurrio un error al agregar la OT en firebase ${error}.`);
      });

    }

  }
  
  depurarDataOT(orden : String){

    if(this.estaActivo){

      this.db.object(`${baseBranch}/${orden}`).remove()
      .then((_) => {
        console.log('La OT fue depurada.');
      })
      .catch((error) => {
          console.log(`Ocurrio un error al elimina la OT en firebase ${error}.`);
      });
      
    }

  }


}
