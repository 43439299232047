// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // Apuntar a localhost
    // apiUrl: 'http://localhost:8080/',
    // Apuntar a UAT
    //apiUrl: 'https://wf-da-services-uat.gnp.com.mx/',
    //apiCrmUrl: 'https://wf-crm-services-uat.gnp.com.mx/',
    // Apuntar a QA - Validar mensaje apuntando Nelson UAT 29 nov 2021
    apiUrl: 'https://wf-da-services-qa.gnp.com.mx/',
    apiCrmUrl: 'https://wf-crm-services-qa.gnp.com.mx/',
    appUrl: 'http://localhost:4200/',
    urlIntermediarios: 'https://portalintermediarios-qa.gnp.com.mx',
    openEndpoints: ['auth', 'refreshtoken'],
    localEndpoints: [
        'assets/i18n/es.json',
        'assets/i18n/en.json',
        'assets/img/art-green.svg',
        'assets/img/art-yellow.svg',
        'assets/img/art-red.svg',
        'assets/img/icon-alert-azul.svg',
        'assets/img/datos-generales.svg',
        'assets/img/datos-agente.svg',
        'assets/img/datos-contratante.svg',
        'assets/img/comentarios.svg',
        'assets/img/expediente.svg',
        'assets/img/close_orange.svg',
        'assets/img/subir.svg',
        'assets/img/eliminar.svg',
        'assets/img/vista-previa.svg',
        'assets/img/filete.svg',
        'assets/img/filete-terminado.svg',
        'assets/img/icon-activada.svg',
        'assets/img/special-auth.svg',
        'assets/img/search-icon-gray.svg'
    ],
    cookie:
        'Z7SSs/U72R/8a50vhg02E5k0N4AM8mFy6johzSwPRNpPzO3iA+QmWdJZd4zTWb7Wq7SFnMRcX06AXJL4Hu2wz433/0rnVQeVVGH7JvLX+IqlnHGg1TegGjVoviY8hzlTeZVIvTeTThP79fURnM5bYG4Ta/NE9WVOd1DTLf8OoSPDwYif6JUcMAKzXmg3VvuNfdpDleQtqe40gAX9N6GYs2vQqj2sbaFGMrKIFLts3Ck0DQMFh///jZEWnhj9BoNSyitnhPt5JWk7/l2Bq3UkmalQY//xQ3SuGurcxINgDUk/LynDnI3YaVX/CuR0MgfcAUKlLLSifCb86QOZ9r0DZsg3JQaePqfZD6Lrd+ZsCRfgJdkfKL8wENy4oVaHevTtP+tm4dgRQWWigC4bpwUFNw== 29-11-2021 Prueba QA',
    siteUrl: 'https://gnp.com.mx/',
    refreshTokenRate: 10,
    digitalFile: {
        maxSizeFilete: 7.5 * 1024 * 1024, // mb
        maxSizeFile: 20 * 1024 * 1024 // mb
    },
    apikey: 'bf0ec2f18988d0003049b689e8bba4f92339d0076ba11d91af97ed63509da185',
    projectID: 'gnp-workflowdas-qa',
    // Formato de fecha para las observaciones
    datePatternObs: 'YYYY-MM-dd HH:mm',
    // TODO: Colocar aca la URL del nuevo front de consulta

    endpointConsulta:
    'https://wf-da-busqueda-qa.gnp.com.mx/consulta/',
    menu_portal_empleados: 'workflow',
    menu_portal_intermediarios: 'servicios_asistidos',
    // TODO: #RF 7, ocultar para ambiente produccion
    show_nn_fuerza_productora: false,
    firebase : {
        apiKey: "AIzaSyAdWepccRo_Po5zNz3V6nh7knesXhtjihg",
        authDomain: "gnp-workflowdas-qa.firebaseapp.com",
        databaseURL: "https://gnp-workflowdas-qa.firebaseio.com",
        projectId: "gnp-workflowdas-qa",
        storageBucket: "gnp-workflowdas-qa.appspot.com",
        messagingSenderId: "185747168661",
        appId: "1:185747168661:web:6d2a92c5aa8661725eea9c"
    },
    firebaseUpdateRate : 1
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
