import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import { TableActionsInterface } from '@interfaces/table.interface';

@Component({
    selector: 'app-grid-table-actions',
    templateUrl: './grid-table-actions.component.html',
    styleUrls: ['./grid-table-actions.component.scss']
})
export class GridTableActionsComponent implements OnChanges {
    @Input() position: number;
    @Input() actions: Array<TableActionsInterface>;
    @Output() selectedAction: EventEmitter<string>;
    icons: { default: string; hover?: string; aux?: string }[];

    constructor() {
        this.selectedAction = new EventEmitter<string>();
        this.icons = [];
    }

    ngOnChanges({ actions }: SimpleChanges): void {
        if (actions && actions.currentValue) {
            for (const action of this.actions) {
                const icons = action.icon;
                this.icons.push({ ...icons, aux: icons.default });
            }
        }
    }
}
