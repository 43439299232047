import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  sessionStatus: Subject<boolean>;

  constructor() {
    this.sessionStatus = new Subject<any>();
  }

  sessionStarted() {
    this.sessionStatus.next(true);  
  }
}
