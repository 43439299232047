import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { CookieModule } from 'ngx-cookie';
import { MaterialModule } from './modules/material/material.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ComponentsModule } from '@components/components.module';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { esUsLocale } from 'ngx-bootstrap/locale';

import { ApiUrlService } from '@interceptors/apiUrl/api-url.service';
import { AuthService } from '@interceptors/auth/auth.service';
import { TranslateService } from '@services/translate/translate.service';

import { AppComponent } from './app.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '@environments/environment';

defineLocale('es', esUsLocale);

export function translateProviderFactory(
    provider: TranslateService
): () => Promise<boolean> {
    return () => provider.loadTranslationFile('es');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        ComponentsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        HttpClientModule,
        CookieModule.forRoot(),
        MaterialModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiUrlService, multi: true },
        {
            provide: APP_INITIALIZER,
            useFactory: translateProviderFactory,
            deps: [TranslateService],
            multi: true
        },
        { provide: MAT_DATE_LOCALE, useValue: 'es' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
