import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-modal-message',
    templateUrl: './modal-message.component.html',
    styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
