import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalPersonService {
  modalData: Subject<any>;

  /* Datos del modal Direccion */
  modalAddressData: any;
  /* Datos del modal Medio de Contacto */
  modalPhonesData: any;
  /* Datos del modal Medio Electronico */
  modalEmailsData: any;
  /* Datos del contratante (Usados en el formulario principal) */
  summaryContractingData: any;
  /* Datos del servicio personaBupData) */
  myPersonaBupData: any;
  /* Consulta de catalogos opcion alta */
  catalogosAltaData: any = null;
  /* Datos del modal direccion fiscal*/
  modalAddressFiscalData: any;

  constructor() {
    this.modalData = new Subject<any>();
  }

  get addressData() {
    return this.modalAddressData;
  }

  set setAddressData(modalAddressData: any) {
    this.modalAddressData = modalAddressData;
  }

  get phonesData() {
    return this.modalPhonesData;
  }

  set setPhonesData(modalPhonesData: any) {
    this.modalPhonesData = modalPhonesData;
  }

  get emailsData() {
    return this.modalEmailsData;
  }

  set setEmailsData(modalEmailsData: any) {
    this.modalEmailsData = modalEmailsData;
  }

  get contractingData() {
    return this.summaryContractingData;
  }

  set setContractingData(summaryContractingData: any) {
    this.summaryContractingData = summaryContractingData;
    // Devolver la data a los subscribers
    this.modalData.next(summaryContractingData);
  }

  get personaBupData() {
    return this.myPersonaBupData;
  }

  set setPersonaBupData(myPersonaBupData: any) {
    this.myPersonaBupData = myPersonaBupData;
  }

  get catalogosAlta() {
    return this.catalogosAltaData;
  }

  set setCatalogosAlta(catalogosAltaData: any) {
    this.catalogosAltaData = catalogosAltaData;
  }

  get addressFiscalData() {
    return this.modalAddressFiscalData;
  }

  set setAddressFiscalData(modalAddressFiscalData: any) {
    this.modalAddressFiscalData = modalAddressFiscalData;
  }
}
