import { Injectable } from '@angular/core';
import { BitacoraData, NombreColumnasBitacora } from '../../interfaces/bitacora-data.interface';
import { saveAs } from 'file-saver';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';

@Injectable({
  providedIn: 'root'
})
export class DescargaCsvService {
  constructor() {
    dayjs.extend(utc, true);
    dayjs.extend(timezone, true);
  }

  descargarBitacora( dataBitacora : BitacoraData[], ot: any): void {
        
    const replacer = (key, value) => (value === null ? '' : value);
    let header = NombreColumnasBitacora.map((col: any) => col.title);

    
    let csv = dataBitacora.map((row: any) => {
       let result = NombreColumnasBitacora
            .map((fieldName) =>JSON.stringify(row[fieldName.value], replacer))
            .join(',');

        result = result.replace(/uwu/g, '	');
        return result;
    });

    csv.unshift(header.join(','));
    
    let csvArray = csv.join('\r\n');

    
    
    const fechaHoy = String(new Date());
    
    const fechaFormat = this.dateFormat(fechaHoy, 'DDMMYYYY');
    
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvArray], {
        type: 'text/plain; charset=utf8'
    });
    
    saveAs(blob, `Bitacora_${ot}.csv`, true);

  }

  dateFormat(date: string, format: string): string {
    return dayjs(date).tz().format(format);
}

}
