import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TableActionsInterface, TableColumnsInterface } from '@interfaces/table.interface';
import { ModalService } from '@services/modal/modal.service';
import { Subscription } from 'rxjs';
import { TableColumnsFixture, TableActionsFixture  } from "@fixtures/table.person.fixture";
import { PersonsDataInterface, SearchPersonsRequestInterface } from '@interfaces/search-person';
import { PersonasService } from '../../../services/personas/personas.service';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalActionResponseInterface } from '@interfaces/modal-buttons.interface';
import { RegisterPersonComponent } from '../register-person/register-person.component';
import { EditPersonComponent } from '../edit-person/edit-person.component';
import { ModalPersonService } from '@services/modal-person/modal-person.service';
import { LoaderService } from '@services/loader/loader.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { esLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';

import * as moment from 'moment';
import { BsDaterangepickerInlineConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { OtService } from '@services/ot/ot.service';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { ServiciosGnpService } from '@services/servicios-gnp/servicios-gnp.service';
import { RegimenCapital } from '@interfaces/regimen-capital';
import { EventosScrollService } from '@services/eventos-scroll/eventos-scroll.service';

@Component({
  selector: 'app-search-person',
  templateUrl: './search-person.component.html',
  styleUrls: ['./search-person.component.scss']
})
export class SearchPersonComponent implements OnInit {
  modalTitle: string = "Búsqueda de Persona";
  formSearchPerson: FormGroup;
  yaBusco: boolean = false;
  // Datos para el resultado de la busqueda
  data: PersonsDataInterface[] = [];
  columns: Array<TableColumnsInterface>;
  actions: Array<TableActionsInterface>;


  criteriosBusqueda: any[] = [
    {value: 'clave', viewValue: 'Código de cliente'},
    {value: 'nombre', viewValue: 'Nombre(s)'},
    {value: 'rfc', viewValue: 'R.F.C.'},
  ];
  tiposPersona: any[] = [
    {value: 'moral', viewValue: 'Moral'},
    {value: 'fisica', viewValue: 'Fisica'}
  ];

  criterioBusquedaSelected: string = '';

  dataRowSelected: PersonsDataInterface;

  subscription: Subscription;

  // Configuracion de Datepicker de bootstrap
  bsConfig: Partial<BsDaterangepickerInlineConfig>;

  constructor(
    private fb: FormBuilder,
    public readonly modalService: ModalService,
      private personasService: PersonasService,
      @Inject(MAT_DIALOG_DATA) public inboundData: any,
      public dialogRef: MatDialogRef<SearchPersonComponent>,
      private readonly dialog: MatDialog,
      public readonly modalPersonService: ModalPersonService,
      private readonly spinner: LoaderService,
      private genericModalService: GenericModalService,
      private localeService: BsLocaleService,
      private readonly datePipe: DatePipe,
      private otService: OtService,
      private storage: LocalStorageService,
      private serviciosGnpService: ServiciosGnpService,
      private eventosScroll: EventosScrollService
) {

      this.subscription = new Subscription();
      this.columns = TableColumnsFixture;
      this.actions = TableActionsFixture;
      esLocale.weekdaysShort = ['dom.','lun', 'mar.', 'mié.', 'jue.', 'vie.', 'sáb.'];
      esLocale.week.dow = 0;
      defineLocale('es', esLocale);
      this.localeService.use('es');
      const bsConfig = {           // #RF 17
        containerClass: 'theme-dark-blue',
        dateInputFormat: 'DD/MM/YYYY',
        clearPosition: 'center',
        maxDate: moment().toDate(),
        returnFocusToInput: true,
        showWeekNumbers: false
      };
      this.bsConfig = Object.assign({}, bsConfig);

  }

  ngOnInit(): void {
    this.buildFrm();
    this.yaBusco = this.otService.yaBusco;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  buildFrm(): void {
    // Se establecen los valores iniciales
    this.formSearchPerson = this.fb.group({
      criterioBusqueda: new FormControl(this.criterioBusquedaSelected, Validators.required),
      statusPersona: new FormControl('', []),
      razonSocial: new FormControl('', []),
      paterno: new FormControl('', []),
      materno: new FormControl('', []),
      nombres: new FormControl('', []),
      clave:  new FormControl('', []),
      rfc:  new FormControl('', []),
      fechaNacimiento:  new FormControl('', []),
      personDataSelected:  null
    });

    this.formSearchPerson.get('rfc').valueChanges.subscribe((myRFC) => {
      if(myRFC) {
        if(!this.validateRfc(myRFC)) {
          this.formSearchPerson.get('rfc').setErrors({pattern: true});
        }
      }
    });
    // Suscripción de los cambios del form
    this.onChangesForm();
    // Actualizar valores en la suscripción del modal
    this.updateModalData();
  }

  selectCriterioBusqueda() {
    this.criterioBusquedaSelected = this.formSearchPerson.value.criterioBusqueda;
    this.data = [];
  }

  onChangesForm(): void {
    this.subscription = this.formSearchPerson.valueChanges.subscribe(() =>
        // Actualizar valores en la suscripción del modal
        this.updateModalData()
    );
  }

  updateModalData(): void {
    this.modalService.modalData.next({
        values: this.formSearchPerson.value,
        valid: this.formSearchPerson.valid
    });
  }

  public searchPersons(): boolean {
    const request: SearchPersonsRequestInterface = {
      criterio: this.formSearchPerson.value.criterioBusqueda === 'clave' ? 'idParticipante' : this.formSearchPerson.value.criterioBusqueda
    };

    if (this.formSearchPerson.value.criterioBusqueda === 'clave') {
      request.idParticipante = this.formSearchPerson.value.clave;
    } else if (this.formSearchPerson.value.criterioBusqueda === 'rfc') {
      request.rfc = this.formSearchPerson.value.rfc;
    } else {
      request.tipo = this.formSearchPerson.value.statusPersona;
      if (this.formSearchPerson.value.statusPersona === 'fisica') {
        request.paterno = this.formSearchPerson.value.paterno;
        request.materno = this.formSearchPerson.value.materno;
        request.nombres = this.formSearchPerson.value.nombres;
        // #RF 17
        if(this.formSearchPerson.value.fechaNacimiento) {
          request.fchNacimiento = this.datePipe.transform(this.formSearchPerson.value.fechaNacimiento, 'yyyy-MM-dd');    // #RF 17
        }
      } else {
        request.razonSocial = this.formSearchPerson.value.razonSocial;
      }
    }
    
    this.otService.setYaBusco = true;
    this.yaBusco = true;

    // Codigo Valido tipo: moral, Razón Social: Lopez
    // Persona Fisica Valida: Apellido1: Zoxev Apellido2: Zoxe Nombre: Juanito
    this.spinner.showModal();
    this.personasService.searchPersons(request).subscribe(
        (dataResponse) => {
            this.spinner.hideModal();
            this.data = dataResponse.personas;
            if(dataResponse.personas.length > 0) {
              this.data = this.data.filter(function(person) {
                return person.banConsolidado && person.banConsolidado == true;
              });
            } else {
              this.data = [];
            }
            if(this.data.length === 0) {
              let argsData= {
                urlParams: this.inboundData.urlParams,
                data: this.data
              };
              this.genericModalService.showModal('generico', "personas", argsData);
              this.dialogRef.close();
              return;
            }
            for (let person of this.data) {
              person.semaforoArt492 = '';
              person.tooltipArt492 = '';
              if (person.hasOwnProperty("art492") && person.art492 !== null && person.art492.length > 0) {
                  if (person.art492 === "1") {
                      person.semaforoArt492 = 'art-492-green';
                      person.tooltipArt492 = 'Se cuenta con los datos mínimos obligatorios y además los opcionales';
                  } else if (person.art492 === "2") {
                      person.semaforoArt492 = 'art-492-yellow';
                      person.tooltipArt492 = 'Se cuenta con los datos mínimos obligatorios';
                  } else {
                      person.semaforoArt492 = 'art-492-red';
                      person.tooltipArt492 = 'No se cuenta con los datos mínimos obligatorios';
                  }
              }
            }
        },
        (error) => {
          this.spinner.hideModal();

          let argsData= {
            urlParams: this.inboundData.urlParams,
            data: this.data
          };
          this.genericModalService.showModal('generico', "personas", argsData);

          this.dialogRef.close();

          console.error('error al obtener los datos de la persona', error);
        }
    );
    return false;
  }

  /**
   * Abrir el modal para registrar una nueva persona.
   * @returns Abrir
   */
  public registerPerson(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '800px';
    dialogConfig.maxWidth = '1200px';

    dialogConfig.data = {

    };
    // Cerramos este modal
    this.dialogRef.close();

    // Abrimos el otro
    const dialogRef = this.dialog.open(RegisterPersonComponent, dialogConfig);
    this.eventosScroll.eventScroll();
    
    dialogRef.afterClosed().subscribe(({value, data, domicilioCompleto, addressfiscalValues}: any) => {
      if (value) {
        this.altaPersonas(data, domicilioCompleto, addressfiscalValues);
      }
    });
  }

  altaPersonas(data: any, domicilio: string, addressfiscalValues: any): void {
    this.spinner.show();
    this.dialogRef.addPanelClass('hidePanel');
    this.personasService.altaPersonas(data).subscribe(({codigo, codigoFiliacion, numeroSecuenciaDomicilio}: any) => {
      const {tipoPersona, razonSocial, paterno, materno, nombres, rfc } = data;
      let domicilioFiscalCompleto = "";
      
    if(addressfiscalValues){
      if(![undefined,null,""].includes(addressfiscalValues.controls['calle'].value)){

        const nombreColonia = addressfiscalValues.controls['chkDireccionPersonalizada'].value ? addressfiscalValues.controls['coloniaCustom'].value 
        : addressfiscalValues.controls['coloniaNombre'].value ;

        domicilioFiscalCompleto = `${addressfiscalValues.controls['calle'].value} Núm. ${addressfiscalValues.controls['numeroExterior'].value} ${addressfiscalValues.controls['numeroInterior'].value} Col.`+ 
        `${nombreColonia}, C.P. ${addressfiscalValues.controls['codigoPostal'].value} `+
        `${addressfiscalValues.controls['municipioNombre'].value} ${addressfiscalValues.controls['estadoNombre'].value}`;
      }
    }
    
      const pyaload = {
        datosContratante: {
          codigo,
          codigoFiliacion,
          numeroSecuenciaDomicilio,
          tipo: tipoPersona,
          razonSocial,
          apellido1: paterno,
          apellido2: materno,
          nombre: nombres,
          rfc,
          domicilio,
          domicilioFiscal : domicilioFiscalCompleto,
          regimenFiscal : ![undefined,null,""].includes(addressfiscalValues) ?
            addressfiscalValues.controls['fiscalNombre'].value : "",
          regimenCapital : ![undefined,null,""].includes(addressfiscalValues) ?
            addressfiscalValues.controls['capitalNombre'].value : ""
        }
      };
      this.personasService.personData.emit(pyaload);
      this.createResponseAddressFiscal(addressfiscalValues, codigo, codigoFiliacion, razonSocial, rfc);
      this.genericModalService.showModal('generico', "exitoAlta", { body: ' Alta persona exitosa' });
    }, (err: any) => {
      this.spinner.hide();
      this.dialogRef.removePanelClass('hidePanel');
      this.genericModalService.showModal('generico', "errorIntermediarios", { error: err.error.mensaje });
    });
  }

  abrirModaSinResultados(): void {
    // Seteamos el titulo y descripcion del modal de confirmacion
    this.modalService.confirmAlertText = {
        title: 'Resultados',
        message: 'No se encontró persona, realice la búsqueda nuevamente o regístrela como una nueva persona.'
    };

  }

  selectedActionHandler(evt: any): void {
    this.dataRowSelected = evt;
    this.formSearchPerson.value.personDataSelected = this.dataRowSelected;
    this.updateModalData();
  }

  closeModal(value: any): void {

    if(value) {

      //Borra datos previos de secciónes Domicilio, Medio de Contacto y Medio Electrónico
      this.modalPersonService.setAddressData = undefined;
      this.modalPersonService.setPhonesData = undefined;
      this.modalPersonService.setEmailsData = undefined;

      // Invocar a PersonarBup
      const payload = {
        idParticipante: this.formSearchPerson.value.personDataSelected.codigo
      };
      this.spinner.showModal();
      this.personasService.personaBup(payload).subscribe(
        (personaBupResponse) => {
            this.spinner.hideModal();
            this.modalPersonService.myPersonaBupData = personaBupResponse;

            if([null,undefined,""].includes(this.modalPersonService.myPersonaBupData.rfc)){

              this.genericModalService.showModal('generico', "errorRfc", { body: "El contratante no cuenta con RFC registrado, favor de darlo de alta." });

            } else {
              
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.minWidth = '800px';
              // Le pasamos la data al otro modal
              dialogConfig.data = this.formSearchPerson.value;

              // Cerramos este modal (Busqueda de personas)
              this.dialogRef.close();
              this.eventosScroll.eventScroll();
                // Abrimos el otro (Edicion de Persona)
              this.dialog.open(EditPersonComponent, dialogConfig);
            }  
          },
          (error) => {
            // Cerrar el modal
            const response: ModalActionResponseInterface = {
              value: false, data: error.error
            };
          }
      );

    } else {   // end if
      // Cerramos este modal (Busqueda de personas)
      const response: ModalActionResponseInterface = {
        value,
        data: this.formSearchPerson.value
      };
      this.dialogRef.close(response);
    }
  }


  /**
   * Validar el RFC.
   *
   * @param curp - Cadena a validar.
   *
   * @returns
   * false - Si el rfc no es correcto.
   * true - Si el rfc es correcto.
   */
   validateRfc(rfc): boolean {
    let reRFC = /^([aA-zZñÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([aA-zZ\d]{3})?$/;
    return rfc.match(reRFC);
  }

  /**
   * Crea payload con la informacion
   * @returns 
   */
  createResponseAddressFiscal(addressfiscalValues: any, codigo: string, codigoFiliacion: string, razonSocial: string, rfc: string): void{
    if(addressfiscalValues){
      const addressRequest = {
          idParticipante: codigo,
          rfc : rfc,
          tipoOperacion: "",
          codFiliacion: codigoFiliacion,
          fechaUltimaModificacion: "",
          nacionalidad: "",
          nombreRazonSocial: addressfiscalValues.controls.nombreRazonSocial.value,
          cveRegimenFiscalSat: addressfiscalValues.controls.regimenFiscal.value,
          calle: addressfiscalValues.controls.calle.value,
          numeroExterior: addressfiscalValues.controls.numeroExterior.value,
          numeroInterior: addressfiscalValues.controls.numeroInterior.value,

          colonia: addressfiscalValues.controls.chkDireccionPersonalizada.value
            ? addressfiscalValues.controls.coloniaCustom.value
            : addressfiscalValues.controls.coloniaNombre.value,

          municipio: addressfiscalValues.controls.municipioNombre.value,
          estado: addressfiscalValues.controls.estadoNombre.value,
          cpFiscal: addressfiscalValues.controls.codigoPostal.value,
          usuarioAudit: this.storage.getItem("email"),
          cveEstado: addressfiscalValues.controls.entidadFederativa.value,
          cveMunicipio: addressfiscalValues.controls.municipio.value,

          cveColonia: addressfiscalValues.controls.chkDireccionPersonalizada.value
            ? ""
            : addressfiscalValues.controls.colonia.value,

          localidad: "",
          regimenCapital: addressfiscalValues.controls.regimenCapital.value,
          guardarDatosFiscalesINFO: true
      }
  
      this.serviciosGnpService.updateAddressFiscal(addressRequest).subscribe(
        (dataResponse) => {
          this.spinner.hide();
          this.dialogRef.removePanelClass('hidePanel');
          console.log("Se guarda direccion fiscal existosa");
  
        },(error) => {
          this.spinner.hide();
          this.dialogRef.removePanelClass('hidePanel');
          this.genericModalService.showModal('generico', "errorEdicion", { body: error.error.mensaje });
          this.dialogRef.close();
        }
      );
    } else {
      this.spinner.hide();
      this.dialogRef.removePanelClass('hidePanel');
    }
  }

}
