import { Injectable } from '@angular/core';
import { HttpService } from '@services/http/http.service';
import { Observable, of, throwError  } from 'rxjs';
import { environment } from '@environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie';
import { catchError, concatMap, delay, map, retryWhen } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { AuthResponseInterface } from '@interfaces/http.interface';
import { PilotResponseInterface } from '@interfaces/pilot.interface';
import { SessionService } from '@services/session/session.service';

const endpointBasePilot = 'usuarios/service/';
const numeroReintentos = 3;
const tiempoDelay = 1000;

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    ltpaToken: string;
    perfilAgente: string;
    permisos: any;
    erroresRefresh: number = 0;
    isConsulta: boolean;
    rolGnp : any; 
    rolAux : string;

    constructor(
        protected readonly http: HttpService,
        private cookieService: CookieService,
        private readonly router: Router,
        private readonly storage: LocalStorageService,
        private readonly sessionService: SessionService
    ) {
        // Leer cookie del cliente o del application context
        /*
        this.ltpaToken = environment.production
            ? this.cookieService.get('LtpaToken2')
            : environment.cookie;
        */
        this.ltpaToken = this.cookieService.get('LtpaToken2');
    }

    requestJwt(email: string, tipoPersona: string): Observable<boolean> {
            var credentials = null;
            if(this.storage.getItem('menu') && this.storage.getItem('menu') == 'workflow') {
                credentials = {
                    cookie: this.ltpaToken,
                    email: email,
                    tipoPersona: tipoPersona
                };
            } else {
                credentials = {
                    cookie: this.ltpaToken,
                    codIntermediario: this.storage.getItem('codIntermediario'),
                    da: this.storage.getItem('da'),
                    tipoPersona: tipoPersona
                };
            }

            return this.portalRequest(credentials);
    }

    portalRequest(credentials: any): Observable<boolean> {

        sessionStorage.setItem('Prueba de concepto',new Date().toISOString());

        return this.http.
            post('login2', credentials)
            .pipe( 
                retryWhen(
                errors => errors.pipe(
                    concatMap((error, count) => {
                        console.log('Intento el login por '+ count + ' vez');
                        if (count < numeroReintentos ) {
                          return of(error);
                        }
                        return throwError(error);
                      }),
                    delay(tiempoDelay)
                    ),
                )   
            )
            .pipe(
                map((response: AuthResponseInterface) =>{
                    this.handleResponse(response, true)
                    // Se agrega llamada a refreshToken
                    this.refreshToken(false);
                }
                ),
                catchError(err => this.handleErrorLogin(err))
            );
    }


    handleErrorLogin(err){
        if(!["",null,undefined].includes(sessionStorage.getItem('refreshtoken'))){
            console.log('Entro al refresh despues del login');
            return this.refreshToken(true);
        } else {
            console.log('Entro a manejar error');
            return this.handleError(this.router, this.storage, err)
        }
    }

    compareMailAddress(mailParam: string, mailJwt: string): void {
        if (mailParam !== mailJwt) {
            this.storage.clear();
        }
    }

    refreshToken(esLogin:boolean): Promise<any> {
        return this.http
            .post('refreshtoken2', {
                refreshtoken: sessionStorage.getItem('refreshtoken')
            })
            .pipe(
                retryWhen(
                    errors => errors.pipe(
                        concatMap((error, count) => {
                            console.log('Intento el refresh por '+ count + ' vez');
                            if (count < numeroReintentos ) {
                              return of(error);
                            }
                            return throwError(error);
                          }),
                        delay(tiempoDelay)
                        ),
                ),
                map((response: AuthResponseInterface) => {
                    console.log('Se reinicio el contador del refresh');
                    this.erroresRefresh = 0;
                    this.handleResponse(response, esLogin);
                    }
                ),
                catchError((err) => {
                        ++ this.erroresRefresh;
                        console.log('Ha fallado el refresh unas ' + this.erroresRefresh + ' veces');
                        if(this.erroresRefresh >= numeroReintentos || esLogin){
                            console.log('Error refresh ERROR');
                            sessionStorage.setItem('refreshtoken','');
                            this.handleError(this.router, this.storage, err);
                        } else {
                            return of(err);
                        }
                }
                )
            )
            .toPromise()
            .then((response: any) => {
                sessionStorage.setItem('token', sessionStorage.getItem('refreshtoken'));
            });
    }

    handleResponse({ jwt, refreshtoken, usuario }: AuthResponseInterface, isLogin): any {
        const mailParam = this.storage.getItem('mail');
        const jwtHelper = new JwtHelperService();
        const { iat, exp, claims } = jwtHelper.decodeToken(jwt);
        this.storage.setItem('token', jwt);
        this.storage.setItem('refreshtoken', refreshtoken);
        this.storage.setItem('userData', JSON.stringify(claims));
        this.storage.setItem('iat', iat);
        this.storage.setItem('exp', exp);
        if(usuario) {
            this.perfilAgente = usuario.perfil_agente;
            this.rolGnp = usuario.rolesGnp;
            this.rolAux = usuario.rol;
        }
        for (const prop in claims) {
            if (claims.hasOwnProperty(prop)) {
                this.storage.setItem(prop, claims[prop]);
            }
        }

        sessionStorage.setItem('token', jwt);
        sessionStorage.setItem('refreshtoken', refreshtoken);

        this.compareMailAddress(mailParam, claims.mail);
        if(isLogin) {
            if(this.storage.getItem('menu') == environment.menu_portal_empleados) {
                this.sessionService.sessionStarted();
            } else {            
                const esHeredado = usuario.rolesGnp ? Boolean(usuario.rolesGnp.find(
                (rol: string) =>
                rol.includes('Heredado'))): false;    
                
                if(esHeredado){
                
                this.validatePilotHeredados(mailParam).subscribe((response: PilotResponseInterface) => {
                    if (response.piloto == true) {
                        if(!response.whitelist_usuarios) {
                            this.forbiddenError(this.router, this.storage);
                            return;
                        }
                    }
                    this.sessionService.sessionStarted();
                }, (error) =>{
                    this.handleError(this.router, this.storage, error);
                });

                } else {
                    this.validatePilot(mailParam).subscribe((response: PilotResponseInterface) => {
                        if (response.piloto == true) {
                            if(response.whitelist_usuarios == false) {
                                this.forbiddenError(this.router, this.storage);
                                return;
                            }
                        }
                        this.sessionService.sessionStarted();
                    }, (error) =>{
                        this.handleError(this.router, this.storage, error);
                    });
                }
            }
        }
        return true;
    }

    handleError(router, storage, err?): Promise<boolean> {
        console.log('Entro a handle error');
        storage.clear();
        return router.navigate(['/not-found']);
    }

    validatePilot(usuario: string): Observable<PilotResponseInterface> {
        return this.http.get(`${endpointBasePilot}piloto`, null, {usuario: usuario});
    }

    validatePilotHeredados(usuario: string): Observable<PilotResponseInterface> {
        return this.http.get(`${endpointBasePilot}piloto/heredados`, null, {usuario: usuario});
    }
    
    forbiddenError(router, storage): Promise<boolean> {
        storage.clear();
        return router.navigate(['/forbidden']);
    }

    getQuery(): any {
        const query = 'queryParamConsulta';
        return JSON.parse(sessionStorage.getItem(query));
    }

}
