import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalActionResponseInterface } from '@interfaces/modal-buttons.interface';
import { ErrorMessagesService } from '@services/error-messages/error-messages.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { ModalService } from '@services/modal/modal.service';

@Component({
  selector: 'app-error-conexion',
  templateUrl: './error-conexion.component.html',
  styleUrls: ['./error-conexion.component.scss']
})
export class ErrorConexionComponent implements OnInit {
  modalTitle: string = "Error de Conexi\u00F3n";
  panelOpenState = false;
  displayData: any = {};
  constructor(
    private fb: FormBuilder,
    public readonly modalService: ModalService,
      @Inject(MAT_DIALOG_DATA) public inboundData: any,
      public dialogRef: MatDialogRef<ErrorConexionComponent>,
      private readonly dialog: MatDialog,
    public readonly errorMessagesService: ErrorMessagesService,
    private genericModalService: GenericModalService) {

  }

  ngOnInit(): void {
    switch (this.inboundData.errorCatalogo) {
      case 'orden':
          this.displayData = {
              descripcionError: this.errorMessagesService.getMsgError("erroresEndpoints.getOT")
          };
          break;
      case 'roles':
          this.displayData = {
              descripcionError: this.errorMessagesService.getMsgError("erroresEndpoints.getRoles")
          };
          break;
      case 'rtoken':
          this.displayData = {
              descripcionError: this.errorMessagesService.getMsgError("erroresLoginApi.refreshToken")
          };
          break;
      case 'msgsError':
          this.displayData = {
              descripcionError: 'No es posible consumir el servicio de consulta de mensajes de error, favor de recargar para continuar.'
          };
          break;
      case 'catalogosInicialesAlta':
          this.displayData = {
              descripcionError: this.errorMessagesService.getMsgError("erroresCatalogosApi.obtener")
          };
          break;
      case 'tiposActivacion':
          this.displayData = {
              descripcionError: this.errorMessagesService.getMsgError("erroresCatalogosApi.activaciones")
          };
          break;
      case 'guionesActivacion':
          this.displayData = {
              descripcionError: this.errorMessagesService.getMsgError("erroresCatalogosApi.guiones")
          };
          break;
      case 'tiposRechazo':
          this.displayData = {
              descripcionError: this.errorMessagesService.getMsgError("erroresCatalogosApi.rechazos")
          };
          break;
    }
    this.displayData.urlEndpoint = this.inboundData.urlEndpoint;
    this.displayData.codigoHttp = this.inboundData.codigoHttp;
    
  }

  reloadPage() {
    switch (this.inboundData.errorCatalogo) {
        case 'orden':
        case 'roles':
        case 'rtoken':
        case 'msgsError':
            window.location.reload();
            break;
        case 'catalogosInicialesAlta':
            this.closeModal(true);
            // parentScope.showModal('alta');
            break;
        case 'tiposActivacion':
        case 'guionesActivacion':
          this.closeModal(true);
          // parentScope.activar();
          break;
        case 'tiposRechazo':
          this.closeModal(true);
          switch (this.inboundData.operacion) {
              case 'turnar':
                this.genericModalService.showModal('changeStatus', 'turnar', undefined);
                break;
              case 'rechazar':
                this.inboundData.parentScope.rechazar();
                break;
          }
          break;
    }
  };

  closeModal(value: any): void {
    const response: ModalActionResponseInterface = {
      value,
      data: null 
    };
    this.dialogRef.close(response);
  }

}
