import { Injectable } from '@angular/core';
import { HttpService } from '@services/http/http.service';
import { Observable } from 'rxjs';

const endPoint = 'fileteador/';

@Injectable({
  providedIn: 'root'
})
export class FileteadorService {
  constructor(private readonly http: HttpService) {}

  /**
   * Carga un archivo al expediente digital.
   * @param data datos del archivo a cargar.
   * 
   * @returns El archivo cargado y su ID.
   */
  cargaArchivo(data: any): Observable<any> {
      return this.http.post(`${endPoint}fileteadorArchivos/v1/cargaArchivo`, data);
  }

  /**
   * Elimina un archivo de storage por medio de su URL.
   * 
   * @param url - URL del archivo a eliminar
   * @returns nada.
   */
  eliminarArchivo(url: any):  Observable<any> {
    return this.http.post(`${endPoint}fileteadorArchivos/v1/deleteFile`, { "url": url });
  }
  
  /**
   * Devuelve el stream de un archivo para su descarga.
   * 
   * @param url - URL del archivo que se desea descargar.
   * 
   * @returns - stream. 
   */
  descargaArchivo(url: string): Observable<any> {
    return this.http.get(url);
  }

  /**
   * Descarga de un archivo del expediente digital.
   * @param request - Datos de la peticion para la url.
   * 
   * @returns - JSON son el base64.
   */
   descargaDoc(url: String): Observable<any> {
    return this.http.get(`${endPoint}fileteadorArchivos/v1/descarga?${url}`);
  }
  
  /**
   * Genera el archivo PDF de la cedula de autorizacion.
   * 
   * @param data - Datos del request.
   * @returns nada.
   */
   generaCedula(data: any):  Observable<any> {
    return this.http.post(`${endPoint}fileteadorArchivos/v1/generaCedula`, data);
  }
  
  /**
   * Genera el archivo PDF de OT generada.
   * 
   * @param data - Datos del request, entre ellos el numero de OT.
   * @returns nada.
   */
   generaReciboOT(data: any):  Observable<any> {
    return this.http.post(`${endPoint}fileteadorArchivos/v1/generaReciboOT`, data);
  }
  
}
