import { Injectable } from '@angular/core';
import { FormResponseInterface } from '@interfaces/form-response.interface';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    modalData: Subject<FormResponseInterface>;
    frmValuesCatalog: any = null;
    confirmAlertText: { title: string; message: string; acceptLabel?: string; cancelLabel?: string} = null;
    constructor() {
        this.modalData = new Subject<any>();
    }
}
