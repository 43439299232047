import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function maxLength(val: number, returnError = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value !== null && control.value.length > val) {
            if (returnError) {
                return {
                    errorMsg: `El número de caracteres no debe ser mayor a ${val}`
                };
            } else {
                control.setValue(control.value.slice(0, val));
            }
        }
        return null;
    };
}
