import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { CatalogosApiService } from '@services/catalogos-api/catalogos-api.service';
import { EndpointsService } from '@services/endpoints/endpoints.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { LoaderService } from '@services/loader/loader.service';
import { OtService } from '@services/ot/ot.service';
import { UtilsService } from '@services/utils/utils.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-turn',
  templateUrl: './turn.component.html',
  styleUrls: ['./turn.component.scss']
})
export class TurnComponent implements OnInit {

  // Datos de la Orden de trabajo
  data: GetOTResponseInterface;
  modalTitle: string = "Turnar";
  formTurnar: FormGroup;

  listaTurnar: any;
  listaCharolas: any;

  rol: any;
  nombre: string;
  catalogosIniciales: any;
  operacion: string;

  templatePayload: any;
  templateObservacion: any;

  showMotivoRechazo: boolean;

  showTurnarA: boolean;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public inboundData: any,
    public dialogRef: MatDialogRef<TurnComponent>,
    private utils: UtilsService,
    private readonly spinner: LoaderService,
    private genericModalService: GenericModalService,
    private otService: OtService,
    private readonly datePipe: DatePipe,
    private endpointsService: EndpointsService,
    private catalogosApiService: CatalogosApiService
  ) {
    this.showMotivoRechazo = false;
    this.showTurnarA = true;
    this.listaTurnar = [];
    this.listaCharolas = [];
    this.operacion = '';
  }

  ngOnInit(): void {
    this.buildFrm();
    // Cargar datos cuando sea una consulta de OT

    // LLego la data
    this.data = this.otService.ot;
    this.data = this.utils.fixOT(this.data);
    // Documentacion del datepicker
    // https:// valor-software.com/ngx-bootstrap/old/6.1.0/#/datepicker

    if (['gestion_med', 'suscripcion_med', 'suscripcion_med_esp'].includes(this.data.statusOrden)) {
      this.showTurnarA = false;
    }

    if (this.otService.permisos) {
      this.listaTurnar = this.otService.permisos.figurasTurnar;
      this.listaCharolas = this.otService.permisos.charolas;
    }

    this.rol = this.otService.rol;
    this.nombre = this.otService.nombre;
    this.catalogosIniciales = this.otService.catalogos;
    this.catalogosIniciales.motivosRechazo = [];
    this.catalogosIniciales.tiposRechazo = [];
    this.operacion = this.inboundData.operacion;

    this.templatePayload = {
      num_ot: this.data.num_ot,
      status: '',
      orden: JSON.parse(JSON.stringify(this.data))
    };
    // TODO: Posible lugar
    this.templateObservacion = {
      "textoTipo": "INTRODUCIDO POR",
      "texto": "",
      "nombre": this.nombre,
      "fecha": '',
      "canDelete": false
    };

    switch (this.operacion) {
      case 'turnar':
        this.modalTitle = "Turnar";
        break;
      case 'rechazar':
        this.modalTitle = "Rechazar";
        this.templatePayload.status = 'rechazada';
        this.templateObservacion.textoTipo = "RECHAZO POR";
        break;
    }

    if (this.otService.tiposRechazo) {
      this.catalogosIniciales.tiposRechazo = this.otService.tiposRechazo;
    } else {

      this.spinner.showModal();
      this.catalogosApiService.getCatalogosRechazo(this.data.generales.lineaNegocio, "*").subscribe(
        (resultRechazo) => {
          this.spinner.hideModal();
          this.catalogosIniciales.tiposRechazo = resultRechazo.opciones;
          this.otService.setTiposRechazo = resultRechazo.opciones;
        }, (errorRechazo) => {
          this.spinner.hideModal();
          let errorData = {
            codigoHttp: errorRechazo.status,
            errorCatalogo: 'tiposRechazo',
            urlReload: location.href,
            endpoint: errorRechazo.url,
            operacion: this.operacion
          };
          this.genericModalService.showModal('errorConexion', errorData, null);
          this.dialogRef.close();
        });
    }

  }  // end ngOnInit()

  buildFrm(): void {
    this.formTurnar = this.fb.group({
      status: new FormControl('', [Validators.required]),
      charolaSeleccionada: new FormControl('', [Validators.required]),
      tipoRechazo: new FormControl('', [Validators.required]),
      motivoRechazo: new FormControl('', [Validators.required]),
      observacion: new FormControl('', [Validators.required])
    });
  }

  submit() {
    let currentDate = this.datePipe.transform(
      new Date(), environment.datePatternObs
    );
    this.templateObservacion.texto = this.formTurnar.get('observacion').value;
    this.templateObservacion.fecha = currentDate;

    switch (this.operacion) {
      case 'turnar':
        this.turnar();
        break;
      case 'rechazar':
        this.rechazar();
    }
  }

  /**
   * Crea el payload para turnar.
   */
  turnar() {
    this.data = this.utils.fixOT(this.data);
    this.templatePayload.status = this.formTurnar.get('status').value;

    var payload = JSON.parse(JSON.stringify(this.templatePayload));
    if (this.templatePayload.status === "charola_virtual") {
      payload.status = this.formTurnar.get('charolaSeleccionada').value;
    }

    if (this.templatePayload.status === "casos_esp" && this.rol === "figura_servicio") {
      this.genericModalService.showModal('solicitudCasosEsp', this.templateObservacion.texto, undefined);
      this.dialogRef.close();
      return;
    }
    payload = this.utils.filterNewObservations(payload);

    if (this.otService.watcherPoliza.changed) {
      payload.orden.observacionesInternas.unshift(this.otService.watcherPoliza.tatuaje());
    }
    if (this.templateObservacion.texto !== '') {
      payload.orden.observacionesInternas.unshift(this.templateObservacion);
    }

    payload = this.utils.deleteEmptyObservations(payload);
    this.enviarCambio(payload, 'turnar');

  }

  /**
   * Crea el payload para el rechazo.
   */
  rechazar() {
    this.data = this.utils.fixOT(this.data);

    var payload = JSON.parse(JSON.stringify(this.templatePayload))
    payload = this.utils.filterNewObservations(payload);
    if (this.otService.watcherPoliza.changed) {
      payload.orden.observacionesInternas.unshift(this.otService.watcherPoliza.tatuaje());
    }
    if (this.templateObservacion.texto !== '') {
      payload.orden.observacionesPortal.unshift({
        ...this.templateObservacion, correo: this.otService.correoUserLogged,
        medioEntrada: this.otService.portalEntrada
      });
    }
    payload.orden.meta = {
      tipoRechazo: this.formTurnar.get('tipoRechazo').value,  // get frm field
      motivoRechazo: this.formTurnar.get('motivoRechazo').value // get frm field
    };
    payload = this.utils.deleteEmptyObservations(payload);
    payload.status = 'rechazada';
    this.enviarCambio(payload, 'rechazar');
  }

  /**
   * hace el request de turnar o rechazar al backend.
   */
  enviarCambio(payload, tipOperacion) {
    this.spinner.show();
    this.dialogRef.addPanelClass('hidePanel');

    if (tipOperacion !== 'rechazar') {
      if (!this.showTurnarA) {
        payload['status'] = "";
      }
    }

    this.endpointsService.turnarOrden(payload, tipOperacion).subscribe(
      (response) => {
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');
        if (this.operacion === 'turnar') {
          this.genericModalService.showModal('generico', 'turnarExitoso', null);
        } else {
          this.otService.setSalir = '';
        }
        this.dialogRef.close();
      }, (error) => {
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');
        if (error.error.warning != undefined || error.error.warning != null) {
          this.genericModalService.showModal('generico', 'avisoErrorTurnar', { error: error.error.message });
        } else {
          this.genericModalService.showModal('generico', 'errorTurnando', { error: error.error.message });
        }
      });

  }

  /**
   * Poblar campos del formulario con data del backend.
   *
   * @param otData - Data del backend.
   *
  */
  populateFields(otData: GetOTResponseInterface) {
    this.data = otData;
    var nombreCompleto = this.data.datosContratante.nombre + " " + this.data.datosContratante.apellido1 + " " + this.data.datosContratante.apellido2;

    this.formTurnar.get('ot').setValue(this.data.num_ot);
    let poliza = this.data.generales.numeroPoliza ? this.data.generales.numeroPoliza : this.data.generales.numeroPrepoliza;
    this.formTurnar.get('poliza').setValue(poliza);
    this.formTurnar.get('da').setValue(this.data.datosAgente.da);
    this.formTurnar.get('cua').setValue(this.data.datosAgente.cua);
    this.formTurnar.get('nombreContratante').setValue(this.data.datosContratante.razonSocial || nombreCompleto);
    this.formTurnar.get('vigencia').setValue('');
    this.formTurnar.get('descripcionAut').setValue('');
    this.formTurnar.get('elementosAut').setValue('');

    // Recuperar el nombre y cargo del usuario en sesion (vienen del endpoint /endpoints/gmm/v1/getNombre)
    this.formTurnar.get('firma').setValue(this.otService.nombre);
    this.formTurnar.get('cargo').setValue(this.otService.cargo);

  }

  /**
   * Mostrar u ocultar el Motivo de Rechazo.
   *
   * @param tipoRechazo - Tipo de rechazo seleccionado.
   */
  getMotivosRechazo(tipoRechazo: any) {
    //Bandera para mostrar u ocultar el motivo de rechazo
    if (this.rol !== 'suscripcion_med' && this.rol !== 'gestion_med'
      && tipoRechazo.value !== '') {
      this.showMotivoRechazo = true;
    }

    this.formTurnar.get('motivoRechazo').enable();

    this.spinner.showModal();
    this.catalogosApiService.getCatalogosRechazo(this.data.generales.lineaNegocio, encodeURI(tipoRechazo.value)).subscribe(
      (resultRechazo) => {
        this.spinner.hideModal();
        this.catalogosIniciales.motivosRechazo = resultRechazo.data;
        if (this.catalogosIniciales.motivosRechazo < 1) {
          this.formTurnar.get('motivoRechazo').disable();
        }
      }, (errorRechazo) => {
        this.spinner.hideModal();
        this.catalogosIniciales.motivosRechazo = [];
        this.formTurnar.get('motivoRechazo').disable();
      });
  }

  /**
   * Cerrar el modal.
   */
  close() {
    this.dialogRef.close();
  }

  /**
   * Ordenar catalogo.
   *
   * @param cat - catalogo a ordenar.
   * @param prop - porpiedad por la que se ordenara el catalogo.
   *
   * @returns - El catalogo ordenado.
   */
  orderBy(cat: string, prop: string) {
    return this.catalogosIniciales[cat].sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }
}
