import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormMenuTypesEnum } from '@enums/form-menu-types.enum';

@Component({
    selector: 'app-form-menu',
    templateUrl: './form-menu.component.html',
    styleUrls: ['./form-menu.component.scss']
})
export class FormMenuComponent {
    @Output() applyFilter: EventEmitter<string>;
    @Output() resetFilter: EventEmitter<void>;
    @Input() title: string;
    @Input() placeholder: string;
    @Input() formType: FormMenuTypesEnum;
    filter: string;

    constructor() {
        this.applyFilter = new EventEmitter<string>();
        this.resetFilter = new EventEmitter<void>();
    }

    onApplyKey(): void {
        this.applyFilter.emit(this.filter);
        this.filter = null;
    }

    onResetFilter(): void {
        this.filter = null;
        this.resetFilter.emit();
    }

    setValue(value): void {
        this.filter = value;
    }
}
