import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./views/main/main.module').then((m) => m.MainModule)
    },
    {
        path: 'forbidden',
        loadChildren: () =>
            import('./views/forbidden/forbidden.module').then(
                (m) => m.ForbiddenModule
            )
    },
    {
        path: 'not-found',
        loadChildren: () =>
            import('./views/not-found/not-found.module').then(
                (m) => m.NotFoundModule
            )
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'not-found'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
