import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output, OnChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SearchAgentComponent } from '@components/modals/search-agent/search-agent.component';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { QueryParamsInterface } from '@interfaces/general.interface';
import { AgentsDataInterface, FolioAgentInterface } from '@interfaces/search-agent';
import { OtService } from '@services/ot/ot.service';
import { Observable, Subscription, of } from 'rxjs';

// Reglas de pantalla para bloquear campos en base al rol del usuario
import { REGLAS_PANTALLA as reglas_pantalla} from '@fixtures/nueva-ot.fixture';
import { environment } from '../../../environments/environment';
import { EventosScrollService } from '../../services/eventos-scroll/eventos-scroll.service';

@Component({
  selector: 'app-agent-data',
  templateUrl: './agent-data.component.html',
  styleUrls: ['./agent-data.component.scss']
})
export class AgentDataComponent implements OnInit, OnChanges  {
  // folio = new FormControl();
  formAgentData: FormGroup;
  agentDataSelected: AgentsDataInterface;

  @Output()
  agentData: EventEmitter<any>;
  outDataSubscription: Subscription;

  opcionesFolioAgente: FolioAgentInterface[] = [];
  opcionesFolioAgente$: Observable<any>;
  // Suscripcion a cambios en el formulario de datos del contratante
  subscriptionFormChanges: Subscription;
  // Suscripcion a OT Inicializada
  subscriptionOT: Subscription;

  // Suscripcion a lectura del rol del usuario.
  subscriptionRol: Subscription;

  @Input()
  urlParams: QueryParamsInterface;
  // Datos de la orden de trabajo
  @Input()
  data: GetOTResponseInterface;

  @Input()
  siga: boolean;

  responsable: boolean;

  // Bandera para saber si se debe mostrar la opcion de busqueda de agente.
  showSearchAgent: boolean;

  // Suscripcion a validacion de formulario.
  $validateForm: Subscription;
  // Variables para validar codigo y nombre de contratante
  flgFolioValido: boolean;
  flgCodigoValido: boolean;
  flgNombreValido: boolean;

  $changeAgentData: Subscription;

  constructor(
    private fb: FormBuilder,
    private readonly dialog: MatDialog,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private otService: OtService,
    private eventosScroll: EventosScrollService
) {
      iconRegistry.addSvgIcon('datos-agente-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/img/datos-agente.svg'));

      this.agentData = new EventEmitter<any>();
      this.outDataSubscription = new Subscription();
      this.subscriptionOT = new Subscription();
      this.subscriptionRol = new Subscription();
      this.$changeAgentData = new Subscription();
      this.responsable = false;
      this.showSearchAgent = false;
      this.flgFolioValido = true;
      this.flgCodigoValido = true;
      this.flgNombreValido = true;
  }

  ngOnInit(): void {
    this.formAgentData = this.fb.group({
      codigo: new FormControl({value: '', disabled: true}),
      nombre: new FormControl({value: '', disabled: true}),
      folio: new FormControl({value: ''}, Validators.required),
      da: new FormControl({value: '', disabled: true}),
      contrato: null,
      cua: null,
      estatus: null,
      productor: null,
      datosAgente: null
    });

    this.formAgentData.get("folio").valueChanges.subscribe((myValue) => {
      this.data.datosAgente.folio = myValue;
      this.updateOT(this.data);
    });

    // Cargar datos cuando sea una consulta de OT
    this.subscriptionOT = this.otService.initialized.subscribe(
      (otData) => {
          // LLego la data
          this.data = otData;

          if (this.data.responsable === this.urlParams.email) {
            this.responsable = true;
          } else {
            this.responsable = false;
            if (this.data.statusOrden === 'registro_da') {
                this.responsable = true;
            }
          }

        // Suscribir a cambios en el rol
        // Desactivar campos en base a las reglas, rol del usuario y data de la Orden de trabajo
        this.subscriptionRol = this.otService.rolInitialized.subscribe(
          (rol) => {
            if(this.urlParams.menu == environment.menu_portal_intermediarios) {
              if(rol != 'coordinacion_da') {
                this.populateFields(this.data);
              } else if(rol == 'coordinacion_da' && this.data.generales.tipoMovimiento == 'FUERZA PRODUCTORA') {
                this,this.showSearchAgent = true;
              }
            }
            if(rol != 'coordinacion_da' && this.urlParams.menu == environment.menu_portal_empleados) {
              this.populateFields(this.data);
            }

            if(this.data.num_ot) {
              this.populateFields(this.data);
            }
            
            this.disableFields(this.data, rol);
            this.formAgentData.get('folio').disable();
        });

      }
    );

    // Actualizat datos del agente al buscar una poliza
    this.subscriptionOT = this.otService.datosAgenteYContratante.subscribe(
      (datosAgenteYContratante) => {
          // LLego la data
          this.data = datosAgenteYContratante;

          this.opcionesFolioAgente = this.data.datosAgente.folio.map((folio: string) => (
            {
              valor: folio,
              texto: folio
            }
          ));
          this.opcionesFolioAgente$ = of(this.opcionesFolioAgente);
          this.formAgentData.get('folio').setValue(this.opcionesFolioAgente[0].valor);

          this.formAgentData.get('folio').markAsTouched();
          this.formAgentData.get('folio').updateValueAndValidity();
          this.populateFields(this.data);
          this.validateRequired();
          this.updateOT(this.data);
    });

    this.$validateForm = this.otService.validateFrm.subscribe((validate) => {
      this.validateRequired();
    });

    // inhabilitar busqueda de datos del agente
    this.$changeAgentData = this.otService.changeAgentData.subscribe((enable) => {
      this.showSearchAgent = enable;
    });
  }

  ngOnChanges() {
    if(this.siga){
      this.showSearchAgent = this.siga;
    }else{
      this.showSearchAgent = this.siga;
    }
  }


  /**
   * Actualiza los datos de la ot en el servicio compartido.
   *
   * @param otData - Datos a actualizar.
   */
   updateOT(otData: GetOTResponseInterface) {
    this.otService.setOt = otData;
  }

  /**
   * Poblar campos del formulario con data del backend.
   *
   * @param otData - Data del backend.
   *
   */
   populateFields(otData: GetOTResponseInterface) {
    this.data = otData;

    this.formAgentData.get('codigo').setValue(this.data.datosAgente.codigo);
    this.formAgentData.get('nombre').setValue(this.data.datosAgente.nombre);
    this.formAgentData.get('da').setValue(this.data.datosAgente.da);
    if(this.data.datosAgente.folio) {
      this.opcionesFolioAgente = [{
          valor: this.data.datosAgente.folio,
          texto: this.data.datosAgente.folio
        }
      ];
    }
    this.opcionesFolioAgente$ = of(this.opcionesFolioAgente);
    this.formAgentData.get('folio').setValue(this.data.datosAgente.folio);

    this.formAgentData.get('contrato').setValue(this.data.datosAgente.contrato);
    this.formAgentData.get('cua').setValue(this.data.datosAgente.cua);
    this.formAgentData.get('estatus').setValue(this.data.datosAgente.estatus);
    this.formAgentData.get('productor').setValue(this.data.datosAgente.productor);
    this.formAgentData.value.datosAgente = this.data.datosAgente;
    if(this.urlParams.orden_id) {
      this.validateRequired();
    }
  }

  /**
   * Inhabilitar campos del formulario en base a los datos de la OT y el rol del usuario.
   * @param data - Datos de la orden de trabajo.
   * @param rol  - Rol del usuario.
   */
   disableFields(data: any, rol: string) {
    if(this.opcionesFolioAgente.length >= 1) {
      this.verfTS();
    } else {
      this.formAgentData.get('folio').disable()
    }

    let _scope = {
      reglas_pantalla: reglas_pantalla,
      rol: rol,
      data: this.data,
      responsable: this.responsable,
      da_usuario: this.urlParams.da,
      stateParams: this.urlParams
    };
    this.showSearchAgent = this.otService.display('lupa_agente', 'enable', _scope);


    if (this.otService.isEmpleado  && this.otService.isConsulta) {
      if(data.status === 'En Registro' 
      || data.status === 'Cancelada Automática'
      || data.status === 'Cancelada Solicitante') {
      this.showSearchAgent = false;
      }
  }


   }

  openSearchAgent(): void {
    // Seteamos el titulo y descripcion del modal de confirmacion
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      urlParams: this.urlParams,
      data: this.data
    };

    this.eventosScroll.eventScroll();

    const dialogRef = this.dialog.open(SearchAgentComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      (output) => {
        if(output.value) {
          this.verfTS();
          this.opcionesFolioAgente = output.data.opcionesFolioAgente;
          this.opcionesFolioAgente$ = of(this.opcionesFolioAgente);
          this.agentDataSelected = output.data.agentDataSelected;
          this.formAgentData.get('codigo').setValue(this.agentDataSelected.codigo);
          this.formAgentData.get('nombre').setValue(this.agentDataSelected.nombre);
          this.formAgentData.get('da').setValue(this.agentDataSelected.da);
          this.formAgentData.get('folio').setValue(this.opcionesFolioAgente[0].valor);
          this.formAgentData.get('contrato').setValue(this.agentDataSelected.contrato);
          this.formAgentData.get('cua').setValue(this.agentDataSelected.cua);
          this.formAgentData.get('estatus').setValue(this.agentDataSelected.estatus);
          this.formAgentData.get('productor').setValue(this.agentDataSelected.productor);
          this.formAgentData.value.datosAgente = this.agentDataSelected;
          this.validateRequired();

          // Actualizar la data en la OT compartida
          this.data.datosAgente.codigo = this.agentDataSelected.codigo;
          this.data.datosAgente.folio = this.agentDataSelected.folios[0];
          this.data.datosAgente.nombre = this.agentDataSelected.nombre;
          this.data.datosAgente.da = this.agentDataSelected.da;
         // El folio lo debe seleccionar el usuario y ya esta programado
         // para que cuando cambie se actualice en la OT
          this.data.datosAgente.contrato = this.agentDataSelected.contrato;
          this.data.datosAgente.cua = this.agentDataSelected.cua;
          this.data.datosAgente.estatus = this.agentDataSelected.estatus;
          this.data.datosAgente.productor = this.agentDataSelected.productor;
          this.updateOT(this.data);
        }
      }
    );
  }

  verfTS(): void {
    if (this.otService.isConsulta) {
      this.formAgentData.get('folio').disable();
      return;
    }
    const tipoSolicitud = localStorage.getItem('tipo_solicitud');
    if(tipoSolicitud === 'nuevo_negocio' || tipoSolicitud === 'cambio_grupo_individual') {
      this.formAgentData.get('folio').enable();
    } else {
      this.formAgentData.get('folio').disable();
    }
  }


  /**
   * Valida los campos de codigo y nombre de persona
   */
   validateRequired(): void {
    this.flgFolioValido = true;
    this.flgCodigoValido = true;
    this.flgNombreValido = true;
    let folioValue = this.formAgentData.get('folio').value;
    let codigoValue = this.formAgentData.get('codigo').value;

    if(folioValue) {
      folioValue = folioValue.toString().trim();
      if(folioValue.length <= 0) {
        this.flgFolioValido = false;
      }
    } else {
      this.flgFolioValido = false;
    }

    if(codigoValue) {
      codigoValue = codigoValue.toString().trim();
      if(codigoValue.length <= 0) {
        this.flgCodigoValido = false;
      }
    } else {
      this.flgCodigoValido = false;
    }

    let nombreValue = this.formAgentData.get('nombre').value;
    if(nombreValue) {
      nombreValue = nombreValue.toString().trim();
      if(nombreValue.length <= 0) {
        this.flgNombreValido = false;
      }
    } else {
      this.flgNombreValido = false;
    }
    this.otService.updateValidation('frmagent', (this.flgFolioValido && this.flgCodigoValido && this.flgNombreValido));
  }

}
