import { EventEmitter, Injectable, Output } from '@angular/core';
import { ActualizaPersonaRequest } from '@interfaces/actualiza.persona.interface';
import { HttpService } from '@services/http/http.service';
import { Observable } from 'rxjs';
import { SearchPersonsRequestInterface, SearchPersonsInterface } from '../../interfaces/search-person';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/auth/auth.service';
import { environment } from '@environments/environment';

const endPoint = 'personas/';

@Injectable({
  providedIn: 'root'
})
export class PersonasService {

  @Output() personData: EventEmitter<any> = new EventEmitter();

  constructor(private readonly http: HttpService, private httpC: HttpClient,
    private authSrv: AuthService) {}

  searchPersons(data: SearchPersonsRequestInterface): Observable<SearchPersonsInterface> {
      return this.http.post(`${endPoint}buscar`, data);
  }

  personaBup(data: any): Observable<any> {
      return this.http.post(`${endPoint}personaBup`, data);
  }

  updatePerson(request: ActualizaPersonaRequest): Observable<any> {
      return this.http.post(`${endPoint}actualizaPersona`, request);
  }

  altaPersonas(data: any): Observable<any> {
    return this.httpC.post(`${endPoint}altaPersona`, data);
  }

  getFirmasCharola(num_ot: string): Observable<any> {
    const { email: usuario } = this.authSrv.getQuery();
    const payload = { num_ot, usuario };
    return this.http.post(
        `consultaendpoints/gmm/v1/getFirmasCharolas`,
        payload
    );
  }

  getFirmas(data: any): Observable<any> {
    return this.http.post(
        `consultaendpoints/gmm/v1/getFirmas`,
        data
    );
  }

  getFirmasRecuperar(numOt: string): Observable<any> {
    return this.http.get(
        `endpoints/gmm/v1/getFirmasRecuperar?num_ot=${numOt}`
    );
  }

  getEstaciones(num_ot: string): Observable<any> {
    const mail = localStorage.getItem('mail');

    return this.http.get(
        `consultaendpoints/gmm/v1/getEstaciones?asignacion_manual=false&num_ot=${num_ot}&usuario=${mail}`
    );
  }

  getAccionesInfo(): Observable<any> {
    return this.http.get(
        `endpoints/gmm/v1/getAccionesInfo`
    );
  }

  postRecuperarOT(data: Object): Observable<any> {
    return this.http.post(
        `endpoints/gmm/v1/recuperarOT`,
        data
    );
  }
}
