import { Component, OnInit, Inject, Input, AfterViewInit, DoCheck, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { UtilsService } from '@services/utils/utils.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiciosGnpService } from '@services/servicios-gnp/servicios-gnp.service';
import { RegimenCapital } from '@interfaces/regimen-capital';
import { RegimenFiscal } from '@interfaces/regimen-fiscal';
import { ModalPersonService } from '@services/modal-person/modal-person.service';
import { LoaderService } from '@services/loader/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-address-fiscal',
  templateUrl: './add-address-fiscal.component.html',
  styleUrls: ['./add-address-fiscal.component.scss']
})
export class AddAddressFiscalComponent implements OnInit , DoCheck , OnDestroy {

  @Input() esEdicion : boolean;
  @Input() formGeneralData: FormGroup;
  @Input() formParticular: FormGroup;
  @Output() flagValidateForm =  new EventEmitter<boolean>();
  @Output() addressFiscalValues = new EventEmitter<any>();
  checkPersonalDes : boolean;

  formAddressFiscal: FormGroup;

  subscription: Subscription;
  modalTitle: string = "Domicilio Fiscal";
  chkDireccionPersonalizada: boolean = false;
  tooltipTipoDomicilio: string;
  tooltipColonia: string;
  tooltipCalle : string = '';

  catalogosCP = {
    entidadesFederativas: [],
    municipios: [],
    colonias: []
  };

  paises = [];

  catRegimenCapital: RegimenCapital[] = [];
  catRegimenFiscal: RegimenFiscal[] = [];

  esMoral: Boolean = false;
  bloqueado : boolean = true;

  constructor(
    private fb: FormBuilder,
    public readonly utils: UtilsService,
    private serviciosGnpService: ServiciosGnpService,
    public readonly modalPersonService: ModalPersonService,
    @Inject(MAT_DIALOG_DATA) public inboundData: any,
    private readonly spinner: LoaderService) {
      this.buildFrm();
    }

  ngOnInit(): void {
    this.checkPersonalDes = false;
    this.buildFrm();
    this.loadCatalogs();
    this.validaPersonaMoral();
    this.actualizaCampoCapital();
    this.loadPaisesNuevo();
    this.loadPaisesViejo();
  }
  ngOnDestroy() {
    
    if(this.subscription != undefined && this.subscription != null){
      this.subscription.unsubscribe();
    }
    
  }


  validaPersonaMoral(){
    if(!this.esEdicion){
      this.esMoral = this.formGeneralData.get('tipoPersona').value == 'moral';
      this.formGeneralData.get('tipoPersona').valueChanges.subscribe((tipo) => {
        this.esMoral = tipo  == 'moral';
        this.actualizaCampoCapital();
        this.regimenFiscal();
      });
    } else {
        this.esMoral = this.modalPersonService.myPersonaBupData.tipo != "fisica";
    }
  }

  loadPaisesViejo(){
    if(this.inboundData?.catalogosIniciales?.paises != undefined ){
      this.paises = this.inboundData.catalogosIniciales.paises;
    }
  }

  ngDoCheck(){
    if(this.formAddressFiscal){
      if(this.validateForm()){
        this.flagValidateForm.emit(this.formAddressFiscal.valid);
        this.addressFiscalValues.emit(this.formAddressFiscal);
      }
      if(!this.esEdicion){
        if( this.formGeneralData.get('tipoPersona').value == 'moral'){
          this.formAddressFiscal.controls.nombreRazonSocial.setValue(this.formGeneralData.get('razonSocial').value);
        } else {
          this.formAddressFiscal.controls.nombreRazonSocial.setValue(this.formGeneralData.get('nombres').value + ' ' + this.formGeneralData.get('paterno').value + ' ' + this.formGeneralData.get('materno').value);
        }
        this.formAddressFiscal.controls.rfc.setValue(this.formGeneralData.get('rfc').value);
      }
    }
  }

  actualizaCampoCapital(){
    if(this.esMoral) {
      this.formAddressFiscal.get('regimenCapital').setValidators(Validators.required);
    } else {
      this.formAddressFiscal.get('regimenCapital').setValidators(null);
    }
    this.formAddressFiscal.get('regimenCapital').setValue("");
    this.formAddressFiscal.get('regimenCapital').updateValueAndValidity();
    this.formAddressFiscal.get('regimenCapital').markAsTouched();
  }

bagregarDatosFiscales(event:any) {
    if(!event.value){
      this.formAddressFiscal.get('mismaDireccionParticular').setValue(false);
    }
}

bmostrarDir(event:any) {

  if(event.value){
    this.cargarParticular();
    this.formAddressFiscal.controls['calle'].disable();
    this.formAddressFiscal.controls['numeroExterior'].disable();
    this.formAddressFiscal.controls['numeroInterior'].disable();
    this.formAddressFiscal.controls['codigoPostal'].disable();
    this.formAddressFiscal.controls['pais'].disable();
    this.formAddressFiscal.controls['entidadFederativa'].disable();
    this.formAddressFiscal.controls['municipio'].disable();
    this.formAddressFiscal.controls['colonia'].disable();
    this.formAddressFiscal.controls['coloniaCustom'].disable();
    this.checkPersonalDes = true;
  } else {
    this.limpiarCampos();
    if(this.subscription != undefined && this.subscription != null){
      this.subscription.unsubscribe();
    }
    this.formAddressFiscal.controls['calle'].enable();
    this.formAddressFiscal.controls['numeroExterior'].enable();
    this.formAddressFiscal.controls['numeroInterior'].enable();
    this.formAddressFiscal.controls['codigoPostal'].enable();
    this.formAddressFiscal.controls['pais'].enable();
    this.formAddressFiscal.controls['entidadFederativa'].enable();
    this.formAddressFiscal.controls['municipio'].enable();
    this.formAddressFiscal.controls['colonia'].enable();
    this.formAddressFiscal.controls['coloniaCustom'].enable();
    this.formAddressFiscal.updateValueAndValidity();
    this.checkPersonalDes = false;
  }
  this.actualizaCampoCapital();

}


  asignarValoresFiscal(dataResponse){
    this.formAddressFiscal.get('codigoPostal').setValue(dataResponse.datosFiscales.cpFiscal);
    this.formAddressFiscal.get('codigoPostal').setValidators(null);
    this.formAddressFiscal.get('calle').setValue(dataResponse.datosFiscales.calle);
    this.formAddressFiscal.get('calle').setValidators(null);
    this.formAddressFiscal.get('numeroExterior').setValue(dataResponse.datosFiscales.numeroExterior);
    this.formAddressFiscal.get('numeroExterior').setValidators(null);
    this.formAddressFiscal.get('numeroInterior').setValue(dataResponse.datosFiscales.numeroInterior);
    this.formAddressFiscal.get('pais').setValue("MEX");

    // Se valida cveEstado nula
    if( ["",undefined,null].includes(dataResponse.datosFiscales.cveEstado)){
        this.catalogosCP.entidadesFederativas = [{texto: dataResponse.datosFiscales.estado , valor : '0' }];
        dataResponse.datosFiscales.cveEstado = '0';
    } 
    this.formAddressFiscal.get('entidadFederativa').setValue(dataResponse.datosFiscales.cveEstado);
    this.formAddressFiscal.get('estadoNombre').setValue(dataResponse.datosFiscales.estado);
    this.formAddressFiscal.get('entidadFederativa').setValidators(null);
    
    // Se valida cveMunicipio nula
    if( ["",undefined,null].includes(dataResponse.datosFiscales.cveMunicipio)){
      this.catalogosCP.municipios =  [{ texto: dataResponse.datosFiscales.municipio , valor : '0' }];
      dataResponse.datosFiscales.cveMunicipio = '0';
    } 

    this.formAddressFiscal.get('municipio').setValue(dataResponse.datosFiscales.cveMunicipio);
    this.formAddressFiscal.get('municipioNombre').setValue(dataResponse.datosFiscales.municipio);
    this.formAddressFiscal.get('municipio').setValidators(null);

    // Se valida cveColonia nula.
    if(!["",null,undefined].includes(dataResponse.datosFiscales.cveColonia)){
      this.setDireccionPersonalizada({
        checked : false
      });
      this.chkDireccionPersonalizada = false;
      this.formAddressFiscal.get('chkDireccionPersonalizada').setValue(false);
    } else {
      this.setDireccionPersonalizada({
        checked : true
      });
      this.chkDireccionPersonalizada = true;
      this.formAddressFiscal.get('chkDireccionPersonalizada').setValue(true);
    }
    this.formAddressFiscal.get('colonia').setValue(dataResponse.datosFiscales.cveColonia);
    this.formAddressFiscal.get('coloniaCustom').setValue(dataResponse.datosFiscales.colonia);
    this.formAddressFiscal.get('coloniaNombre').setValue(dataResponse.datosFiscales.colonia);
    this.formAddressFiscal.get('colonia').setValidators(null);
    this.formAddressFiscal.get('coloniaCustom').setValidators(null);
    
    this.formAddressFiscal.get('regimenFiscal').setValue(dataResponse.datosFiscales.cveRegimenFiscalSat);

    this.formAddressFiscal.get('fiscalNombre').setValue(this.catRegimenFiscal.find(element => 
      element.id == dataResponse.datosFiscales.cveRegimenFiscalSat).valor);
  
    this.esMoral = this.modalPersonService.myPersonaBupData.tipo != "fisica";
              
    if(this.esMoral && dataResponse.datosFiscales.regimenCapital != ""){
        this.formAddressFiscal.get('regimenCapital').setValue(dataResponse.datosFiscales.regimenCapital);
        this.formAddressFiscal.get('capitalNombre').setValue(this.catRegimenCapital.find(element => 
          element.id == dataResponse.datosFiscales.regimenCapital).valor);
    }
              
    this.flagValidateForm.emit(this.formAddressFiscal.valid);
    this.addressFiscalValues.emit(this.formAddressFiscal);
  
    if(![null,undefined,""].includes(dataResponse.datosFiscales.cpFiscal) && dataResponse.datosFiscales.cpFiscal.length >= 5 ){
      this.formAddressFiscal.disable();
      this.checkPersonalDes = true;
    }
              
    this.formAddressFiscal.updateValueAndValidity(); 
  }


  obtenerDireccionFiscal(){

    if(this.esEdicion){
      this.serviciosGnpService.getDireccionFisica(this.modalPersonService.personaBupData.codigoFiliacion).subscribe(
        (dataResponse) => {
          
          if(!dataResponse.error && dataResponse.datosFiscales){

            this.tooltipCalle = dataResponse.datosFiscales.calle;
            this.formAddressFiscal.get('enviarDireccionFiscal').setValue(true);
            this.formAddressFiscal.get('mismaDireccionParticular').setValue(false);
            this.formAddressFiscal.get('esDireccionCargada').setValue(true);
            
            
            if(![null,undefined,""].includes(dataResponse.datosFiscales.cpFiscal) && dataResponse.datosFiscales.cpFiscal.length >= 5 ){
              this.formAddressFiscal.get('codigoPostal').setValue(dataResponse.datosFiscales.cpFiscal);
              this.loadCatalogsByCPPrecarga(dataResponse.datosFiscales.cpFiscal,true,dataResponse);
            } else {
              this.asignarValoresFiscal(dataResponse);
            }  
          }

        }, (error) => {
          this.spinner.hideModal();
        }
      );
    }
  }


  loadCatalogs(){
    this.regimenCapital();
    this.regimenFiscal();
  }

  regimenCapital(){
    this.serviciosGnpService.getRegimenCapital().subscribe(
      (dataResponse) => {
       console.log(dataResponse);

       for(let elemento in dataResponse){
        this.catRegimenCapital.push(new RegimenCapital(elemento,dataResponse[elemento]));
       }
      }, (error) => {
        this.spinner.hideModal();
      }
    );
  }


  cargarParticular(){
    // Asignar los valores de la dirección particular.
    this.asignarValoresCasa();

    // Escuchar los cambios de la dirección particular.
    this.agregarListeners();
  }

  asignarValoresCasa(){
    const valorCP = this.formParticular.get('codigoPostal').value;
    this.formAddressFiscal.get('codigoPostal').setValue(valorCP);
    this.formAddressFiscal.get('chkDireccionPersonalizada').setValue(this.formParticular.get('chkDireccionPersonalizada').value);
    this.formAddressFiscal.get('calle').setValue(this.formParticular.get('calle').value);
    this.formAddressFiscal.get('numeroExterior').setValue(this.formParticular.get('numeroExterior').value);
    this.formAddressFiscal.get('numeroInterior').setValue(this.formParticular.get('numeroInterior').value);
    this.formAddressFiscal.get('pais').setValue(this.formParticular.get('pais').value);
    this.formAddressFiscal.get('colonia').setValue(this.formParticular.get('colonia').value);
    this.formAddressFiscal.get('coloniaCustom').setValue(this.formParticular.get('coloniaCustom').value);
    this.formAddressFiscal.get('entidadFederativa').setValue(this.formParticular.get('entidadFederativa').value);
    this.formAddressFiscal.get('municipio').setValue(this.formParticular.get('municipio').value);

    this.loadCatalogsByCPPrecarga(valorCP,false,null);

  };

  agregarListeners(){

    this.subscription = this.formParticular.valueChanges.subscribe((value)=>{
      this.formAddressFiscal.get('codigoPostal').setValue(value['codigoPostal']);
      this.formAddressFiscal.get('chkDireccionPersonalizada').setValue(this.formParticular.get('chkDireccionPersonalizada').value);
      this.formAddressFiscal.get('calle').setValue(value['calle']);
      this.formAddressFiscal.get('numeroExterior').setValue(value['numeroExterior']);
      this.formAddressFiscal.get('numeroInterior').setValue(value['numeroInterior']);
      this.formAddressFiscal.get('pais').setValue(value['pais']);
      this.formAddressFiscal.get('colonia').setValue(value['colonia']);
      this.formAddressFiscal.get('coloniaCustom').setValue(value['coloniaCustom']);
      this.formAddressFiscal.get('entidadFederativa').setValue(value['entidadFederativa']);
      this.formAddressFiscal.get('municipio').setValue(this.formParticular.get('municipio').value);

      this.loadCatalogsByCPPrecarga(value['codigoPostal'],false,value);

    });

  }

  loadPaisesNuevo() {
    const request = { opcion: 'alta' };
    this.spinner.showModal();
    this.serviciosGnpService.loadCatalogosRegistration(request).subscribe(
      (dataResponse) => {
          this.spinner.hideModal();
          for(const cat of  dataResponse.catalogos) {
            switch (cat.nombre) {
              case 'PAIS':
                this.paises = cat.valores;
                  break;
            }
          }
      }, (error) => {
        this.spinner.hideModal();
      }
    );
  }



  limpiarCampos(){

    this.formAddressFiscal.get('regimenFiscal').setValue('');
    this.formAddressFiscal.get('calle').setValue('');
    this.formAddressFiscal.get('numeroExterior').setValue('');
    this.formAddressFiscal.get('numeroInterior').setValue('');
    this.formAddressFiscal.get('codigoPostal').setValue(this.inboundData.domicilio ? this.inboundData.domicilio.codigoPostal : '');
    this.formAddressFiscal.get('pais').setValue(this.inboundData.domicilio ? this.inboundData.domicilio.pais : 'MEX');
    this.formAddressFiscal.get('entidadFederativa').setValue(this.inboundData.domicilio ? this.inboundData.domicilio.entidadFederativa : '');
    this.formAddressFiscal.get('municipio').setValue(this.inboundData.domicilio ? this.inboundData.domicilio.municipio : '');
    this.formAddressFiscal.get('colonia').setValue(this.inboundData.domicilio ? this.inboundData.domicilio.colonia : '');
    this.formAddressFiscal.get('coloniaCustom').setValue(this.inboundData.domicilio ? this.inboundData.domicilio.coloniaCustom : '');
    this.formAddressFiscal.get('chkDireccionPersonalizada').setValue(this.chkDireccionPersonalizada);
    this.formAddressFiscal.get('coloniaNombre').setValue('');
    this.formAddressFiscal.get('municipioNombre').setValue('');
    this.formAddressFiscal.get('estadoNombre').setValue('');
    this.formAddressFiscal.get('fiscalNombre').setValue('');
    this.formAddressFiscal.get('capitalNombre').setValue('');

  }

  buildFrm(): void {

    this.formAddressFiscal = this.fb.group({
      enviarDireccionFiscal : new FormControl(false, Validators.required),
      mismaDireccionParticular : new FormControl(false, Validators.required),
      nombreRazonSocial: new FormControl({ value: this.modalPersonService.myPersonaBupData ? this.modalPersonService.myPersonaBupData.nombreCompleto : null, disabled: true}),
      rfc: new FormControl({ value: this.modalPersonService.myPersonaBupData ?  this.modalPersonService.myPersonaBupData.rfc: '', disabled: true}),
      regimenFiscal: new FormControl('', Validators.required),
      regimenCapital: new FormControl(''),
      calle: new FormControl('', Validators.required),
      numeroExterior: new FormControl('', Validators.required),
      numeroInterior: new FormControl(''),
      codigoPostal: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.codigoPostal : '', Validators.required),
      pais: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.pais : 'MEX', Validators.required),
      entidadFederativa: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.entidadFederativa : '', Validators.required),
      municipio: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.municipio : '', Validators.required),
      colonia: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.colonia : '', Validators.required),
      coloniaCustom: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.coloniaCustom : '', Validators.required),
      chkDireccionPersonalizada: new FormControl(this.chkDireccionPersonalizada),
      coloniaNombre: new FormControl(''),
      municipioNombre: new FormControl(''),
      estadoNombre: new FormControl(''),
      fiscalNombre: new FormControl(''),
      capitalNombre: new FormControl(''),
      esDireccionCargada : new FormControl(false)
    });

  }

  regimenFiscal(){
    
    if(!this.esEdicion){
      this.esMoral = this.formGeneralData.get('tipoPersona').value == 'moral';
    } else {
        this.esMoral = this.modalPersonService.myPersonaBupData.tipo != "fisica";
    }
    
    this.serviciosGnpService.getRegimenFiscal(this.getValuePerson(this.esMoral)).subscribe(
      (data) => {
        console.log(data);

        this.catRegimenFiscal = [];
        for(let elemento in data){
         this.catRegimenFiscal.push(new RegimenFiscal(elemento,data[elemento]));
        }

        this.obtenerDireccionFiscal();

       }, (error) => {
        console.error('Ocurrio un error!: ', error);
       }
    );
  }

  private getValuePerson(esMoral: Boolean): string{
    return esMoral == true ? 'm':'f';
  }

  loadCatalogsByCPPrecarga(cp: any, esServicio: boolean , valores: any ){
    const cpInput: string = cp;
    if (cpInput && cpInput.toString().length !== 5) {
      this.formAddressFiscal.get('codigoPostal').setErrors({minlength: true});
      this.catalogosCP = {
        entidadesFederativas: [],
        municipios: [],
        colonias: []
      };
      return;
    }

    if (cpInput && cpInput.toString().length === 5) {
      const request = { cp: cpInput };
      this.spinner.showModal();
      this.serviciosGnpService.loadCatalogosByCP(request).subscribe(
        (dataResponse) => {
          this.spinner.hideModal();
            for(const cat of  dataResponse.catalogos) {
              switch (cat.nombre) {
                case 'ESTADO_REPUBLICA':
                    this.catalogosCP.entidadesFederativas = cat.valores;
                    break;
                case 'MUNICIPIO':
                    this.catalogosCP.municipios = cat.valores;
                    break;
                case 'COLONIA':
                    this.catalogosCP.colonias = cat.valores;
                    break;
              }
            }

            if(esServicio){

              this.asignarValoresFiscal(valores);

            } else {
              if(valores != null){

                this.formAddressFiscal.get('entidadFederativa').setValue(valores['entidadFederativa']);
                this.formAddressFiscal.get('municipio').setValue(valores['municipio']);
                this.formAddressFiscal.get('colonia').setValue(valores['colonia']);
                this.formAddressFiscal.get('coloniaCustom').setValue(valores['coloniaCustom']);
                this.formAddressFiscal.get('chkDireccionPersonalizada').setValue(valores['chkDireccionPersonalizada']);
                this.chkDireccionPersonalizada = this.formAddressFiscal.get('chkDireccionPersonalizada').value;
                this.formAddressFiscal.get('estadoNombre').setValue(this.catalogosCP.entidadesFederativas[0].texto);
                this.formAddressFiscal.get('municipioNombre').setValue(this.catalogosCP.municipios[0].texto);
                this.formAddressFiscal.get('coloniaNombre').setValue(valores['coloniaNombre']);
                this.formAddressFiscal.get('municipio').setValue(this.catalogosCP.municipios[0].valor);
                
                if (this.catalogosCP.colonias.length === 1 )  {
                  this.formAddressFiscal.controls['colonia'].setValue(this.catalogosCP.colonias[0].valor);
                  this.formAddressFiscal.controls['coloniaNombre'].setValue(this.catalogosCP.colonias[0].texto);
                } else {
                  if([null,"",undefined].includes(valores['coloniaNombre']) && 
                  this.catalogosCP.colonias.length > 1 && !valores['chkDireccionPersonalizada']){
                    this.formAddressFiscal.controls['coloniaNombre'].setValue(
                      this.catalogosCP.colonias.find(element => 
                        element.valor == valores['colonia']).texto);
                  }
                }

              } else {

                this.formAddressFiscal.get('entidadFederativa').setValue(this.formParticular.get('entidadFederativa').value);
                this.formAddressFiscal.get('municipio').setValue(this.formParticular.get('municipio').value);
                this.formAddressFiscal.get('colonia').setValue(this.formParticular.get('colonia').value);
                this.formAddressFiscal.get('coloniaCustom').setValue(this.formParticular.get('coloniaCustom').value);
                this.formAddressFiscal.get('coloniaNombre').setValue(this.formParticular.get('coloniaNombre').value);
                this.formAddressFiscal.get('estadoNombre').setValue(this.catalogosCP.entidadesFederativas[0].texto);
                this.formAddressFiscal.get('municipioNombre').setValue(this.catalogosCP.municipios[0].texto);
                this.formAddressFiscal.get('entidadFederativa').setValue(this.formParticular.get('entidadFederativa').value);
                this.actualizaDireccionPersonalizada(this.formParticular.get('chkDireccionPersonalizada').value);

                if (this.catalogosCP.colonias.length === 1 )  {
                  this.formAddressFiscal.controls['colonia'].setValue(this.catalogosCP.colonias[0].valor);
                  this.formAddressFiscal.controls['coloniaNombre'].setValue(this.catalogosCP.colonias[0].texto);
                } else {
                  if([null,"",undefined].includes(this.formParticular.get('coloniaNombre').value) && 
                  this.catalogosCP.colonias.length > 1 && 
                  !this.formParticular.get('chkDireccionPersonalizada').value){
                    this.formAddressFiscal.controls['coloniaNombre'].setValue(
                      this.catalogosCP.colonias.find(element => 
                        element.valor == this.formParticular.get('colonia').value).texto);
                  }
                }
              }
            }
           
        }, (error) => {
          this.spinner.hideModal();
          this.formAddressFiscal.get('codigoPostal').setErrors({notfound: true});
        }
      );
    }
  }

  /**
   * Carga los catalogos relacionados con un CP.
   */
   loadCatalogsByCP(cp: any) {
    const cpInput: string = cp;
    if (cpInput && cpInput.toString().length !== 5) {
      this.formAddressFiscal.get('codigoPostal').setErrors({minlength: true});
      this.catalogosCP = {
        entidadesFederativas: [],
        municipios: [],
        colonias: []
      };
      return;
    }

    if (cpInput && cpInput.toString().length === 5) {
      const request = { cp: cpInput };
      this.spinner.showModal();
      this.serviciosGnpService.loadCatalogosByCP(request).subscribe(
        (dataResponse) => {
          this.spinner.hideModal();
            for(const cat of  dataResponse.catalogos) {
              switch (cat.nombre) {
                case 'ESTADO_REPUBLICA':
                    this.catalogosCP.entidadesFederativas = cat.valores;
                    break;
                case 'MUNICIPIO':
                    this.catalogosCP.municipios = cat.valores;
                    break;
                case 'COLONIA':
                    this.catalogosCP.colonias = cat.valores;
                    break;
              }
            }
            // Seleccionar la primera opcion
            this.formAddressFiscal.controls['entidadFederativa'].setValue(this.catalogosCP.entidadesFederativas[0].valor);
            this.formAddressFiscal.controls['estadoNombre'].setValue(this.catalogosCP.entidadesFederativas[0].texto);
            this.formAddressFiscal.controls['municipio'].setValue(this.catalogosCP.municipios[0].valor);
            this.formAddressFiscal.controls['municipioNombre'].setValue(this.catalogosCP.municipios[0].texto);
            if (this.catalogosCP.colonias.length === 1) {
              this.formAddressFiscal.controls['colonia'].setValue(this.catalogosCP.colonias[0].valor);
              this.formAddressFiscal.controls['coloniaNombre'].setValue(this.catalogosCP.colonias[0].texto);
            }
        }, (error) => {
          this.spinner.hideModal();
          this.formAddressFiscal.get('codigoPostal').setErrors({notfound: true});
        }
      );
    }

  }

  /**
   * Check para colonia personalizada.
   * @param chk Check
   */
   setDireccionPersonalizada(evt) {
    if(evt.checked) {
      this.formAddressFiscal.get('coloniaCustom').setValidators(Validators.required);
      this.formAddressFiscal.get('colonia').setValidators(null);
    } else {
      this.formAddressFiscal.get('colonia').setValidators(Validators.required);
      this.formAddressFiscal.get('coloniaCustom').setValidators(null);
    }
    this.formAddressFiscal.get('coloniaCustom').updateValueAndValidity();
    this.formAddressFiscal.get('colonia').updateValueAndValidity();
    this.setDireccionPersonalizadaDos(evt);
  }

     setDireccionPersonalizadaDos(chk) {
      this.chkDireccionPersonalizada = !this.chkDireccionPersonalizada;
      if(this.chkDireccionPersonalizada) {
        this.tooltipColonia = this.formAddressFiscal.value.coloniaCustom;
      } else {
        this.tooltipColonia = '';
      }
      this.formAddressFiscal.get('chkDireccionPersonalizada').setValue(chk.checked);
      this.formAddressFiscal.get('chkDireccionPersonalizada').updateValueAndValidity();
    }

    validateForm() {
      this.formAddressFiscal.get('nombreRazonSocial').markAsTouched();
      this.formAddressFiscal.get('nombreRazonSocial').updateValueAndValidity();

      this.formAddressFiscal.get('rfc').markAsTouched();
      this.formAddressFiscal.get('rfc').updateValueAndValidity();

      this.formAddressFiscal.get('regimenFiscal').markAsTouched();
      this.formAddressFiscal.get('regimenFiscal').updateValueAndValidity();

      this.formAddressFiscal.get('regimenCapital').markAsTouched();
      this.formAddressFiscal.get('regimenCapital').updateValueAndValidity();

      this.formAddressFiscal.get('calle').markAsTouched();
      this.formAddressFiscal.get('calle').updateValueAndValidity();

      this.formAddressFiscal.get('numeroExterior').markAsTouched();
      this.formAddressFiscal.get('numeroExterior').updateValueAndValidity();

      this.formAddressFiscal.get('numeroInterior').markAsTouched();
      this.formAddressFiscal.get('numeroInterior').updateValueAndValidity();

      this.formAddressFiscal.get('codigoPostal').markAsTouched();
      this.formAddressFiscal.get('codigoPostal').updateValueAndValidity();

      this.formAddressFiscal.get('pais').markAsTouched();
      this.formAddressFiscal.get('pais').updateValueAndValidity();

      this.formAddressFiscal.get('entidadFederativa').markAsTouched();
      this.formAddressFiscal.get('entidadFederativa').updateValueAndValidity();

      this.formAddressFiscal.get('municipio').markAsTouched();
      this.formAddressFiscal.get('municipio').updateValueAndValidity();

      if(this.chkDireccionPersonalizada) {
        this.formAddressFiscal.get('coloniaCustom').setValidators(Validators.required);
        this.formAddressFiscal.get('coloniaCustom').markAsTouched();
        this.formAddressFiscal.get('coloniaCustom').updateValueAndValidity();
        this.formAddressFiscal.get('colonia').setValidators(null);
        this.formAddressFiscal.get('colonia').markAsTouched();
        this.formAddressFiscal.get('colonia').updateValueAndValidity();
      } else {
        this.formAddressFiscal.get('colonia').setValidators(Validators.required);
        this.formAddressFiscal.get('colonia').markAsTouched();
        this.formAddressFiscal.get('colonia').updateValueAndValidity();
        this.formAddressFiscal.get('coloniaCustom').setValidators(null);
        this.formAddressFiscal.get('coloniaCustom').markAsTouched();
        this.formAddressFiscal.get('coloniaCustom').updateValueAndValidity();
      }

      this.formAddressFiscal.updateValueAndValidity();
      const formValid =  this.formAddressFiscal.status == 'VALID';
      return formValid;
    }

    setText(flag: number, valor: any){
      switch(flag){
        case 1:
          this.formAddressFiscal.controls['coloniaNombre'].setValue(valor.source.selected.viewValue);
        break;
        case 2:
          this.formAddressFiscal.controls['municipioNombre'].setValue(valor.source.selected.viewValue);
        break;
        case 3:
          this.formAddressFiscal.controls['estadoNombre'].setValue(valor.source.selected.viewValue);
          break;
        case 4:
          this.formAddressFiscal.controls['fiscalNombre'].setValue(valor.source.selected.viewValue);
          break;
        case 5:
          this.formAddressFiscal.controls['capitalNombre'].setValue(valor.source.selected.viewValue);
        break;
      }
    }

    actualizaDireccionPersonalizada(check: boolean): void{

      if(check){
        this.chkDireccionPersonalizada = true;
        this.formAddressFiscal.get('colonia').setValue(this.formParticular.get('coloniaCustom').value);
      } else {
        this.chkDireccionPersonalizada = false;
        this.formAddressFiscal.get('colonia').setValue(this.formParticular.get('colonia').value);
      }

    }

}
