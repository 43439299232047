import { Component,Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TableActionsInterface, TableColumnsInterface } from '@interfaces/table.interface';
import { ModalService } from '@services/modal/modal.service';
import { Subscription } from 'rxjs';
import { TableColumnsFixture, TableActionsFixture  } from "@fixtures/table.fixture";
import { AgentsDataInterface, FolioAgentInterface, SearchAgentsRequestInterface } from '@interfaces/search-agent';
import { ServiciosGnpService } from '@services/servicios-gnp/servicios-gnp.service';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalActionResponseInterface } from '@interfaces/modal-buttons.interface';
import { LoaderService } from '@services/loader/loader.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { UtilsService } from '@services/utils/utils.service';

@Component({
  selector: 'app-search-agent',
  templateUrl: './search-agent.component.html',
  styleUrls: ['./search-agent.component.scss']
})
export class SearchAgentComponent implements OnInit {
  modalTitle: string = "Buscar Intermediarios";
  formSearchAgent: FormGroup;

  // Datos para el resultado de la busqueda
  agentsData: AgentsDataInterface[] = [];
  agentDataSelected: AgentsDataInterface;
  opcionesFolioAgente: FolioAgentInterface[] = [];

  columns: Array<TableColumnsInterface>;
  actions: Array<TableActionsInterface>;

  criteriosBusqueda: any[] = [
    {value: 'clave', viewValue: 'Código de Intermediario'},
    {value: 'nombre', viewValue: 'Nombre(s)'}
  ];
  tiposPersona: any[] = [
    {value: 'moral', viewValue: 'Moral'},
    {value: 'fisica', viewValue: 'Fisica'}
  ];

  criterioBusquedaSelected: string = '';
  subscription: Subscription;
  constructor(
    private fb: FormBuilder,
    public readonly modalService: ModalService,
    private serviciosGnpService: ServiciosGnpService,
    @Inject(MAT_DIALOG_DATA) public inboundData: any,
    public dialogRef: MatDialogRef<SearchAgentComponent>,
    private readonly spinner: LoaderService,
    private genericModalService: GenericModalService,
    public readonly utils: UtilsService) {
      this.subscription = new Subscription();
      this.columns = TableColumnsFixture;
      this.actions = TableActionsFixture;
  }

  ngOnInit(): void {
    this.buildFrm();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  buildFrm(): void {
    // Se establecen los valores iniciales
    this.formInit();

    // Suscripción de los cambios del form
    this.onChangesForm();
    // Actualizar valores en la suscripción del modal
    this.updateModalData();
  }

  formInit(typeForm?: string, statusPersona?: string): void {
    let form: any = {
      criterioBusqueda: new FormControl(this.criterioBusquedaSelected, Validators.required),
      statusPersona: new FormControl(statusPersona, Validators.required)
    };
    switch (typeForm) {
      case 'clave':
        form = {
          criterioBusqueda: new FormControl(this.criterioBusquedaSelected, Validators.required),
          clave:  new FormControl('', Validators.required)
        };
      break;
      case 'fisica':
        form = {
          ...form,
          paterno: new FormControl('', Validators.required),
          materno: new FormControl('', Validators.required),
          nombre: new FormControl('', Validators.required),
        };
      break;
      case 'moral':
        form = {
          ...form,
          razonSocial:  new FormControl('', Validators.required)
        };
      break;
    }
    this.formSearchAgent = this.fb.group(form);
  }

  selectCriterioBusqueda(): void {
    const criterioBusqueda = this.formSearchAgent.value.criterioBusqueda;
    this.criterioBusquedaSelected = criterioBusqueda;
    const claveExist = criterioBusqueda === 'clave';
    if (claveExist) {
      this.formSearchAgent.controls['statusPersona'].patchValue('');
    }
    const statusPersona = this.formSearchAgent.value.statusPersona;
    const typeForm = statusPersona ? statusPersona : criterioBusqueda;
    const persona = !claveExist ? statusPersona : '';
    this.formInit(typeForm, persona);
    this.agentsData = [];
  }

  onChangesForm(): void {
    this.subscription = this.formSearchAgent.valueChanges.subscribe(() =>
        // Actualizar valores en la suscripción del modal
        this.updateModalData()
    );
  }

  updateModalData(): void {
    this.modalService.modalData.next({
        values: this.formSearchAgent.value,
        valid: this.formSearchAgent.valid
    });
  }

  public searchAgents(): boolean {
    const request: SearchAgentsRequestInterface = {
      criterio: this.formSearchAgent.value.criterioBusqueda,
      ramo: 'gmm'
    };
    if (this.inboundData.urlParams.da) {
      request.da = JSON.parse(this.inboundData.urlParams.da)[0].trim();
    }
    const tipoSolicitudParam = this.inboundData.data.generales.tipoSolicitud;
    request.validar = tipoSolicitudParam === 'nuevo_negocio' || tipoSolicitudParam === 'cambio_grupo_individual';

    if (this.formSearchAgent.value.criterioBusqueda === 'clave') {
      request.clave = this.formSearchAgent.value.clave;
    } else {
      request.persona = this.formSearchAgent.value.statusPersona;
      if (this.formSearchAgent.value.statusPersona === 'moral') {
        request.razonSocial = this.formSearchAgent.value.razonSocial;
      } else {
        request.paterno = this.formSearchAgent.value.paterno;
        request.materno = this.formSearchAgent.value.materno;
        request.nombre = this.formSearchAgent.value.nombre;
      }
    }

    // Codigo Valido: 0053411001,  0090246001
    this.spinner.showModal();
    this.serviciosGnpService.searchAgents(request).subscribe(
        (dataResponse) => {
            this.spinner.hideModal();
            this.agentsData = dataResponse.agentes;
            if (this.agentsData.length < 1) {
              this.genericModalService.showModal('generico', "intermediarios", {urlParams: this.inboundData.urlParams, data: this.inboundData.data});
              this.dialogRef.close();
            }

        }, (errorResponse) =>{
          this.spinner.hideModal();
          this.genericModalService.showModal('generico', "errorIntermediarios", { error: errorResponse.error.mensaje });
          this.dialogRef.close();
        }
    );
    return false;
  }

  selectedActionHandler(evt: AgentsDataInterface) {
    this.agentDataSelected = evt;
    this.updateModalData();
    let opcionesFolio: any = [];
    for (let entry of this.agentDataSelected.folios) {
      opcionesFolio.push({valor: entry, texto: entry });
    }
    this.opcionesFolioAgente = opcionesFolio;
  }

  closeModal(value: any): void {
    const response: ModalActionResponseInterface = {
        value,
        data: {
          agentDataSelected: value ? this.agentDataSelected: null,
          opcionesFolioAgente: this.opcionesFolioAgente
        }
    };
    this.dialogRef.close(response);
  }
}
