import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatMenuTrigger } from '@angular/material/menu';
import {
    ResponseActionInterface,
    ResponseFilterInterface
} from '@interfaces/general.interface';
import {
    TableActionsInterface,
    TableColumnsInterface
} from '@interfaces/table.interface';
import { MatTableDataSource } from '@angular/material/table';
import { FormMenuTypesEnum } from '@enums/form-menu-types.enum';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'app-grid-table',
    templateUrl: './grid-table.component.html',
    styleUrls: ['./grid-table.component.scss']
})
export class GridTableComponent implements OnChanges, AfterViewInit {
    @Input() data: Array<object>;
    @Input() displayedColumns: Array<TableColumnsInterface>;
    @Input() actions: Array<TableActionsInterface>;
    @Output() applyFilter: EventEmitter<ResponseFilterInterface>;
    @Output() resetFilter: EventEmitter<void>;
    @Output() selectedAction: EventEmitter<ResponseActionInterface>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('tableData') tableData: ElementRef;
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
    @ViewChild(MatSort) sort: MatSort;

    columnsToDisplay: Array<string>;
    dataSource: MatTableDataSource<any>;
    selectedItem: number;
    elementPositionY: number;
    filter: string;
    keyFilter: string;
    actionPlaceholder: string;
    actionTitle: string;
    actionType: FormMenuTypesEnum;

    constructor(
      private paginatorIntl: MatPaginatorIntl
    ) {
        this.paginatorIntl.getRangeLabel = this.rangeLabel;
        this.applyFilter = new EventEmitter<ResponseFilterInterface>();
        this.resetFilter = new EventEmitter<void>();
        this.selectedAction = new EventEmitter<any>();
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    ngOnChanges({ data, displayedColumns }: SimpleChanges): void {
        if (data && data.currentValue) {
            this.dataSource = new MatTableDataSource<any>(this.data);
            this.dataSource.paginator = this.paginator;
        }

        if (displayedColumns && displayedColumns.currentValue) {
            this.columnsToDisplay = this.displayedColumns.map((col) => col.key);
            this.columnsToDisplay.splice(0,0,'select');
        }
        this.dataSource.sort = this.sort;
    }

    onRowHover(id: number, el: MouseEvent): void {
        if (this.selectedItem === id) {
            return;
        }
        const element = el.target as HTMLElement;
        this.elementPositionY =
            element.offsetTop + this.tableData.nativeElement.offsetTop;
        this.selectedItem = id;
        this.dataSource.data = this.dataSource.data.map((item) => ({
            ...item,
            selected: item.id === id
        }));
    }

    selectedActionHandler(action: string): void {
        this.selectedAction.emit({
            action,
            item: this.data.filter((item: any) => item.id === this.selectedItem)
        });
    }

    showFilter({ filterOptions, key }: TableColumnsInterface): void {
        this.actionPlaceholder = filterOptions.placeholder;
        this.actionTitle = filterOptions.title;
        this.actionType = filterOptions.type;
        this.keyFilter = key;
        for (const col of this.displayedColumns) {
            col.key === key ? (col.active = true) : (col.active = false);
        }
    }

    applyFilterHandler(filter): void {
        this.applyFilter.emit({ key: this.keyFilter, value: filter });
        this.menuTrigger.closeMenu();
    }

    onCloseMenu(): void {
        for (const col of this.displayedColumns) {
            col.active = false;
        }
    }


  // Codigo para la seleccion de la fila
  selection = new SelectionModel<object>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: object): string {
    const tm = Math.floor(Date.now() / 1000);
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${tm + 1}`;
  }

  onChangeCheckbox(evt, row) {
    // Limpiar selecciones previas
    this.selection.clear();

    // Pasar al padre la data o null
    if( evt.checked ) {
        this.selectedAction.emit(row);
    } else {
        this.selectedAction.emit(null);
    }
    return this.selection.toggle(row);

  }

  rangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
        startIndex < length
            ? Math.min(startIndex + pageSize, length)
            : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }
}
