import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { QueryParamsInterface } from '@interfaces/general.interface';
import { Observacion } from '@interfaces/observation.interface';
import { CatalogosApiService } from '@services/catalogos-api/catalogos-api.service';
import { OtService } from '@services/ot/ot.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

// Reglas de pantalla para bloquear campos en base al rol del usuario
import { REGLAS_PANTALLA as reglas_pantalla} from '@fixtures/nueva-ot.fixture';
import { DatePipe } from '@angular/common';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-additional-comments',
  templateUrl: './additional-comments.component.html',
  styleUrls: ['./additional-comments.component.scss']
})
export class AdditionalCommentsComponent implements OnInit {

  observacionesPortal: Array<Observacion>;
  @Output() observacionesPortalChange: EventEmitter<Array<Observacion>>;

  observacionesInternas: Array<Observacion>;
  @Output() observacionesInternasChange: EventEmitter<Array<Observacion>>;

  formAdditionalComments: FormGroup;
  isAutorizacionEspecialSelected: boolean  = false;
  autorizacionEspecialNo = new FormControl(true);
  autorizacionEspecialSi = new FormControl(false);

  desviacionOperativaDa = new FormControl(false);
  otAutorizada = new FormControl(false);

  // Tipos de desviacion Operativa
  @Input()
  desviacionesList: Array<any> = new Array();

  @Output()
  outputData: EventEmitter<any>;
  // Suscripcion a cambios en el formulario de comentarios adicionales
  outDataSubscription: Subscription;


  // Datos de la orden de trabajo
  @Input()
  data: GetOTResponseInterface;

  // Rol del usuario en sesion.
  @Input()
  rol: string;

  // Suscripcion a OT Inicializada
  subscriptionOT: Subscription;

  // Suscripcion a lectura del rol del usuario.
  subscriptionRol: Subscription;

  tieneRolDesviacion: boolean;

  tieneRolAutorizada: boolean;
  reglas_pantalla = reglas_pantalla;

  @Input()
  urlParams: QueryParamsInterface;

  responsable: boolean;
  btnAddObservInt: boolean;

  // Suscripcion a Nombre del usuario Inicializado
  subscriptionNombreUsuario: Subscription;
  // Nombre del usuario en sesion getNombre
  nombreUsuario: string;

  tooltipRazonDesviacion: string;

  showObservacionesInternas: boolean;
  showAtribuible: boolean;
  rolBloquear: boolean;
  empleadoBloquear : boolean;


  lstObservaciones = ['observacion1','observacion2'];

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private otService: OtService,
    private catalogosApiService: CatalogosApiService,
    private readonly datePipe: DatePipe
    ) {
      iconRegistry.addSvgIcon('ot-autorizada-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icon-activada.svg'));

    this.observacionesPortalChange = new EventEmitter<Array<Observacion>>();
    this.observacionesInternasChange = new EventEmitter<Array<Observacion>>();

    this.outputData = new EventEmitter<any>();
    this.outDataSubscription = new Subscription();
    this.subscriptionOT  = new Subscription();
    this.subscriptionRol = new Subscription();

    this.tieneRolDesviacion = false;
    this.tieneRolAutorizada = false;
    this.responsable = false;
    this.btnAddObservInt = true;
    this.nombreUsuario = '';
    this.showObservacionesInternas = true;
    this.showAtribuible = false;
  }

  ngOnInit(): void {
    this.rolBloquear = false;
    this.empleadoBloquear = false;
    this.buildFrm();

    this.desviacionesList = [];

    // Cargar datos cuando sea una consulta de OT
    this.subscriptionNombreUsuario = this.otService.nombreInitialized.subscribe(
      (nombreUsuario) => {
      this.nombreUsuario = nombreUsuario;
    });

    // Cargar datos cuando sea una consulta de OT
    this.subscriptionOT = this.otService.initialized.subscribe(
      (otData) => {

        // LLego la data
        this.data = otData;

        if (this.data.responsable === this.urlParams.email) {
          this.responsable = true;
        } else {
          this.responsable = false;
          if (this.data.statusOrden === 'registro_da') {
              this.responsable = true;
          }
        }
        
        // Suscribir a cambios en el rol
        // Desactivar campos en base a las reglas, rol del usuario y data de la Orden de trabajo
        this.subscriptionRol = this.otService.rolInitialized.subscribe(
          (rol) => {
            this.rol = rol;
            this.disableFields(this.data, rol);
        });

        this.rolBloquear = this.authService.rolGnp.includes('GM Work Site Emite') 
                || this.authService.rolGnp.includes('Respaldo MC emite');
        
        if(this.authService.rolGnp.includes('GM Work Site Emite') 
        || this.authService.rolGnp.includes('Respaldo MC emite') ){
            
            this.autorizacionEspecialSi.disable();
            this.autorizacionEspecialNo.disable();
        
          }

        
        if(this.data.meta == undefined) {
          this.showAtribuible == false;  
        } else {
          this.showAtribuible = (this.data.meta.tipoAtribuible !== null 
                                && this.urlParams.menu === environment.menu_portal_empleados);
        }
        this.populateFields(this.data);

        // Se agrega validacion
        if((this.data.observacionesInternas && this.data.observacionesInternas.length === 0)
        && this.urlParams.menu === environment.menu_portal_intermediarios) {
          this.showObservacionesInternas = false;
        }

    });


    this.formAdditionalComments.get("autorizacionEspecialPorque").valueChanges.subscribe((myValue) => {
      this.data.razonAutorizacionEsp = myValue;
      this.updateOT(this.data);
    });

    this.formAdditionalComments.get("razonDesviacion").valueChanges.subscribe((myValue) => {
      this.data.razon_desviacion = myValue;
      this.tooltipRazonDesviacion = myValue;
      this.updateOT(this.data);
    });

    this.lstObservaciones.forEach(observacion => {
      this.agregarEventosEmoji(observacion);
    });

  }

  quitarEmojis(texto: string): string {
    const expresionEmojis = /[\uD800-\uDFFF].|[\u200D\uFE0F\u20E3\u00a9\u00ae\u3030\u303D\u2000-\u2BFF\u3200-\u33FF\uF900-\uFD3F\uFE00-\uFE6F\uFEFF]/g;                   
    return texto.replace(expresionEmojis, '');
  }

  agregarEventosEmoji(observacion){

    this.formAdditionalComments.get(observacion).valueChanges.subscribe((myValue) => {
      const valorSinEmojis = this.quitarEmojis(myValue);
      this.formAdditionalComments.get(observacion).setValue(valorSinEmojis, {emitEvent: false});
    });

  }

  buildFrm(): void {
    this.formAdditionalComments = this.fb.group({
      observacion1: new FormControl({value: '', disabled: false}, Validators.required),
      observacion2: new FormControl({value: '', disabled: false}, Validators.required),
      autorizacionEspecialPorque: new FormControl({value: ''}, Validators.required),
      desviacionDA: new FormControl(this.desviacionOperativaDaSelected, []),
      desviacion: new FormControl({value: false, disabled: false}, []),
      razonDesviacion: new FormControl({value: '', disabled: !this.desviacionOperativaDaSelected}, []),  // razon_desviacion
      autorizada: new FormControl(this.desviacionOperativaDaSelected, []),
      tipoAtribuible: new FormControl({value: '', disabled: true}, [])
    });
  }


  /**
   * Actualiza los datos de la ot en el servicio compartido.
   *
   * @param otData - Datos a actualizar.
   */
   updateOT(otData: GetOTResponseInterface) {
    this.otService.setOt = otData;
  }

  /**
   * Poblar campos del formulario con data del backend.
   *
   * @param otData - Data del backend.
   *
   */
   populateFields(otData: GetOTResponseInterface) {
    this.data = otData;

    this.observacionesPortal = otData.observacionesPortal;
    this.observacionesInternas = otData.observacionesInternas;
    this.setAutorizacionEspecial(otData.autorizacionEsp);
    this.formAdditionalComments.get('autorizacionEspecialPorque').setValue(otData.razonAutorizacionEsp);
    this.desviacionOperativaDa.setValue(otData.desviacion ? otData.desviacion: false);
    if(otData.desviacion) {
      this.formAdditionalComments.get('razonDesviacion').setValue(otData.razon_desviacion);
      this.tooltipRazonDesviacion = otData.razon_desviacion;
    }
    this.setDesviacionOrigin(otData.desviacion ? otData.desviacion: false);

    if(this.tieneRolAutorizada && otData.autorizada) {
      this.setOtAutorizada({checked: otData.autorizada});
    }
    if(otData.meta && otData.meta.tipoAtribuible) {
      this.formAdditionalComments.get('tipoAtribuible').setValue(otData.meta.tipoAtribuible);
    }
  }

  disableAddObservations1: boolean = true;
  /**
   * Inhabilitar campos del formulario en base a los datos de la OT y el rol del usuario.
   * @param data - Datos de la orden de trabajo.
   * @param rol  - Rol del usuario.
   */
  disableFields(data: any, rol: string) {
    this.rol = rol;
    let userDA = "";

    if (typeof(this.urlParams.da) === "string") {
      userDA = JSON.parse(this.urlParams.da)[0].trim();
    }
    const _scope = {
      reglas_pantalla: reglas_pantalla,
      rol: rol,
      data: this.data,
      responsable: this.responsable,
      da_usuario: userDA,
      stateParams: this.urlParams
    };
       
    const acciones = this.authService.permisos.acciones;
    const validCoordinarDa = this.rol === 'coordinacion_da' && (_scope.data.status === 'Activada' || _scope.data.status === 'Rechazada' || _scope.data.status === 'Terminada');
    const validCoordinarDaInput = this.rol === 'coordinacion_da' && 
    (_scope.data.status === 'Activada' || _scope.data.status === 'Rechazada' || _scope.data.status === 'Terminada' 
    || _scope.data.status === "En Registro" );
    const validAgenteCert = this.rol === 'agente_certificado' && _scope.data.status === 'Rechazada';
    const validAgenteCertTer = this.rol === 'agente_certificado' && (_scope.data.status === 'Terminada' || _scope.data.status === 'Activada');
    
    const validAgenteCertInput = this.rol === 'agente_certificado' 
    && (_scope.data.status === 'Terminada' 
    || _scope.data.status === 'Activada' || _scope.data.status === "En Registro"  );
    
    if (this.otService.isConsulta) {
      if (!this.otService.isEmpleado) {
        if (validCoordinarDaInput) {
          this.autorizacionEspecialSi.enable();
          this.autorizacionEspecialNo.enable();
        } else if (validAgenteCert) {
          this.autorizacionEspecialSi.disable();
          this.autorizacionEspecialNo.disable();
        } else if (validAgenteCertTer) {
          this.autorizacionEspecialSi.enable();
          this.autorizacionEspecialNo.enable();
        } else if(this.otService.display('autorizacion_esp', 'enable', _scope)) {
          this.autorizacionEspecialSi.disable();
          this.autorizacionEspecialNo.disable();
        } else {
          this.autorizacionEspecialSi.disable();
          this.autorizacionEspecialNo.disable();
        }
      } else {
        if (this.otService.display('autorizacion_esp', 'enable', _scope)) {
          this.autorizacionEspecialSi.disable();
          this.autorizacionEspecialNo.disable();
        } else {
          this.autorizacionEspecialSi.enable();
          this.autorizacionEspecialNo.enable();
        }
      }
    } else {
      this.autorizacionEspecialSi.enable();
      this.autorizacionEspecialNo.enable();
    }

    // Se deshabilita autorización especial en reprocesos.
    if(this.data.status == 'Terminada' && (this.urlParams.menu == 'servicios_asistidos' || (this.urlParams.menu == 'workflow' && this.data.statusOrden == 'registro_da')) ){
      this.autorizacionEspecialSi.disable();
      this.autorizacionEspecialNo.disable();
    }

    if((data.statusOrden == 'registro_da' || data.statusOrden == 'activada' || data.statusOrden == 'rechazada') ? !(reglas_pantalla.autorizacion_esp.enable.indexOf(rol) >= 0) : true) {
      this.formAdditionalComments.get('autorizacionEspecialPorque').disable();
    }

    this.tieneRolDesviacion = reglas_pantalla.desviacion_operativa.show.indexOf(rol) >= 0;

    if(!this.responsable) {
      this.desviacionOperativaDa.disable();
    }

    if(this.responsable && this.isCasoEspAsig()){
      this.btnAddObservInt = true;
    } else{
      if(this.isSuperRol() && (this.data.statusOrden == 'figura_servicio' || this.data.statusOrden == 'casos_esp')){
        this.formAdditionalComments.get('razonDesviacion').disable();
        this.formAdditionalComments.get('observacion1').disable();
        this.formAdditionalComments.get('observacion2').disable();
        this.btnAddObservInt = false;
      } else {
  
        if(this.isSuperRol() && (data.status != 'Terminada'
        && data.status != 'Rechazada' && data.status != 'Cancelada')) {
          this.desviacionOperativaDa.enable();
        }
  
        if(this.tieneRolDesviacion && !data.desviacion) {
          this.formAdditionalComments.get('razonDesviacion').disable();
        }
    
        if (this.otService.isEmpleado  && this.otService.isConsulta) {
          if(data.status === 'En Registro' 
          || data.status === 'Cancelada Automática'
          || data.status === 'Cancelada Solicitante') {
            
            this.desviacionOperativaDa.disable();
            this.formAdditionalComments.get('razonDesviacion').disable();
            this.empleadoBloquear = true;
    
          }
        }
        
        this.tieneRolAutorizada = reglas_pantalla.autorizada.show.indexOf(rol) >= 0;
    
        if(!(reglas_pantalla.autorizada.enable.indexOf(rol) >= 0) || !this.responsable) {
          this.otAutorizada.disable();
        }
    
        if (this.otService.isConsulta) {
          if (!this.otService.isEmpleado) {
            if (validCoordinarDaInput) {
              this.formAdditionalComments.get('observacion1').enable();
            } else if (validAgenteCert) {
              this.formAdditionalComments.get('observacion1').disable();
            } else if (validAgenteCertInput) {
              this.formAdditionalComments.get('observacion1').enable();
            } else if (this.otService.display('input_observaciones_1', 'enable', _scope)) {
              this.formAdditionalComments.get('observacion1').disable();
            } else {
              this.formAdditionalComments.get('observacion1').disable();
            }
          } else {
            if (_scope.data.statusOrden.includes('charola_virt')) {
              this.formAdditionalComments.get('observacion1').disable();
            } else {
              if (acciones.includes('emitir') && this.responsable) {
                this.formAdditionalComments.get('observacion1').enable();
              } else {
                this.formAdditionalComments.get('observacion1').disable();
              }
              if (acciones.includes('emitir') && this.isSuperRol()) {
                this.formAdditionalComments.get('observacion1').enable(); 
              }
            }
          }
        } else {
          if (this.otService.display('input_observaciones_1', 'enable', _scope)) {
            this.formAdditionalComments.get('observacion1').disable();
          } else {
            this.formAdditionalComments.get('observacion1').enable();
          }
        }
    
        if (this.otService.isConsulta) {
          if (!this.otService.isEmpleado) {
            if (validCoordinarDaInput) {
              this.disableAddObservations1 = false;
            } else if (validAgenteCert) {
              this.disableAddObservations1 = true;
            } else if (validAgenteCertInput) {
              this.disableAddObservations1 = false;
            } else if (this.otService.display('input_observaciones_1', 'enable', _scope)) {
              this.disableAddObservations1 = this.otService.display('input_observaciones_1', 'enable', _scope);
            } else {
              this.disableAddObservations1 = true;
            }
          } else {
            if (_scope.data.statusOrden.includes('charola_virt')) {
              this.disableAddObservations1 = true;
            } else {
              if (acciones.includes('emitir') && this.responsable) {
                this.disableAddObservations1 = false;
              } else {
                this.disableAddObservations1 = true;
              }
              if (acciones.includes('emitir') && this.isSuperRol()) {
                this.disableAddObservations1 = false;
              }
            }
          }
        } else {
          if (this.otService.display('input_observaciones_1', 'enable', _scope)) {
            this.disableAddObservations1 = true;
          } else {
            this.disableAddObservations1 = false;
          }
        }
    
        if (_scope.data.statusOrden.includes('charola_virt')) {
          this.btnAddObservInt = false;
          this.formAdditionalComments.get('observacion2').disable();
        } else {
          if(_scope.data.status === 'Terminada' || _scope.data.status === 'Activada' || _scope.data.status === 'Rechazada' || _scope.data.status === 'Cancelada') {
            this.btnAddObservInt = false;
            this.formAdditionalComments.get('observacion2').disable();
          }
        }
        
        if(!this.responsable) {
          this.btnAddObservInt = false;
          this.formAdditionalComments.get('observacion2').disable();
        }
        if(this.isSuperRol() && (this.data.status !== 'Terminada' && this.data.status !== 'Cancelada')) {
          this.btnAddObservInt = true;
          this.formAdditionalComments.get('observacion2').enable();
        }
    
        if(this.authService.rolGnp.includes('GM Work Site Emite') 
        || this.authService.rolGnp.includes('Respaldo MC emite') ){
    
            this.autorizacionEspecialSi.disable();
            this.autorizacionEspecialNo.disable();
    
        }
        
        if((this.authService.rolAux.includes('figura_servicio') 
                    || this.authService.rolGnp.includes('Workflow DA Gerente Figura de Servicio'))
                     && this.data.status === "En Registro" ){
          this.btnAddObservInt = false;
        }
    
        if (this.otService.isEmpleado  && this.otService.isConsulta) {
          if(_scope.data.status === 'En Registro' 
          || _scope.data.status === 'Cancelada Automática'
          || _scope.data.status === 'Cancelada Solicitante') {
            this.btnAddObservInt = false;
            this.formAdditionalComments.get('observacion2').disable();
            this.autorizacionEspecialSi.disable();
            this.autorizacionEspecialNo.disable();
          }
        }
    
        if(!this.otService.isEmpleado && 
          _scope.rol === 'agente_certificado' && _scope.data.status === "En Registro" ){
          this.autorizacionEspecialSi.enable();
          this.autorizacionEspecialNo.enable();
        }
      }

    }

    if(this.otService.esUsuarioBancaSeguro ){

        this.autorizacionEspecialSi.disable();
        this.autorizacionEspecialNo.disable();

        if(_scope.data.statusOrden === 'operador_banca' || _scope.data.statusOrden === 'registro_da' 
         || _scope.data.statusOrden === 'rechazada' ){
          this.formAdditionalComments.get('observacion1').enable();
          this.formAdditionalComments.get('observacion2').enable();
          this.disableAddObservations1 = false;
          this.btnAddObservInt = true;
        } else {
          this.formAdditionalComments.get('observacion1').disable();
          this.formAdditionalComments.get('observacion2').disable();
          this.disableAddObservations1 = true;
          this.btnAddObservInt = false;
        }

    }


    if(this.authService.rolGnp.includes('Workflow DA Operador Alta GMM') 
    &&  ( _scope.data.status === 'Rechazada' || _scope.data.status === 'Activada' )){
      this.formAdditionalComments.get('observacion1').enable();
      this.formAdditionalComments.get('observacion2').enable();
      this.disableAddObservations1 = false;
      this.btnAddObservInt = true;
    }


      

  }

  /**
   * Valida si es uno de los 3 super roles.
   * @returns true si lo es, false en caso contrario.
   */
  isSuperRol(): boolean {
    const rolesConPermisoDesviacion: string[] = ['supervisor_gmm','asignador', 'gerente_gmm'];

    let responseValue = false;
    for(let myUrl of rolesConPermisoDesviacion) {
        if(this.rol && this.rol.search(myUrl) >= 0) {
          responseValue = true;
            break;
        }
    }
    
    return responseValue;
  }

  isCasoEspAsig(): boolean {
    const rolesConPermisoDesviacion: string[] = ['casos_esp'];

    let responseValue = false;
    for(let myUrl of rolesConPermisoDesviacion) {
        if(this.rol && this.rol.search(myUrl) >= 0) {
          responseValue = true;
            break;
        }
    }
    
    return responseValue;
  }

  setAutorizacionEspecial( autorizacionEspecial ) {
    
    if(autorizacionEspecial) {
      this.autorizacionEspecialSi.setValue(true);
      this.autorizacionEspecialNo.setValue(false);
    } else {
      this.autorizacionEspecialSi.setValue(false);
      this.autorizacionEspecialNo.setValue(true);
    }
    this.isAutorizacionEspecialSelected = autorizacionEspecial;

    this.data.autorizacionEsp = this.isAutorizacionEspecialSelected;
    this.updateOT(this.data);
  }

  removeObservacion(index: number, observacioneVar: string): void {
    if (this.data[observacioneVar][index].canDelete) {
      this[observacioneVar].splice(index, 1);
      if (observacioneVar === 'observacionesPortal') {
        this.observacionesPortalChange.emit(this[observacioneVar]);
      } else {
        this.observacionesInternasChange.emit(this[observacioneVar]);
      }
      this.data[observacioneVar] = this[observacioneVar];
      this.updateOT(this.data);
    }
  }

  preventEnter(event: KeyboardEvent) {
    event.preventDefault();
  }

  addObservacion(observacion: string): void {
    if(!this.validateEmpty(observacion)) {
      return;
    }
    const currentDate = this.datePipe.transform(
      new Date(), environment.datePatternObs
    );
    const newChip: Observacion = {
      nombre: this.nombreUsuario,
      fecha: currentDate,
      texto: this.formAdditionalComments.get(observacion).value,
      canDelete: true
    }
    if (observacion === 'observacion1') {
      this.observacionesPortal = [newChip, ...this.observacionesPortal];
    } else {
      this.observacionesInternas = [newChip, ...this.observacionesInternas];
    }

    this.formAdditionalComments.get(observacion).reset();

    if (observacion === 'observacion1') {
      this.observacionesPortalChange.emit(this.observacionesPortal);
    } else {
      this.observacionesInternasChange.emit(this.observacionesInternas);
    }

    this.agregarEventosEmoji(observacion);

    this.data.observacionesInternas = this.observacionesInternas;
    this.data.observacionesPortal = this.observacionesPortal;
    this.updateOT(this.data);
  }

  /**
   * Devuelve true en caso de que el valor del campo tenga un valor,
   * false en caso contrario.
   *
   * @param field - Campo a validar.
   * @returns true | false
   */
  validateEmpty(field:string): boolean {

    let obs = this.formAdditionalComments.get(field).value;
    if(obs) {
      obs = obs.toString().trim();
    }
    if(obs.length <= 0) {
      this.formAdditionalComments.get(field).markAsTouched();
      this.formAdditionalComments.get(field).updateValueAndValidity();
      return false;
    }

    return true;
  }

  desviacionOperativaDaSelected: boolean = false;

  setDesviacionOrigin(des){
    this.formAdditionalComments.controls['desviacionDA'].setValue(des);
    this.desviacionOperativaDaSelected = des;
    this.formAdditionalComments.get('desviacion').setValue(des);
    this.setDesvacionOperativaComments();
  }

  setDesviacionOperativaDa(evt) {
    this.formAdditionalComments.controls['desviacionDA'].setValue(evt.checked);
    this.desviacionOperativaDaSelected = evt.checked;
    this.formAdditionalComments.get('desviacion').setValue(evt.checked);
    this.setDesvacionOperativaComments();
   }



   setDesvacionOperativaComments() {

    if( this.desviacionOperativaDaSelected == false ) {
      this.formAdditionalComments.get('razonDesviacion').disable();
      this.formAdditionalComments.get('desviacion').setValue(undefined);
    } else {
      this.formAdditionalComments.get('razonDesviacion').enable();
      this.data.razon_desviacion = this.formAdditionalComments.get('razonDesviacion').value;
    }

    this.data.desviacion = this.desviacionOperativaDaSelected;
    this.updateOT(this.data);
   }

  otAutorizadaSelected: boolean = false;

  setOtAutorizada(evt) {
    this.formAdditionalComments.get('autorizada').setValue(evt.checked);
    this.otAutorizada.setValue(evt.checked);
    this.otAutorizadaSelected = evt.checked;

    // Guardar en OT Compartida
    this.data.autorizada = this.otAutorizadaSelected;
    this.updateOT(this.data);
   }

   display(input, action): boolean {
    let userDA = "";

    if (typeof(this.urlParams.da) === "string") {
      userDA = JSON.parse(this.urlParams.da)[0].trim();
    }
    let _scope = {
      reglas_pantalla: reglas_pantalla,
      rol: this.rol,
      data: this.data,
      responsable: this.responsable,
      da_usuario: userDA,
      stateParams: this.urlParams
    };
    return this.otService.display(input, action, _scope);
   }


   ocultarTache(){
    return this.otService.isEmpleado && this.otService.isConsulta
       && (this.data.status === 'En Registro' || this.data.status === 'Cancelada Automática'
       || this.data.status === 'Cancelada Solicitante');
   }

   validateDisable(): boolean{

    if(this.isSuperRol() && (this.data.statusOrden == 'figura_servicio' || this.data.statusOrden == 'casos_esp')){
      return true;
    } else {
      if(!(this.data.statusOrden == 'registro_da' || this.data.statusOrden == 'activada' || this.data.statusOrden == 'rechazada') || this.data.status == 'Terminada' || this.rolBloquear || this.empleadoBloquear){
        return true;
      } else {
        return false;
      }

    }

   }
}
