import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    statusEvent: Subject<boolean>;
    statusModalEvent: Subject<boolean>;

    constructor() {
        this.statusEvent = new Subject<boolean>();
        this.statusModalEvent = new Subject<boolean>();
    }

    hide(): void {
        this.statusEvent.next(false);
    }

    show(): void {
        this.statusEvent.next(true);
    }

    
    hideModal(): void {
        this.statusModalEvent.next(false);
    }

    showModal(): void {
        this.statusModalEvent.next(true);
    }
}
