import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
    ProductoCatalogosApiInterface,
    RamosInterface,
    SocioComercialCatalogosApiInterface,
    TipoSolicitudCatalogosApiInterface
} from '@interfaces/catalogos-api';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import {
    QueryParamsInterface,
    SelectInputInterface
} from '@interfaces/general.interface';
import { OtService } from '@services/ot/ot.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
// Reglas de pantalla para bloquear campos en base al rol del usuario
import { REGLAS_PANTALLA as reglas_pantalla } from '@fixtures/nueva-ot.fixture';
import { environment } from '../../../../../environments/environment';
import { UtilsService } from '@services/utils/utils.service';
import { ServiciosGnpService } from '@services/servicios-gnp/servicios-gnp.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';

@Component({
    selector: 'app-gmm-nn-cgi',
    templateUrl: './gmm-nn-cgi.component.html',
    styleUrls: ['./gmm-nn-cgi.component.scss']
})
export class GmmNnCgiComponent implements OnInit {
    @Input()
    urlParams: QueryParamsInterface;

    ramosList: RamosInterface[] = [
        { valor: 'gmm', texto: 'Gastos Médicos Mayores' }
    ];

    @Input()
    tipoSolicitudList: Array<TipoSolicitudCatalogosApiInterface> = new Array();

    @Input()
    productosList: Array<ProductoCatalogosApiInterface> = new Array();

    @Input()
    rol: string;

    sociosList: Array<SocioComercialCatalogosApiInterface> = new Array();;

    // Datos de la orden de trabajo
    @Input()
    data: GetOTResponseInterface;

    // Suscripcion a lectura de la OT.
    subscription: Subscription;

    // Suscripcion a lectura del rol del usuario.
    subscriptionRol: Subscription;

    form: FormGroup;
    
    // Suscripcion a validacion de formulario.
    $validateForm: Subscription;

    monedasList: Array<SelectInputInterface> = [
        { value: 'pesos', viewValue: 'Pesos' },
        { value: 'dolares', viewValue: 'Dolares' }
    ];

    monedaSelected: string = 'pesos';
    riesgoSelectoSelected: boolean = false;
    solidezFamiliarSelected: boolean = false;

    responsable: boolean;

    // Mostrar / Ocultar el checkbox de Fuerza productora
    showFuerzaProductora: boolean;

    cargaBanca: boolean = false;

    constructor(
        private activateRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private otService: OtService,
        private utils: UtilsService,
        private serviciosGnpService : ServiciosGnpService,
        private genericModalService: GenericModalService,
    ) {}

    // Suscripcion al cambio de tipo de solicitud
    subscrTipoSolicitud: Subscription;
    ngOnInit(): void {

        this.showFuerzaProductora = environment.show_nn_fuerza_productora;
        this.form = this.fb.group({
            lineaNegocio: new FormControl({ value: '', disabled: true }),
            tipoSolicitud: new FormControl(
                { value: '', disabled: false },
                Validators.required
            ),
            producto: new FormControl(
                { value: '', disabled: false },
                Validators.required
            ),
            numeroSolicitantes: new FormControl(
                { value: '', disabled: false }
            ),
            prima: new FormControl(
                { value: '', disabled: false }
            ),
            prepoliza: new FormControl({ value: '', disabled: false }),
            poliza: new FormControl({ value: '', disabled: false }),
            moneda: new FormControl({
                value: this.monedaSelected,
                disabled: false
            }),
            riesgoSelecto: new FormControl({
                value: this.riesgoSelectoSelected,
                disabled: false
            }),
            folioRiesgoSelecto: new FormControl(''),
            solidezFamiliar: new FormControl({
                value: this.solidezFamiliarSelected,
                disabled: false
            }),
            fuerzaProductora: new FormControl({
                value: '',
                disabled: false
            }),
            
        });



        this.subscription = this.otService.initialized.subscribe((otData) => {
            // LLego la data
            this.data = otData;

            const habilitarBancaSeguro = this.data.statusOrden === 'registro_da' && this.usuarioBancaSeguro();
            this.data.generales.bancaSeguro = habilitarBancaSeguro ? true : 
            this.data.generales.bancaSeguro === undefined ? false : this.data.generales.bancaSeguro;

            if(this.data.statusOrden !== 'registro_da' || 
                (this.data.statusOrden === 'registro_da' && this.usuarioBancaSeguro())){
                    this.cargaBanca = true;
                    // Se agrega control para socioComercial
                    this.form.addControl('socioComercial',new FormControl(
                        { value: '', disabled: !habilitarBancaSeguro },
                        habilitarBancaSeguro ? Validators.required : null
                    ));
                    this.form.get('socioComercial').valueChanges.subscribe((myValue) => {
                        this.data.generales.nombreSocioComercial = myValue.nombreSocioComercial;
                        this.data.generales.codigoSocioComercial = myValue.idNegocioOperable;
                        this.updateOT(this.data);
                    });
                    // Se agrega control para bancaSeguros
                    this.form.addControl("bancaSeguros", new FormControl({
                        value:  this.data.generales.bancaSeguro,
                        disabled: true
                    }));
                    this.form.get('bancaSeguros').disable();

                    this.serviciosGnpService.getSociosComerciales().subscribe(
                        (data) => {
                            this.sociosList = data;     
                                //Si es ot generado se actualiza valor de socioComercial
                                if(this.data.statusOrden !== 'registro_da' && this.data.generales.nombreSocioComercial != ''
                                && this.data.generales.nombreSocioComercial != undefined ){
                                    this.form.get('socioComercial').setValue(this.sociosList.find(
                                        (element) => element.nombreSocioComercial ===  this.data.generales.nombreSocioComercial ));
                                }
                        }, (error) => {
                            if(this.data.statusOrden === 'registro_da'){
                                this.genericModalService.showModal('generico', 'sinSociosComerciales', { mensaje : error.error.mensaje });
                            }
                        }
                    );
            }

            if (this.data.responsable === this.urlParams.email) {
                this.responsable = true;
            } else {
                this.responsable = false;
                if (this.data.statusOrden === 'registro_da') {
                    this.responsable = true;
                }
            }
            // Suscribir a cambios en el rol
            // Desactivar campos en base a las reglas, rol del usuario y data de la Orden de trabajo
            this.subscriptionRol = this.otService.rolInitialized.subscribe(
                (rol) => {
                    this.disableFields(this.data, rol);
                }
            );

            this.populateFields(this.data);
        });

        this.form.get('tipoSolicitud').valueChanges.subscribe((myValue) => {
            this.data.generales.tipoSolicitud = myValue;
            this.updateOT(this.data);
        });
        
        this.form.get('producto').valueChanges.subscribe((myValue) => {
            if(myValue != undefined){
                this.cambiarProducto(myValue);
            }
        });

        this.form
            .get('numeroSolicitantes')
            .valueChanges.subscribe((myValue) => {
                this.data.generales.numeroSolicitantes = myValue;
                this.updateOT(this.data);
            });
        this.form.get('prima').valueChanges.subscribe((myValue) => {
            this.data.generales.prima = myValue;
            this.updateOT(this.data);
        });
        this.form.get('prepoliza').valueChanges.subscribe((myValue) => {
            this.data.generales.numeroPrepoliza = myValue;
            // TODO: Revisa que hace watcherPoliza
            this.updateOT(this.data);
        });
        this.form.get('poliza').valueChanges.subscribe((myValue) => {
            this.data.generales.numeroPoliza = myValue;
            this.updateOT(this.data);
        });
        this.form.get('moneda').valueChanges.subscribe((myValue) => {
            this.data.generales.moneda = myValue;
            this.updateOT(this.data);
        });
        this.form.get('riesgoSelecto').valueChanges.subscribe((myValue) => {
            this.data.generales.riesgoSelecto = myValue;
            this.updateOT(this.data);
        });
        this.form
            .get('folioRiesgoSelecto')
            .valueChanges.subscribe((myValue) => {
                this.data.generales.folioRiesgoSelecto = myValue;
                this.updateOT(this.data);
            });
        this.form.get('solidezFamiliar').valueChanges.subscribe((myValue) => {
            this.data.generales.solidezFamiliar = myValue;
            this.updateOT(this.data);
        });

        // Si ya hay datos, actualizar los campos.
        let myOtData = this.otService.ot;
        if (myOtData) {
            this.populateFields(myOtData);
            this.disableFields(this.data, this.rol);
        }

        this.$validateForm = this.otService.validateFrm.subscribe((validate) => {
            this.form.updateValueAndValidity();
            this.form.get('producto').markAsTouched();
            this.form.get('producto').updateValueAndValidity();

            if(this.data.statusOrden !== 'registro_da' || 
            (this.data.statusOrden === 'registro_da' && this.usuarioBancaSeguro())){

            this.form.get('socioComercial').markAsTouched();
            this.form.get('socioComercial').updateValueAndValidity();

            }
            
            if(this.riesgoSelectoSelected) {
                this.form.get('folioRiesgoSelecto').setValidators(Validators.required);
                this.form.get('folioRiesgoSelecto').markAsTouched();
                this.form.get('folioRiesgoSelecto').updateValueAndValidity();
            } else {
              this.form.get('folioRiesgoSelecto').setValidators(null);
            }
            this.otService.updateValidation('frmnn', this.form.status == 'VALID');
        });
    } // end ngOnInit()


    usuarioBancaSeguro(){
     return this.otService.esUsuarioBancaSeguro;       
    }

    watcherPoliza(numeroPrepoliza: any) {
        this.otService.watcherPoliza.update(numeroPrepoliza);
    }

    cambiarProducto(myValue: any){
        var tmpp = this.productosList.find((o) => o.texto === myValue);

        this.data.generales.producto = tmpp
            ? tmpp.valor
            : this.data.generales.producto;
        if (tmpp && tmpp.clave) {
            this.data.generales.codigoPlan = tmpp.clave;
        } else {
            delete this.data.generales.codigoPlan;
        }

        this.data.generales.productoDescripcion = myValue;
        this.updateOT(this.data);
    }

    /**
     * Actualiza los datos de la ot en el servicio compartido.
     *
     * @param otData - Datos a actualizar.
     */
    updateOT(otData: GetOTResponseInterface) {
        this.otService.setOt = otData;
    }

    /**
     * Poblar campos del formulario con data del backend.
     *
     * @param otData - Data del backend.
     *
     */
    populateFields(otData: GetOTResponseInterface) {
        this.data = otData;
        this.form
            .get('lineaNegocio')
            .setValue(this.data.generales.lineaNegocio);
        this.form
            .get('tipoSolicitud')
            .setValue(this.data.generales.tipoSolicitud);
        this.form
            .get('producto')
            .setValue(this.data.generales.productoDescripcion);
        this.form
            .get('numeroSolicitantes')
            .setValue(this.data.generales.numeroSolicitantes);
        if(this.data.generales.prima) {
            let num = parseFloat(this.data.generales.prima.toString().replace(/[^0-9]/g, '')).toFixed(0);
            if( !isNaN(parseInt(num)) ) {
                this.form.get('prima').setValue(this.formatNumber(num));
            } else {
                this.form.get('prima').setValue('');
            }
        } else {
            this.form.get('prima').setValue('');
        }
        this.form
            .get('prepoliza')
            .setValue(this.data.generales.numeroPrepoliza);
        this.form.get('poliza').setValue(this.data.generales.numeroPoliza);
        this.form.get('moneda').setValue(this.data.generales.moneda);
        this.riesgoSelectoSelected = this.data.generales.riesgoSelecto
            ? this.data.generales.riesgoSelecto
            : false;
        this.form.get('riesgoSelecto').setValue(this.riesgoSelectoSelected);
        this.form
            .get('folioRiesgoSelecto')
            .setValue(this.data.generales.folioRiesgoSelecto);
        this.solidezFamiliarSelected = this.data.generales.solidezFamiliar
            ? this.data.generales.solidezFamiliar
            : false;
        this.form.get('solidezFamiliar').setValue(this.solidezFamiliarSelected);
    }

    /**
     * Inhabilitar campos del formulario en base a los datos de la OT y el rol del usuario.
     * @param data - Datos de la orden de trabajo.
     * @param rol  - Rol del usuario.
     */
    disableFields(data: any, rol: string) {
        if (
            !(
                reglas_pantalla.tipo_solicitud.enable.indexOf(rol) >= 0 &&
                data.statusOrden == 'registro_da'
            )
        ) {
            this.form.get('tipoSolicitud').disable();
        }

        if (
            data.statusOrden == 'registro_da'
                ? !(reglas_pantalla.generales.enable.indexOf(rol) >= 0)
                : true
        ) {
            this.form.get('producto').disable();
        }

        if (
            data.statusOrden == 'registro_da'
                ? !(reglas_pantalla.generales.enable.indexOf(rol) >= 0)
                : true
        ) {
            this.form.get('numeroSolicitantes').disable();
        }

        if (
            data.statusOrden == 'registro_da'
                ? !(reglas_pantalla.generales.enable.indexOf(rol) >= 0)
                : true
        ) {
            this.form.get('prima').disable();
        }

        if (
            !(reglas_pantalla.num_poliza.enable.indexOf(rol) >= 0) ||
            !this.responsable
        ) {
            this.form.get('prepoliza').disable();
        }

        this.form.get('poliza').disable();
        this.form.get('moneda').disable();

        if (
            data.statusOrden == 'registro_da'
                ? !(reglas_pantalla.generales.enable.indexOf(rol) >= 0)
                : true
        ) {
            this.form.get('riesgoSelecto').disable();
        }

        if (
            data.statusOrden == 'registro_da'
                ? !(reglas_pantalla.generales.enable.indexOf(rol) >= 0)
                : true
        ) {
            this.form.get('folioRiesgoSelecto').disable();
        }

        if (
            data.statusOrden == 'registro_da'
                ? !(reglas_pantalla.generales.enable.indexOf(rol) >= 0)
                : true
        ) {
            this.form.get('solidezFamiliar').disable();
        }

        // #RF 7
        if (data.statusOrden !== 'registro_da') {
            this.form.get('fuerzaProductora').disable();
        }

        if (this.otService.isEmpleado  && this.otService.isConsulta) {
            if(data.status === 'En Registro' 
            || data.status === 'Cancelada Automática'
            || data.status === 'Cancelada Solicitante') {
              this.form.get('tipoSolicitud').disable();
              this.form.get('producto').disable();
              this.form.get('numeroSolicitantes').disable();
              this.form.get('prima').disable();
              this.form.get('prepoliza').disable();
              this.form.get('poliza').disable();
              this.form.get('moneda').disable();
              this.form.get('riesgoSelecto').disable();
              this.form.get('folioRiesgoSelecto').disable();
              this.form.get('solidezFamiliar').disable();
              this.form.get('fuerzaProductora').disable();
            }
          }
    }

    setRiesgoSelecto(evt) {
        this.riesgoSelectoSelected = evt.checked;
        if(evt.checked) {
            this.form.get('folioRiesgoSelecto').setValidators(Validators.required);
        } else {
            this.form.get('folioRiesgoSelecto').clearValidators();
        }
        this.form.get('folioRiesgoSelecto').updateValueAndValidity();
    }

    setSolidezFamiliar(evt) {
        this.solidezFamiliarSelected = evt.checked;
        this.data.generales.solidezFamiliar = this.solidezFamiliarSelected;
    }

    cambioSolicitud(tipoSolicitudData) {
        this.urlParams.tipo_solicitud = tipoSolicitudData.value;
        
        // #RF 7
        this.showFuerzaProductora = false;
        if(environment.show_nn_fuerza_productora && tipoSolicitudData === 'nuevo_negocio') {
           this.showFuerzaProductora = true;
        }
        
        let params2: URLSearchParams = this.utils.serialize(JSON.parse(JSON.stringify(this.urlParams)));
        let newUrl = 'home?' + params2.toString();  
        this.router.navigateByUrl(newUrl,{ replaceUrl: true });
    }

    setProducto(valor) {
        if(valor != undefined){
            this.cambiarProducto(valor.value);
        }
    }

    soloNumeros(event: Event): void {
      const evento = (event.target as HTMLInputElement).value;
      const numero = parseFloat(evento.replace(/[^0-9]/g, '')).toFixed(0);
      const result = !isNaN(Number(numero)) ? numero : '';
      this.form.controls['numeroSolicitantes'].patchValue(result);
    }

    formatoMoneda(event: any) {
      let numero = parseFloat(event.target.value.toString().replace(/[^0-9]/g, '')).toFixed(0);
      if( !isNaN(parseInt(numero)) ) {
        this.form.controls['prima'].setValue(numero);
        event.target.value = this.formatNumber(numero);
      } else {
        this.form.controls['prima'].setValue('');
        event.target.value = "";
      }
    }

    formatNumber(num: string): string {
        const amount_parts = num.split(',');
        const regexp = /(\d+)(\d{3})/;
        while (regexp.test(amount_parts[0])){
          amount_parts[0] = amount_parts[0].replace(regexp, '$1' + ',' + '$2');
        }
        return amount_parts.join();
    }
}
