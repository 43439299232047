export interface BitacoraData {
    fechaInicial : string;
    usuarioCreador : string;
    estacionCreacion : string;
    responsable : string;
    estacionResponsable : string;
    fechaFinal : string;
    accion : string;
}


export const NombreColumnasBitacora : any[] = [
    {
      title: 'Fecha inicial',
      value: 'fechaInicial' 
    } ,
    {
      title: 'Usuario de creación',
      value: 'usuarioCreador' 
    } ,
    {
      title: 'Estación de creación',
      value: 'estacionCreacion' 
    } ,
    {
      title: 'Usuario responsable',
      value: 'responsable' 
    } ,
    {
      title: 'Estación de responsable',
      value: 'estacionResponsable' 
    } ,
    {
      title: 'Fecha final',
      value: 'fechaFinal' 
    } ,
    {
      title: 'Acción',
      value: 'accion' 
    } ,
];