import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { OtService } from '@services/ot/ot.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(
        private otService: OtService,
        private acRouter: ActivatedRoute,
        private router: Router,
        private readonly storage: LocalStorageService
    ) {

        const origen = window.location.origin;
        let url = window.location.href;
        this.otService.isConsulta = url.includes('orden_id');
        this.otService.isEmpleado = !url.includes('Intermediario');
        
        if(url.includes('bancaSeguro')){
            this.acRouter.queryParams.subscribe((params: Params) => {
                this.otService.esOTBancaSeguro = params.bancaSeguro  === 'Si';
            });
        }

        if (url.includes('alta_otservicios_asistidos')) {
          url = url.replace('workflow/alta_ot','');
        }

        if(url.indexOf('#!/generador') > 0) {
            const newString: string = url
            .replace(origen,'')
            .replace('#!/generador','default');
            let myparams = newString.split('/');
            let myparams2 = newString.split('?')[1];
            let newUrl = 'home?' + myparams2 + '&menu=' + myparams[2];
            this.router.navigateByUrl(newUrl);
        }
    }
    
}
