import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from '@services/loader/loader.service';
import { ModalService } from '@services/modal/modal.service';
import { ServiciosGnpService } from '@services/servicios-gnp/servicios-gnp.service';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDaterangepickerInlineConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import * as moment from 'moment';

import { Subscription } from 'rxjs';
import { UtilsService } from '@services/utils/utils.service';

@Component({
  selector: 'app-register-person',
  templateUrl: './register-person.component.html',
  styleUrls: ['./register-person.component.scss']
})
export class RegisterPersonComponent implements OnInit {

  panelOpenState: boolean = true;
  modalTitle: string = "Alta de Solicitante";
  formGeneralData: FormGroup;
  formAddress: FormGroup;
  formMeansOfContact: FormGroup;

  tipoPersonaSelected: string;
  generoSelected: string;
  addressfiscalValues: any;
  nombreColonia: string;

  subscriptionFrm1: Subscription;
  subscriptionFrm2: Subscription;
  subscriptionFrm3: Subscription;
  nacionalidadesList: any = [];
  paisesList: any = [];
  catalogosIniciales = {
    tiposDomicilio: [{
      "texto": "CASA",
      "valor": "CS"
             }],
    ocupaciones: [],
    giros: [],
    nacionalidades: [],
    paises: [],
    tiposTelefono: [],
    ladas: []
  };
  catalogosCP = {
    entidadesFederativas: [],
    municipios: [],
    colonias: []
  };

  //Tool tip para el giro
  tooltipGiro: string;

  // Configuracion de Datepicker de bootstrap
  bsConfig: Partial<BsDaterangepickerInlineConfig>;

  constructor(
    private fb: FormBuilder,
    public readonly modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public inboundData: any,
    public dialogRef: MatDialogRef<RegisterPersonComponent>,
    private serviciosGnpService: ServiciosGnpService,
    private readonly spinner: LoaderService,
    private readonly datePipe: DatePipe,
    private localeService: BsLocaleService,
    public readonly utils: UtilsService) {

    this.subscriptionFrm1 = new Subscription();
    this.subscriptionFrm2 = new Subscription();
    this.subscriptionFrm3 = new Subscription();

      esLocale.weekdaysShort = ['dom.','lun', 'mar.', 'mié.', 'jue.', 'vie.', 'sáb.'];
      esLocale.week.dow = 0;
      defineLocale('es', esLocale);
      this.localeService.use('es');
      const bsConfig = {
        containerClass: 'theme-dark-blue',
        dateInputFormat: 'DD/MM/YYYY',
        showClearButton: true,
        clearPosition: 'center',
        maxDate: moment().toDate(),
        showWeekNumbers: false
      };
      this.bsConfig = Object.assign({}, bsConfig);

      this.tipoPersonaSelected = '';
      this.generoSelected = '';
      this.sizeLadaNacional = 2;
      this.sizeTelefono = 7;

  }

  sizeLadaNacional: number;
  sizeTelefono: number;

  ngOnInit(): void {
    this.buildFrm();
    this.loadCatalogs();
    this.tooltipGiro = '';

    this.formGeneralData.get('fechaConst').valueChanges.subscribe((myDate) => {
      if(this.formGeneralData.get('rfc').value) {
        // Convertir a string
        let strFecha = this.datePipe.transform(myDate, 'yyMMdd');
        if (!this.formGeneralData.get('rfc').value.includes(strFecha)) {
          this.formGeneralData.get('fechaConst').setErrors({rfc: true});
        }
      }
    });
    this.formGeneralData.get('fechaNac').valueChanges.subscribe((myDate) => {
      if(this.formGeneralData.get('rfc').value) {
        // Convertir a string
        let strFecha = this.datePipe.transform(myDate, 'yyMMdd');
        if (!this.formGeneralData.get('rfc').value.includes(strFecha)) {
          this.formGeneralData.get('fechaNac').setErrors({rfc: true});
        }
      }
    });
    this.formGeneralData.get('rfc').valueChanges.subscribe((myRFC) => {
      let tp = this.formGeneralData.get('tipoPersona').value;
      if(tp === 'fisica') {
        if(this.formGeneralData.get('fechaNac').value) {
          // Convertir a string
          let strFecha = this.datePipe.transform(this.formGeneralData.get('fechaNac').value, 'yyMMdd');
          if (!myRFC.includes(strFecha)) {
            this.formGeneralData.get('rfc').setErrors({pattern: true});
          }
        }
      } else {
        if(this.formGeneralData.get('fechaConst').value) {
          // Convertir a string
          let strFecha = this.datePipe.transform(this.formGeneralData.get('fechaConst').value, 'yyMMdd');
          if (!myRFC.includes(strFecha)) {
            this.formGeneralData.get('rfc').setErrors({pattern: true});
          }
        }
      }
    });
    this.formGeneralData.get('curp').valueChanges.subscribe((myCurp) => {
      if(myCurp) {
        if(!this.validateCurp(myCurp)) {
          this.formGeneralData.get('curp').setErrors({curp: true});
        }
      }
    });

    this.formMeansOfContact.get('correo').valueChanges.subscribe((myEmail) => {
      if(myEmail) {
        if(!this.validateEmail(myEmail)) {
          this.formMeansOfContact.get('correo').setErrors({pattern: true});
        }
      }
    });

  }

  tipoPersonaFisica = new FormControl(true);
  tipoPersonaMoral = new FormControl(false);
  generoFemenino = new FormControl(false);
  generoMasculino = new FormControl(false);


  chkDireccionPersonalizada = new FormControl(false);

  curpPattern = "";
  buildFrm(): void {
    // Se establecen los valores iniciales
    this.formGeneralData = this.fb.group({
      tipoPersona: new FormControl('fisica', []),
      paterno: new FormControl('', [Validators.required]),
      razonSocial: new FormControl('', [Validators.required]),
      materno: new FormControl(''),
      nombres: new FormControl('', [Validators.required]),
      rfc: new FormControl('', [Validators.required]),  // Moral y fisica
      fechaNac: new FormControl('', [Validators.required]),
      fechaConst: new FormControl('', [Validators.required]),  // Moral
      nacionalidad: new FormControl('', []),
      paisNacimiento: new FormControl('', []),
      curp: new FormControl('', []),
      genero: new FormControl('', [Validators.required]),
      folioMercantil: new FormControl('', [Validators.required]),  // Moral
      giro: new FormControl('', [Validators.required])  // Moral
    });
    this.formAddress = this.fb.group({
      tipoDomicilio: new FormControl({value: "CS",disabled: true}, [Validators.required]),
      calle: new FormControl('', [Validators.required]),
      numeroExterior: new FormControl('', Validators.required),
      numeroInterior: new FormControl(''),
      referenciaUbicacion: new FormControl(''),
      codigoPostal: new FormControl('', [Validators.required]),
      pais: new FormControl('', [Validators.required]),
      entidadFederativa: new FormControl('', [Validators.required]),
      municipio: new FormControl('', [Validators.required]),
      colonia: new FormControl('', [Validators.required]),
      coloniaCustom: new FormControl('', []),
      chkDireccionPersonalizada: this.chkDireccionPersonalizada,
      coloniaNombre: new FormControl('',[])
    });
    this.formMeansOfContact = this.fb.group({
      tipoTelefono: new FormControl('', [Validators.required]),
      ladaInternacional: new FormControl('52', [Validators.required]),
      ladaNacional: new FormControl('', [Validators.required]),
      telefono: new FormControl('', [Validators.required]),
      numExtension: new FormControl('', []),
      correo: new FormControl('', [Validators.required])
    });

    this.setTipoPersona('fisica');
    // Suscripción de los cambios del form
    this.onChangesForm();
    // Actualizar valores en la suscripción del modal
    this.updateModalData();
  }


  /**
   * Validar la curp.
   *
   * @param curp - Cadena a validar.
   *
   * @returns
   * false - Si la curp no es correcta.
   * true - Si la curp es correcta.
   */
  validateCurp(curp): boolean {
    let reCurp = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    return curp.match(reCurp);
  }

  /**
   * Validar el correo.
   *
   * @param email - Cadena a validar.
   *
   * @returns
   * false - Si el correo no es correcta.
   * true - Si el correo es correcta.
   */
   validateEmail(email): boolean {
    let reEmail = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/;
    return email.match(reEmail);
  }

  loadCatalogs() {
    const request = { opcion: 'alta' };
    this.spinner.showModal();
    this.serviciosGnpService.loadCatalogosRegistration(request).subscribe(
      (dataResponse) => {
          this.spinner.hideModal();
          for(const cat of  dataResponse.catalogos) {
            switch (cat.nombre) {
              case 'TIPO_DOMICILIO':
                  // Ya se han preestablecido
                  break;
              case 'OCUPACION':
                  this.catalogosIniciales.ocupaciones = cat.valores;
                  break;
              case 'GIRO_MERCANTIL':
                  this.catalogosIniciales.giros = cat.valores;
                  break;
              case 'NACIONALIDAD':
                  this.catalogosIniciales.nacionalidades = cat.valores;
                  break;
              case 'PAIS':
                this.catalogosIniciales.paises = cat.valores;
                  break;
              case 'CONTACTO':
                this.catalogosIniciales.tiposTelefono = cat.valores;
                  break;
              case 'ladaInternacional':
                this.catalogosIniciales.ladas = cat.valores;
                  break;
            }
          }
      }, (error) => {
        this.spinner.hideModal();
      }
    );
  }

  /**
   * Carga los catalogos relacionados con un CP.
   */
  loadCatalogsByCP(evt: any) {
    const cpInput: string = evt.target.value;
    if (cpInput && cpInput.toString().length !== 5) {
      this.formAddress.get('codigoPostal').setErrors({minlength: true});
      this.catalogosCP = {
        entidadesFederativas: [],
        municipios: [],
        colonias: []
      };
      return;
    }

    if (cpInput && cpInput.toString().length === 5) {
    const request = { cp: cpInput };
    this.spinner.showModal();
    this.serviciosGnpService.loadCatalogosByCP(request).subscribe(
      (dataResponse) => {
        this.spinner.hideModal();
          for(const cat of  dataResponse.catalogos) {
            switch (cat.nombre) {
              case 'ESTADO_REPUBLICA':
                  this.catalogosCP.entidadesFederativas = cat.valores;
                  break;
              case 'MUNICIPIO':
                  this.catalogosCP.municipios = cat.valores;
                  break;
              case 'COLONIA':
                  this.catalogosCP.colonias = cat.valores;
                  break;
            }
          }
          // Seleccionar la primera opcion
          this.formAddress.controls['entidadFederativa'].setValue(this.catalogosCP.entidadesFederativas[0].valor);;
          this.formAddress.controls['municipio'].setValue(this.catalogosCP.municipios[0].valor);
          if (this.catalogosCP.colonias.length === 1) {
            this.formAddress.controls['colonia'].setValue(this.catalogosCP.colonias[0].valor);
            this.nombreColonia = this.catalogosCP.colonias[0].texto;
          }
          this.formAddress.controls['pais'].setValue('MEX');
      }, (error) => {
        this.spinner.hideModal();
        this.formAddress.get('codigoPostal').setErrors({notfound: true});
      }
    );
    }

  }

  onChangesForm(): void {
    this.subscriptionFrm1 = this.formGeneralData.valueChanges.subscribe(() =>
        // Actualizar valores en la suscripción del modal
        this.updateModalData()
    );
    this.subscriptionFrm2 = this.formAddress.valueChanges.subscribe(() =>
        // Actualizar valores en la suscripción del modal
        this.updateModalData()
    );
    this.subscriptionFrm3 = this.formMeansOfContact.valueChanges.subscribe(() =>
        // Actualizar valores en la suscripción del modal
        this.updateModalData()
    );
  }

  updateModalData(): void {
    this.modalService.modalData.next({
        values: this.formGeneralData.value,
        valid: this.formGeneralData.valid
    });
    this.modalService.modalData.next({
        values: this.formAddress.value,
        valid: this.formAddress.valid
    });
    this.modalService.modalData.next({
        values: this.formMeansOfContact.value,
        valid: this.formMeansOfContact.valid
    });
  }

  // Cerrar el modal
  closeModal(value: any): void {
  
    let formGeneralValid = false;
    if(value) {
      if(this.formGeneralData.get('tipoPersona').value == 'fisica') {

        let statusPersonaFisica = [];
        let g = 0;

        this.formGeneralData.get('paterno').markAsTouched();
        this.formGeneralData.get('paterno').updateValueAndValidity();
        statusPersonaFisica[g++] = this.formGeneralData.get('paterno').valid;

        this.formGeneralData.get('materno').markAsTouched();
        this.formGeneralData.get('materno').updateValueAndValidity();
        statusPersonaFisica[g++] = this.formGeneralData.get('materno').valid;

        this.formGeneralData.get('nombres').markAsTouched();
        this.formGeneralData.get('nombres').updateValueAndValidity();
        statusPersonaFisica[g++] = this.formGeneralData.get('nombres').valid;

        this.formGeneralData.get('rfc').markAsTouched();
        this.formGeneralData.get('rfc').updateValueAndValidity();
        statusPersonaFisica[g++] = this.formGeneralData.get('rfc').valid;
        // hack changes
        if(this.formGeneralData.get('rfc').value) {
          this.formGeneralData.get('rfc').setValue(this.formGeneralData.get('rfc').value);
        }

        this.formGeneralData.get('fechaNac').markAsTouched();
        this.formGeneralData.get('fechaNac').updateValueAndValidity();
        statusPersonaFisica[g++] = this.formGeneralData.get('fechaNac').valid;
        // hack changes
        if(this.formGeneralData.get('fechaNac').value) {
          this.formGeneralData.get('fechaNac').setValue(this.formGeneralData.get('fechaNac').value);
        }

        this.formGeneralData.get('curp').markAsTouched();
        this.formGeneralData.get('curp').updateValueAndValidity();
        statusPersonaFisica[g++] = this.formGeneralData.get('curp').valid;
        // hack changes
        if(this.formGeneralData.get('curp').value) {
          this.formGeneralData.get('curp').setValue(this.formGeneralData.get('curp').value);
        }

        this.formGeneralData.get('genero').markAsTouched();
        this.formGeneralData.get('genero').updateValueAndValidity();
        statusPersonaFisica[g++] = this.formGeneralData.get('genero').valid;

        this.formGeneralData.get('nacionalidad').markAsTouched();
        this.formGeneralData.get('nacionalidad').updateValueAndValidity();
        statusPersonaFisica[g++] = this.formGeneralData.get('nacionalidad').valid;

        this.formGeneralData.get('paisNacimiento').markAsTouched();
        this.formGeneralData.get('paisNacimiento').updateValueAndValidity();
        statusPersonaFisica[g++] = this.formGeneralData.get('paisNacimiento').valid;

        let statusInvalid = statusPersonaFisica.filter(function(status) {
          return status == false;
        });
        formGeneralValid = statusInvalid.length == 0;
      } else {

        let statusPersonaMoral = [];
        let g = -1;

        this.formGeneralData.get('razonSocial').markAsTouched();
        this.formGeneralData.get('razonSocial').updateValueAndValidity();
        statusPersonaMoral[g++] = this.formGeneralData.get('razonSocial').valid;

        this.formGeneralData.get('fechaConst').markAsTouched();
        this.formGeneralData.get('fechaConst').updateValueAndValidity();
        statusPersonaMoral[g++] = this.formGeneralData.get('fechaConst').valid;
        // hack changes
        if(this.formGeneralData.get('fechaConst').value) {
          this.formGeneralData.get('fechaConst').setValue(this.formGeneralData.get('fechaConst').value);
        }

        this.formGeneralData.get('rfc').markAsTouched();
        this.formGeneralData.get('rfc').updateValueAndValidity();
        statusPersonaMoral[g++] = this.formGeneralData.get('rfc').valid;
        // hack changes
        if(this.formGeneralData.get('rfc').value) {
          this.formGeneralData.get('rfc').setValue(this.formGeneralData.get('rfc').value);
        }
        this.formGeneralData.get('folioMercantil').markAsTouched();
        this.formGeneralData.get('folioMercantil').updateValueAndValidity();
        statusPersonaMoral[g++] = this.formGeneralData.get('folioMercantil').valid;

        this.formGeneralData.get('giro').markAsTouched();
        this.formGeneralData.get('giro').updateValueAndValidity();
        statusPersonaMoral[g++] = this.formGeneralData.get('giro').valid;

        let statusInvalid = statusPersonaMoral.filter(function(status) {
          return status == false;
        });	
        formGeneralValid = statusInvalid.length == 0;
      }

      if(this.formAddress.value.chkDireccionPersonalizada == true) {
        this.formAddress.get('coloniaCustom').setValidators(Validators.required);
        this.formAddress.get('colonia').setValidators(null);
      } else {
        this.formAddress.get('colonia').setValidators(Validators.required);
        this.formAddress.get('coloniaCustom').setValidators(null);
      }
      this.formAddress.get('coloniaCustom').updateValueAndValidity();
      this.formAddress.get('colonia').updateValueAndValidity();

      this.formAddress.markAllAsTouched();
      this.formAddress.updateValueAndValidity();

      this.formMeansOfContact.markAllAsTouched();
      this.formMeansOfContact.updateValueAndValidity();

      if(!formGeneralValid || !this.formAddress.valid || !this.formMeansOfContact.valid) {
        return;
      }
    }
    const { colonias, entidadesFederativas, municipios } = this.catalogosCP;

    let {
      curp,
      fechaConst: fechaConstitucion,
      fechaNac: fechaNacimiento,
      folioMercantil,
      genero,
      giro,
      materno,
      nacionalidad,
      nombres,
      paisNacimiento,
      paterno,
      razonSocial,
      rfc,
      tipoPersona
    } = this.formGeneralData.value;

    const {
      calle,
      chkDireccionPersonalizada: hayColoniaCustom,
      codigoPostal,
      colonia,
      coloniaCustom,
      entidadFederativa,
      municipio,
      numeroExterior,
      numeroInterior,
      pais,
      referenciaUbicacion,
      tipoDomicilio
    } = this.formAddress.getRawValue();

    const {
      correo,
      ladaInternacional,
      ladaNacional,
      numExtension,
      telefono,
      tipoTelefono
    } = this.formMeansOfContact.value;

    const {texto: colString} = this.formAddress.value.chkDireccionPersonalizada == true ?  this.formAddress.value.coloniaCustom : colonias.length > 0 ? colonias.find(({valor}: any) => valor === colonia) : '';
    const {texto: munString} = municipios.length > 0 ? municipios.find(({valor}: any) => valor === municipio) : '';
    const {texto: fedString} = entidadesFederativas.length > 0 ? entidadesFederativas.find(({valor}: any) => valor === entidadFederativa) : '';

    let response: any = {
        value,
        domicilioCompleto: `${calle} Núm. ${numeroExterior} Col. ${this.formAddress.value.chkDireccionPersonalizada == true ?  this.formAddress.value.coloniaCustom : colString}, C.P. ${codigoPostal} ${munString} ${fedString}`,
        addressfiscalValues: this.addressfiscalValues
    };

    fechaNacimiento = this.datePipe.transform(fechaNacimiento, 'yyyyMMdd');
    fechaConstitucion = this.datePipe.transform(fechaConstitucion, 'yyyyMMdd');
    if (tipoPersona === 'fisica') {
      response.data = {
        calle,
        codigoPostal,
        colonia,
        coloniaCustom,
        correo,
        curp,
        entidadFederativa,
        fechaNacimiento,
        genero,
        hayColoniaCustom,
        ladaInternacional,
        ladaNacional,
        municipio,
        nacionalidad,
        nombres,
        numeroExterior,
        numeroInterior,
        pais,
        paisNacimiento,
        paterno,
        materno,
        rfc,
        telefono,
        tipoDomicilio,
        tipoPersona,
        tipoTelefono

      };
    } else {
      response.data = {
        calle,
        codigoPostal,
        colonia,
        coloniaCustom,
        correo,
        entidadFederativa,
        fechaConstitucion,
        folioMercantil,
        giro,
        hayColoniaCustom,
        ladaInternacional,
        ladaNacional,
        municipio,
        nacionalidad,
        numeroExterior,
        numeroInterior,
        referenciaUbicacion,
        pais,
        paisNacimiento,
        razonSocial,
        rfc,
        telefono,
        tipoDomicilio,
        tipoPersona,
        tipoTelefono

      };
    }

    if(tipoTelefono == 'TLLB') {
      response.data.numExtension = numExtension;
    }
    this.dialogRef.close(response);
  }

  setTipoPersona(tp) {
    if(tp === 'fisica') {
      this.tipoPersonaMoral.setValue(false);
      this.tipoPersonaFisica.setValue(true);
    } else {
      this.formGeneralData.get('paisNacimiento').setValidators(null);
      this.tipoPersonaFisica.setValue(false);
      this.tipoPersonaMoral.setValue(true);
    }

    this.tipoPersonaSelected = tp;
    this.formGeneralData.controls['tipoPersona'].setValue(tp);
    this.formGeneralData.get('rfc').reset();
    this.formGeneralData.get('rfc').updateValueAndValidity();
    this.formGeneralData.get('razonSocial').reset();
    this.formGeneralData.get('razonSocial').updateValueAndValidity();
  }

  setGenero(gen) {
    if(gen === 'f') {
      this.generoMasculino.setValue(false);
      this.generoFemenino.setValue(true);
    } else {
      this.generoFemenino.setValue(false);
      this.generoMasculino.setValue(true);
    }

    this.generoSelected = gen;
    this.formGeneralData.get('genero').setValue(gen);
   }

  setDireccionPersonalizada(evt) {
    if(evt.checked) {
      this.formAddress.get('coloniaCustom').setValidators(Validators.required);
      this.formAddress.get('colonia').setValidators(null);
    } else {
      this.formAddress.get('colonia').setValidators(Validators.required);
      this.formAddress.get('coloniaCustom').setValidators(null);
    }
    this.formAddress.get('coloniaCustom').updateValueAndValidity();
    this.formAddress.get('colonia').updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.subscriptionFrm1.unsubscribe();
    this.subscriptionFrm2.unsubscribe();
    this.subscriptionFrm3.unsubscribe();
  }


  /**
   * Actualizar tool tip cuando cambie el giro.
   */
   giroChange():void {
    let id = this.formGeneralData.get('giro').value;
    let valFound = this.catalogosIniciales.giros.find((o) => o.valor === id);
    this.tooltipGiro = valFound.texto;
  }

  getAddressValues(event: any){
    this.addressfiscalValues = event;
  }

  setText(valor: any){
    this.nombreColonia = valor.source.selected.viewValue;
  }

}
