import { Injectable } from '@angular/core';
import {
  ObtenerCatalogosApiInterface,
  SpecialCasesCatalogsInterface
} from '@interfaces/catalogos-api';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OtService {
  isConsulta: boolean;
  isEmpleado: boolean;
  esOTBancaSeguro: boolean = false;
  esUsuarioBancaSeguro: boolean = false;

  // Para indicar si la OT ya ha sido inicializada con data del backend
  initialized: Subject<GetOTResponseInterface>;

  // Para indicar si la OT ha sido actualizada con data del usuario
  updated: Subject<GetOTResponseInterface>;

  // Para indicar si el rol ya ha sido inicializado con data del backend
  rolInitialized: Subject<string>;

  // Para indicar si el nombre del usuario ya ha sido inicializado con data del backend
  nombreInitialized: Subject<string>;

  // Para leer los datos de la OT desde cualquier componente
  orden: GetOTResponseInterface;

  // Catalogos documentos|movimientos|solicitudes|productos
  catalogosApi: ObtenerCatalogosApiInterface;

  // Para leer el rol del usuario desde cualquier componente
  userRol: string;

  // Para consultar nombre completo del usuario desde cualquier componente
  userFullName: string;

  // Para cargo del usuario desde cualquier componente
  userCargo: string;

  // Para saber si mandó a validar. Independientemente del resultado.
  validPolicy: boolean;
  // Para saber si el número de póliza fue verificado y es válido.
  policyValid: boolean;

  // Para indicar cuando el usuario desea crear una nueva OT y se debe redireccionar
  newOT: Subject<GetOTResponseInterface>;

  // Para indicar cuando el usuario eligio la opcion salir.
  salir: Subject<string>;

  // Permisos que se poblan en la funcion initContext()
  permissions: any;

  // Tipos de rechazo
  rejectionTypes: any;

  // Watcher de la poliza
  policyWatcher: any;

  // Para indicar que se actualizo el policyWatcher
  policyWatcherUpdated: Subject<GetOTResponseInterface>;

  // Catalogos para casos especiales.
  specialCasesCatalogs: SpecialCasesCatalogsInterface;

  // Para indicar indicar que se actualizaron los datos del Agente y del Contratante.
  datosAgenteYContratante: Subject<GetOTResponseInterface>;

  // Tipos de Activacion
  activationTypes: any;

  // Tipos de Guion
  scriptTypes: any;

  // Para indicar si los catalogos documentos|movimientos|solicitudes|productos con data del backend
  catalogosInitialized: Subject<ObtenerCatalogosApiInterface>;

  // Para indicar cuando el usuario eligio la opcion guardar.
  guardar: Subject<boolean>;

  // Para saber si el usuario ya busco una persona.
  yaBuscoPersona: boolean;

  // Para indicar si la OT ya ha sido inicializada con data del backend
  validateFrm: Subject<boolean>;

  // Objeto para guardar los resultados de las validaciones de los formularios.
  validationResults: any;

  // habilitar / inhabilitar edicion de datos de agente
  changeAgentData: Subject<boolean>;

  botonera: boolean;
  botoneraRecuperar: boolean;
  botoneraValidacion: boolean;

  portalEntrada: 'PDE' | 'PDI';
  correoUserLogged: string;

  constructor() {
    this.initialized = new Subject<GetOTResponseInterface>();
    this.updated = new Subject<GetOTResponseInterface>();
    this.rolInitialized = new Subject<string>();
    this.nombreInitialized = new Subject<string>();
    this.orden = undefined;
    this.userRol = undefined;
    this.userFullName = undefined;
    this.userCargo = undefined;
    this.catalogosApi = undefined;
    this.newOT = new Subject<GetOTResponseInterface>();
    this.salir = new Subject<string>();
    this.permissions = undefined;
    this.rejectionTypes = undefined;
    this.policyWatcher = undefined;
    this.policyWatcherUpdated = new Subject<any>();
    this.specialCasesCatalogs = undefined;
    this.datosAgenteYContratante = new Subject<GetOTResponseInterface>();
    this.activationTypes = undefined;
    this.scriptTypes = undefined;
    this.catalogosInitialized = new Subject<ObtenerCatalogosApiInterface>();
    this.guardar = new Subject<boolean>();
    this.validateFrm = new Subject<boolean>();
    this.validationResults = {
      frmnn: { valid: false },
      frmmr: { valid: false },
      frmagent: { valid: false },
      frmperson: { valid: false }
    };
    this.changeAgentData = new Subject<boolean>();
    this.botonera = undefined;
    this.botoneraValidacion = undefined;
  }

  set setAgentData(enable: boolean) {
    // Avisar a los subscribers
    this.changeAgentData.next(enable);
  }

  set isInitialized(getOTResponseInterface: GetOTResponseInterface) {
    this.orden = getOTResponseInterface;
    // Avisar a los subscribers
    this.initialized.next(getOTResponseInterface);
  }

  set setRolInitialized(rol: string) {
    this.userRol = rol;
    // Avisar a los subscribers
    this.rolInitialized.next(rol);
  }

  set setNombreInitialized(nombreUsuario: string) {
    this.userFullName = nombreUsuario;
    // Avisar a los subscribers
    this.nombreInitialized.next(nombreUsuario);
  }

  set setCargoInitialized(cargoUsuario: string) {
    this.userCargo = cargoUsuario;
  }

  /**
   * Guarda los datos de la OT actualizada.
   */
  set setOt(otData: GetOTResponseInterface) {
    this.orden = otData;
    // Avisar a los subscribers
    this.updated.next(otData);
  }
  /**
   * Obtener los datos actualizados de la OT.
   */
  get ot(): GetOTResponseInterface {
    return this.orden;
  }

  set setRol(rol: string) {
    this.userRol = rol;
  }
  get rol(): string {
    return this.userRol;
  }

  set setCatalogos(catalogos: ObtenerCatalogosApiInterface) {
    this.catalogosApi = catalogos;
    // Avisar a los subscribers
    this.catalogosInitialized.next(catalogos);
  }
  get catalogos(): ObtenerCatalogosApiInterface {
    return this.catalogosApi;
  }

  set setPolizaValida(validPolicy: boolean) {
    this.validPolicy = validPolicy;
  }
  get polizaValida(): boolean {
    return this.validPolicy;
  }

  set setValidoPoliza(policyValid: boolean) {
    this.policyValid = policyValid;
  }
  get validoPoliza(): boolean {
    return this.policyValid;
  }

  get nombre(): string {
    return this.userFullName;
  }

  get cargo(): string {
    return this.userCargo;
  }

  /**
   * Guarda los permisos.
   */
  set setPermisos(permisos: any) {
    this.permissions = permisos;
  }
  /**
   * Obtener los permisos.
   */
  get permisos(): any {
    return this.permissions;
  }

  /**
   * Guarda los tipos de rechazo.
   */
  set setTiposRechazo(tiposRechazo: any) {
    this.rejectionTypes = tiposRechazo;
  }
  /**
   * Obtener los tipos de rechazo.
   */
  get tiposRechazo(): any {
    return this.rejectionTypes;
  }

  /**
   * Guarda el watcher de la poliza.
   */
  set setWatcherPoliza(watcherPoliza: any) {
    this.policyWatcher = watcherPoliza;
    this.policyWatcherUpdated.next(watcherPoliza);
  }

  /**
   * Obtener el watcher de la poliza.
   */
  get watcherPoliza(): any {
    return this.policyWatcher;
  }

  /**
   * Guarda los catalogos de caso especiales.
   */
  set setSpecialCasesCatalogs(
    catalogosCasosEspeciales: SpecialCasesCatalogsInterface
  ) {
    this.specialCasesCatalogs = catalogosCasosEspeciales;
  }
  /**
   * Obtener los catalogos de caso especiales.
   */
  get catalogosCasosEspeciales(): SpecialCasesCatalogsInterface {
    return this.specialCasesCatalogs;
  }

  /**
   * Actualizar los datos del agente y contratante.
   */
  set setDatosAgenteYContratante(ot: GetOTResponseInterface) {
    this.orden = ot;
    // Avisar a los subscribers
    this.datosAgenteYContratante.next(ot);
  }

  /**
   * Guarda los tipos de activacion.
   */
  set setTiposActivacion(tiposActivacion: any) {
    this.activationTypes = tiposActivacion;
  }
  /**
   * Obtener los tipos de activacion.
   */
  get tiposActivacion(): any {
    return this.activationTypes;
  }

  /**
   * Guarda los tipos de rechazo.
   */
  set setGuionesActivacion(guionesActivacion: any) {
    this.scriptTypes = guionesActivacion;
  }
  /**
   * Obtener los tipos de guion.
   */
  get guionesActivacion(): any {
    return this.scriptTypes;
  }

  /**
   * Guarda bandera si ya se busco la persona.
   */
  set setYaBusco(yaBuscoPersona: boolean) {
    this.yaBuscoPersona = yaBuscoPersona;
  }
  /**
   * Obtener bandera para saber si ya se busco la persona.
   */
  get yaBusco(): boolean {
    return this.yaBuscoPersona;
  }

  set validateForms(validate: boolean) {
    // Avisar a los subscribers
    this.validateFrm.next(validate);
  }

  updateValidation(formName: string, valid: boolean) {
    this.validationResults[formName].valid = valid;
  }

  display(input, action, _scope): boolean {
    try {
      var enReglas =
        _scope.reglas_pantalla[input][action].indexOf(_scope.rol) >= 0;
      var adicionales = false;
      switch (input) {
        case 'lupa_agente':
        case 'lupa_contratante':
          if (
            _scope.data.generales.tipoSolicitud === 'movimiento' ||
            _scope.data.generales.tipoSolicitud === 'renovacion'
          ) {
            if (
              _scope.data.generales.siga &&
              _scope.data.statusOrden === 'registro_da'
            ) {
              adicionales = true;
            }
          } else {
            if (_scope.data.statusOrden === 'registro_da') {
              adicionales = true;
            }
          }
          break;
        case 'autorizacion_esp':
          if (action === 'enable') {
            if (
              _scope.data.statusOrden === 'activada' &&
              _scope.rol === 'coordinacion_da' &&
              _scope.da_usuario === _scope.data.da
            ) {
              return false;
            }
            if (!_scope.responsable) {
              return true;
            }
            // Esto contesta a la pregunta ¿disable autorizacion_esp?
            if (
              _scope.data.statusOrden === 'registro_da' ||
              _scope.data.statusOrden === 'rechazada'
            ) {
              // Si el rechazo lo hizo una figura de servicio, es definitivo.
              if (
                _scope.data.statusOrden === 'rechazada' &&
                _scope.data.statusAnterior === 'figura_servicio'
              ) {
                return true;
              }
              // Si está en alguno de esos estados, checamos si el rol dice que puede.
              if (enReglas) {
                return false; // enable that shit
              } else {
                return true; // estás en el status correcto, pero con el rol incorrecto. Disable it.
              }
            } else if (_scope.data.statusOrden === 'activada') {
              // Si está activada, depende de si pueden moverla o no:
              if (
                _scope.stateParams.email !== _scope.data.usuario
              ) {
                // Esto implica que el botón de Turnar está desactivado, así que no deben poder modificar nada.
                return true;
              } else {
                // Sí puede sacarla de activada, así que debe poder modificar autorizacion_esp.
                return false;
              }
            } else {
              return true; // Si no está en uno de esos estados, disable that shit
            }
          }
          break;
        case 'input_observaciones_1':
          if (_scope.data.status === 'Terminada') {
            // Es un reproceso
            if (
              _scope.rol === 'agente_certificado' ||
              _scope.rol === 'coordinacion_da'
            ) {
              return false;
            }
          }
          if (
            _scope.data.statusOrden === 'activada' &&
            _scope.rol === 'coordinacion_da'
          ) {
            return false;
          }
          if (!_scope.responsable) {
            return true;
          }
          if (
            _scope.data.statusOrden === 'rechazada' ||
            _scope.data.statusOrden === 'terminada' ||
            _scope.data.statusOrden === 'cancelada'
          ) {
            return true;
          }
          return false;
          break;
        case 'adjuntar_documentos':
          if ((_scope.data.status !== 'Terminada' || _scope.data.status == 'Activada'
            || _scope.data.status !== 'Cancelada') && this.isSuperRol()) {
            return true;
          }

          if (_scope.data.status === 'Terminada') {
            // Es un reproceso
            if (
              _scope.rol === 'agente_certificado' ||
              _scope.rol === 'coordinacion_da'
            ) {
              return true;
            }
          }

          if (!_scope.responsable) {
            return false;
          }

          if (
            enReglas &&
            _scope.responsable &&
            _scope.data.statusOrden !== 'cancelada' &&
            _scope.data.statusOrden !== 'terminada'
          ) {
            if (
              _scope.data.statusOrden === 'rechazada' &&
              !_scope.data.autorizacionEsp
            ) {
              return false;
            }
            return true;
          }
          return false;
          break;
        default:
          adicionales = true;
      }
      return enReglas && adicionales;
    } catch (error) {
      return false;
    }
  } // end display function

  /**
   * Indicar que se desea crar una nueva OT, recargar.
   * @param ot - Datos de la Orden de Trabajo.
   */
  set registrarNueva(ot: GetOTResponseInterface) {
    // Avisar a los subscribers
    this.newOT.next(ot);
  }

  /**
   * Indicar que se desea realizar la operacion salir.
   * si la cadena trae el valor 'desdeBoton' entonces el usario
   * lo hizo desde el boton salir en la botonera.
   *
   * @param origen - Para saber desde donde se dio click.
   */
  set setSalir(origen: string) {
    // Avisar a los subscribers
    this.salir.next(origen);
  }

  /**
   * Indicar que se desea realizar la operacion guardar.
   *
   * @param userResponse - true Si, false No.
   */
  set setGuardar(userResponse: boolean) {
    // Avisar a los subscribers
    this.guardar.next(userResponse);
  }

  /**
* Valida si es uno de los 3 super roles.
* @returns true si lo es, false en caso contrario.
*/
  isSuperRol(): boolean {
    const rolesConPermisoDesviacion: string[] = ['supervisor_gmm', 'asignador', 'gerente_gmm'];

    let responseValue = false;
    for (let myUrl of rolesConPermisoDesviacion) {
      if (this.rol && this.rol.search(myUrl) >= 0) {
        responseValue = true;
        break;
      }
    }
    return responseValue;
  }

  set setBotonera(botonera: boolean) {
    this.botonera = botonera;
  }

  get getBotonera(): boolean {
    return this.botonera;
  }
}
