import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OTSummary } from '@interfaces/endpoints.interface';
import { QueryParamsInterface } from '@interfaces/general.interface';
import { ErrorMessagesService } from '@services/error-messages/error-messages.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { ModalService } from '@services/modal/modal.service';
import * as R from 'ramda';
import { OtService } from '@services/ot/ot.service';
import { LoaderService } from '@services/loader/loader.service';
import { FileteadorService } from '@services/fileteador/fileteador.service';

@Component({
  selector: 'app-generated-order',
  templateUrl: './generated-order.component.html',
  styleUrls: ['./generated-order.component.scss']
})
export class GeneratedOrderComponent implements OnInit {
  otData: OTSummary[] = [];

  displayedColumns: string[] = ['key', 'value'];
  dataSource: any;

  // Parametros de la URL (llega en la data del modal)
  urlParams: QueryParamsInterface;

  lineaNegocio: string;
  da: any;
  orden: any;
  cua: any;
  nombreAgente: string;
  nombreAsegurado: string;
  documentosEntregados: any;
  tipoSolicitud: string;
  tipoSolicitud_pdf: any;

  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  @ViewChild('pdfSubtitle', {static: false}) pdfSubtitle: ElementRef;
  rows:any[];

  constructor(
    public readonly modalService: ModalService,
      @Inject(MAT_DIALOG_DATA) public inboundData: any,
      public dialogRef: MatDialogRef<GeneratedOrderComponent>,
    public readonly errorMessagesService: ErrorMessagesService,
    private genericModalService: GenericModalService,
    private otService: OtService,
    private readonly spinner: LoaderService,
    private fileteador: FileteadorService) {
      
  }

  ngOnInit(): void {
    this.lineaNegocio = this.inboundData.response.generales.lineaNegocio;
    this.da = this.inboundData.response.datosAgente.da;
    this.orden = this.inboundData.response.num_ot;
    // TODO: Eliminar este hardcode
    // this.orden = '21G26G8848';
    this.cua = this.inboundData.response.datosAgente.cua;
    this.nombreAgente = this.inboundData.response.datosAgente.nombre;
    var apellido2 = this.inboundData.response.datosContratante.apellido2 ? " "+this.inboundData.response.datosContratante.apellido2 : "";
    this.nombreAsegurado = this.inboundData.response.datosContratante.razonSocial || this.inboundData.response.datosContratante.nombre + ' ' + this.inboundData.response.datosContratante.apellido1 + apellido2;
    this.documentosEntregados = this.inboundData.response.docs;
    this.tipoSolicitud = this.inboundData.response.generales.tipoSolicitud;
    this.urlParams = this.inboundData.urlParams;

    switch(this.inboundData.response.generales.tipoSolicitud){
      case 'nuevo_negocio':
        this.tipoSolicitud_pdf = "Nuevo Negocio";
        break;
      case 'movimiento':
        this.tipoSolicitud_pdf = "Movimiento";
        break;
      case 'cambio_grupo_individual':
        this.tipoSolicitud_pdf = "Cambio Grupo a Individual";
        break;
      case "renovacion":
        this.tipoSolicitud_pdf = "Renovación";
        break;
      default:
        this.tipoSolicitud_pdf = "";
        break;
    }

    this.rows = [{
      "title": "Estatus",
      "result": "Enviado al C.N.O"
    }, {
      "title": "Ramo",
      "result": this.translateLineaNegocio(this.lineaNegocio)
    }, {
      "title": "Dirección de Agencia",
      "result": this.da
    }, {
      "title": "Orden Trabajo",
      "result": this.orden
    }, {
      "title": "Clave y Nombre del Agente",
      "result": `${this.cua}-${this.nombreAgente}`
    }, {
      "title": "Nombre del Asegurado y/o Contratante",
      "result": this.nombreAsegurado
    }, {
      "title": "Tipo de Solicitud",
      "result": this.translateTipoSolicitud(this.tipoSolicitud_pdf)
    }, {
      "title": "Documentos Electrónicos Entregados",
      "result": R.reduce(function(str, doc) {
        return str + R.prop('tipoDoc')(doc) + "\n"
      }, "")(this.documentosEntregados)
    }];
    
    this.otData = [
      {key: 'Estatus', value: "Enviado al C.N.O"},
      {key: 'Ramo', value: this.translateLineaNegocio(this.lineaNegocio)},
      {key: 'Dirección de Agencia', value: this.da},
      {key: 'Orden de Trabajo', value: this.orden},
      {key: 'Clave y Nombre del Agente', value: `${this.cua}-${this.nombreAgente}`},
      {key: 'Nombre del Asegurado y/o Contratante', value: this.nombreAsegurado},
      {key: 'Tipo de Solicitud', value: this.translateTipoSolicitud(this.tipoSolicitud_pdf)},
      {key: 'Documentos Electrónicos Entregados', value: R.reduce(function(str, doc) {
        return str + R.prop('tipoDoc')(doc) + "\n"
      }, "")(this.documentosEntregados)}
    ];
    
    this.dataSource = this.otData;
  }

  close() {
    if (this.urlParams.menu === 'workflow') {
      this.genericModalService.showModal('generico', 'nuevaOT', {urlParams: this.urlParams, data: this.inboundData.response});
    } else {
      if (this.urlParams.orden_id) {
        this.otService.setSalir = '';
      } else {
        //Invocar a registrarNueva() en ActionButtonsComponent
        this.otService.registrarNueva = this.inboundData.response;
      }
    }
    
    this.dialogRef.close();
  };

  translateLineaNegocio(x) {
    switch(x){
      case 'gmm':
        return "Gastos Médicos Mayores";
      case 'vida':
        return "Vida"
      default:
        return x;
    }
  }

  translateTipoSolicitud(x) {
    switch(x){
      case "nuevo_negocio":
        return "Nuevo Negocio";
      default:
        return x;
    }
  }

  /**
   * Request a para obtener el archivo PDF en el backend.
   */
  download() {
    let payload = {
      num_ot: this.orden
    };
    this.spinner.show();
    this.dialogRef.addPanelClass('hidePanel');
    this.fileteador.generaReciboOT(payload).subscribe(
      (responseGeneraReciboOT) => {
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');
        this.downloadAsPDF(responseGeneraReciboOT.archivo);
      }, (errorGeneraReciboOT) =>{
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');     
    });
  }

  /**
   * Descarga de la cedula de autorizacion.
   * 
   * @param fileContent - base64 del archivo.
   */
   downloadAsPDF(fileContent: string): void {
    var documentContent = fileContent;
    var documentName = 'recibo.pdf';
    var myDocumentLink = document.createElement("a");
    myDocumentLink.href = documentContent;
    myDocumentLink.download = documentName;
    document.body.appendChild(myDocumentLink);
    myDocumentLink.click();
  }

}
