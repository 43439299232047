import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ObtenerCatalogosApiInterface } from '@interfaces/catalogos-api';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { QueryParamsInterface } from '@interfaces/general.interface';
import { EndpointsService } from '@services/endpoints/endpoints.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { LoaderService } from '@services/loader/loader.service';
import { ModalService } from '@services/modal/modal.service';
import { OtService } from '@services/ot/ot.service';
import { UtilsService } from '@services/utils/utils.service';
import { SessionService } from '@services/session/session.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DialogConfirmComponent } from '@components/modals/dialog-confirm/dialog-confirm.component';
import { DialogInputConfirmComponent } from '@components/modals/dialog-input-confirm/dialog-input-confirm.component';
import { REGLAS_PANTALLA as reglas_pantalla} from '@fixtures/nueva-ot.fixture';
import { AuthService } from '@services/auth/auth.service';
import { FirebaseOtService } from '@services/firebase-ot/firebase-ot.service';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { RecoverComponent } from '@components/modals/recover/recover.component';
import { PersonasService } from '@services/personas/personas.service';

import { EventosScrollService } from '@services/eventos-scroll/eventos-scroll.service';
import { BitacoraComponent } from '@components/modals/bitacora/bitacora.component';

@Component({
    selector: 'app-action-buttons',
    templateUrl: './action-buttons.component.html',
    styleUrls: ['./action-buttons.component.scss']
})
export class ActionButtonsComponent implements OnInit {
    @Input()
    urlParams: QueryParamsInterface;
    @Input()
    data: GetOTResponseInterface;

    @Input()
    permisos: any;

    subscription: Subscription;

    // Suscripcion a cambios en la OT compartida
    subscriptionUpdateOT: Subscription;

    // Suscripcion a OT Inicializada
    subscriptionOT: Subscription;

    // DA en la URL, Para habilitar o inhabilitar el boton Turnar
    da_usuario: string;

    // Nombre del usuario en sesion getNombre
    nombreUsuario: string;

    // Catalogos documentos|movimientos|solicitudes|productos
    catalogos: ObtenerCatalogosApiInterface;

    // Recargar para crear un nueva OT
    subscriptionNuevaOT: Subscription;

    // Suscripcion a Operacion Salir
    subscriptionSalir: Subscription;

    // Suscripcion a Operacion Guardar
    subscriptionGuardar: Subscription;

    // Suscripcion policyWatcher actualizado
    subscriptionPolicyWatcher: Subscription;

    // watcher de la poliza
    policyWatcher: any;

    rol: string;

    // Suscripcion a lectura del rol del usuario.
    subscriptionRol: Subscription;

    // Tipos de documentos a validar en la seccion de expediente digital
    opcionesDocumentos: any;

    responsable: boolean;

    isCharola: boolean;

    rolValido: boolean;

    rolValidoReprocesar: boolean;

    noFigura: boolean;

    esEmpleado: boolean;

    constructor(
      private authService: AuthService,
        private utils: UtilsService,
        private otService: OtService,
        public readonly dialog: MatDialog,
        private modalService: ModalService,
        private readonly sessionService: SessionService,
        private genericModalService: GenericModalService,
        private readonly spinner: LoaderService,
        private endpointsService: EndpointsService,
        private readonly datePipe: DatePipe,
        private firebaseOt : FirebaseOtService,
        private readonly storage: LocalStorageService,
        private personasSrv: PersonasService,
        private eventosScroll: EventosScrollService,

    ) {
        this.noFigura = true;
        this.subscription = new Subscription();
        this.subscriptionOT = new Subscription();
        this.subscriptionUpdateOT = new Subscription();
        this.subscriptionNuevaOT = new Subscription();
        this.subscriptionSalir = new Subscription();
        this.subscriptionPolicyWatcher = new Subscription();
        this.nombreUsuario = '';
        this.subscriptionGuardar = new Subscription();
        this.responsable = false;
        this.isCharola = false;
    }

    disableTurnButton: boolean;
    ngOnInit(): void {

        this.rolValido = true;
        this.rolValidoReprocesar = true;

        this.esEmpleado = true

        this.urlParams = {
            email: '',
            firma: '',
            ramo: '',
            roles: [],
            tipoPersona: '',
            tipo_solicitud: '',
            orden_id: '',
            menu: '',
            da: ''
        };
        // Cargar datos cuando sea una consulta de OT
        this.subscriptionOT = this.otService.initialized.subscribe((otData) => {
            // LLego la data
            this.data = otData;

            this.esEmpleado = this.otService.isEmpleado;


            if (this.data.responsable === this.urlParams.email) {
                this.responsable = true;
            } else {
                this.responsable = false;
                if (this.data.statusOrden === 'registro_da') {
                    this.responsable = true;
                }
            }

            const esHeredado = this.authService.rolGnp
            ? this.authService.rolGnp.find(
                  (rol: string) =>
                      rol.includes('Heredado')
              )
            : false;

            this.rolValido =  !( this.authService.rolGnp.includes('GM Work Site Emite') 
            || this.authService.rolGnp.includes('Respaldo MC emite') || this.authService.rolGnp.includes('Agentes')
            || Boolean(esHeredado) );

            this.rolValidoReprocesar =  !( this.authService.rolGnp.includes('Agentes') || Boolean(esHeredado) );
            
            if (this.urlParams.da && typeof this.urlParams.da === 'string') {
                this.da_usuario = JSON.parse(this.urlParams.da)[0].trim();
            }
        });

        // Cargar datos cuando sea una consulta de OT
        this.subscriptionNuevaOT = this.otService.newOT.subscribe(
            (otData: GetOTResponseInterface) => {
                this.registrarNueva(otData);
            }
        );

        // Operacion Salir
        this.subscriptionSalir = this.otService.salir.subscribe(
            (param: string) => {
                this.salir(param);
            }
        );

        // Operacion Guardar
        this.subscriptionGuardar = this.otService.guardar.subscribe(
            (param: boolean) => {
                this.guardar(param);
            }
        );

        // suscrbirse a cambios en el watcher para enviarlo a donde se requiera
        this.subscriptionPolicyWatcher = this.otService.policyWatcherUpdated.subscribe(
            (policyWatcher) => {
                // LLego la data del policyWatcher
                this.policyWatcher = policyWatcher;
            }
        );

        // Suscribir a cambios en el rol
        // Desactivar campos en base a las reglas, rol del usuario y data de la Orden de trabajo
        this.subscriptionRol = this.otService.rolInitialized.subscribe(
            (rol) => {

                this.rol = rol;
                let userDA = "";

                if (typeof(this.urlParams.da) === "string") {
                  userDA = JSON.parse(this.urlParams.da)[0].trim();
                }

                let _scope = {
                  reglas_pantalla: reglas_pantalla,
                  rol: rol,
                  data: this.data,
                  responsable: this.responsable,
                  da_usuario: userDA,
                  stateParams: this.urlParams
                };
                this.isCharola = _scope.data.statusOrden.includes('charola_virt');
                const validCoordinarDa = this.rol === 'coordinacion_da' && (_scope.data.status === 'Activada' || _scope.data.status === 'Rechazada' || _scope.data.status === 'Terminada');
                const validAgenteCert = this.rol === 'agente_certificado' && _scope.data.status === 'Rechazada';
                const validAgenteCertTer = this.rol === 'agente_certificado' && (_scope.data.status === 'Terminada' || _scope.data.status === 'Activada');

                if (validCoordinarDa) {
                  this.disableTurnButton = true;
                } else if (validAgenteCert) {
                  this.disableTurnButton = false;
                } else if (validAgenteCertTer) {
                  this.disableTurnButton = true;
                } else {
                  this.disableTurnButton = this.otService.display('adjuntar_documentos', 'show', _scope);
                }

                if( this.authService.rolGnp.includes('GM Work Site Emite') 
                || this.authService.rolGnp.includes('Respaldo MC emite')){
                    if(_scope.data.status === 'Activada' || _scope.data.status === 'Rechazada'){
                        this.disableTurnButton = true;
                    }
                }
                if(this.esFiguraServicio()){
                    this.noFigura = false;
                }

            }
        );

        // Suscribir a lectura de catalogos documentos|movimientos|solicitudes|productos
        this.subscriptionRol = this.otService.catalogosInitialized.subscribe(
            (catalogos) => {
                this.opcionesDocumentos = catalogos.documentos;
            }
        );

        // Actualizat datos del agente al buscar una poliza
        this.subscriptionOT = this.otService.datosAgenteYContratante.subscribe(
            (datosAgenteYContratante) => {
                // LLego la data
                this.data = datosAgenteYContratante;
        });

        this.disableTurnButton = false;
    } // end ngOnInit()

    /**
     * Recarga para generar una nueva OT.
     */
    registrarNueva(data: GetOTResponseInterface): void {
        window.location.reload();
    }

    esFiguraServicio(){
       return this.authService.rolAux.includes('figura_servicio') 
                || this.authService.rolGnp.includes('Workflow DA Gerente Figura de Servicio');
    }

    validaBotonEmpleado(){
        return !this.esEmpleado || (this.esEmpleado && !this.otService.isConsulta);
    }

    eliminarPolizasVacias(data){
        
        const eliminar = ['numeroPoliza', 'numeroPrepoliza'];

        eliminar.forEach((elemento) => {
            if  (data.hasOwnProperty(elemento) && (data[elemento] === null || data[elemento] === undefined)) {
              delete data[elemento];
            }
        });
    }

    enviar(): void {
        this.data = this.otService.ot;
        this.data = this.utils.fixOT(this.data);

        this.eliminarPolizasVacias(this.data.generales);

        //Disparar validacion de datos generales
        this.otService.validateForms = true;

        if(this.urlParams.tipo_solicitud === 'nuevo_negocio' ||
        this.urlParams.tipo_solicitud === 'cambio_grupo_individual') {
            if(!this.otService.validationResults.frmnn.valid) {
                this.showModal('generico', 'validacionEnviar', null);
                return;
            }
        }
        if(this.urlParams.tipo_solicitud === 'movimiento' ||
        this.urlParams.tipo_solicitud === 'renovacion') {
            if(!this.otService.validationResults.frmmr.valid) {
                this.showModal('generico', 'validacionEnviar', null);
                return;
            }
        }

        if(!this.otService.validationResults.frmagent.valid) {
            this.showModal('generico', 'validacionEnviar', null);
            return;
        }

        if(!this.otService.validationResults.frmperson.valid) {
            this.showModal('generico', 'validacionEnviar', null);
            return;
        }

        if(this.data.autorizacionEsp && this.data.razonAutorizacionEsp == null){
            this.showModal('generico', 'validacionEnviar', null);
            return;
        }

        // Obtener los Catalogos documentos|movimientos|solicitudes|productos
        this.catalogos = this.otService.catalogos;
        // Para saber si el número de póliza fue verificado y es válido.
        let polizaValida = this.otService.polizaValida;
        // Para saber si mandó a validar. Independientemente del resultado.
        let validoPoliza = this.otService.validoPoliza;
        let opcionesDocumentos = this.catalogos.documentos;
        if (this.data.statusOrden !== 'registro_da') {
            return;
        }
        // Validación de póliza. Sólo para movimiento.
        if (
            this.data.generales.tipoSolicitud === 'movimiento' &&
            this.data.generales.siga !== true
        ) {
            if (!validoPoliza) {
                this.showModal('generico', 'noHaValidado', null);
                return;
            }
            if (!polizaValida) {
                this.showModal('generico', 'polizaInvalida', {
                    numeroPoliza: this.data.generales.numeroPoliza
                });
                return;
            }
        }

        if (
            !this.data.generales.numeroSolicitantes
        ) {
            this.data.generales.numeroSolicitantes = 0;
        }

        // Validación de documentos
        let i: number,
            requiredDocs = [];
        for (i = 0; i < opcionesDocumentos.length; i++) {
            if (opcionesDocumentos[i].requerido) {
                requiredDocs.push(opcionesDocumentos[i].texto);
            }
        }
        for (i = 0; i < this.data.docs.length; i++) {
            if (requiredDocs.indexOf(this.data.docs[i].tipoDoc) >= 0) {
                requiredDocs.splice(
                    requiredDocs.indexOf(this.data.docs[i].tipoDoc),
                    1
                );
            }
        }
        if (requiredDocs.length > 0) {
            this.showModal('generico', 'faltanDocs', { docs: requiredDocs });
            return;
        }
        // Pasó validaciones
        this.spinner.show();
        let payloadOrden = this.construirPayload('enviar');

        this.endpointsService.enviarOrden(payloadOrden).subscribe(
            (responseCreateOT) => {
                this.spinner.hide();
                this.showModal(
                    'ordenGenerada',
                    { ot: responseCreateOT, urlParams: this.urlParams },
                    null
                );
            },
            (errorCreateOT) => {
                this.spinner.hide();
                this.showModal('generico', 'errorEnviando', {
                    error: errorCreateOT.error.message
                });
            }
        );
    }

    /**
     * Contruye el payload para persistir una OT.
     *
     * @param operacion - Operacion enviar|guardar.
     *
     * @returns
     */
    construirPayload(operacion) {
        if(this.data.datosAgente.folios) {
            delete this.data.datosAgente.folios;
        }
        let payload = JSON.parse(JSON.stringify(this.data));

        payload['medio_apertura'] = "WF DA´s";

        this.nombreUsuario = this.otService.nombre;

        let currentDate = this.datePipe.transform(
            new Date(),
            environment.datePatternObs
        );

        payload.usuario = this.urlParams.email;
        if (this.urlParams.da !== undefined) {
            payload.da = JSON.parse(this.urlParams.da)[0].trim();
        } else {
            payload.da = this.data.datosAgente.da;
        }
        if (payload.autorizacionEsp && operacion === 'enviar') {
            let observacion = {
                textoTipo: 'INTRODUCIDO POR',
                texto:
                    '¿Por qué requiere autorización especial?\n' +
                    payload.razonAutorizacionEsp,
                nombre: this.nombreUsuario,
                fecha: currentDate,
                canDelete: true
            };
            if (this.urlParams.menu === 'workflow') {
                payload.observacionesInternas.unshift(observacion);
            } else if (this.urlParams.menu === 'servicios_asistidos') {
                payload.observacionesPortal.unshift(observacion);
            }
        }
        payload = this.utils.filterNewObservations(payload);
        payload = this.utils.deleteEmptyObservations(payload);
        return payload;
    }

    // Operacion guardar, para el portal de intermediarios
    guardar(flag): void {
        this.data = this.utils.fixOT(this.data);

        //Disparar validacion de datos generales
        this.otService.validateForms = true;

        if(this.urlParams.tipo_solicitud === 'nuevo_negocio' ||
        this.urlParams.tipo_solicitud === 'cambio_grupo_individual') {
            if(!this.otService.validationResults.frmnn.valid) {
                this.showModal('generico', 'validacionEnviar', null);
                return;
            }
        }
        if(this.urlParams.tipo_solicitud === 'movimiento' ||
        this.urlParams.tipo_solicitud === 'renovacion') {
            if(!this.otService.validationResults.frmmr.valid) {
                this.showModal('generico', 'validacionEnviar', null);
                return;
            }
        }

        if(!this.otService.validationResults.frmagent.valid) {
            this.showModal('generico', 'validacionEnviar', null);
            return;
        }

        if(!this.otService.validationResults.frmperson.valid) {
            this.showModal('generico', 'validacionEnviar', null);
            return;
        }

        // Obtener los Catalogos documentos|movimientos|solicitudes|productos
        this.catalogos = this.otService.catalogos;
        // Para saber si el número de póliza fue verificado y es válido.
        let polizaValida = this.otService.polizaValida;
        // Para saber si mandó a validar. Independientemente del resultado.
        let validoPoliza = this.otService.validoPoliza;
        let opcionesDocumentos = this.catalogos.documentos;
        if (this.data.statusOrden !== 'registro_da') {
            return;
        }
        // Validación de póliza. Sólo para movimiento.
        if (
            this.data.generales.tipoSolicitud === 'movimiento' &&
            this.data.generales.siga !== true
        ) {
            if (!validoPoliza) {
                this.showModal('generico', 'noHaValidado', null);
                return;
            }
            if (!polizaValida) {
                this.showModal('generico', 'polizaInvalida', {
                    numeroPoliza: this.data.generales.numeroPoliza
                });
                return;
            }
        }

        if (
            !this.data.generales.numeroSolicitantes
        ) {
            this.data.generales.numeroSolicitantes = 0;
        }

        
        this.spinner.show();
        this.endpointsService
            .guardarOrden(this.construirPayload('guardar'))
            .subscribe(
                (response) => {
                    this.spinner.hide();
                    if (flag) {
                        // flag nos indica si estamos guardando porque borraron un documento y hay que actualizar la orden
                        this.showModal('generico', 'borradoExitoso', undefined);
                    } else {
                        // Si hay orden_id, significa que es una orden que ya habían guardado y entraron por consulta.
                        // En el modal de aceptar, el botón debe regresar a consulta, no recargar.
                        this.showModal(
                            'generico',
                            this.urlParams.orden_id
                                ? 'guardarExitosoSalir'
                                : 'guardarExitosoNueva',
                            { num_ot: response.num_ot }
                        );
                    }
                },
                (errorGuardar) => {
                    this.spinner.hide();
                    this.showModal('generico', 'guardarFallido', {
                        error: errorGuardar.error.message
                    });
                }
            );
    }

    showModal(tipo: string, accion: any, extras2: any): MatDialogRef<unknown, any> {
        return this.genericModalService.showModal(tipo, accion, extras2);
    }

    rolesRespaldoMedico(){
        return this.authService.rolGnp.includes('GM Work Site Emite') 
        || this.authService.rolGnp.includes('Respaldo MC emite');
    }


    /**
     * Operacion Turnar para los roles de banca seguro.
     */
     turnarActivada(statusEnviar){

        const status = statusEnviar;
        const operacion = statusEnviar;

        var payload = {
            num_ot: this.data.num_ot,
            status: status,
            orden: JSON.parse(JSON.stringify(this.data))
        };

        payload = this.utils.filterNewObservations(payload);
        payload = this.utils.deleteEmptyObservations(payload);

        this.spinner.show();
        this.endpointsService.turnarOrden(payload, operacion).subscribe(
            (response) => {
                this.spinner.hide();
                this.otService.setSalir = '';
            },
            (error) => {
                this.spinner.hide();
                if(error.error.warning != undefined || error.error.warning != null){
                    this.genericModalService.showModal('generico', 'avisoErrorTurnar', { error: error.error.message });
                  } else {
                    this.genericModalService.showModal('generico', 'errorTurnando', { error: error.error.message });
                }
            }
        );
    }



    /**
     * Operacion Turnar para el intermediario.
     */
    turnarIntermediario() {
        this.data = this.utils.fixOT(this.data);

        if(this.rolesRespaldoMedico()){
            this.data.autorizacionEsp = false;
        }


        if(this.rolesRespaldoMedico()){
            this.data.autorizacionEsp = false;
        }

        if(this.data.autorizacionEsp && this.data.razonAutorizacionEsp == null){
            this.showModal('generico', 'faltaAutorizacion', null);
            return;
        }

        if (
            !this.data.autorizacionEsp &&
            this.data.statusOrden === 'rechazada' &&
            !(this.rolesRespaldoMedico())
        ) {
            this.showModal('generico', 'noAutorizacion', undefined);
            return;
        }
        
        if (this.data.statusOrden === 'activada') {
            let agregoObs = this.data.observacionesPortal.some(function (x) {
                return x.canDelete;
            });
            if (!agregoObs && !(this.rolesRespaldoMedico())) {
                this.showModal('generico', 'faltaObs', undefined);
                return;
            }
        }

        let status = '';
        var operacion = '';
        if (this.data.statusOrden === 'activada') {
            status = 'desactivada';
            operacion = 'desactivar';
        } else if (this.data.statusOrden === 'rechazada') {
            status = 'desrechazada';
            operacion = 'desrechazar';
        }

        var payload = {
            num_ot: this.data.num_ot,
            status: status,
            orden: JSON.parse(JSON.stringify(this.data))
        };

        let currentDate = this.datePipe.transform(
            new Date(),
            environment.datePatternObs
        );

        payload = this.utils.filterNewObservations(payload);
        if (payload.orden.autorizacionEsp) {
            var observacion = {
                textoTipo: 'INTRODUCIDO POR',
                texto:
                    '¿Por qué requiere autorización especial?\n' +
                    payload.orden.razonAutorizacionEsp,
                nombre: this.nombreUsuario,
                fecha: currentDate,
                canDelete: false
            };
            if (this.urlParams.menu === 'workflow') {
                payload.orden.observacionesInternas.unshift(observacion);
            } else if (this.urlParams.menu === 'servicios_asistidos') {
                payload.orden.observacionesPortal.unshift(observacion);
            }
        }
        payload = this.utils.deleteEmptyObservations(payload);
        this.spinner.show();
        this.endpointsService.turnarOrden(payload, operacion).subscribe(
            (response) => {
                this.spinner.hide();
                this.otService.setSalir = '';
            },
            (error) => {
                this.spinner.hide();
                if(error.error.warning != undefined || error.error.warning != null){
                    this.genericModalService.showModal('generico', 'avisoErrorTurnar', { error: error.error.message });
                  } else {
                    this.genericModalService.showModal('generico', 'errorTurnando', { error: error.error.message });
                }
            }
        );
    }

    /**
     * Operacion reprocesar.
     */
    reprocesar() {
        this.data = this.utils.fixOT(this.data);

        var agregoObs = this.data.observacionesPortal.some(function (x) {
            return x.canDelete;
        });

        if (!agregoObs && !(this.rolesRespaldoMedico())) {
            this.showModal('generico', 'faltaObs', undefined);
            return;
        }

        let payload: any = {
            num_ot: this.data.num_ot,
            usuario: this.urlParams.email,
            orden: this.data
        };

        payload = this.utils.filterNewObservations(payload);
        payload = this.utils.deleteEmptyObservations(payload);

        // Invocar a endpoints/gmm/v1/reprocesarOT
        this.spinner.show();
        this.endpointsService.reprocesar(payload).subscribe(
            (response) => {
                this.spinner.hide();
                this.otService.setSalir = '';
            },
            (error) => {
                this.spinner.hide();
                this.showModal('generico', 'errorTurnando', {
                    error: error.error.message
                });
            }
        );
     }

     /**
      * Revaloracion especial.
      */
     revaloracionEspecial(): void{

        this.data = this.utils.fixOT(this.data);

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        this.eventosScroll.eventScroll();
        const dialogRef = this.dialog.open(
            DialogInputConfirmComponent,
            dialogConfig
        );

        this.subscription = dialogRef.afterClosed().subscribe((response) => {

            if (response) {
                this.data.razonAutorizacionEsp = response;
                this.data.autorizacionEsp = true;

                let payload: any = {
                    num_ot: this.data.num_ot,
                    usuario: this.urlParams.email,
                    orden: this.data
                };

                let currentDate = this.datePipe.transform(
                    new Date(),
                    environment.datePatternObs
                );
        
                payload = this.utils.filterNewObservations(payload);
                if (response) {
                    var observacion = {
                        textoTipo: 'INTRODUCIDO POR',
                        texto:
                            '¿Por qué requiere autorización especial?\n' +
                            payload.orden.razonAutorizacionEsp,
                        nombre: this.otService.userFullName,
                        fecha: currentDate,
                        canDelete: false
                    };
                    if (this.urlParams.menu === 'workflow') {
                        payload.orden.observacionesInternas.forEach(element => {
                            element.canDelete = false;
                        });
                        payload.orden.observacionesInternas.unshift(observacion);
                    } else if (this.urlParams.menu === 'servicios_asistidos') {
                        payload.orden.observacionesPortal.forEach(element => {
                            element.canDelete = false;
                        });
                        payload.orden.observacionesPortal.unshift(observacion);
                    }
                }
                payload = this.utils.deleteEmptyObservations(payload);

                // Invocar a endpoints/gmm/v1/revalorarOT
                this.spinner.show();
                this.endpointsService.revaloracionEspecial(payload).subscribe(
                    (response) => {
                        this.spinner.hide();
                        this.showModal('generico', 'revaloracionExitosa', null);
                    },
                    (error) => {
                        this.spinner.hide();
                        this.showModal('generico', 'errorTurnando', {
                            error: error.error.message
                        });
                    }
                );
            }

        });
     }

    /**
     * Operacion salir.
     * @param param - Parametros para saber si la operacion se eligio desde el boton salir.
     */
    salir(param: string = '') {
        this.data = this.utils.fixOT(this.data);

        // Construimos la url de consulta para redireccionar
        let fixedParams = JSON.parse(JSON.stringify(this.urlParams));
        Object.keys(fixedParams).forEach(function (key) {
            if (
                fixedParams[key] === undefined ||
                key === 'menu' ||
                key === 'orden_id' ||
                key === 'asignacion'
            ) {
                delete fixedParams[key];
            }
        });

        // Serializacion de parametros
        let params: URLSearchParams = this.utils.serialize(fixedParams);

        // params.toString() devuelve los parametros serializados sin el ?
        const asigConsulta = (this.urlParams.asignacion === 'true' ? '/asignacion?' : '/consulta?');

        var url =
            environment.endpointConsulta +
            this.urlParams.menu +
            asigConsulta +
            params.toString();

        if(this.otService.isConsulta){
            const otEliminar = ![undefined,null,""].includes(this.urlParams.orden_id) ? 
            this.urlParams.orden_id : this.storage.getItem('orden_id')
            this.firebaseOt.depurarDataOT(otEliminar);
        }
        // Checamos si la acción viene desde el botón de salir o desde una función
        if (param === 'desdeBoton') {
            const estaEnRegistro = this.data.statusOrden === 'registro_da' && this.validaBotonEmpleado();

            // Checamos si hay nuevas observaciones Internas
            var agregoObs = this.data.observacionesInternas.some(function (x) {
                return x.canDelete;
            });
            // Checamos si hay nuevas observaciones de Portal
            var agregoObsPortal = this.data.observacionesPortal.some(function (
                x
            ) {
                return x.canDelete;
            }) &&  (this.data.status != 'Cancelada Solicitante'
               && this.data.status != 'Cancelada Automática');

            if(this.data.status === 'En Registro' 
                && this.otService.isEmpleado  && this.otService.isConsulta){
                    agregoObsPortal = false;
            }

            // Checamos si hay nuevos Documentos
            var agregoNewDocs = this.data.docs.some(function (doc) {
                return !doc.fecha;
            });

            if (
                agregoObs ||
                agregoObsPortal ||
                agregoNewDocs ||
                (estaEnRegistro && !this.esFiguraServicio())
            ) {
                this.genericModalService
                    .showModal('generico', 'guardarObsDocs', null)
                    .afterClosed()
                    .subscribe((response) => {
                        if (response === 'guardarObsDocs') {
                            if (estaEnRegistro) {
                                const payloadGuardar = this.construirPayload(
                                    'guardar'
                                );

                                this.spinner.show();

                                this.endpointsService
                                    .guardarOrden(payloadGuardar)
                                    .subscribe(
                                        (responseGuardarOrden) => {
                                            this.spinner.hide();
                                            window.location.href = url;
                                        },
                                        (error) => {

                                            this.spinner.hide();
                                            this.showModal(
                                                'generico',
                                                'errorGuardarObsDocs',
                                                null
                                            );
                                        }
                                    );
                            } else {
                                var newObservations = this.data.observacionesInternas.filter(
                                    function (observation) {
                                        return observation.canDelete;
                                    }
                                );
                                var newObservationsPortal = this.data.observacionesPortal.filter(
                                    function (observation) {
                                        return observation.canDelete;
                                    }
                                );
                                // Obtener solos los documentos que NO tengan fecha
                                var newDocs = this.data.docs.filter(function (
                                    doc
                                ) {
                                    return !doc.fecha;
                                });

                                // invocamos el servicio para guardar cambios
                                var payload: any = {
                                    num_ot: this.data.num_ot,
                                    usuario: this.urlParams.email
                                };
                                if (agregoObs) {
                                    payload.observacionesInternas = newObservations;
                                }
                                if (agregoObsPortal) {
                                    payload.observacionesPortal = newObservationsPortal;
                                }
                                if (agregoNewDocs) {
                                    payload.docs = newDocs;
                                }

                                this.spinner.show();

                                this.endpointsService
                                    .guardarObsDocs(payload)
                                    .subscribe(
                                        (responseGuardarObsDocs) => {

                                            this.spinner.hide();
                                            window.location.href = url;
                                        },
                                        (error) => {

                                            this.spinner.hide();
                                            this.showModal(
                                                'generico',
                                                'errorGuardarObsDocs',
                                                null
                                            );
                                        }
                                    );
                            }
                        } else {
                            // Si hizo click en cancelar, salimos sin guardar
                            window.location.href = url;
                        }
                    });

            } else {
                // Si no agregó observaciones, ni documentos, ni esta en registro, nos salimos directo
                window.location.href = url;
            }
        } else {
            // Si es desde una función, nos salimos directo.
            window.location.href = url;
        }
    }

    /* --------------- Acciones botones inferiores menu == 'workflow' && statusOrden != 'registro_da' --------------------------- */
    emitir() {
        this.data = this.utils.fixOT(this.data);
        let allDocsApproved = true,
            requiredDocs = [],
            i;
        for (i = 0; i < this.opcionesDocumentos.length; i++) {
            if (this.opcionesDocumentos[i].requerido) {
                requiredDocs.push(this.opcionesDocumentos[i].texto);
            }
        }

        for (i = 0; i < this.data.docs.length; i++) {
            if (
                requiredDocs.indexOf(this.data.docs[i].tipoDoc) >= 0 &&
                !this.data.docs[i].aprobado
            ) {
                allDocsApproved = false;
            }
        }

        // Para estos dos roles, omitir la validacion de los documentos
        if (
            this.data.statusOrden === 'suscripcion_adm_esp' ||
            this.data.statusOrden === 'suscripcion_med_esp'
        ) {
            allDocsApproved = true;
        }

        if (!allDocsApproved) {
            this.showModal('generico', 'docsReqNoValidados', {
                accion: 'enviar',
                docs: requiredDocs
            });
            return;
        }

        // $scope.showSpinner = true;
        this.spinner.show();
        this.endpointsService.validaEnvioArmado(this.data.num_ot).subscribe(
            (response) => {
                this.spinner.hide();
                if (
                    this.data.generales.tipoSolicitud === 'movimiento' ||
                    this.data.generales.tipoSolicitud === 'renovacion'
                ) {
                    if (response.generaCaratula === false) {
                        this.showModal(
                            'generico',
                            'avisoSeTerminaOT',
                            undefined
                        );
                    } else {
                        this.data.generales.numeroPoliza =
                            response.numeroPoliza;
                        if (this.otService.watcherPoliza.changed) {
                            this.showModal('cambioPoliza', true, undefined);
                        } else {
                            this.showModal('cambioPoliza', false, undefined);
                        }
                    }
                } else if (
                    this.data.generales.tipoSolicitud === 'nuevo_negocio' ||
                    this.data.generales.tipoSolicitud ===
                        'cambio_grupo_individual'
                ) {
                    if (response.generaCaratula && response.emitidaInfo) {
                        this.data.generales.numeroPoliza =
                            response.numeroPoliza;
                        if (this.otService.watcherPoliza.changed) {
                            this.showModal('cambioPoliza', true, undefined);
                        } else {
                            this.showModal('cambioPoliza', false, undefined);
                        }
                    } else if (
                        response.generaCaratula &&
                        response.emitidaInfo === false
                    ) {
                        if(!["", undefined, null].includes(response.numeroPoliza)){
                            this.data.generales.numeroPoliza =
                            response.numeroPoliza;
                        }
                        if (this.otService.watcherPoliza.changed) {
                            this.showModal('cambioPoliza', true, true);
                        } else {
                            this.showModal('cambioPoliza', false, true);
                        }
                    } else if (response.generaCaratula === false) {
                        this.showModal('generico', 'avisoSeTerminaOT', null);
                    }
                }
            },
            (error) => {
                this.spinner.hide();
                this.showModal('generico', 'errorValidaEnvioArmado', undefined);
            }
        );
    }

    // Operacion turnar
    abrirTurnar() {
        this.data = this.utils.fixOT(this.data);

        if(this.otService.isEmpleado && this.data.status === 'Activada'){
            
            this.turnarActivada('desactivada');

        } else if(this.otService.isEmpleado &&  (
            this.otService.esUsuarioBancaSeguro || this.authService.rolGnp.includes('Workflow DA Operador Alta GMM')  )
            && this.data.status === 'Rechazada'){
                this.turnarActivada('desrechazada');
        } 
         else {

            this.abrirTurnarPrincipal();
        }

        
    }

    abrirTurnarPrincipal(){
        var cond1Turnar =
        this.data.statusOrden === 'suscripcion_esp' ||
        this.data.statusOrden === 'captura';
    var cond2Turnar =
        this.data.statusOrden === 'asesor_mov' ||
        this.data.statusOrden === 'gestion_adm';
    if (cond1Turnar || cond2Turnar) {
        var allDocsApproved = true;

        for (var i = 0; i < this.data.docs.length; i++) {
            if (!this.data.docs[i].aprobado) {
                allDocsApproved = false;
            }
        }
        if (allDocsApproved) {
            this.showModal('changeStatus', 'turnar', undefined);
        } else {
            this.showModal('generico', 'docsNoValidados', undefined);
        }
    } else if (
        this.data.statusOrden === 'figura_servicio' &&
        this.data.autorizada
    ) {
        this.showModal('autorizacionEsp', this.urlParams, undefined);
    } else {
        this.showModal('changeStatus', 'turnar', undefined);
    }
    }

    /**
     * Operacion Activar.
     */
    activar() {
        if (
            this.data.generales.tipoSolicitud === 'nuevo_negocio' ||
            this.data.generales.tipoSolicitud === 'cambio_grupo_individual'
        ) {
            if (this.rol === 'suscripcion_med' || this.rol === 'casos_esp') {
                this.activarConfirmacion();
            } else {
                // modal pro
                this.showModal('activar', undefined, undefined);
            }
        } else if (
            this.data.generales.tipoSolicitud === 'movimiento' ||
            this.data.generales.tipoSolicitud === 'renovacion'
        ) {
            if (
                this.rol === 'suscripcion_med' ||
                this.rol === 'gestion_med' ||
                this.rol === 'casos_esp'
            ) {
                this.activarConfirmacion();
            } else {
                // modal pro
                this.showModal('activar', undefined, undefined);
            }
        }
    }

    /**
     * Muestra un modal de confirmacion, si el usuario acepta
     * invoca al servicio para activar la OT.
     */
    activarConfirmacion() {
        this.data = this.utils.fixOT(this.data);

        // Seteamos el titulo y descripcion del modal de confirmacion
        this.modalService.confirmAlertText = {
            title: 'Activar',
            message: '¿Deseas activar esta OT?',
            acceptLabel: 'Si',
            cancelLabel: 'No'
        };

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        this.eventosScroll.eventScroll();
        const dialogRef = this.dialog.open(
            DialogConfirmComponent,
            dialogConfig
        );
        this.subscription = dialogRef.afterClosed().subscribe((response) => {
            if (response && response.value) {
                let payload = {
                    num_ot: this.data.num_ot,
                    status: 'activada',
                    orden: this.data
                };

                payload = this.utils.filterNewObservations(payload);
                if (this.otService.watcherPoliza.changed) {
                    payload.orden.observacionesInternas.unshift(
                        this.otService.watcherPoliza.tatuaje()
                    );
                }
                payload = this.utils.deleteEmptyObservations(payload);

                this.spinner.show();
                this.endpointsService.turnarOrden(payload, 'activar').subscribe(
                    (response) => {
                        this.spinner.hide();
                        this.otService.setSalir = '';
                        // })
                    },
                    (error) => {
                        this.spinner.hide();
                        if(error.error.warning != undefined || error.error.warning != null){
                            this.genericModalService.showModal('generico', 'avisoErrorTurnar', { error: error.error.message });
                          } else {
                            this.genericModalService.showModal('generico', 'errorRechazando', { error: error.error.message });
                        }
                    }
                );
            }
        });
    }

    /**
     * Operacion rechazar.
     */
    rechazar() {
        this.data = this.utils.fixOT(this.data);
        if (
            this.rol === 'suscripcion_med' ||
            this.rol === 'gestion_med' ||
            this.rol === 'casos_esp'
        ) {
            if (this.rol === 'gestion_med') {
                var agregoObs = false;
                for (
                    var i = 0;
                    i < this.data.observacionesInternas.length;
                    i++
                ) {
                    if (this.data.observacionesInternas[i].canDelete) {
                        agregoObs = true;
                    }
                }
                if (!agregoObs) {
                    this.showModal('generico', 'faltaObs', undefined);
                    return;
                }
            }
            this.showModal('rechazarConfirmacion', undefined, undefined);
        } else {
            this.showModal('changeStatus', 'rechazar', undefined);
        }
    }

    /**
     * Operacion recuperar.
     */
    recuperar() {
        const typeBtn = 'recuperar';
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        const dialogRef = this.dialog.open(
            RecoverComponent,
            dialogConfig
        );

        this.subscription = dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.personasSrv.postRecuperarOT(data).subscribe(
                    (valueOt: any) => {
                        this.spinner.hide();
                        this.showModal('generico', 'recuperacionExitosa', null);
                    },
                    ({ error }: any) => {
                        this.spinner.hide();
                        this.showModal('generico', 'recuperacionError', null);
                    }
                );
            }
        });

    }


    /**
     * Operacion Cancelar
     */
    confirmarCancelar(flagSolicitante: boolean) {
        this.data = this.utils.fixOT(this.data);
        this.showModal('cancelar-da', 'cancelar-da', flagSolicitante);
    }

      /**
   * Valida si es alguno de los 3 super roles.
   * @returns true si es alguno, false en caso contrario.
   */
  isSuperRol(): boolean {
    const superRoles: string[] = ['supervisor_gmm','asignador', 'gerente_gmm'];
    

    let isSuperRol = false;
    for(let myUrl of superRoles) {
        if(this.rol && this.rol.search(myUrl) >= 0) {
            isSuperRol = true;
            break;
        }
    }
    return isSuperRol;
  }

  validaEnRegistro(): boolean {
    if (this.data.status == "En Registro") {
        return true;
    } else {
        return false;
    }
  }

  bitacora(): void {

    //this.showModal('bitacora', undefined, undefined);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    this.eventosScroll.eventScroll();
    const dialogRef = this.dialog.open(
        BitacoraComponent,
        dialogConfig
    );

  }

  isSuperRolFiguraServicio(): boolean {
    const superRoles: string[] = ['figura_servicio','gerente_figura'];
    

    let isSuperRol = true;
    for(let myUrl of superRoles) {
        if(this.rol) {
            if(this.rol.search(myUrl) >= 0) {
                isSuperRol = false;
                break;
            }
        } else {
            isSuperRol = true;
        }
    }
    return isSuperRol;
  }

  validaEscenarios(): boolean {

    if(this.otService.botonera) {
        return false;
    } else {
        if(this.isSuperRol() && (this.data.statusOrden == 'figura_servicio' || this.data.statusOrden == 'casos_esp') ){
            return true;
        } else {
            return false;
        }
    }
  }

  validaRecuperar(): boolean {

    if(this.otService.botoneraRecuperar) {
        return true;
    } else {
       return false;
    }

  }

  isSuperRolSinAsignador(): boolean {
    const superRoles: string[] = ['supervisor_gmm', 'gerente_gmm'];
    

    let isSuperRol = false;
    for(let myUrl of superRoles) {
        if(this.rol && this.rol.search(myUrl) >= 0) {
            isSuperRol = true;
            break;
        }
    }
    return isSuperRol;
  }

  validaBotonera(accion): boolean {

    if(this.otService.botonera) {
        if((this.permisos.acciones.indexOf(accion) >= 0)){
            return true;
        }

    } else {
        if(this.otService.botoneraValidacion){
            return false;
        } else {
            if((this.permisos.acciones.indexOf(accion) >= 0 && !this.isCharola && this.responsable) 
                || this.validaSuperRol(accion)){
                        return true;
            } else {
                return false;
            }
        }
    }
  }

  validaSuperRol(accion){
    if(this.otService.esUsuarioBancaSeguro){

        if(accion === 'turnar'){
            return this.permisos.acciones.indexOf(accion) >= 0 
                && (this.data.status == 'Rechazada' || this.data.status === 'Activada');
        } else {
            return this.permisos.acciones.indexOf(accion) >= 0 
         &&  ( this.data.statusOrden === 'operador_banca'  || this.isSuperRol() );
        }

    } else {
        return (this.isSuperRol() && this.data.status != 'Terminada' && 
        this.data.status != 'Cancelada' && this.data.status != 'Cancelada Solicitante' && 
        this.data.status != 'Cancelada Automática' && this.data.status != 'Rechazada' && this.data.status != 'Activada' );
    }
  }

}
