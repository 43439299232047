import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(protected readonly http: HttpClient) {}

    get(
        endpoint: string,
        headers?: {
            [header: string]: string | string[];
        },
        params?:
            | HttpParams
            | {
                  [param: string]: string | string[];
              }
    ): Observable<any> {
        return this.http.get(endpoint, { headers, params });
    }

    getBlob(
        endpoint: string,
        options?: any
    ): Observable<any> {
        return this.http.get(endpoint, options);
    }

    post(
        endpoint: string,
        data?,
        headers?: {
            [header: string]: string | string[];
        }
    ): Observable<any> {
        return this.http.post(endpoint, data, { headers });
    }

    upload(endpoint: string, data: FormData): Observable<HttpEvent<any>> {
        return this.http.post(endpoint, data, {
            reportProgress: true,
            observe: 'events'
        });
    }

    put(
        endpoint: string,
        data?,
        headers?: {
            [header: string]: string | string[];
        }
    ): Observable<any> {
        return this.http.put(endpoint, data, { headers });
    }

    delete(endpoint: string): Observable<any> {
        return this.http.delete(endpoint);
    }
}
