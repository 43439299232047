import { Component, OnInit, Inject  } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-input-confirm',
  templateUrl: './dialog-input-confirm.component.html',
  styleUrls: ['./dialog-input-confirm.component.scss']
})
export class DialogInputConfirmComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<DialogInputConfirmComponent>,
    @ Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit(): void {
  }

  cancelar() {
    this.dialogRef.close();
  }

}
