import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { CatalogosApiService } from '@services/catalogos-api/catalogos-api.service';
import { EndpointsService } from '@services/endpoints/endpoints.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { LoaderService } from '@services/loader/loader.service';
import { OtService } from '@services/ot/ot.service';
import { UtilsService } from '@services/utils/utils.service';
import { ActivateComponent } from '../activate/activate.component';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-change-policy',
    templateUrl: './change-policy.component.html',
    styleUrls: ['./change-policy.component.scss']
})
export class ChangePolicyComponent implements OnInit {
    // Datos de la Orden de trabajo
    data: GetOTResponseInterface;
    modalTitle: string = 'Enviar';
    formChangePolicy: FormGroup;

    // Nombre del usuario en sesion
    nombre: string;

    atribuiblesList: Array<string>;

    // Bandera para saber si tiene algun reproceso
    reproceso: boolean;

    constructor(
        private utils: UtilsService,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public inboundData: any,
        public dialogRef: MatDialogRef<ActivateComponent>,
        private readonly spinner: LoaderService,
        private genericModalService: GenericModalService,
        private otService: OtService,
        private readonly datePipe: DatePipe,
        private endpointsService: EndpointsService,
        private catalogosApiService: CatalogosApiService
    ) {}

    ngOnInit(): void {
        this.atribuiblesList = ['Atribuible DA', 'Atribuible GNP'];
        // Leer datos de la ot
        this.data = this.otService.ot;
        this.nombre = this.otService.nombre;
        this.reproceso = parseInt(this.data.numeroReprocesos) > 0;
        this.buildFrm();

        this.populateFields();
        this.disable();
    }

    // Inhabilitar campos
    disable(): void {
        if ((this.data.generales.tipoSolicitud === 'nuevo_negocio' ||
            this.data.generales.tipoSolicitud === 'cambio_grupo_individual') 
            && !this.inboundData.casoEspecial) {
            this.formChangePolicy.get('poliza').disable();
        }
    }

    // Poblar campos
    populateFields() {
        if (!["", undefined, null].includes(this.data.generales.numeroPoliza)) {
            this.formChangePolicy.get('polizaOriginal').setValue(this.data.generales.numeroPoliza);
            this.formChangePolicy.get('poliza').setValue(this.data.generales.numeroPoliza);
        }
    }

    // Construir el formulario
    buildFrm(): void {
        this.formChangePolicy = this.fb.group({
            polizaOriginal: new FormControl({ value: '', disabled: false }, []),
            poliza: new FormControl({ value: '', disabled: false }, [
                Validators.required
            ]),
            atribuible: new FormControl({ value: '', disabled: false}, 
            this.reproceso ? [Validators.required] : [])
        });
    }

    /**
     * Invocar a turnarOrden.
     */
    guardar() {
        if (this.inboundData.casoEspecial) {
            this.formChangePolicy.get('poliza').markAsTouched();
            this.formChangePolicy.get('poliza').updateValueAndValidity();
            this.formChangePolicy.updateValueAndValidity();
            if(!(this.formChangePolicy.status == 'VALID')) {
                return;
            }

            let numPoliza = this.formChangePolicy.get('poliza').value;
            if (numPoliza.length < 14) {
                let digitosFaltantes = 14 - numPoliza.length;
                var zeroString = "";
                for (var i = 0; i < digitosFaltantes; i++) {
                    zeroString += "0";
                }
                numPoliza = zeroString + numPoliza;
            }
            if(this.data.generales.tipoSolicitud == 'nuevo_negocio'
            || this.data.generales.tipoSolicitud == 'cambio_grupo_individual') {
                this.data.generales.numeroPoliza = numPoliza;
            } 
            
            this.formChangePolicy.get('poliza').setValue(numPoliza);
            this.formChangePolicy.get('polizaOriginal').setValue(numPoliza);
        }

        this.data = this.utils.fixOT(this.data);
        var payload = {
            num_ot: this.data.num_ot,
            status: 'armado',
            orden: JSON.parse(JSON.stringify(this.data))
        };
        payload = this.utils.filterNewObservations(payload);
        if (this.inboundData.cambios) {
            payload.orden.observacionesInternas.unshift(
                this.otService.watcherPoliza.tatuaje()
            );
        }

        if (
            this.formChangePolicy.get('polizaOriginal').value !==
            this.formChangePolicy.get('poliza').value
        ) {

            if(this.data.generales.tipoSolicitud == 'nuevo_negocio'
            || this.data.generales.tipoSolicitud == 'cambio_grupo_individual') {
                payload.orden.generales.numeroPoliza = this.formChangePolicy
                .get('poliza')
                .value.toString();
            }
            
            let currentDate = this.datePipe.transform(
                new Date(),
                environment.datePatternObs
            );

            var observacion = {
                textoTipo: 'Cambio de Póliza realizado por: ',
                texto: `Número de Póliza : ${this.formChangePolicy
                    .get('poliza')
                    .value.toString()}`,
                nombre: this.nombre,
                fecha: currentDate,
                canDelete: false
            };
            payload.orden.observacionesInternas.unshift(observacion);
        }

        payload = this.utils.deleteEmptyObservations(payload);

        if (this.reproceso) {
            payload.orden.meta = {
                tipoAtribuible: this.formChangePolicy.get('atribuible').value
            };
        }
        
        this.spinner.show();
        this.dialogRef.addPanelClass('hidePanel');
        this.endpointsService.turnarOrden(payload, 'emitir').subscribe(
            (response) => {
                this.spinner.hide();
                this.dialogRef.removePanelClass('hidePanel');
                this.otService.setSalir = '';
                this.dialogRef.close();
            },
            (error) => {
                this.spinner.hide();
                this.dialogRef.removePanelClass('hidePanel');
                this.dialogRef.close();
                if(error.error.warning != undefined || error.error.warning != null){
                    this.genericModalService.showModal('generico', 'avisoErrorTurnar', { error: error.error.message });
                  } else {
                    this.genericModalService.showModal('generico', 'errorTurnando', { error: error.error.message });
                }
            }
        );
    }

    /**
     * Close the modal.
     */
    cancelar() {
        this.dialogRef.close();
    }
}
