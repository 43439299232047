import {
    Directive,
    EventEmitter,
    Output,
    HostListener,
    HostBinding
} from '@angular/core';

@Directive({
    selector: '[appUploadFile]'
})
export class UploadFileDirective {
    @Output() fileUploaded = new EventEmitter<File>();

    @HostBinding('style.background-color') private background = '#f6f9fd';

    // Dragover Event
    @HostListener('dragover', ['$event']) dragOver(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.background = '#e4e8ed';
    }

    // Dragleave Event
    @HostListener('dragleave', ['$event']) public dragLeave(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.background = '#f6f9fd';
    }

    // Drop Event
    @HostListener('drop', ['$event']) public drop(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.background = '#f6f9fd';

        const files: FileList = event.dataTransfer.files;
        if (files.length > 0) {
            this.fileUploaded.emit(files[0]);
        } else {
            this.fileUploaded.emit(null);
            console.warn('No se cargo ningún archivo.....');
        }
    }

    @HostListener('change', ['$event.target.files']) emitFiles(
        event: FileList
    ): void {
        if (event.length > 0) {
            this.fileUploaded.emit(event[0]);
        } else {
            this.fileUploaded.emit(null);
            console.warn('No se cargo ningún archivo.....');
        }
    }

    constructor() {}
}
