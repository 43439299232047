import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { ToastService } from '@services/toast/toast.service';
import { AuthService as Auth } from '@services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements HttpInterceptor {
    // URLs que requieren apiKey (las que no estan aqui envian el jwt)
    apiKeyUrlsList: string[] = ['configuraciones','gmm/catalogos', 'gmm/rechazos', 'gmm/guiones' 
    , 'gmm/activaciones'];

    constructor(
        private readonly toastServ: ToastService,
        private readonly auth: Auth,
        private readonly storage: LocalStorageService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): any {
        let request = req;
        if (environment.openEndpoints.indexOf(req.url) === -1) {
            const token = sessionStorage.getItem('token');
            let requireApiKey = false;
            for(let myUrl of this.apiKeyUrlsList) {
                if(req.url.search(myUrl) >= 0) {
                    requireApiKey = true;
                    break;
                }
            }
            
            if(requireApiKey) {
                request = req.clone({
                    setHeaders: {
                        apikey: environment.apikey
                    }
                });
            } else {
                request = req.clone({
                    setHeaders: {
                        authorization: `Bearer ${token}`
                    }
                });    
            }
        }
        return next.handle(request);
    }
}
