import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@services/translate/translate.service';

@Pipe({
    name: 'translate'
})
export class TranslatePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(value: string, arg?: string): string {
        if (!value || value.trim().length === 0) {
            return '';
        }

        let result = this.translateService.getTranslation(value);

        if (arg !== undefined) {
            result = result.replace('{input}', arg);
        }

        return result;
    }
}
