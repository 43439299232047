import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit
} from '@angular/core';
import { LoaderService } from '@services/loader/loader.service';

@Component({
  selector: 'app-loader-modal',
  templateUrl: './loader-modal.component.html',
  styleUrls: ['./loader-modal.component.scss']
})
export class LoaderModalComponent implements OnInit {
  hidden: boolean;
  show: boolean;

  constructor(
      private elRef: ElementRef,
      private readonly loaderService: LoaderService,
      private cd: ChangeDetectorRef
  ) {
      this.hidden = true;
      this.loaderService.statusModalEvent.subscribe((status) => {
          this.show = status;
          if (this.show) {
              this.hidden = false;
              this.cd.detectChanges();
          }
      });
  }

  ngOnInit(): void {
      this.elRef.nativeElement
          .querySelector('.gnp-loading-body')
          .addEventListener('transitionend', () => {
              this.hidden = !this.show;
          });
  }
}
