import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SearchPersonComponent } from '@components/modals/search-person/search-person.component';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { QueryParamsInterface } from '@interfaces/general.interface';
import { ModalPersonService } from '@services/modal-person/modal-person.service';
import { OtService } from '@services/ot/ot.service';
import { Subscription } from 'rxjs';

// Reglas de pantalla para bloquear campos en base al rol del usuario
import { REGLAS_PANTALLA as reglas_pantalla} from '@fixtures/nueva-ot.fixture';
import { PersonasService } from '@services/personas/personas.service';
import { ServiciosGnpService } from '@services/servicios-gnp/servicios-gnp.service';
import { RegimenCapital } from '@interfaces/regimen-capital';
import { RegimenFiscal } from '@interfaces/regimen-fiscal';
import { EventosScrollService } from '../../services/eventos-scroll/eventos-scroll.service';

@Component({
  selector: 'app-contracting-party-data',
  templateUrl: './contracting-party-data.component.html',
  styleUrls: ['./contracting-party-data.component.scss']
})
export class ContractingPartyDataComponent implements OnInit, OnDestroy, OnChanges {

  // Suscripcion a OT Inicializada
  subscriptionOT: Subscription;

  formContractingData: FormGroup;

  @Output()
  contractingPartyData: EventEmitter<any>;

  @Input()
  urlParams: QueryParamsInterface;

  inboundData: any;

  @Input()
  rol: string;

  // Datos de la orden de trabajo
  @Input()
  data: GetOTResponseInterface;

  @Input()
  siga: boolean;

  altaPersonaSub: Subscription;

  // Suscripcion a lectura de la OT.
  subscription: Subscription;

  // Suscripcion a lectura del rol del usuario.
  subscriptionRol: Subscription;

  // Tool tip de la razon social
  tooltipRazonSocial: string;
  // Tool tip de domicilio
  tooltipDomicilio: string;

  // Tool tipo de domicilio fiscal
  tooltipDomicilioFiscal : string;

  responsable: boolean;

   // Bandera para saber si se debe mostrar la opcion de busqueda de agente.
   showSearchContracting: boolean;

  // Suscripcion a validacion de formulario.
  $validateForm: Subscription;

  // Variables para validar codigo y tipo de contranate
  flgCodigoValido: boolean;
  flgTipoPersonaValido: boolean;

  // Catalogos de regimen Fiscal y regimen Capital
  catRegimenCapital: RegimenCapital[] = [];
  catRegimenFiscal: RegimenFiscal[] = [];
  mostrarRegimenCapital = false;
  tooltipRegimenFiscal : string;
  tooltipRegimenCapital : string;


  constructor(
    private personasSrv: PersonasService,
    private fb: FormBuilder,
    private readonly dialog: MatDialog,
    private serviciosGnpService : ServiciosGnpService,
    public readonly modalPersonService: ModalPersonService,
    private otService: OtService,
    private eventosScroll: EventosScrollService
) {
    this.subscription = new Subscription();

    this.contractingPartyData = new EventEmitter<any>();
    this.subscriptionOT = new Subscription();
    this.responsable = false;
    this.showSearchContracting = false;
    this.flgCodigoValido = true;
    this.flgTipoPersonaValido = true;
  }

  ngOnInit(): void {
    this.buildFrm();
    this.subscription = this.modalPersonService.modalData.subscribe(
      (response) => {
          // LLego la data
          this.data.datosContratante = response;
          if(response) {
            this.populateFields(this.data);
            this.validateRequired();
          }
      }
    );

    // Cargar datos cuando sea una consulta de OT
    this.subscriptionOT = this.otService.initialized.subscribe((otData) => {
        // LLego la data
        this.data = otData;

        // Suscribir a cambios en el rol
        // Desactivar campos en base a las reglas, rol del usuario y data de la Orden de trabajo
        this.subscriptionRol = this.otService.rolInitialized.subscribe(
          (rol) => {
            this.disableFields(this.data, rol);
        });

        this.populateFields(this.data);
    });

    // Actualizat datos del contratante al buscar una poliza
    this.subscriptionOT = this.otService.datosAgenteYContratante.subscribe(
      (datosAgenteYContratante) => {     
          // LLego la data
          this.data = datosAgenteYContratante;
          this.populateFields(this.data);

          this.regimenCapitalCat();

          this.serviciosGnpService.getRegimenFiscal('f').subscribe(
            (data) => {
              for(let elemento in data){
               this.catRegimenFiscal.push(new RegimenFiscal(elemento,data[elemento]));
              }

              this.serviciosGnpService.getRegimenFiscal('m').subscribe(
                (dataD) => {
                  for(let elementoD in dataD){
                   this.catRegimenFiscal.push(new RegimenFiscal(elementoD,dataD[elementoD]));
                  }

                  this.serviciosGnpService.getDireccionFisica(this.data.datosContratante.codigoFiliacion).subscribe((dataResponse) => {
                    if(!dataResponse.error && dataResponse.datosFiscales){
                                
                    this.data.datosContratante.domicilioFiscal = `${dataResponse.datosFiscales.calle} Núm. ${dataResponse.datosFiscales.numeroExterior} ${dataResponse.datosFiscales.numeroInterior} Col.`+ 
                    `${dataResponse.datosFiscales.colonia}, C.P. ${dataResponse.datosFiscales.cpFiscal} ${dataResponse.datosFiscales.municipio} ${dataResponse.datosFiscales.estado}`;
                    this.populateFields(this.data);
          
                        if(![null,"",undefined].includes(dataResponse.datosFiscales.regimenCapital)){
                          this.mostrarRegimenCapital = true;

                          const valorCapital = this.catRegimenCapital.find(element => 
                            element.id.toUpperCase() == dataResponse.datosFiscales.regimenCapital.toUpperCase());

                          this.formContractingData.get('regimenCapital').setValue(valorCapital ? valorCapital.valor : '');
                            this.tooltipRegimenCapital = this.formContractingData.get('regimenCapital').value;
                            this.data.datosContratante.regimenCapital = this.tooltipRegimenCapital;
                        } else {
                          this.mostrarRegimenCapital = false;
                          this.data.datosContratante.regimenCapital = "";
                        }

                          const valorFiscal = this.catRegimenFiscal.find(element => 
                            element.id == dataResponse.datosFiscales.cveRegimenFiscalSat);

                          this.formContractingData.get('regimenFiscal').setValue(valorFiscal ? valorFiscal.valor : '');
          
                            this.tooltipRegimenFiscal = this.formContractingData.get('regimenFiscal').value;
                            this.data.datosContratante.regimenFiscal = this.tooltipRegimenFiscal;
                        
                    }
                  });


                 }
              );
            


             }
          );
          
        

          this.validateRequired();
    });

    this.$validateForm = this.otService.validateFrm.subscribe((validate) => {
      this.validateRequired();
    });

    this.altaPersonaSub = this.personasSrv.personData.subscribe((data: any) => this.populateFields(data));
  }

  ngOnChanges() {
    if(this.siga){
      this.showSearchContracting = this.siga;
    }else{
      this.showSearchContracting = this.siga;
    }
  }


  regimenCapitalCat(){
    this.serviciosGnpService.getRegimenCapital().subscribe(
      (dataResponse) => {
       for(let elemento in dataResponse){
        this.catRegimenCapital.push(new RegimenCapital(elemento,dataResponse[elemento]));
       }
      }
    );
  }

  buildFrm(): void {

    this.formContractingData = this.fb.group({
      codigo: new FormControl({value: '', disabled: true}, Validators.required),
      tipo: new FormControl({value: '', disabled: true}, Validators.required),
      razonSocial: new FormControl({value: '', disabled: true},
      Validators.required),
      apellido1: new FormControl({value: '', disabled: true},
      Validators.required),
      apellido2: new FormControl({value: '', disabled: true}),
      nombre: new FormControl({value: '', disabled: true}),
      rfc: new FormControl({value: '', disabled: true},
      Validators.required),
      domicilio: new FormControl({value: '', disabled: true}),
      tipoPersona: 'fisica',
      datosContratante: null,
      domicilioFiscal: new FormControl({value: '', disabled: true}),
      regimenFiscal: new FormControl({value: '', disabled: true}),
      regimenCapital: new FormControl({value: '', disabled: true})
    });


  }


  /**
   * Actualiza los datos de la ot en el servicio compartido.
   *
   * @param otData - Datos a actualizar.
   */
   updateOT(otData: GetOTResponseInterface) {
    this.otService.setOt = otData;
  }

  /**
   * Inhabilitar campos del formulario en base a los datos de la OT y el rol del usuario.
   * @param data - Datos de la orden de trabajo.
   * @param rol  - Rol del usuario.
   */
   disableFields(data: any, rol: string) {
    let _scope = {
      reglas_pantalla: reglas_pantalla,
      rol: rol,
      data: this.data,
      responsable: this.responsable,
      da_usuario: this.urlParams.da,
      stateParams: this.urlParams
    };
    this.showSearchContracting = this.otService.display('lupa_contratante', 'enable', _scope);

        if (this.otService.isEmpleado  && this.otService.isConsulta) {
          if(data.status === 'En Registro' 
          || data.status === 'Cancelada Automática'
          || data.status === 'Cancelada Solicitante') {
          this.showSearchContracting = false;
          }
      }
   }

  /**
   * Poblar campos del formulario con data del backend.
   *
   * @param otData - Data del backend.
   *
   */
   populateFields(otData: GetOTResponseInterface) {
    this.data = otData;

    this.formContractingData.get('codigo').setValue(otData.datosContratante.codigo);
    let descripcionPersona = (otData.datosContratante.tipo == 'fisica') ? 'Fisica' : (otData.datosContratante.tipo == 'moral') ? 'Moral' : '';

    this.formContractingData.get('tipo').setValue(descripcionPersona);
    this.formContractingData.get('tipoPersona').setValue(otData.datosContratante.tipo);
    this.formContractingData.get('razonSocial').setValue(otData.datosContratante.razonSocial);
    this.formContractingData.get('apellido1').setValue(otData.datosContratante.apellido1);
    this.formContractingData.get('apellido2').setValue(otData.datosContratante.apellido2);
    this.formContractingData.get('nombre').setValue(otData.datosContratante.nombre);
    this.formContractingData.get('rfc').setValue(otData.datosContratante.rfc);
    this.formContractingData.get('domicilio').setValue(otData.datosContratante.domicilio);
    this.formContractingData.get('domicilioFiscal').setValue(otData.datosContratante.domicilioFiscal);
    this.formContractingData.get('regimenFiscal').setValue(otData.datosContratante.regimenFiscal);
    this.formContractingData.get('regimenCapital').setValue(otData.datosContratante.regimenCapital);
    this.mostrarRegimenCapital= ![null,"",undefined].includes(otData.datosContratante.regimenCapital);
    this.formContractingData.value.datosContratante = otData.datosContratante;
    this.tooltipRazonSocial = otData.datosContratante.razonSocial;
    this.tooltipDomicilio = otData.datosContratante.domicilio;
    this.tooltipDomicilioFiscal = otData.datosContratante.domicilioFiscal;
    this.tooltipRegimenFiscal = otData.datosContratante.regimenFiscal;
    this.tooltipRegimenCapital = otData.datosContratante.regimenCapital;
    if(this.urlParams.orden_id) {
      this.validateRequired();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.altaPersonaSub.unsubscribe();
  }

  openSearchPerson(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      urlParams: this.urlParams
    };
    this.otService.setYaBusco = false;
    this.eventosScroll.eventScroll();
    this.dialog.open(SearchPersonComponent, dialogConfig);
  }

  /**
   * Valida los campos de codigo y tipo de persona
   */
  validateRequired(): void {
    this.flgCodigoValido = true;
    this.flgTipoPersonaValido = true;
    let codigoValue = this.formContractingData.get('codigo').value;

    if(codigoValue) {
      codigoValue = codigoValue.toString().trim();
      if(codigoValue.length <= 0) {
        this.flgCodigoValido = false;
      }
    } else {
      this.flgCodigoValido = false;
    }
    
    let tipoValue = this.formContractingData.get('tipo').value;
    if(tipoValue) {
      tipoValue = tipoValue.toString().trim();
      if(tipoValue.length <= 0) {
        this.flgTipoPersonaValido = false;
      }
    } else {
      this.flgTipoPersonaValido = false;
    }
    this.otService.updateValidation('frmperson', (this.flgCodigoValido && this.flgTipoPersonaValido));
  }

}
