import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OtService } from '@services/ot/ot.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { PersonasService } from '@services/personas/personas.service';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { AuthService } from '@services/auth/auth.service';
import { LoaderService } from '@services/loader/loader.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

  title: string = '';
  listFirma: any[];
  trabajoStation: any[];
  accionInfo: any[];
  fistCheck: any;
  modalForm: FormGroup;
  disabledTrabjo: boolean;
  valueButton: string;
  statusTerminada: boolean;
  charola: boolean;
  regionales : String[];
  das : String[];
  mostrarRegional: boolean;

  // Datos de la Orden de trabajo
  data: GetOTResponseInterface;

  constructor(
    public dialogRef: MatDialogRef<RecoverComponent>,
    private otService: OtService,
    private personasSrv: PersonasService,
    public authSrv: AuthService,
    private formBuilder: FormBuilder,
    private readonly spinner: LoaderService,
    ) { }

  ngOnInit(): void {
    let formPayload: any = {
      checkOne: new FormControl('', [Validators.required]),
      checkTwo: new FormControl('', [Validators.required])
    };
    // Leer datos de la ot
    this.data = this.otService.ot;
    this.getAccionesInfo();
    this.formInit();
    this.loadFirstSelect();

  }

  getAccionesInfo(): void {
    this.personasSrv
        .getAccionesInfo()
        .subscribe(
            ({ acciones_info }: any) => (this.accionInfo = acciones_info)
        );
}

  formInit(): void {
    this.valueButton = 'recuperar';
    const status  = this.data.status;
    this.statusTerminada = status === 'Terminada';
    let formPayload: any = {
        checkOne: new FormControl('', [Validators.required]),
        checkTwo: new FormControl('', [Validators.required]),
        checkThree: new FormControl('')
    };

    this.modalForm = this.formBuilder.group(formPayload);
}

  /**
     * Cerrar este modal.
     *
     */
  close() {
    this.dialogRef.close();
  }

  // Salir
  salir() {
    this.spinner.show();
    const  num_ot = this.data.num_ot;
    const  estacion: any = null;
        const email  = localStorage.getItem('mail');
        /*let payload: any = {
            typeBtn: this.valueButton
        };*/
        let data = {};

        switch (this.valueButton) {
          case 'recuperar':
                data = {
                    asignadoA: this.modalForm.controls['checkTwo'].value,
                    estacion: this.modalForm.controls['checkOne'].value,
                    num_ot,
                    usuario: email
                };
                if (this.statusTerminada) {
                    data = {
                        ...data,
                        claveSolicitud: this.modalForm.controls['checkThree']
                            .value
                    };
                }
                break;
        }

        /*payload = {
          ...payload,
          data
        };*/
      this.modalClose(data);

  }

  modalClose(data?: any): void {
    this.dialogRef.close(data);
  }

  changeSelect({ value }: MatSelectChange): void {
    this.modalForm.controls['checkTwo'].setValue('');
    this.listFirma = [];
    this.loadFirmas(value);
  }

  loadFirmas(change?: string): void {
    const da = this.data.da;
    const estacion = this.trabajoStation;
    const num_ot = this.data.num_ot;
    const status = this.data.status;
    const estado = change ? change : estacion;
    const isEstacionCharola = estacion.includes('charola_virt');
    const recuperar =
        status === 'Terminada' ||
        status === 'Rechazada' ||
        status === 'Activada';
    this.modalForm.controls['checkOne'].patchValue(estado);
    if (isEstacionCharola && !change && !this.mostrarRegional) {
        this.personasSrv.getFirmasCharola(num_ot).subscribe((resp: any) => {
            const { usuarios } = resp;
            this.modalForm.controls['checkOne'].patchValue(resp.estacion);
            this.listFirma = usuarios;
        });
        return;
    }
    if (change) {
      this.loadAsignar({ da, estado });
      return;
    }
    
    this.loadRecuperar(num_ot);

    
  }

  loadAsignar(payload: Object): void {
    const { checkOne } = this.modalForm.value;
    if(!this.mostrarRegional){
        this.personasSrv.getFirmas(payload).subscribe(({ usuarios }: any) => {
            this.listFirma = usuarios;
            this.charola = checkOne.includes('charola_virt');
        });
    }
  }

  loadRecuperar(num_ot: string): void {
    this.personasSrv
        .getFirmasRecuperar(num_ot)
        .subscribe(({ estacion, usuarios }: any) => {
            this.modalForm.controls['checkOne'].patchValue(estacion);
            this.listFirma = usuarios;
        });
  }

  loadFirstSelect(): void {
    const num_ot = this.data.num_ot;
    this.personasSrv
        .getEstaciones(num_ot)
        .subscribe(
            ({ estaciones }: any) => (this.trabajoStation = estaciones)
        );
}

}
