import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductoCatalogosApiInterface, TipoMovimientoCatalogosApiInterface, TipoSolicitudCatalogosApiInterface } from "@interfaces/catalogos-api";
import { QueryParamsInterface } from '../../interfaces/general.interface';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';

@Component({
  selector: 'app-general-data',
  templateUrl: './general-data.component.html',
  styleUrls: ['./general-data.component.scss']
})
export class GeneralDataComponent {

  @Input()
  urlParams: QueryParamsInterface;

  @Input()
  tipoSolicitudList:  Array<TipoSolicitudCatalogosApiInterface> = new Array();

  @Input()
  productosList: Array<ProductoCatalogosApiInterface> = new Array();

  @Input()
  tiposMovimientoList: Array<TipoMovimientoCatalogosApiInterface> = new Array();
  
  @Input()
  rol: string;

  // Datos de la orden de trabajo
  @Input()
  data: GetOTResponseInterface;

  @Output() 
  generalData: EventEmitter<any>;

  @Output()
  siga = new EventEmitter<boolean>();

  constructor() {
    this.generalData = new EventEmitter<any>();
  }

  // Recepcion de los datos generales de nuevo negocio actualizados
  receiveNuevoNegocioData(evt) {
      // Enviar la data al padre (home)
      this.generalData.emit(evt);
  }

  getFlagSiga(event: boolean) {
    this.siga.emit(event);
  }
  
}
