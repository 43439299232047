import { DecimalPipe } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { YearsAmountFormInterface } from '@interfaces/wallet-result.interface';
import { maxLength } from '@validators/max-length.validator';

@Component({
    selector: 'app-input-currency',
    templateUrl: './input-currency.component.html',
    styleUrls: ['./input-currency.component.scss']
})
export class InputCurrencyComponent implements OnChanges {
    @Input() min: number;
    @Input() max: number;
    @Input() label: string = null;
    @Input() hint: string = null;
    @Output() enterAmount: EventEmitter<YearsAmountFormInterface>;

    control: FormGroup;
    amount: number;

    constructor(private readonly dp: DecimalPipe) {
        this.enterAmount = new EventEmitter();
        this.buildForm();
    }

    ngOnChanges({ min, max }: SimpleChanges): void {
        if (min && min.currentValue && max && max.currentValue) {
            this.buildForm();
            this.initControls();
        }
    }

    buildForm(): void {
        this.control = new FormGroup({
            formattedAmount: new FormControl(null, [
                Validators.required,
                maxLength(9)
            ]),
            amount: new FormControl(null, [
                (control: AbstractControl) => Validators.max(this.max)(control),
                (control: AbstractControl) => Validators.min(this.min)(control),
                Validators.required
            ])
        });
    }

    initControls(): void {
        if (this.min) {
            this.setFormattedAmount(String(this.min));
            this.control.get('amount').setValue(this.min);
            this.formatToNumber();
            this.emitValue();
        }
    }

    setFormattedAmount(value: string): void {
        this.control.get('formattedAmount').setValue(value);
    }

    setFormat(): void {
        this.setFormattedAmount(
            this.control.get('formattedAmount').value.replace(/[^0-9.]/g, '')
        );
        this.cleanFormat();
        this.control
            .get('amount')
            .setValue(this.control.get('formattedAmount').value);
        this.formatToNumber();
        this.emitValue();
    }

    formatToNumber(): void {
        this.setFormattedAmount(
            this.dp.transform(this.control.get('formattedAmount').value)
        );
    }

    cleanFormat(): void {
        this.setFormattedAmount(
            this.control.get('formattedAmount').value.replace(/,/g, '')
        );
    }

    emitValue(): void {
        this.enterAmount.emit({
            amount: this.control.get('amount').value,
            valid: this.control.valid
        });
    }
}
