import { Injectable } from '@angular/core';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { QueryParamsInterface } from '@interfaces/general.interface';

/**
 * Servicio con metodos utilitarios.
 */
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }
  
  /**
   * Deja unicamente las observaciones nuevas.
   * 
   * @param payload - Objeto que trae una Orden de Trabajo. 
   * @returns OT fixed
   */
   filterNewObservations(payload) {
    let newObsPortal = [];
    let newObsInt = [];
    if(payload.orden && payload.orden.observacionesInternas) {
      newObsInt = payload.orden.observacionesInternas.filter(function(observation) {
              return observation.canDelete;
          });	
      payload.orden.observacionesInternas = JSON.parse(JSON.stringify(newObsInt));
    }
    
    if(payload.orden && payload.orden.observacionesPortal) {
      newObsPortal = payload.orden.observacionesPortal.filter(function(observation) {
              return observation.canDelete;
          });	
            payload.orden.observacionesPortal = JSON.parse(JSON.stringify(newObsPortal));
    }
    return JSON.parse(JSON.stringify(payload));
  }

  /**
   * Deja unicamente las observaciones que no esten vacias.
   * 
   * @param payload - Objeto que trae una Orden de Trabajo. 
   * @returns OT fixed.
   */
  deleteEmptyObservations(payload) {
    if (payload.orden && payload.orden.observacionesInternas && payload.orden.observacionesInternas.length === 0) {
        delete payload.orden.observacionesInternas;
    }
    if (payload.orden && payload.orden.observacionesPortal && payload.orden.observacionesPortal.length === 0) {
        delete payload.orden.observacionesPortal;
    }
    return JSON.parse(JSON.stringify(payload));
  }

  
    /**
     * Serializes the form element so it can be passed to the back end through the url.
     * The objects properties are the keys and the objects values are the values.
     * ex: { "a":1, "b":2, "c":3 } would look like ?a=1&b=2&c=3
     * @param  {SystemSetup} obj - The system setup to be url encoded
     * @returns URLSearchParams - The url encoded system setup
     */
     serialize(obj: QueryParamsInterface): URLSearchParams {
      let params: URLSearchParams = new URLSearchParams();

      for (var key in obj) {
          if (obj.hasOwnProperty(key)) {
              var element = obj[key];

              params.set(key, element);
          }
      }
      return params;
  }

  /**
   * Elimina ciertos datos de de la OT. Se debe invocar antes de realizar alguna operacion en la botonera.
   * 
   * @param ot - Datos de la orden de trabajo.
   */
  fixOT(ot: GetOTResponseInterface): GetOTResponseInterface {
    if(ot.docs && ot.docs.length > 0) {
      ot.docs = ot.docs.map(({tmpAprobado, ...keepAttrs}) => keepAttrs);
    }
    if(ot.generales.numeroSolicitantes) {
      ot.generales.numeroSolicitantes = Number(ot.generales.numeroSolicitantes);
    }
    return ot;
  }

  /**
   * Validar que la entrada sean solo numeros.
   * 
   * @param event - valor del evento.
   * @returns - true si son solo numeros, false en caso contrario.
   */
   _OnlyNumbers(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
      return false;
    }
  }
  /**
   * Validar que la entrada sean solo numeros, letras y -/.
   * 
   * @param event - valor del evento.
   * @returns - true si cumple los caracteres, false en caso contrario.
   */
   _AlphanumericAddress(event: any) {
    const pattern = /[a-zA-Z0-9\-\/\ ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
      return false;
    }
  }
  /**
   * Validar que la entrada sean solo numeros positivos.
   * 
   * @param event - valor del evento.
   * @returns - true si son solo numeros, false en caso contrario.
   */
   _OnlyPositiveNumbers(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
      return false;
    }
  }
}
