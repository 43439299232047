import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserDataInterface } from '@interfaces/user-data.interface';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { SessionService } from '@services/session/session.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    user: UserDataInterface;
    cua: string;
    menu: string;
    @Output() drawerToggle = new EventEmitter<void>();

    sessionSubscription: Subscription;

    constructor(private readonly storage: LocalStorageService,
        private readonly sessionService: SessionService) {
            this.sessionSubscription = new Subscription();
        }

    ngOnInit(): void {
        this.menu = '';
        this.cua = '';
        this.user = JSON.parse("{}") as UserDataInterface;

        this.sessionSubscription = this.sessionService.sessionStatus.subscribe(
            (status) => {
                // LLego la data
                this.menu = this.storage.getItem('menu');
                this.cua = this.storage.getItem('cua');
                this.user = JSON.parse(
                    this.storage.getItem('userData')
                ) as UserDataInterface;
        });

    }
}
