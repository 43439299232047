import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalPersonService } from '@services/modal-person/modal-person.service';
import { ModalService } from '@services/modal/modal.service';
import { PersonasService } from '@services/personas/personas.service';
import { ServiciosGnpService } from '@services/servicios-gnp/servicios-gnp.service';
import { Subscription } from 'rxjs';
import { ActualizaPersonaRequest, ActualizaDomicilioFiscal } from '@interfaces/actualiza.persona.interface';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { AddAddressComponent } from '../add-address/add-address.component';
import { AddContactMethodComponent } from '../add-contact-method/add-contact-method.component';
import { AddEmailAddressComponent } from '../add-email-address/add-email-address.component';
import { ActualizaPersonaDomicilioRequest, ActualizaPersonaMedioContactoRequest, ActualizaPersonaMedioElectronicoRequest } from '../../../interfaces/actualiza.persona.interface';
import { LoaderService } from '@services/loader/loader.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { AuthService } from '@services/auth/auth.service';
import { EventosScrollService } from '@services/eventos-scroll/eventos-scroll.service';
import { OtService } from '@services/ot/ot.service';

export interface Domicilio {
domicilio: string;
tipoDomicilio: string;
calle: string;
numeroExterior: string;
numeroInterior: string;
referenciaUbicacion: string;
codigoPostal: string;
pais: string;
entidadFederativa: string;
municipio: string;
colonia: string;
coloniaCustom: string;
chkDireccionPersonalizada: any;

}

export interface Telefono {
  tipoTelefono: string;
  ladaInternacional: string;
  ladaNacional: string;
  telefono: string;
  numExtension: string;
}

export interface Email {
  tipoMedioElectronico: string;
  correo: string;
}

@Component({
  selector: 'app-edit-person',
  templateUrl: './edit-person.component.html',
  styleUrls: ['./edit-person.component.scss']
})
export class EditPersonComponent implements OnInit {

  modalTitle: string = "Edición de Solicitante";
  formGeneralData: FormGroup;
  formAddress: FormGroup;
  formPhones: FormGroup;
  formEmais: FormGroup;

  subscriptionFrm1: Subscription;
  subscriptionFrm2: Subscription;
  subscriptionFrm3: Subscription;
  subscriptionFrm4: Subscription;

  DOMICILIOS: Domicilio[] = [{
    domicilio: '',
    tipoDomicilio: '',
    calle: '',
    numeroExterior: '',
    numeroInterior: '',
    referenciaUbicacion: '',
    codigoPostal: '',
    pais: '',
    entidadFederativa: '',
    municipio: '',
    colonia: '',
    coloniaCustom: '',
    chkDireccionPersonalizada: false
  }];

  TELEFONOS: Telefono[] = [{
    tipoTelefono: '',
    ladaInternacional: '',
    ladaNacional: '',
    telefono: '',
    numExtension: ''
  }];

  EMAILS: Email[] = [{
    tipoMedioElectronico: '',
    correo: ''
  }];

  displayedColumns: string[] = ['tipoDomicilio', 'domicilio'];
  displayedColumnsPhones: string[] = ['tipoTelefono', 'ladaInternacional', 'ladaNacional', 'telefono'];
  displayedColumnsEmails: string[] = ['tipoMedio', 'descripcion'];
  dsAddress:any;
  dsPhones:any;
  dsEmails:any;
  personaBupData:any;

  catalogosIniciales = {
    tiposDomicilio: [{
      "texto": "CASA",
      "valor": "CS"
             },
             {
      "texto": "FISCAL",
      "valor": "F"
             }],
    ocupaciones: [],
    giros: [],
    nacionalidades: [],
    paises: [],
    tiposTelefono: [],
    ladas: []
  };

  //Tooltip para el giro o actividad
  tooltipGiro = '';

  constructor(
    private fb: FormBuilder,
    public readonly modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public inboundData: any,
    public dialogRef: MatDialogRef<EditPersonComponent>,
    private serviciosGnpService: ServiciosGnpService,
    private personasService: PersonasService,
    private readonly dialog: MatDialog,
    public readonly modalPersonService: ModalPersonService,
    private readonly spinner: LoaderService,
    private genericModalService: GenericModalService,
    private authService: AuthService,
    private storage: LocalStorageService,
    private eventosScroll: EventosScrollService,
    private otService : OtService
) {

    this.subscriptionFrm1 = new Subscription();
    this.subscriptionFrm2 = new Subscription();
    this.subscriptionFrm3 = new Subscription();
    this.subscriptionFrm4 = new Subscription();
    this.dsAddress = this.DOMICILIOS;
    this.dsPhones = this.TELEFONOS;
    this.dsEmails = this.EMAILS;
    }

  ngOnInit(): void {
    this.personaBupData = this.modalPersonService.myPersonaBupData;
    this.buildFrm();
    
    this.loadCatalogs();
    this.readCapturedData();
  }

  buildFrm(): void {
    this.formGeneralData = this.fb.group({
      // Campos persona Moral
      razonSocial: new FormControl({value: this.personaBupData.nombreCompleto, disabled: true}, []),
      rfc: new FormControl({value: this.personaBupData.rfc, disabled: true}, []),
      fechaConstitución: new FormControl({value: this.personaBupData.fecha, disabled: true}, []),
      folioMercantil: new FormControl({value: this.personaBupData.folioMercantil, disabled: true}, []),
      giro: new FormControl({value: this.personaBupData.giro, disabled: true}, []),
      // Campos persona Fisica
      paterno: new FormControl({value: this.personaBupData.paterno, disabled: true}, []),
      materno: new FormControl({value: this.personaBupData.materno, disabled: true}, []),
      nombre: new FormControl({value: this.personaBupData.nombre, disabled: true}, []),
      fecha: new FormControl({value: this.personaBupData.fecha, disabled: true}, []),
      rfcFisica: new FormControl({value: this.personaBupData.rfc, disabled: true}, []),
      curp: new FormControl({value: this.personaBupData.curp, disabled: true}, []),
      generoF: new FormControl({value: this.personaBupData.genero=="Femenino", disabled: true}, []),
      generoM: new FormControl({value: this.personaBupData.genero=="Masculino", disabled: true}, []),
      nacionalidad: new FormControl({value: this.personaBupData.nacionalidad, disabled: true}, []),
      paisNacimiento: new FormControl({value: this.personaBupData.paisNacimiento, disabled: true}, []),
    });

  }

  /**
   * Leer los catalogos necesarios para este modal
   */
  loadCatalogs() {
    let catalogosAlta = this.modalPersonService.catalogosAlta;
    if(catalogosAlta) {
      this.catalogosIniciales = catalogosAlta;
    } else {
      
      this.spinner.showModal();
      const request = { opcion: 'alta' };
      this.serviciosGnpService.loadCatalogosRegistration(request).subscribe(
        (dataResponse) => {
            this.spinner.hideModal();
            for(const cat of  dataResponse.catalogos) {
              switch (cat.nombre) {
                case 'TIPO_DOMICILIO':
                    // Ya se han preestablecido
                    break;
                case 'OCUPACION':
                    this.catalogosIniciales.ocupaciones = cat.valores;
                    break;
                case 'GIRO_MERCANTIL':
                    this.catalogosIniciales.giros = cat.valores;
                    break;
                case 'NACIONALIDAD':
                    this.catalogosIniciales.nacionalidades = cat.valores;
                    break;
                case 'PAIS':
                  this.catalogosIniciales.paises = cat.valores;
                    break;
                case 'CONTACTO':
                  this.catalogosIniciales.tiposTelefono = cat.valores;
                    break;
                case 'ladaInternacional':
                  this.catalogosIniciales.ladas = cat.valores;
                    break;
              }
            }
            this.modalPersonService.setCatalogosAlta = this.catalogosIniciales;
            this.tooltipGiro = this.getName(this.personaBupData.giro, this.catalogosIniciales.giros);
        }, (error) => {
          this.spinner.hideModal();
        }
      );
    }
  }
  
  // Cerrar el modal
  closeModal(value: any): void {

    if(value) {
      // TODO: Armar el request con esta estructura
      // https:// wf-crm-services-qa.gnp.com.mx/personas/actualizaPersona
      // Armar la data del domicilio

      this.modalPersonService.setContractingData = undefined;

      const domicilioRequest: ActualizaPersonaDomicilioRequest = {
        pais: this.DOMICILIOS[0].pais,
        tipoDomicilio: this.DOMICILIOS[0].tipoDomicilio,
        calle: this.DOMICILIOS[0].calle,
        numeroExterior: this.DOMICILIOS[0].numeroExterior,
        numeroInterior: this.DOMICILIOS[0].numeroInterior,
        referenciaUbicacion: this.DOMICILIOS[0].referenciaUbicacion,
        codigoPostal: this.DOMICILIOS[0].codigoPostal,
        colonia: this.DOMICILIOS[0].colonia,
        coloniaCustom: this.DOMICILIOS[0].coloniaCustom,
        entidadFederativa: this.DOMICILIOS[0].entidadFederativa,
        municipio: this.DOMICILIOS[0].municipio,
        direccion: this.DOMICILIOS[0].domicilio,
        hayColoniaCustom: this.DOMICILIOS[0].chkDireccionPersonalizada.checked
      };
      const medioContactoRequest: ActualizaPersonaMedioContactoRequest = {
        ladaInternacional: this.TELEFONOS[0].ladaInternacional,
        tipoTelefono: this.TELEFONOS[0].tipoTelefono,
        ladaNacional: this.TELEFONOS[0].ladaNacional,
        telefono: this.TELEFONOS[0].telefono        
      };
      if(this.TELEFONOS[0].numExtension && this.TELEFONOS[0].numExtension !== '') {
        medioContactoRequest.numExtension = this.TELEFONOS[0].numExtension;
      }
      medioContactoRequest
      const medioElectronicoRequest: ActualizaPersonaMedioElectronicoRequest = {
        tipoMedioElectronico: this.EMAILS[0].tipoMedioElectronico ? this.EMAILS[0].tipoMedioElectronico.toString().toLowerCase() : '',
        correo: this.EMAILS[0].correo
      };
      const actualizaPersonaRequest: ActualizaPersonaRequest = {
        domicilio: domicilioRequest,
        medioContacto: medioContactoRequest,
        codigo: this.personaBupData.codigo,
        codigoFiliacion: this.personaBupData.valorClaveOrigen,
        medioElectronico: {}
      };
      if(this.EMAILS[0].tipoMedioElectronico !== '') {
        actualizaPersonaRequest.medioElectronico = medioElectronicoRequest;
      }
      if(actualizaPersonaRequest.domicilio.tipoDomicilio == ''
      || actualizaPersonaRequest.medioContacto.tipoTelefono == '') {
        this.genericModalService.showModal('generico', 'faltaDatoEdicion', undefined);
        return;
      }


      const addressFiscalRequest = this.createResponseAddressFiscal();

      let domicilioFiscalCompleto = "";
      if(addressFiscalRequest){
        const dataAdressFiscal = this.modalPersonService.addressFiscalData.value;
        if(![undefined,null,""].includes(addressFiscalRequest['calle'])){
          domicilioFiscalCompleto = `${addressFiscalRequest['calle']} Núm. ${addressFiscalRequest['numeroExterior']}  ${addressFiscalRequest['numeroInterior']} Col.`+ 
          `${addressFiscalRequest['colonia']}, C.P. ${addressFiscalRequest['cpFiscal']} ${dataAdressFiscal['municipioNombre']} ${dataAdressFiscal['estadoNombre']}`;
        }
      }

      this.spinner.show();
      this.dialogRef.addPanelClass('hidePanel');
      this.personasService.updatePerson(actualizaPersonaRequest).subscribe(
        (dataResponse) => {

          // Guardar la data de este modal en el service de personas
          this.modalPersonService.setContractingData = {
            codigo: this.personaBupData.codigo,
            codigoFiliacion: this.personaBupData.valorClaveOrigen,
            // El siguiente dato se obtiene del servicio 
            // https:// wf-crm-services-qa.gnp.com.mx/personas/actualizaPersona
            numeroSecuenciaDomicilio: dataResponse.numeroSecuenciaDomicilio,
            tipo: this.personaBupData.tipo,
            razonSocial: this.formGeneralData.controls['razonSocial'].value,
            apellido1: this.formGeneralData.controls['paterno'].value,
            apellido2: this.formGeneralData.controls['materno'].value,
            nombre: this.formGeneralData.controls['nombre'].value,
            rfc: this.personaBupData.rfc.toUpperCase(),
            domicilio: this.DOMICILIOS[0].domicilio,
            domicilioFiscal : domicilioFiscalCompleto,
            regimenFiscal: ![null,undefined,""].includes(this.modalPersonService.addressFiscalData) 
              ? this.modalPersonService.addressFiscalData.value['fiscalNombre'] : "",
            regimenCapital: ![null,undefined,""].includes(this.modalPersonService.addressFiscalData)
             ? this.modalPersonService.addressFiscalData.value['capitalNombre'] : ""
          };

           // Actualiza el domicilio fiscal
           if(![null,undefined,""].includes(addressFiscalRequest) && addressFiscalRequest.guardarDatosFiscalesINFO){
            this.updateAddressFiscal(addressFiscalRequest);
           } else {
            this.spinner.hide();
            this.dialogRef.removePanelClass('hidePanel');
           }  

          this.genericModalService.showModal('generico', "exitoEdicion", { body: dataResponse.mensaje });
          this.dialogRef.close();
        },
        (error) => {
          this.spinner.hide();
          this.dialogRef.removePanelClass('hidePanel');
          this.genericModalService.showModal('generico', "errorEdicion", { body: error.error.mensaje });
          this.dialogRef.close();
        }
      );

    } else { // end if = true
      this.dialogRef.close();
    }

  }

  // Agregar un domicilio
  addAddress(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '800px';
    // Le pasamos la data al otro modal
    dialogConfig.data = {
      catalogosIniciales: this.catalogosIniciales
    };

    // Cerramos este modal (Edicion de personas)
    this.dialogRef.close();

    // Abrimos el otro (Agregar Domicilio de Persona)
    this.eventosScroll.eventScroll();
    this.dialog.open(AddAddressComponent, dialogConfig);
  }

  // Modificar un domicilio
  editAddress(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '800px';
    // Le pasamos la data al otro modal
    dialogConfig.data = {
      catalogosIniciales: this.catalogosIniciales,
      domicilio: this.DOMICILIOS[0]
    };

    // Cerramos este modal (Edicion de personas)
    this.dialogRef.close();

    // Abrimos el otro (Agregar Domicilio de Persona)
    this.eventosScroll.eventScroll();
    this.dialog.open(AddAddressComponent, dialogConfig);
  }

  
  // Agregar un telefono
  addPhone(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '800px';
    // Le pasamos la data al otro modal
    dialogConfig.data = {
      catalogosIniciales: this.catalogosIniciales
    };

    // Cerramos este modal (Edicion de personas)
    this.dialogRef.close();

    // Abrimos el otro (Medio de Contacto de Persona)
    this.eventosScroll.eventScroll();
    this.dialog.open(AddContactMethodComponent, dialogConfig);
  }

  // Modificar un telefono
  editPhone(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '800px';
    // Le pasamos la data al otro modal
    dialogConfig.data = {
      catalogosIniciales: this.catalogosIniciales,
      telefono: this.TELEFONOS[0]
    };

    // Cerramos este modal (Edicion de personas)
    this.dialogRef.close();

    // Abrimos el otro (Medio de Contacto de Persona)
    this.eventosScroll.eventScroll();
    this.dialog.open(AddContactMethodComponent, dialogConfig);
  }

  
  // Agregar email
  addEmail(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '600px';

    // Cerramos este modal (Edicion de personas)
    this.dialogRef.close();

    // Abrimos el otro (Email de la Persona)
    this.eventosScroll.eventScroll();
    this.dialog.open(AddEmailAddressComponent, dialogConfig);
  }

  // Modificar email
  editEmail(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '600px';
    // Le pasamos la data al otro modal
    dialogConfig.data = this.EMAILS[0]

    // Cerramos este modal (Edicion de personas)
    this.dialogRef.close();

    // Abrimos el otro (Email de la Persona)
    this.eventosScroll.eventScroll();
    this.dialog.open(AddEmailAddressComponent, dialogConfig);
  }

  /**
   * Leer los datos capturados en los modales hijos.
   */
  readCapturedData() {
    if(this.modalPersonService.addressData) {
      this.DOMICILIOS[0] = this.modalPersonService.addressData;
    }
    if(this.modalPersonService.modalPhonesData) {
      this.TELEFONOS[0] = this.modalPersonService.phonesData;
    }
    if(this.modalPersonService.emailsData) {
      this.EMAILS[0] = this.modalPersonService.emailsData;
    }
  }

  /**
   * Obtiene la descripcion del giro.
   * 
   * @param id -Id del giro
   * @param catalogo - catalogo de giros
   * @returns - Descripcion del giro.
   */
  getName(id, catalogo) {
    let valueResponse = '';

    if (id) {
      let valFound = catalogo.find((o) => o.valor === id);
      valueResponse = valFound.texto;
    }
    return valueResponse;
  };

  /**
   * Se crea la data del domicilio fiscal.
   * @returns 
   */
  createResponseAddressFiscal(): any{
    let addressRequest: ActualizaDomicilioFiscal;
    if(this.modalPersonService.addressFiscalData){
      if(this.modalPersonService.addressFiscalData) {
        addressRequest = {
          idParticipante: this.modalPersonService.myPersonaBupData.codigo,
          rfc : this.modalPersonService.addressFiscalData.controls.rfc.value,
          tipoOperacion: "",
          codFiliacion: this.modalPersonService.myPersonaBupData.codigoFiliacion,
          fechaUltimaModificacion: "",
          nacionalidad: "",
          nombreRazonSocial: this.modalPersonService.addressFiscalData.controls.nombreRazonSocial.value,
          cveRegimenFiscalSat: this.modalPersonService.addressFiscalData.controls.regimenFiscal.value,
          calle: this.modalPersonService.addressFiscalData.controls.calle.value,
          numeroExterior: this.modalPersonService.addressFiscalData.controls.numeroExterior.value,
          numeroInterior: this.modalPersonService.addressFiscalData.controls.numeroInterior.value,

          colonia: this.modalPersonService.addressFiscalData.controls.chkDireccionPersonalizada.value
            ? this.modalPersonService.addressFiscalData.controls.coloniaCustom.value
            : this.modalPersonService.addressFiscalData.controls.coloniaNombre.value,

          municipio: this.modalPersonService.addressFiscalData.controls.municipioNombre.value,
          estado: this.modalPersonService.addressFiscalData.controls.estadoNombre.value,
          cpFiscal: this.modalPersonService.addressFiscalData.controls.codigoPostal.value,
          usuarioAudit: this.storage.getItem("email"),
          cveEstado: this.modalPersonService.addressFiscalData.controls.entidadFederativa.value,
          cveMunicipio: this.modalPersonService.addressFiscalData.controls.municipio.value,

          cveColonia: this.modalPersonService.addressFiscalData.controls.chkDireccionPersonalizada.value
            ? ""
            : this.modalPersonService.addressFiscalData.controls.colonia.value,
          localidad: "",
          regimenCapital: this.modalPersonService.addressFiscalData.controls.regimenCapital.value,
          guardarDatosFiscalesINFO: !this.modalPersonService.addressFiscalData.controls.esDireccionCargada.value
        }
      };
    } else {
      addressRequest = null;
    }

    return addressRequest;
  }

  updateAddressFiscal(addressFiscalRequest: ActualizaDomicilioFiscal){
    if(addressFiscalRequest){
      this.serviciosGnpService.updateAddressFiscal(addressFiscalRequest).subscribe(
        (dataResponse) => {
          this.spinner.hide();
          this.dialogRef.removePanelClass('hidePanel');
          console.log("Se guarda direccion fiscal existosa");
  
        },(error) => {
          this.spinner.hide();
          this.dialogRef.removePanelClass('hidePanel');
          this.genericModalService.showModal('generico', "errorEdicion", { body: error.error.mensaje });
          this.dialogRef.close();
        }
      );
    } else {
      this.spinner.hide();
      this.dialogRef.removePanelClass('hidePanel');
    }
  }
}


