import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    DeleteOTRequestInterface,
    GetNombreResponseInterface,
    GetOTRequestInterface,
    GetOTResponseInterface,
    GetRolesResponseInterface
} from '@interfaces/endpoints.interface';
import { HttpService } from '@services/http/http.service';
import { Observable } from 'rxjs';

const endPoint = 'endpoints/';

const endPointConsulta = 'consultaendpoints/';

@Injectable({
    providedIn: 'root'
})
export class EndpointsService {
    constructor(private readonly http: HttpService) {}

    /**
     * Obtener los datos de una orden de trabajo.
     *
     * @param request - Payload para este request.
     *
     * @returns Datos de la OT.
     */
    getOrden(
        request: GetOTRequestInterface
    ): Observable<GetOTResponseInterface> {
        return this.http.post(`${endPointConsulta}gmm/v1/getOT`, request);
    }

    /**
     * Envia la data de una Orden de trabajo.
     *
     * @param request - Payload para este request.
     *
     * @returns Datos de la OT.
     */
    // TODO: tipar la respuesta
    enviarOrden(request: any): Observable<any> {
        var payloadEnviar = JSON.parse(JSON.stringify(request));
        payloadEnviar = this.limpiarObservaciones(payloadEnviar);
        payloadEnviar.operacion = 'enviar';
        return this.http.post(`${endPoint}gmm/v1/createOT`, payloadEnviar);
    }

    /**
     * Guarda la data de una Orden de trabajo.
     *
     * @param request - Payload para este request.
     *
     * @returns Datos de la OT.
     */
    // TODO: tipar la respuesta
    guardarOrden(payload: any): Observable<any> {
        payload.operacion = 'guardar';
        return this.http.post(`${endPoint}gmm/v1/createOT`, payload);
    }

    /**
     * Guarda la observaciones.
     *
     * @param request - Payload para este request.
     *
     * @returns Datos de la OT.
     */
    guardarObsDocs(payload: any): Observable<any> {
        var payloadEnviar = JSON.parse(JSON.stringify(payload));
        payloadEnviar = this.limpiarObservaciones(payloadEnviar);
        return this.http.put(`${endPoint}gmm/v1/UpdateOT`, payloadEnviar);
    }

    /**
     * Turnar la orden.
     *
     * @param payload - Payload para este request.
     *
     * @returns Datos de la OT.
     */
    // TODO: tipar la respuesta
    turnarOrden(payload: any, operacion: string): Observable<any> {
        payload.orden = this.limpiarObservaciones(payload.orden);
        payload.orden.operacion = operacion;
        return this.http.post(`${endPoint}gmm/v1/changeStatus`, payload);
    }

    /**
     * Obtiene los roles del usuario.
     *
     * @param request - Payload para este request.
     *
     * @returns Arreglos de roles.
     */
    getRoles(request: any): Observable<GetRolesResponseInterface> {
        return this.http.get(`${endPointConsulta}gmm/v1/getRoles`, null, request);
    }

    /**
     * Obtener el nombre del usuario.
     *
     * @param request - Payload para este request.
     *
     * @returns Nombre del usuario.
     */
    getUsuario(request: any): Observable<GetNombreResponseInterface> {
        return this.http.post(`${endPointConsulta}gmm/v1/getNombre`, request);
    }

    /**
     * Reprocesar la orden.
     *
     * @param payload - Payload para este request.
     *
     * @returns Datos de la OT.
     */
    // TODO: tipar la respuesta
    reprocesar(payload: any): Observable<any> {
        payload.orden = this.limpiarObservaciones(payload.orden);
        console.info('Payload reprocesar: ', payload);

        return this.http.post(`${endPoint}gmm/v1/reprocesarOT`, payload);
    }

    /**
     * Revaloracion especial.
     *
     * @param payload  - Payload para este request.
     * @returns  Datos de la OT.
     */
    revaloracionEspecial(payload: any): Observable<any> {

        console.info('Payload revaloracion especial: ', payload);
        return this.http.post(`${endPoint}gmm/v1/revalorarOT`, payload);

    }

    /**
     * Realiza la validacion de envio a armado.
     *
     * @param numOT - Numero de la orden de trabajo.
     *
     * @returns Respuesta del backend.
     */
    validaEnvioArmado(numOT: string): Observable<any> {
        return this.http.get(
            `${endPoint}gmm/v1/validaEnvioArmado/${numOT}`,
            null
        );
    }

    /**
     * Marca todas las observaciones como no eliminables.
     *
     * @param payload - Orden de trabajo;
     *
     * @returns - Orden de trabajo ajustada.
     */
    limpiarObservaciones(payload) {
        let i;
        if (payload.observacionesPortal) {
            for (i = 0; i < payload.observacionesPortal.length; i++) {
                if (
                    payload.observacionesPortal[i].hasOwnProperty('canDelete')
                ) {
                    payload.observacionesPortal[i].canDelete = false;
                }
            }
        }

        if (payload.observacionesInternas) {
            for (i = 0; i < payload.observacionesInternas.length; i++) {
                if (
                    payload.observacionesInternas[i].hasOwnProperty('canDelete')
                ) {
                    payload.observacionesInternas[i].canDelete = false;
                }
            }
        }

        return payload;
    }

    /**
     * Terminar la orden.
     *
     * @param payload - Payload para este request.
     *
     * @returns Datos de la OT.
     */
    // TODO: tipar la respuesta
    terminarOrden(payload: any): Observable<any> {
        console.info('Payload terminarOT: ', payload);
        return this.http.post(`${endPoint}gmm/v1/terminarOT`, payload);
    }

    /**
     * Eliminar la orden.
     *
     * @param payload - Payload para este request.
     *
     * @returns Datos de la OT.
     */
    // TODO: tipar la respuesta
    eliminarOT(payload: DeleteOTRequestInterface): Observable<any> {
        console.info('Payload deleteOT: ', payload);
        return this.http.post(`${endPoint}gmm/v1/deleteOT`, payload);
    }

    
    /**
     * Obtiene los tipos de movimiento.
     *
     * @returns Arreglo.
     */
     getAccionesInfo(): Observable<any> {
        return this.http.get(`${endPoint}gmm/v1/getAccionesInfo`, null, null);
    }

    /**
     * Realiza la extracción de la informacion de la Bitácora
     *
     * @param numOT - Numero de la orden de trabajo.
     *
     * @returns Respuesta del backend.
     */
    getValuesBitacora(numOT: string): Observable<any> {
        return this.http.get(
            `${endPoint}gmm/v1/getBitacora?num_ot=${numOT}`,
            null
        );
    }

}
