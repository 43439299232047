import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { LoaderService } from '@services/loader/loader.service';
import { ModalPersonService } from '@services/modal-person/modal-person.service';
import { ModalService } from '@services/modal/modal.service';
import { OtService } from '@services/ot/ot.service';
import { environment } from '../../../../environments/environment';
import { UtilsService } from '@services/utils/utils.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { EndpointsService } from '@services/endpoints/endpoints.service';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})
export class CancelComponent implements OnInit {

  modalTitle: string = "Cancelar";
  observaciones: string;
  
  formCancel: FormGroup;

  /**
   * Datos de la OT modificando.
   */
  data: GetOTResponseInterface;
  
  /**
   * Nombre completo del usuario en sesion.
   */
  nombre: string;

  constructor(
    private utils: UtilsService,
    private fb: FormBuilder,
    public readonly modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public inboundData: any,
    public dialogRef: MatDialogRef<CancelComponent>,
    public readonly modalPersonService: ModalPersonService,
    private readonly dialog: MatDialog,
    private readonly spinner: LoaderService, 
    private otService: OtService,
    private readonly datePipe: DatePipe, 
    private genericModalService: GenericModalService,
    private endpointsService: EndpointsService) {
      this.observaciones = "";
    }

  ngOnInit(): void {
    this.buildFrm();

    // LLego la data
    this.data = this.otService.ot;
    this.nombre = this.otService.nombre;
  }

  buildFrm(): void {
    this.formCancel = this.fb.group({
      observaciones: new FormControl('', Validators.required)
    });
  }

  /**
   * Invocar al back para turnar la orden con la observacion indicada.
   */
  funcionCancelar() {
    this.data = this.utils.fixOT(this.data);
    let currentDate = this.datePipe.transform(
      new Date(), environment.datePatternObs
    );
  
    let payload = {
      num_ot: this.data.num_ot,
      status: 'cancelada',
      orden: this.data
    };
    payload = this.utils.filterNewObservations(payload);
    if (this.formCancel.get('observaciones').value !== "") {
        let templateObservacion = {
            "textoTipo": "CANCELADO POR",
            "texto": this.formCancel.get('observaciones').value,
            "nombre": this.nombre,
            "fecha": currentDate,
            "canDelete": false
        }
        payload.orden.observacionesInternas.unshift(templateObservacion);
    }
    payload = this.utils.deleteEmptyObservations(payload);
  
    this.spinner.show();
    this.dialogRef.addPanelClass('hidePanel');
    this.endpointsService.turnarOrden(payload, 'cancelar').subscribe(
    (response) => {
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');
        this.otService.setSalir = '';
    }, (error) =>{
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');
        if(error.error.warning != undefined || error.error.warning != null){
          this.genericModalService.showModal('generico', 'avisoErrorTurnar', { error: error.error.message });
        } else {
          this.genericModalService.showModal('generico', 'errorTurnando', { error: error.error.message });
        }
    });
  }  // end funcionCancelar()

  
  /**
   * Cerrar el modal
   */
   close () {
    // Cerramos este modal (Captura de direccion)
    this.dialogRef.close();
  }

}
