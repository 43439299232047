import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalService } from '@services/modal/modal.service';
import { Subscription } from 'rxjs';
import { FormResponseInterface } from '@interfaces/form-response.interface';
import {
    ModalActionResponseInterface,
    ModalButtonsInterface
} from '@interfaces/modal-buttons.interface';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
    portal: ComponentPortal<any>;
    subscription: Subscription;
    formData: FormResponseInterface;
    actionButtons: Array<ModalButtonsInterface>;

    constructor(
        private readonly modalService: ModalService,
        public dialogRef: MatDialogRef<ModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.dialogRef.disableClose = true;
        this.subscription = new Subscription();
    }

    ngOnInit(): void {
        this.portal = new ComponentPortal(this.data.component);
        this.actionButtons = this.data.buttons;
        this.subscription = this.modalService.modalData.subscribe(
            (response) => {
                this.formData = response;
            }
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    closeModal(value: any, validation: boolean): void {
        const response: ModalActionResponseInterface = {
            value,
            data: this.formData ? this.formData.values : null
            // Usar en caso de requerir que el formulario sea valido
            // data: validation ? this.formData.values : null
        };
        this.dialogRef.close(response);
    }
}
