import { GetOTResponseInterface } from "@interfaces/endpoints.interface";

// Generamos el id de la orden
let date = new Date();
const crypto = window.crypto;
const valorRandom = crypto.getRandomValues(new Uint32Array(1))[0];
const random = Math.floor(( valorRandom * Math.pow(2,-32))   * (10000 - 1000) + 1000);

export const NUEVA_OT: GetOTResponseInterface = {
    idOrden: ('' + date.getTime() + random),
    statusOrden: 'registro_da',
    generales: {
        lineaNegocio: '',
        tipoSolicitud: '',
        moneda: 'pesos'
    },
    datosAgente: {
        cua: "",
        contrato: "",
        da: "",
        nombre: "",
        estatus: "",
        folio: ""
    },
    datosContratante: {
        codigo: "",
        tipo: "",
        nombre: "",
        apellido1: "",
        apellido2: "",
        rfc: "",
        fecha: "",
        domicilio: "",
        domicilioFiscal: "",
        regimenCapital: "",
        regimenFiscal: "",
        art492: "",
        codigoFiliacion: ""
    },
    autorizacionEsp: false,
    docs: [],
    observacionesPortal: [],
    observacionesInternas: []
}


export const REGLAS_PANTALLA: any = {
    generales: {
        enable: [
            "coordinacion_da",
            "agente_certificado",
            "captura",
            "suscripcion_adm",
            "gestion_adm",
            "supervisor",
            "asignador",
            // #RF 22
            "supervisor_gmm",
            "gerente_gmm",
            "gm_workstie_emite",
            "gm_worksite_emite",
            "respaldo_mc_emite",
            "operador_banca"
        ]
    },
    tipo_solicitud: {
        enable: [
            "supervisor",
            "asignador",
            "operador_banca"
        ]
    },
    num_poliza: {
        show: [
            "asesor_mov",
            "captura",
            "suscripcion_adm",
            "suscripcion_med",
            "gestion_adm",
            "gestion_med",
            "casos_esp",
            "figura_servicio",
            "supervisor",
            "asignador",
            "armado",
            // #RF 22
            "supervisor_gmm",
            "gerente_gmm"
        ],
        enable: [
            "asesor_mov",
            "captura",
            "suscripcion_adm",
            "gestion_adm",
            // #RF 22
            "supervisor_gmm",
            "gerente_gmm",
            "asignador"
        ],
    },
    lupa_agente: {
        show: [
            "coordinacion_da",
            "agente_certificado",
            "supervisor",
            "asignador",
            "gm_workstie_emite",
            "gm_worksite_emite",
            "respaldo_mc_emite",
            "operador_banca"
        ],
        enable: [
            "coordinacion_da",
            "agente_certificado",
            "supervisor",
            "asignador",
            "gm_workstie_emite",
            "gm_worksite_emite",
            "respaldo_mc_emite",
            "operador_banca"
        ]
    },
    select_folio_agente: {
        enable: [
            "coordinacion_da",
            "agente_certificado",
            "supervisor",
            "asignador",
            "gm_workstie_emite",
            "gm_worksite_emite",
            "respaldo_mc_emite",
            "operador_banca"
        ]
    },
    lupa_contratante: {
        show: [
            "coordinacion_da",
            "agente_certificado",
            "supervisor",
            "asignador",
            "gm_workstie_emite",
            "gm_worksite_emite",
            "respaldo_mc_emite",
            "operador_banca"
        ],
        enable: [
            "coordinacion_da",
            "agente_certificado",
            "supervisor",
            "asignador",
            "gm_workstie_emite",
            "gm_worksite_emite",
            "respaldo_mc_emite",
            "operador_banca"
        ]
    },
    autorizacion_esp: {
        enable: [
            "coordinacion_da",
            "agente_certificado",
            "supervisor",
            "asignador"
        ]
    },
    autorizada: {
        show: [
            "figura_servicio",
            "casos_esp"
        ],
        enable: [
            "figura_servicio",
            "casos_esp"
        ]
    },
    desviacion_operativa: {
        show: [
            "asesor_mov",
            "suscripcion_adm",
            // Nuevos roles para #RF 21
            "suscripcion_esp",
            "suscripcion_adm_esp",
            "captura",
            "suscripcion_med_esp",
            "suscripcion_med",
            "casos_esp",
            "supervisor_gmm",
            "asignador",
            "gerente_gmm"
        ],
        enable: [
            "suscripcion_adm",
            "asesor_mov",
            // Nuevos roles para #RF 21
            "suscripcion_esp",
            "suscripcion_adm_esp",
            "captura",
            "suscripcion_med_esp",
            "suscripcion_med",
            "casos_esp",
            "supervisor_gmm",
            "asignador",
            "gerente_gmm"
        ]
    },
    observaciones_internas: {
        show: [
            "asesor_mov",
            "captura",
            "suscripcion_adm",
            "suscripcion_med",
            "gestion_adm",
            "gestion_med",
            "casos_esp",
            "figura_servicio",
            "supervisor",
            "asignador",
            "armado",
            "suscripcion_adm_esp",
            "suscripcion_med_esp",
            // #RF 22
            "supervisor_gmm",
            "gerente_gmm"
        ]
    },
    input_observaciones_1: {
        show: [
            "coordinacion_da",
            "agente_certificado",
            "asesor_mov",
            "captura",
            "suscripcion_adm",
            "suscripcion_med",
            "gestion_adm",
            "gestion_med",
            "casos_esp",
            "figura_servicio",
            "supervisor",
            "asignador",
            "suscripcion_esp",
            "suscripcion_adm_esp",
            "suscripcion_med_esp",
            // #RF 22
            "supervisor_gmm",
            "gerente_gmm"
        ],
        enable: [
            "figura_servicio",
        ]
    },
    input_observaciones_2: {
        show: [
            "figura_servicio",
            "suscripcion_adm",
            "gestion_adm",
            "asesor_mov",
            "asignador",
            "supervisor",
            "suscripcion_esp",
            "suscripcion_adm_esp",
            "suscripcion_med_esp",
            // #RF 22
            "supervisor_gmm",
            "gerente_gmm"
        ]
    },
    adjuntar_documentos: {
        show: [
            "coordinacion_da",
            "agente_certificado",
            "captura",
            "suscripcion_adm",
            "suscripcion_med",
            "gestion_adm",
            "gestion_med",
            "casos_esp",
            "figura_servicio",
            "supervisor",
            "asignador",
            "asesor_mov",
            "suscripcion_esp",
            // #RF 22
            "supervisor_gmm",
            "gerente_gmm",
            "gm_workstie_emite",
            "gm_worksite_emite",
            "respaldo_mc_emite",
            "operador_banca"
        ],
        enable: [
            "captura",
            "suscripcion_adm",
            "gestion_adm",
            "casos_esp",
            "figura_servicio",
            "supervisor",
            "asignador",
            // #RF 22
            "supervisor_gmm",
            "gerente_gmm",
            "gm_workstie_emite",
            "gm_worksite_emite",
            "respaldo_mc_emite",
            "operador_banca"
        ]
    },
    eliminar_documentos: {
        show: [
            "coordinacion_da",
            "agente_certificado",
            "supervisor",
            "asignador",
            "gm_workstie_emite",
            "gm_worksite_emite",
            "respaldo_mc_emite",
            "operador_banca"
        ],
        enable: [
            "coordinacion_da",
            "agente_certificado",
            "supervisor",
            "asignador",
            "gm_workstie_emite",
            "gm_worksite_emite",
            "respaldo_mc_emite",
            "operador_banca"
        ]
    },
    validar_documentos: {
        show: [
            "asesor_mov",
            "captura",
            "suscripcion_adm",
            "gestion_adm",
            "suscripcion_esp",
            // #RF 22
            "asignador",
            "supervisor_gmm",
            "gerente_gmm",
            "gm_workstie_emite",
            "gm_worksite_emite",
            "respaldo_mc_emite",
            "operador_banca"
        ],
        enable: [
            "captura",
            "asesor_mov",
            "suscripcion_adm",
            "gestion_adm",
            "suscripcion_adm_esp",
            "suscripcion_esp",
            "suscripcion_med_esp",
            // #RF 22
            "asignador",
            "supervisor_gmm",
            "gerente_gmm",
            "gm_workstie_emite",
            "gm_worksite_emite",
            "respaldo_mc_emite",
            "operador_banca"
        ]
    }
    
}