import { Component, OnInit, Inject } from '@angular/core';
import { UtilsService } from '@services/utils/utils.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from '@services/loader/loader.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { OtService } from '@services/ot/ot.service';
import { DatePipe } from '@angular/common';
import { EndpointsService } from '@services/endpoints/endpoints.service';
import { CatalogosApiService } from '@services/catalogos-api/catalogos-api.service';
import { DescargaCsvService } from '@services/descarga-csv/descarga-csv.service';
import { BitacoraData, NombreColumnasBitacora } from '@interfaces/bitacora-data.interface';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-bitacora',
  templateUrl: './bitacora.component.html',
  styleUrls: ['./bitacora.component.scss']
})
export class BitacoraComponent implements OnInit {

  formActivate: FormGroup;
  data: any;
  displayedColumns: any;
  ot: any;
  dataSource: MatTableDataSource<any>;

  constructor(
              @Inject(MAT_DIALOG_DATA) public inboundData: any,
              public dialogRef: MatDialogRef<BitacoraComponent>,
              private readonly spinner: LoaderService,
              private otService: OtService,
              private endpointsService: EndpointsService,
              private descargaCsv: DescargaCsvService) {

      this.data = [];
  }

  ngOnInit(): void {
    this.ot = this.otService.ot.num_ot;
    this.displayedColumns = ['fechaInicial', 'usuarioCreador', 'estacionCreacion', 'responsable', 'estacionResponsable', 'fechaFinal', 'accion'];
    this.getValuesTable();
  }


  descargarCsv(){
    this.descargaCsv.descargarBitacora(this.data, this.otService.ot.num_ot);
  }

  close() {
    this.dialogRef.close();
  }

  getValuesTable(): any{
    this.spinner.show();
    this.endpointsService.getValuesBitacora(this.otService.ot.num_ot).subscribe(
      (response) => {

        this.data = [];

        if(response.movimientos){
          this.data = response.movimientos;
        }

        this.dataSource = new MatTableDataSource<any>(this.data);

        this.spinner.hide();

      },
      (error) => {
        this.spinner.hide();
      }
    );
    this.spinner.hide();
  }

}
