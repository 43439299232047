import { Component, OnInit, Input } from '@angular/core';
import { LoaderService } from '@services/loader/loader.service';
import { MatTableDataSource } from '@angular/material/table';
import { GetOTDatosAseguradosInterface } from '@interfaces/endpoints.interface';
 

@Component({
  selector: 'app-insured-data',
  templateUrl: './insured-data.component.html',
  styleUrls: ['./insured-data.component.scss']
})
export class InsuredDataComponent implements OnInit {

  data: GetOTDatosAseguradosInterface[];
  displayedColumns: any;
  dataSource: MatTableDataSource<any>;

  @Input() listaAsegurados: any[];

  constructor(private readonly spinner: LoaderService) {
      this.data = [];
  }

  ngOnInit(): void {
    this.displayedColumns = ['codFiliacion', 'nombre', 'reglas'];
    this.getValuesTable();
  }

  getValuesTable(): any{
    this.data = this.listaAsegurados;
    this.dataSource = new MatTableDataSource<any>(this.data);
  }
   
}
