import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { EndpointsService } from '@services/endpoints/endpoints.service';
import { ErrorMessagesService } from '@services/error-messages/error-messages.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { LoaderService } from '@services/loader/loader.service';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { ModalService } from '@services/modal/modal.service';
import { OtService } from '@services/ot/ot.service';
import { EditPersonComponent } from '../edit-person/edit-person.component';
import { SearchAgentComponent } from '../search-agent/search-agent.component';
import { SearchPersonComponent } from '../search-person/search-person.component';
import { EventosScrollService } from '@services/eventos-scroll/eventos-scroll.service';

@Component({
    selector: 'app-generic',
    templateUrl: './generic.component.html',
    styleUrls: ['./generic.component.scss']
})
export class GenericComponent implements OnInit {
    tipo: string;
    args: any;
    header: string;
    botonCerrar: string;
    showBotonGenerico: boolean;

    // Datos de la Orden de trabajo
    data: GetOTResponseInterface;

    constructor(
        private fb: FormBuilder,
        public readonly modalService: ModalService,
        @Inject(MAT_DIALOG_DATA) public inboundData: any,
        public dialogRef: MatDialogRef<GenericComponent>,
        private readonly dialog: MatDialog,
        public readonly errorMessagesService: ErrorMessagesService,
        private genericModalService: GenericModalService,
        private otService: OtService,
        private readonly spinner: LoaderService,
        private readonly storage: LocalStorageService,
        private endpointsService: EndpointsService,
        private eventosScroll: EventosScrollService

    ) {}

    ngOnInit(): void {
        this.tipo = this.inboundData.tipo;
        this.args = this.inboundData.args;
        this.header = 'Error';
        this.botonCerrar = 'Aceptar';
        this.showBotonGenerico = true;

        switch (this.tipo) {
            case 'cancelacionOT':
                this.header  = 'Cancelación exitosa';
                this.showBotonGenerico = false;
                break;
            case 'guardarExitosoSalir':
            case 'guardarExitosoNueva':
                this.header = 'Orden Guardada';
                this.showBotonGenerico = false;
                break;
            case 'faltaObs':
                this.header = 'Observaciones';
                break;
            case 'faltaAutorizacion':
                this.header = 'Autorización Especial';
                break;
            case 'exitoAutEsp':
                this.header = 'Autorización Especial';
                this.showBotonGenerico = false;
                break;
            case 'nuevaOT':
                this.header = '¿Registrar Nueva Orden?';
                this.showBotonGenerico = false;
                break;
            case 'turnarExitoso':
            case 'recuperarExitoso':
                this.header = 'Éxito';
                this.showBotonGenerico = false;
                break;
            case 'docsNoValidados':
            case 'docsReqNoValidados':
            case 'noAutorizacion':
            case 'polizaInvalida':
                this.header = 'Importante';
                break;
            case 'errorPermisos':
            case 'sinSociosComerciales':    
                this.header = 'Error';
                this.showBotonGenerico = false;
                break;
            case 'subidaArchivos':
            case 'borradoExitoso':
                this.header = 'Éxito';
                break;
            case 'faltanDocs':
                this.header = 'Faltan Documentos Requeridos';
                break;
            case 'faltaDatoEdicion':
                this.header = 'Faltan Datos Requeridos';
                this.showBotonGenerico = false;
                break;
            case 'limpiar':
                this.header = '¿Estás seguro?';
                this.botonCerrar = 'Cancelar';
                break;
            case 'cancelar':
                this.header = '¿Guardar OT?';
                this.botonCerrar = 'Cancelar';
                break;
            case 'validacionEnviar':
                this.header = 'Campo Faltante';
                break;
            case 'errorAlta':
                this.header = 'Error en Alta';
                this.showBotonGenerico = false;
                break;
            case 'errorEdicion':
                this.header = 'Error en Edición';
                this.showBotonGenerico = false;
                break;
            case 'errorRfc':
                this.header = 'Error al obtener el RFC';
                this.showBotonGenerico = false;
                break;
            case 'exitoAlta':
                this.header = 'Alta Exitosa';
                break;
            case 'exitoEdicion':
                this.header = 'Edición Exitosa';
                break;
            case 'guardarObsDocs':
                this.header = 'Guardar';
                this.botonCerrar = 'Cancelar';
                break;
            case 'avisoSeTerminaOT':
                this.header = 'Importante';
                this.showBotonGenerico = false;
                break;
            case 'intermediarios':
            case 'personas':
                this.header = 'Resultado';
                this.showBotonGenerico = false;
                break;
            case 'errorGetAccionesInfo':
                this.header = 'Error';
                break;
            case 'autorizacionEspecial':
                this.header = 'Autorización especial';
                break;
            case 'revaloracionExitosa':
                this.header = 'Revaloración exitosa';
                this.showBotonGenerico = false;
                break;
            case 'recuperacionExitosa':
                this.header = 'Recuperación exitosa';
                this.showBotonGenerico = false;
                break;
            case 'recuperacionError':
                this.header = 'Error';
                break;
            case 'avisoErrorTurnar':
                this.header = 'Aviso';
                break;
            
        }

        // Leer datos de la ot
        this.data = this.otService.ot;
    }

    /**
     * Cerrar este modal.
     *
     */
    close() {
        this.dialogRef.close();
    }

    limpiar() {
        this.otService.registrarNueva = this.data;
        this.dialogRef.close();
    }

    cancelar(guardar) {
        if (guardar) {
            this.otService.setGuardar = false;
        } else {
            if (this.data.num_ot !== undefined) {
                // Si la OT había sido guardada, tiene num_ot y hay que borrarla de DataStore
                const mailParam = this.storage.getItem('mail');
                this.spinner.show();
                this.endpointsService
                    .eliminarOT({
                        num_ot: this.data.num_ot,
                        usuario: mailParam
                    })
                    .subscribe(
                        (response) => {
                            this.spinner.hide();
                            this.otService.setSalir = '';
                            this.dialogRef.close();
                        },
                        (error) => {
                            this.spinner.hide();
                            this.genericModalService.showModal(
                                'generico',
                                'errorTerminandoOT',
                                {}
                            );
                            this.dialogRef.close();
                        }
                    );
            } else {
                this.otService.setSalir = '';
                this.dialogRef.close();
            }
        }
    }

    openEdicion() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.minWidth = '800px';
        this.eventosScroll.eventScroll();
        this.dialog.open(EditPersonComponent, dialogConfig);
        this.dialogRef.close();
    }

    openAlta() {
        
    }

    // Implementado
    salir() {
        this.otService.setSalir = '';
        this.dialogRef.close();
    }

    // Implementado
    registrarNueva() {
        this.otService.registrarNueva = this.inboundData.args.data;
        this.dialogRef.close();
    }

    // Implementado
    guardarObsDocs() {
        this.dialogRef.close('guardarObsDocs');
    }

    terminarOT() {
        var numsOT = [
            {
                tipoSolicitud: this.data.generales.tipoSolicitud,
                num_ot: this.data.num_ot
            }
        ];
        var payload = {
            estado: 'terminada',
            guia: '',
            nums_ot: numsOT,
            usuario: this.data.usuario
        };

        this.spinner.show();
        this.endpointsService.terminarOrden(payload).subscribe(
            (response) => {
                this.spinner.hide();
                this.otService.setSalir = '';
                this.dialogRef.close();
            },
            (error) => {
                this.spinner.hide();
                this.genericModalService.showModal(
                    'generico',
                    'errorTerminandoOT',
                    {}
                );
                this.dialogRef.close();
            }
        );
    }

    openIntermediarios() {
        // Seteamos el titulo y descripcion del modal de confirmacion
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;

        dialogConfig.data = {
            urlParams: this.inboundData.args.urlParams,
            data: this.inboundData.args.data
        };
        this.eventosScroll.eventScroll();
        this.dialog.open(SearchAgentComponent,dialogConfig);
        this.dialogRef.close();
    }

    openPersonas() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;

        dialogConfig.data = {
            urlParams: this.inboundData.args.urlParams,
            data: this.inboundData.args.data
        };
        this.otService.setYaBusco = true;
        this.eventosScroll.eventScroll();
        this.dialog.open(SearchPersonComponent, dialogConfig);
        this.dialogRef.close();
    }
}
