import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RamosInterface, SocioComercialCatalogosApiInterface, TipoMovimientoCatalogosApiInterface, TipoSolicitudCatalogosApiInterface } from '@interfaces/catalogos-api';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { QueryParamsInterface, SelectInputInterface } from '@interfaces/general.interface';
import { LoaderService } from '@services/loader/loader.service';
import { OtService } from '@services/ot/ot.service';
import { ServiciosGnpService } from '@services/servicios-gnp/servicios-gnp.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { Subscription } from 'rxjs';
import { REGLAS_PANTALLA as reglas_pantalla} from '@fixtures/nueva-ot.fixture';
import { UtilsService } from '@services/utils/utils.service';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-gmm-m-r',
  templateUrl: './gmm-m-r.component.html',
  styleUrls: ['./gmm-m-r.component.scss']
})
export class GmmMRComponent implements OnInit {

  /**
   * Parametros en la URL.
   */
  @Input()
  urlParams: QueryParamsInterface;

  /**
   * Opciones para el combobox Ramo.
   */
  ramosList:  RamosInterface[] = [
    {valor:'gmm', texto: 'Gastos Médicos Mayores'}
  ];

  /**
   * Opciones para el combobox Tipo de solicitud.
   */
  @Input()
  tipoSolicitudList:  Array<TipoSolicitudCatalogosApiInterface> = new Array();

  sociosList: Array<SocioComercialCatalogosApiInterface> = new Array();;

  /**
   * Opciones para el combobox Tipo de Movimiento.
   */
  @Input()
  tiposMovimientoList: Array<TipoMovimientoCatalogosApiInterface> = new Array();

  /**
   * Datos de la orden de trabajo que se esta creando/editando.
   */
  @Input()
  data: GetOTResponseInterface;

  @Output() flagSiga = new EventEmitter<boolean>();

  // Suscripcion a lectura de la OT.
  subscription: Subscription;

  // Suscripcion a lectura del rol del usuario.
  subscriptionRol: Subscription;

  form: FormGroup;

  // Suscripcion a cambios en el formulario de datos generales
  formChangesSubscription: Subscription;

  // Rol del usuario
  rol: string;

  monedasList: Array<SelectInputInterface> = [
    {value:'pesos', viewValue: 'Pesos'},
    {value:'dolares', viewValue: 'Dolares'}
  ];

  monedaSelected: string = 'pesos';
  sigaSelected: boolean = false;
  solidezFamiliarSelected: boolean = false;

  // Para saber si el checkbox de riesgo selecto esta habilitado
  riesgoSelectoSelected: boolean = false;

  validoPoliza: boolean;
  polizaValida: boolean;
  opcionesFolioAgente: any;

  subscrTipoSolicitud: Subscription;

  // Suscripcion a validacion de formulario.
  $validateForm: Subscription;

  FUERZA_PRODUCTORA: string = 'FUERZA PRODUCTORA';

  cargaInicial: boolean;

  cargaBanca: boolean = false;

  constructor(private fb: FormBuilder,
    private otService: OtService,
    private router: Router,
    private readonly spinner: LoaderService,
    private serviciosGnpService: ServiciosGnpService,
    private genericModalService: GenericModalService,
    private utils: UtilsService) {
    this.formChangesSubscription = new Subscription();

    this.rol = '';
    this.cargaInicial = false;

   }

  ngOnInit(): void {

    this.form = this.fb.group({
      lineaNegocio: new FormControl({value: '', disabled: true}),
      tipoSolicitud: new FormControl({value: ''}, Validators.required),
      tipoMovimiento: new FormControl({value: ''}, Validators.required),
      otReferencia: new FormControl({value: '', disabled: false}),
      siga: new FormControl({value: this.sigaSelected, disabled: false}),
      numeroPoliza: new FormControl({value: '', disabled: false}, Validators.required),
      numeroSolicitantes: new FormControl(''),
      riesgoSelecto: new FormControl({value: this.riesgoSelectoSelected, disabled: false}),
      folioRiesgoSelecto: new FormControl('')
    });

    this.form.get("tipoSolicitud").valueChanges.subscribe((myValue) => { 
      this.data.generales.tipoSolicitud = myValue;
      this.updateOT(this.data);

      if(this.urlParams.tipo_solicitud != myValue){
        this.urlParams.tipo_solicitud = myValue;
        let params2: URLSearchParams = this.utils.serialize(JSON.parse(JSON.stringify(this.urlParams)));
        let newUrl = 'home?' + params2.toString();
        this.router.navigateByUrl(newUrl,{ replaceUrl: true });
      }
    });
    this.form.get("tipoMovimiento").valueChanges.subscribe((myValue) => {
      const tipoSolicitud = this.form.get("tipoSolicitud").value;
      const tipoMovimiento = myValue;
      if(this.cargaInicial || this.urlParams.orden_id) {
        this.cargaInicial = false;
        return;
      }
      if(tipoSolicitud === 'movimiento' && tipoMovimiento === this.FUERZA_PRODUCTORA) {
        this.searchPolicy();
      }
      this.changeAgentData(tipoSolicitud, tipoMovimiento);

      this.data.generales.tipoMovimiento = myValue;
      this.updateOT(this.data);
    });
    this.form.get("otReferencia").valueChanges.subscribe((myValue) => {
      this.data.generales.otReferencia = myValue;
      this.updateOT(this.data);
    });
    this.form.get("riesgoSelecto").valueChanges.subscribe((myValue) => {
      this.data.generales.riesgoSelecto = myValue;
      this.updateOT(this.data);
    });
    this.form.get("folioRiesgoSelecto").valueChanges.subscribe((myValue) => {
      this.data.generales.folioRiesgoSelecto = myValue;
      this.updateOT(this.data);
    });
    this.form.get("siga").valueChanges.subscribe((myValue) => {
      this.data.generales.siga = myValue;
      this.updateOT(this.data);
    });
    this.form.get("numeroPoliza").valueChanges.subscribe((myValue) => {
      this.data.generales.numeroPoliza = myValue;
      this.updateOT(this.data);
    });
    this.form.get("numeroSolicitantes").valueChanges.subscribe((myValue) => {
      this.data.generales.numeroSolicitantes = myValue;
      this.updateOT(this.data);
    });

   // Si ya hay datos, actualizar los campos.
    let myOtData = this.otService.ot;

    if(myOtData) {
      this.data = myOtData;

      this.llenarInfoBancaSeguro();

      this.populateFields(myOtData);

      let myRol = this.otService.rol;
      if(myRol) {
        this.rol = myRol;
        this.disableFields(this.data, myRol);

      }
    } else {
      this.subscription = this.otService.initialized.subscribe((otData) => {
        this.cargaInicial = true;
        // LLego la data
        this.data = otData;

        this.llenarInfoBancaSeguro();

        // Suscribir a cambios en el rol
        // Desactivar campos en base a las reglas, rol del usuario y data de la Orden de trabajo
        this.subscriptionRol = this.otService.rolInitialized.subscribe(
          (rol) => {
            this.rol = rol;
              this.disableFields(this.data, rol);
              this.changeAgentData(this.urlParams.tipo_solicitud, this.data.generales.tipoMovimiento);
          }
        );

        this.populateFields(this.data);
      });
    }

    // Para saber si el número de póliza fue verificado y es válido.
    this.otService.setValidoPoliza = false;
    // Para saber si mandó a validar. Independientemente del resultado.
    this.otService.setPolizaValida = false;

    this.$validateForm = this.otService.validateFrm.subscribe((validate) => {

      if(this.data.statusOrden !== 'registro_da' || 
      (this.data.statusOrden === 'registro_da' && this.usuarioBancaSeguro())){

      this.form.get('socioComercial').markAsTouched();
      this.form.get('socioComercial').updateValueAndValidity();

      }

      this.form.get('tipoSolicitud').markAsTouched();
      this.form.get('tipoSolicitud').updateValueAndValidity();
      this.form.get('tipoMovimiento').markAsTouched();
      this.form.get('tipoMovimiento').updateValueAndValidity();
      
      this.form.get('numeroPoliza').markAsTouched();
      this.form.get('numeroPoliza').updateValueAndValidity();
      
      if(this.riesgoSelectoSelected) {
        this.form.get('folioRiesgoSelecto').setValidators(Validators.required);
          this.form.get('folioRiesgoSelecto').markAsTouched();
          this.form.get('folioRiesgoSelecto').updateValueAndValidity();
      } else {
        this.form.get('folioRiesgoSelecto').setValidators(null);
      }

      this.form.updateValueAndValidity();
      this.otService.updateValidation('frmmr', this.form.status == 'VALID');
    });
  }


  llenarInfoBancaSeguro(){

    const habilitarBancaSeguro = this.data.statusOrden === 'registro_da' && this.usuarioBancaSeguro();
    this.data.generales.bancaSeguro = habilitarBancaSeguro ? true : 
    this.data.generales.bancaSeguro === undefined ? false : this.data.generales.bancaSeguro;



    if(this.data.statusOrden !== 'registro_da' || 
        (this.data.statusOrden === 'registro_da' && this.usuarioBancaSeguro())){
            this.cargaBanca = true;
            // Se agrega control para socioComercial
            this.form.addControl('socioComercial',new FormControl(
                { value: '', disabled: !habilitarBancaSeguro },
                habilitarBancaSeguro ? Validators.required : null
            ));
            this.form.get('socioComercial').valueChanges.subscribe((myValue) => {
                if(myValue != undefined){
                  this.data.generales.nombreSocioComercial = myValue.nombreSocioComercial;
                  this.data.generales.codigoSocioComercial = myValue.idNegocioOperable;
                  this.updateOT(this.data);
                }
            });
            // Se agrega control para bancaSeguros
            this.form.addControl("bancaSeguros", new FormControl({
                value:  this.data.generales.bancaSeguro,
                disabled: true
            }));
            this.form.get('bancaSeguros').disable();

            this.serviciosGnpService.getSociosComerciales().subscribe(
              (data) => {
                  this.sociosList = data;
                  //Si es ot generado se actualiza valor de socioComercial
                  if(this.data.statusOrden !== 'registro_da' && this.data.generales.nombreSocioComercial != ''
                  && this.data.generales.nombreSocioComercial != undefined ){
                      this.form.get('socioComercial').setValue(this.sociosList.find(
                          (element) => element.nombreSocioComercial ===  this.data.generales.nombreSocioComercial ));
                  }
              }, (error) => {
                  if(this.data.statusOrden === 'registro_da'){
                    this.genericModalService.showModal('generico', 'sinSociosComerciales', { mensaje : error.error.mensaje });
                  }   
                  console.log('Ocurrio un error al obtener los socios comerciales', error);
              }
          );
    }

  }

  /**
   * 
   * @param tipoSolicitud - movimiento o renovacion.
   * @param tipoMovimiento - catalogo de tipos de movimiento.
   */
  changeAgentData(tipoSolicitud: string, tipoMovimiento: string) {
    if(tipoSolicitud == 'movimiento') {
      if(tipoMovimiento == this.FUERZA_PRODUCTORA 
        && (this.rol == 'coordinacion_da' || 
        (this.urlParams.menu == environment.menu_portal_empleados && this.urlParams.orden_id == undefined))) {
        this.otService.setAgentData = true;
      } else {
        this.otService.setAgentData = false;
      }
    }
  }

  usuarioBancaSeguro(){
    return this.otService.esUsuarioBancaSeguro;     
   }

  /**
   * Inhabilitar campos del formulario en base a los datos de la OT y el rol del usuario.
   * @param data - Datos de la orden de trabajo.
   * @param rol  - Rol del usuario.
   */
  disableFields(data: any, rol: string) {
    if(!(reglas_pantalla.tipo_solicitud.enable.indexOf(rol) >= 0 && data.statusOrden == 'registro_da')) {
      this.form.get('tipoSolicitud').disable();
    }

    if((data.statusOrden == 'registro_da') ? !(reglas_pantalla.generales.enable.indexOf(rol) >= 0) : true) {
      this.form.get('tipoMovimiento').disable();
    }

    if((data.statusOrden == 'registro_da') ? !(reglas_pantalla.generales.enable.indexOf(rol) >= 0) : true) {
      this.form.get('otReferencia').disable();
    }

    if((data.statusOrden == 'registro_da') ? !(reglas_pantalla.generales.enable.indexOf(rol) >= 0) : true) {
      this.form.get('siga').disable();
    }

    if((data.statusOrden == 'registro_da') ? !(reglas_pantalla.generales.enable.indexOf(rol) >= 0) : true) {
      this.form.get('numeroPoliza').disable();
    }

    if((data.statusOrden == 'registro_da') ? !(reglas_pantalla.generales.enable.indexOf(rol) >= 0) : true) {
      this.form.get('numeroSolicitantes').disable();
    }

    if((data.statusOrden == 'registro_da') ? !(reglas_pantalla.generales.enable.indexOf(rol) >= 0) : true) {
      this.form.get('riesgoSelecto').disable();
    }

    if((data.statusOrden == 'registro_da') ? !(reglas_pantalla.generales.enable.indexOf(rol) >= 0) : true) {
      this.form.get('folioRiesgoSelecto').disable();
    }

    if (this.otService.isEmpleado  && this.otService.isConsulta) {
      if(data.status === 'En Registro' 
      || data.status === 'Cancelada Automática'
      || data.status === 'Cancelada Solicitante') {
        this.form.get('tipoSolicitud').disable();
        this.form.get('tipoMovimiento').disable();
        this.form.get('otReferencia').disable();
        this.form.get('siga').disable();
        this.form.get('numeroPoliza').disable();
        this.form.get('numeroSolicitantes').disable();
        this.form.get('riesgoSelecto').disable();
        this.form.get('folioRiesgoSelecto').disable();

      }
    }
  }

  /**
   * Actualiza los datos de la ot en el servicio compartido.
   *
   * @param otData - Datos a actualizar.
   */
  updateOT(otData: GetOTResponseInterface) {
    this.otService.setOt = otData;
  }

  /**
   * Poblar campos del formulario con data del backend.
   *
   * @param otData - Data del backend.
   *
   */
  populateFields(otData: GetOTResponseInterface) {
    this.form.get('numeroPoliza').setValue(this.data.generales.numeroPoliza);
    this.data = otData;
    this.form.get('lineaNegocio').setValue(this.data.generales.lineaNegocio);
    this.form.get('tipoSolicitud').setValue(this.data.generales.tipoSolicitud);
    this.form.get('tipoMovimiento').setValue(this.data.generales.tipoMovimiento);
    this.form.get('otReferencia').setValue(this.data.generales.otReferencia);
    this.sigaSelected = this.data.generales.siga ? this.data.generales.siga : false;
    this.form.get('siga').setValue(this.sigaSelected);
    this.form.get('numeroSolicitantes').setValue(this.data.generales.numeroSolicitantes);
    this.riesgoSelectoSelected = this.data.generales.riesgoSelecto ? this.data.generales.riesgoSelecto : false;
    this.form.get('riesgoSelecto').setValue(this.riesgoSelectoSelected);
    this.form.get('folioRiesgoSelecto').setValue(this.data.generales.folioRiesgoSelecto);
  }

  setSiga(evt) {
    this.sigaSelected = evt.checked;
    this.flagSiga.emit(this.sigaSelected);
  }

  setSolidezFamiliar(evt) {
    this.solidezFamiliarSelected = evt.checked;
  }

  /**
   * Aplicar las validaciones de poliza al dar click al icono de buscar.
   * @returns Un mensaje dependiendo de lo que resuelva el back.
   *
   */
   searchPolicy() {
    let numPoliza = this.form.get('numeroPoliza').value;
    
    if (numPoliza === undefined || numPoliza === '') {
      this.form.get('numeroPoliza').markAsTouched();
      this.form.get('numeroPoliza').updateValueAndValidity();
      return;
    }

    if (numPoliza.length < 14) {
        let digitosFaltantes = 14 - numPoliza.length;
        var zeroString = "";
        for (var i = 0; i < digitosFaltantes; i++) {
            zeroString += "0";
        }
        numPoliza = zeroString + numPoliza;
    }
    this.form.get('numeroPoliza').setValue(numPoliza);
    let tipoMovimiento = this.form.get('tipoMovimiento').value;
    let tipoMov = typeof(tipoMovimiento);

    let tipoSolicitud = this.form.get("tipoSolicitud").value;

    if(tipoMov === "object" || (tipoMovimiento === 'string' && tipoMovimiento === '') || tipoMov === undefined) {
      return;
    }

    if(tipoSolicitud === undefined || tipoSolicitud === '') {
      return;
    }
    
    let daUsuario = "";
    if (this.urlParams.da && typeof this.urlParams.da === 'string'
      && this.urlParams.menu === environment.menu_portal_intermediarios) {
      daUsuario = JSON.parse(this.urlParams.da)[0].trim();
    }
    let payloadVerificar = {
        "da": daUsuario,
        "num_poliza": numPoliza,
        "tipo_movimiento": tipoMovimiento,    // #RF 07
        "tipo_solicitud": tipoSolicitud
    };
    
    this.spinner.show();
    this.serviciosGnpService.verificarPoliza(payloadVerificar).subscribe((response) => {
      this.spinner.hide();
      this.otService.setValidoPoliza = true;
      if (response.permitido) {
        this.otService.setPolizaValida = true;
        
        if(response.datosAgente.length > 0) {
          if(this.rol != 'agente_certificado') {
            this.data.datosAgente = response.datosAgente[0];
          } else {
            this.data.datosAgente.folio = response.datosAgente[0].folio;
          }
        } 
        this.data.datosContratante = response.datosContratante;
        this.data.generales.producto = response.productoTecnico + ":" + response.productoComercial;
        // Actualiza la OT en el servicio compartido e informa a los
        // componentes de datos del agente y datos del contratante
        this.otService.setDatosAgenteYContratante = this.data;
      } else {
        this.polizaValida = false;
        this.genericModalService.showModal('generico', "polizaInvalida", { numeroPoliza: numPoliza});
      }
    }, (errorResponse) => {

      this.spinner.hide();
      this.genericModalService.showModal('generico', "errorPoliza", { error: errorResponse.error.mensaje });
    });
   }

   resetPoliza() {
    this.otService.setPolizaValida = false;
    this.otService.setValidoPoliza = false;
   }

   ngOnDestroy() {
     
    if(this.formChangesSubscription != undefined){
      this.formChangesSubscription.unsubscribe();
    }

    if(this.subscrTipoSolicitud != undefined){
      this.subscrTipoSolicitud.unsubscribe();
    }
    
    if(this.subscription != undefined){
      this.subscription.unsubscribe();
    }
    
   }

  setRiesgoSelecto(evt) {
    this.riesgoSelectoSelected = evt.checked;
  }

  soloNumeros(event: Event): void {
    const evento = (event.target as HTMLInputElement).value;
    const numero = parseFloat(evento.replace(/[^0-9]/g, '')).toFixed(0);
    const result = !isNaN(Number(numero)) ? numero : '';
    this.form.controls['numeroSolicitantes'].patchValue(result);
  }
}
