import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { QueryParamsInterface } from '@interfaces/general.interface';
import { EndpointsService } from '@services/endpoints/endpoints.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { LoaderService } from '@services/loader/loader.service';
import { OtService } from '@services/ot/ot.service';
import { Subscription } from 'rxjs';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDaterangepickerInlineConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import * as moment from 'moment';

import { environment } from '../../../../environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from '@services/utils/utils.service';
import { FileteadorService } from '@services/fileteador/fileteador.service';

@Component({
  selector: 'app-special-authorization',
  templateUrl: './special-authorization.component.html',
  styleUrls: ['./special-authorization.component.scss']
})
export class SpecialAuthorizationComponent implements OnInit {
  urlParams: QueryParamsInterface;

  @Input()
  funcionarioAutorizador: string;
  
  cargo: string;

  // Datos de la Orden de trabajo 
  data: GetOTResponseInterface;

  // Suscripcion a OT Inicializada
  subscriptionOT: Subscription;
  
  modalTitle: string = "Autorización Especial";
  formSpecialAuthorization: FormGroup;

  // Configuracion de Datepicker de bootstrap
  bsConfig: Partial<BsDaterangepickerInlineConfig>;

  // Para establecer la fecha minima permitida al usuario
  minDate: any;
  
  // Para saber si el cargo debe ser editable o no.
  cargoEditable: boolean;

  constructor(
    private utils: UtilsService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public inboundData: any,
    public dialogRef: MatDialogRef<SpecialAuthorizationComponent>,
    private readonly spinner: LoaderService,
    private genericModalService: GenericModalService, 
    private otService: OtService,
    private readonly datePipe: DatePipe,
    private endpointsService: EndpointsService,
    private localeService: BsLocaleService,
    iconRegistry: MatIconRegistry, 
    sanitizer: DomSanitizer,
    private fileteador: FileteadorService,
    ) {
      // Registrar Iconos de las secciones
      iconRegistry.addSvgIcon('calendar-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/img/datos-generales.svg'));
        
      esLocale.weekdaysShort = ['dom.','lun', 'mar.', 'mié.', 'jue.', 'vie.', 'sáb.'];
      esLocale.week.dow = 0;
      defineLocale('es', esLocale);
      this.localeService.use('es');
      const bsConfig = {
        containerClass: 'theme-dark-blue', 
        dateInputFormat: 'DD/MM/YYYY',
        clearPosition: 'center',
        minDate: moment().subtract(2, 'years').toDate(),
        showWeekNumbers: false
      };
      this.bsConfig = Object.assign({}, bsConfig);
      this.cargoEditable = true;
    }

  ngOnInit(): void {
    this.cargo = this.otService.cargo;
    this.cargoEditable = (this.cargo && this.cargo !== '') ? true : false;
    this.buildFrm();
    
    this.urlParams = this.inboundData.urlParams;
    // LLego la data
    this.data = this.otService.ot;
    this.populateFields(this.data);

    // Documentacion del datepicker
    // https:// valor-software.com/ngx-bootstrap/old/6.1.0/#/datepicker
  }

  buildFrm(): void {
    this.formSpecialAuthorization = this.fb.group({
      ot: new FormControl({value: '', disabled: true}, [Validators.required]),
      poliza: new FormControl({value: '', disabled: true}, [Validators.required]),
      da: new FormControl({value: '', disabled: true}, [Validators.required]),
      cua: new FormControl({value: '', disabled: true}, [Validators.required]),
      nombreContratante: new FormControl({value: '', disabled: true}, [Validators.required]),
      vigencia: new FormControl({value: '', disabled: true}, [Validators.required]),
      descripcionAut: new FormControl({value: '', disabled: false}, [Validators.required]),
      elementosAut: new FormControl({value: '', disabled: false}, [Validators.required]),
      firma: new FormControl({value: '', disabled: true}, [Validators.required]),
      cargo: new FormControl({value: '', disabled: this.cargoEditable}, [Validators.required])
    });
  }

  /**
   * Poblar campos del formulario con data del backend.
   * 
   * @param otData - Data del backend.
   * 
  */
  populateFields(otData: GetOTResponseInterface) {
    this.data = otData;
    var nombreCompleto = this.data.datosContratante.nombre + " " + this.data.datosContratante.apellido1 + " " + this.data.datosContratante.apellido2;
    
    this.formSpecialAuthorization.get('ot').setValue(this.data.num_ot);
    let poliza = this.data.generales.numeroPoliza ? this.data.generales.numeroPoliza : this.data.generales.numeroPrepoliza;
    this.formSpecialAuthorization.get('poliza').setValue(poliza);
    this.formSpecialAuthorization.get('da').setValue(this.data.datosAgente.da);
    this.formSpecialAuthorization.get('cua').setValue(this.data.datosAgente.cua);
    this.formSpecialAuthorization.get('nombreContratante').setValue(this.data.datosContratante.razonSocial || nombreCompleto);
    // precargar la fecha actual. #RF 6
    this.formSpecialAuthorization.get('vigencia').setValue(new Date());
    this.formSpecialAuthorization.get('descripcionAut').setValue('');
    this.formSpecialAuthorization.get('elementosAut').setValue('');
    
    // Recuperar el nombre y cargo del usuario en sesion (vienen del endpoint /endpoints/gmm/v1/getNombre)
    this.formSpecialAuthorization.get('firma').setValue(this.otService.nombre);
    this.formSpecialAuthorization.get('cargo').setValue(this.cargo);

  }

  close() {
    this.dialogRef.close();
  }

  enviar() {
    if(!this.validateEmpty('descripcionAut')) {
      return;
    }
    if(!this.validateEmpty('elementosAut')) {
      return;
    }
    if(!this.validateEmpty('cargo')) {
      return;
    }

    this.data = this.utils.fixOT(this.data);
    let payload = {
        num_ot: this.data.num_ot,
        status: 'linea_produccion',
        orden: JSON.parse(JSON.stringify(this.data))
    };
    
    payload = this.utils.filterNewObservations(payload);

    let templateOT = "Orden de Trabajo: " + this.formSpecialAuthorization.get('ot').value + ". \n";
    let templatePoliza = "Número de Póliza/Prepóliza: " + this.formSpecialAuthorization.get('poliza').value + ". \n";
    let templateDA = "Dirección de Agencia: " + this.formSpecialAuthorization.get('da').value + ". \n";
    let templateCUA = "Clave del Agente: " + this.formSpecialAuthorization.get('cua').value + ". \n";
    let templateNomContratante = "Nombre del Contratante: " + this.formSpecialAuthorization.get('nombreContratante').value + ". \n";
    // Devuelve un Date()
    const vigencia = this.formSpecialAuthorization.get('vigencia').value;
    let templateVigAuth = "Vigencia de la autorización: " + this.datePipe.transform(vigencia, 'YYYY-MM-dd') + ". \n";
    let templateElementosAut = "Elementos que sustentan la autorización: " + this.formSpecialAuthorization.get('elementosAut').value + ". \n";
    let templateFirma = "Nombre y cargo de la persona que autoriza: " + this.otService.nombre + " " + this.formSpecialAuthorization.get('cargo').value + ". \n";
    let templateDescripcionAut = "Descripción de la autorización: " + this.formSpecialAuthorization.get('descripcionAut').value + ". \n";
        
    let textBits = [{
            "input": "ot",
            "template": templateOT
        },
        {
            "input": "poliza",
            "template": templatePoliza
        },
        {
            "input": "da",
            "template": templateDA
        },
        {
            "input": "cua",
            "template": templateCUA
        },
        {
            "input": "nombreContratante",
            "template": templateNomContratante
        },
        {
            "input": "vigencia",
            "template": templateVigAuth
        },
        {
            "input": "descripcionAut",
            "template": templateDescripcionAut
        },
        {
            "input": "elementosAut",
            "template": templateElementosAut
        },
        {
            "input": "firma",
            "template": templateFirma
        }
    ];
    let textoObservacion = '';
    for (let i = 0; i < textBits.length; i++) {
      if (this.formSpecialAuthorization.controls[textBits[i].input].value) {
        textoObservacion += textBits[i].template;
      }
    }
    let currentDate = this.datePipe.transform(
      new Date(), environment.datePatternObs
    );
    let observacion = {
        "textoTipo": "AUTORIZACION ESPECIAL POR",
        "texto": textoObservacion,
        "nombre": this.otService.nombre,
        "fecha": currentDate,
        "canDelete": false
    };

    payload.orden.observacionesInternas.unshift(observacion);
    payload = this.utils.deleteEmptyObservations(payload);

    this.spinner.show();
    this.dialogRef.addPanelClass('hidePanel');
    this.endpointsService.turnarOrden(payload, 'turnar').subscribe(
      (response) => {
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');
        // Generar el PDF de la cedula de autorizacion.
        this.generaCedula(payload);
      }, (error) =>{
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');
        if(error.error.warning != undefined || error.error.warning != null){
          this.genericModalService.showModal('generico', 'avisoErrorTurnar', { error: error.error.message });
        } else {
          this.genericModalService.showModal('generico', 'errorTurnando', { error: error.error.message });
        }
    });
  }  // end enviar()
    
  /**
   * Devuelve true en caso de que el valor del campo tenga un valor, 
   * false en caso contrario.
   * 
   * @param field - Campo a validar.
   * @returns true | false
   */
   validateEmpty(field:string): boolean {
     
    let fieldValue = this.formSpecialAuthorization.get(field).value;
    if(fieldValue) {
      fieldValue = fieldValue.toString().trim();
      if(fieldValue.length <= 0) {
        this.formSpecialAuthorization.get(field).markAsTouched();
        this.formSpecialAuthorization.get(field).updateValueAndValidity();
        return false;
      }
    } else {
      this.formSpecialAuthorization.get(field).markAsTouched();
      this.formSpecialAuthorization.get(field).updateValueAndValidity();
      return false;
    }

    return true;
  }
  
  /**
   * Invocar al fileteador para que genere el PDF de Cedula de autorizacion #RF 6.
   * 
   * @param payload - payload con el que se genera el PDF.
   * 
   */
  generaCedula(payload: any) {
    payload.orden.descCedulaAutorizacion = this.formSpecialAuthorization.get('descripcionAut').value;
    payload.orden.cargoCedulaAutorizacion = this.formSpecialAuthorization.get('cargo').value;
    
    this.spinner.show();
    this.dialogRef.addPanelClass('hidePanel');
    this.fileteador.generaCedula(payload).subscribe(
      (responseGeneraCedula) => {
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');
        this.dialogRef.close();
        this.genericModalService.showModal('generico', 'exitoAutEsp', { ot: this.data.num_ot });
        this.downloadCedula(responseGeneraCedula.archivo);
      }, (errorGeneraCedula) =>{
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');
        this.genericModalService.showModal('generico', 'errorTurnando', { error: errorGeneraCedula.message[0] });        
    });
    
  }

  /**
   * Descarga de la cedula de autorizacion.
   * 
   * @param fileContent - base64 del archivo.
   */
   downloadCedula(fileContent: string): void {
    var documentContent = fileContent;
    var documentName = 'Cédula de autorización';
    var myDocumentLink = document.createElement("a");
    myDocumentLink.href = documentContent;
    myDocumentLink.download = documentName;
    document.body.appendChild(myDocumentLink);
    myDocumentLink.click();
  }
}
