import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

  @Injectable({
    providedIn: 'root'
  })
  export class EventosScrollService {

    constructor() {
    }

    eventScroll(){
        window.parent.postMessage('toMiddleScroll',environment.urlIntermediarios);
    }

 }
