import {
    CommonModule,
    CurrencyPipe,
    DatePipe,
    DecimalPipe
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@pipes/pipes.module';
import { MaterialModule } from '../modules/material/material.module';
import { DirectivesModule } from '@directives/directives.module';
import { DataUsuarioComponent } from './data-usuario/data-usuario.component';
import { HeaderComponent } from './header/header.component';
import { ToastComponent } from './toast/toast.component';
import { FooterComponent } from './footer/footer.component';
import { LoaderComponent } from './loader/loader.component';
import { RouterModule } from '@angular/router';
import { InputCurrencyComponent } from './input-currency/input-currency.component';
import { ModalComponent } from './modals/modal/modal.component';
import { ModalMessageComponent } from './modals/modal-message/modal-message.component';
import { DialogConfirmComponent } from './modals/dialog-confirm/dialog-confirm.component';
import { GeneralDataComponent } from './general-data/general-data.component';
import { AgentDataComponent } from './agent-data/agent-data.component';
import { ContractingPartyDataComponent } from './contracting-party-data/contracting-party-data.component';
import { InsuredDataComponent } from './insured-data/insured-data.component';
import { AdditionalCommentsComponent } from './additional-comments/additional-comments.component';
import { DigitalFileComponent } from './digital-file/digital-file.component';
import { ActionButtonsComponent } from './action-buttons/action-buttons.component';
import { PortalModule } from '@angular/cdk/portal';
import { SearchAgentComponent } from './modals/search-agent/search-agent.component';
import { GridTableActionsComponent } from './grid-table-actions/grid-table-actions.component';
import { GridTableComponent } from './grid-table/grid-table.component';
import { FormMenuComponent } from './form-menu/form-menu.component';
import { SearchPersonComponent } from './modals/search-person/search-person.component';
import { GridTablePersonsComponent } from './grid-table-persons/grid-table-persons.component';
import { RegisterPersonComponent } from './modals/register-person/register-person.component';
import { EditPersonComponent } from './modals/edit-person/edit-person.component';
import { AddContactMethodComponent } from './modals/add-contact-method/add-contact-method.component';
import { AddEmailAddressComponent } from './modals/add-email-address/add-email-address.component';
import { AddAddressComponent } from './modals/add-address/add-address.component';
import { ErrorConexionComponent } from './modals/error-conexion/error-conexion.component';
import { LoaderModalComponent } from './loader-modal/loader-modal/loader-modal.component';
import { GmmNnCgiComponent } from './general-data/gmm-nn-cgi/gmm-nn-cgi/gmm-nn-cgi.component';
import { GmmMRComponent } from './general-data/gmm-m-r/gmm-m-r/gmm-m-r.component';
import { GenericComponent } from './modals/generic/generic.component';
import { GeneratedOrderComponent } from './modals/generated-order/generated-order.component';
import { SpecialAuthorizationComponent } from './modals/special-authorization/special-authorization.component';
import { TurnComponent } from './modals/turn/turn.component';
import { SpecialCasesComponent } from './modals/special-cases/special-cases.component';
import { RefuseConfirmationComponent } from './modals/refuse-confirmation/refuse-confirmation.component';
import { CancelComponent } from './modals/cancel/cancel.component';
import { ActivateComponent } from './modals/activate/activate.component';
import { ChangePolicyComponent } from './modals/change-policy/change-policy.component';
import { CancelDaComponent } from './modals/cancel-da/cancel-da.component';
import { AddAddressFiscalComponent } from './modals/add-address-fiscal/add-address-fiscal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogInputConfirmComponent } from './modals/dialog-input-confirm/dialog-input-confirm.component';
import { RecoverComponent } from './modals/recover/recover.component';
import { BitacoraComponent } from './modals/bitacora/bitacora.component';

@NgModule({
    providers: [DatePipe, CurrencyPipe, DecimalPipe],
    declarations: [
        DataUsuarioComponent,
        HeaderComponent,
        ToastComponent,
        FooterComponent,
        LoaderComponent,
        InputCurrencyComponent,
        ModalComponent,
        ModalMessageComponent,
        DialogConfirmComponent,
        GridTableComponent,
        GridTableActionsComponent,
        FormMenuComponent,

        GeneralDataComponent,
        AgentDataComponent,
        ContractingPartyDataComponent,
        InsuredDataComponent,
        AdditionalCommentsComponent,
        DigitalFileComponent,
        ActionButtonsComponent,
        SearchAgentComponent,
        SearchPersonComponent,
        GridTablePersonsComponent,
        RegisterPersonComponent,
        EditPersonComponent,
        AddContactMethodComponent,
        AddEmailAddressComponent,
        AddAddressComponent,
        ErrorConexionComponent,
        LoaderModalComponent,
        GmmNnCgiComponent,
        GmmMRComponent,
        GenericComponent,
        GeneratedOrderComponent,
        SpecialAuthorizationComponent,
        TurnComponent,
        SpecialCasesComponent,
        RefuseConfirmationComponent,
        CancelComponent,
        ActivateComponent,
        ChangePolicyComponent,
        CancelDaComponent,
        AddAddressFiscalComponent,
        DialogInputConfirmComponent,
        RecoverComponent,
        BitacoraComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        PipesModule,
        DirectivesModule,
        RouterModule,
        PortalModule,
        MatFormFieldModule
    ],
    exports: [
        DataUsuarioComponent,
        HeaderComponent,
        ToastComponent,
        FooterComponent,
        LoaderComponent,
        InputCurrencyComponent,
        ModalComponent,
        GridTableComponent,
        GridTableActionsComponent,
        FormMenuComponent,

        GeneralDataComponent,
        AgentDataComponent,
        ContractingPartyDataComponent,
        InsuredDataComponent,
        AdditionalCommentsComponent,
        DigitalFileComponent,
        ActionButtonsComponent,
        GridTablePersonsComponent
    ],
    entryComponents: [SearchAgentComponent]
})
export class ComponentsModule {}
