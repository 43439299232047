import {
    TableActionsInterface,
    TableColumnsInterface
} from '@interfaces/table.interface';

const imgBase = './assets/img/';

export const TableColumnsFixture: TableColumnsInterface[] = [
    {
        key: 'cua',
        label: 'C.U.A.',
        filter: false,
        filterOptions: null
    },
    {
        key: 'contrato',
        label: 'Contrato',
        filter: false,
        filterOptions: null
    },
    {
        key: 'da',
        label: 'DA',
        filter: false,
        filterOptions: null
    },
    {
        key: 'nombre',
        label: 'Nombre del Agente / Razón Social',
        filter: false,
        filterOptions: null
    },
    {
        key: 'estatus',
        label: 'Estatus',
        filter: false,
        filterOptions: null
    }
];

export const TableActionsFixture: TableActionsInterface[] = [
    {
        icon: {
            default: `${imgBase}delete/delete_24px.svg`,
            hover: `${imgBase}delete/delete_orange.svg`
        },
        value: 'select',
        tooltip: 'Seleccionar'
    }
];
