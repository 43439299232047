import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CatalogosInterface, SocioComercialCatalogosApiInterface } from '@interfaces/catalogos-api';
import { RequestVerificarPolizaInterface } from '@interfaces/servicios-gnp.interface';
import { HttpService } from '@services/http/http.service';
import { Observable } from 'rxjs';
import { SearchAgentsRequestInterface, SearchAgentsInterface } from '../../interfaces/search-agent';
import { ActualizaDomicilioFiscal } from '@interfaces/actualiza.persona.interface';
import { DireccionFiscalResponse } from '@interfaces/direccion-fiscal.interface';
import { AuthService } from '@services/auth/auth.service';

const endPoint = 'servicios-gnp/';

@Injectable({
  providedIn: 'root'
})
export class ServiciosGnpService {
  constructor(private readonly http: HttpService,private authService: AuthService) {}

  searchAgents(data: SearchAgentsRequestInterface): Observable<SearchAgentsInterface> {
      return this.http.post(`${endPoint}agentes/v1/buscar`, data);
  }

  loadCatalogosRegistration(request: any): Observable<CatalogosInterface> {
      return this.http.get(`${endPoint}catalogos/v1/catalogos`, null, request);
  }

  loadCatalogosByCP(request: any): Observable<CatalogosInterface> {
      return this.http.post(`${endPoint}catalogos/v1/codigoPostal`, request);
  }

  verificarPoliza(request: RequestVerificarPolizaInterface): Observable<any> {

    if(this.authService.rolGnp.includes('GM Work Site Emite') 
    || this.authService.rolGnp.includes('Respaldo MC emite')){
      request['rol'] = this.authService.rolGnp
    }

    return this.http.post(`${endPoint}prepolizas/v1/buscar`, request);
  }

  getRegimenCapital() : Observable<any> {
    return this.http.get(`${endPoint}catalogos/v1/regimen-capital`);
  }

  getDireccionFisica(codigoFiliacion) : Observable<DireccionFiscalResponse> {
      return this.http.get(`${endPoint}domicilio/v1/consulta-datos-fiscales?codigoFiliacion=${codigoFiliacion}`);
  }

  getRegimenFiscal(persona: any) : Observable<any> {
    const request = { persona: persona };
    return this.http.get(`${endPoint}catalogos/v1/regimen-fiscal`, null,  request);
  }

  /**
   * Descarga de un archivo del expediente digital.
   * @param request - Datos de la peticion para la url.
   * 
   * @returns - Archivo en formato blob.
   */
   descargaDoc(url: String): Observable<any> {
    const options = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
      }),
     // have to explicitly give as 'blob' or 'json'
      responseType: 'blob' as 'blob'  
    };
    return this.http.getBlob(`${endPoint}filenet/v1/buscar?${url}`, options);
  }

  /**
   * Actualiza el domicilio fiscal.
   * @param request
   * @returns
   */
  updateAddressFiscal(request: ActualizaDomicilioFiscal): Observable<any>{
    return this.http.post(`${endPoint}domicilio/v1/alta-datos-fiscales`, request);
  }


  /**
   * Obtener socios comerciales.
   * @returns
   */
  getSociosComerciales(): Observable<SocioComercialCatalogosApiInterface[]> {
    return this.http.get(`${endPoint}bancaseguro/v1/socios-comerciales`);
  }

}
