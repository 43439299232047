import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalService } from '@services/modal/modal.service';

@Component({
    selector: 'app-dialog-confirm',
    templateUrl: './dialog-confirm.component.html',
    styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {
    constructor(public readonly modalService: ModalService,
        public dialogRef: MatDialogRef<DialogConfirmComponent>,) {
        if(!modalService.confirmAlertText.acceptLabel) {
            modalService.confirmAlertText.acceptLabel = "Aceptar"
        }
        if(!modalService.confirmAlertText.cancelLabel) {
            modalService.confirmAlertText.cancelLabel = "Cancelar"
        }
    }

    ngOnInit(): void {}

    
    /**
   * Cerrar el modal
   */
   close (response) {
    // Cerramos este modal (Captura de direccion)
    this.dialogRef.close({value: response});
    }

}
