import { Injectable } from '@angular/core';
import { HttpService } from '@services/http/http.service';
import { Observable } from 'rxjs';
import { ConfiguracionesResponseInterface, GetTipoRechazoInterface, GuionesActivacionResponseInterface, ObtenerCatalogosApiInterface, ObtenerRequestCatalogosApiInterface, SpecialCasesCatalogsInterface, TiposActivacionResponseInterface } from '@interfaces/catalogos-api';

const endPoint = 'catalogos-api/';

@Injectable({
  providedIn: 'root'
})
export class CatalogosApiService {
  constructor(private readonly http: HttpService) {}

  /**
   * Obtener los catalogos generales documento, productos y solicitudes.
   * 
   * @param data - Payload para este request.
   * 
   * @returns Listado de catalogos.
   */
   obtener(data: ObtenerRequestCatalogosApiInterface): Observable<ObtenerCatalogosApiInterface> {
    return this.http.post(`${endPoint}catalogos/obtener`, data);
  }

  /**
   * Obtener los mensajes de error para el usuario.
   * 
   * @param ramo - Ramo de GMM 
   * @returns Listado de mensajes de error encapsulados en un objeto.
   */
  getMensajesError(ramo: string): Observable<ConfiguracionesResponseInterface> {
      return this.http.get(`${endPoint}${ramo}/configuraciones`);
  }

  /**
   * Obtener el catalogo de tipos de desviacion.
   * 
   * @param request - Datos de la peticion.
   *  
   * @returns Listado de de tipos de desviacion.
   */
   getCatalogosDesviacion(request: any): Observable<any> {
    return this.http.get(`${endPoint}${request.ramo}/catalogos`, null, { tipo: 'DesviacionDA' });
  }

  /**
   * Obtener los motivos de rechazo.
   * 
   * @param ramo - ramo gmm.
   * @param catalogo - Nombre del catalogo o * para todos.
   * 
   * @returns Listado de tipos de rechazo.
   */
   getCatalogosRechazo(ramo: string, catalogo: string): Observable<GetTipoRechazoInterface> {
    return this.http.get(`${endPoint}${ramo}/rechazos`, null, { operador: catalogo });
  }

  /**
   * Obtener los los catalogos para el modal de Solicitud de Caso especiales.
   *    * 
   * @returns Listado de catalogos.
   */
   getCatalogosSolicitudCasosEsp(): Observable<SpecialCasesCatalogsInterface> {
    return this.http.get(`${endPoint}catalogos/casos_especiales`, null, {});
  }

  /**
   * Obtener el catalogo de tipos de activacion.
   * 
   * @param data - Payload para este request.
   * 
   * @returns Listado de tipos de activacion.
   */
   getCatalogosActivacion(ramo, payload): Observable<TiposActivacionResponseInterface> {
    return this.http.post(`${endPoint}${ramo}/activaciones`, payload);
  }

  /**
   * Obtener el catalogo de guiones de activacion.
   * 
   * @param data - Payload para este request.
   * 
   * @returns Listado de guiones de activacion.
   */
   getGuionesActivacion(ramo, payload): Observable<GuionesActivacionResponseInterface> {
    return this.http.get(`${endPoint}${ramo}/guiones`, null, payload);
  }

}
