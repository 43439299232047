import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { EndpointsService } from '@services/endpoints/endpoints.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { LoaderService } from '@services/loader/loader.service';
import { ModalPersonService } from '@services/modal-person/modal-person.service';
import { ModalService } from '@services/modal/modal.service';
import { OtService } from '@services/ot/ot.service';
import { UtilsService } from '@services/utils/utils.service';
import { environment } from '../../../../environments/environment';
import { CancelComponent } from '../cancel/cancel.component';

@Component({
  selector: 'app-cancel-da',
  templateUrl: './cancel-da.component.html',
  styleUrls: ['./cancel-da.component.scss']
})
export class CancelDaComponent implements OnInit {

  modalTitle: string = "Cancelar";
  observaciones: string;

  formCancel: FormGroup;

  /**
   * Datos de la OT modificando.
   */
  data: GetOTResponseInterface;

  /**
   * Nombre completo del usuario en sesion.
   */
  nombre: string;
  flagSolicitante: boolean;

  constructor(
    private utils: UtilsService,
    private fb: FormBuilder,
    public readonly modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public inboundData: any,
    public dialogRef: MatDialogRef<CancelComponent>,
    public readonly modalPersonService: ModalPersonService,
    private readonly dialog: MatDialog,
    private readonly spinner: LoaderService,
    private otService: OtService,
    private readonly datePipe: DatePipe,
    private genericModalService: GenericModalService,
    private endpointsService: EndpointsService) {
    this.observaciones = "";
  }

  ngOnInit(): void {
    this.buildFrm();
    this.flagSolicitante = this.inboundData.flagSolicitante;
    // LLego la data
    this.data = this.otService.ot;
    this.nombre = this.otService.nombre;
    console.log('estoy en cancel')
  }

  buildFrm(): void {
    this.formCancel = this.fb.group({
      observaciones: new FormControl('', Validators.required)
    });
  }

  /**
   * Invocar al back para turnar la orden con la observacion indicada.
   */
  cancel() {
    this.data = this.utils.fixOT(this.data);
    let currentDate = this.datePipe.transform(
      new Date(), environment.datePatternObs
    );
    const valorStatus = this.valorStatus(this.flagSolicitante);


    let payload = {
      num_ot: this.data.num_ot,
      status: valorStatus,
      orden: this.data
    };

    payload = this.utils.filterNewObservations(payload);
    // TODO: Agregar propiedades faltantes
    //Insertar el tatuado en las observaciones de portal
    let observacionPortal = {
      "textoTipo": "Cancelación de orden de trabajo por",
      "texto": this.formCancel.get('observaciones').value,
      "nombre": 'GNP',
      "fecha": currentDate,
      "canDelete": false,
      correo: this.otService.correoUserLogged,
      medioEntrada: this.otService.portalEntrada
    };
    payload.orden.observacionesPortal.unshift(observacionPortal);

    if (this.formCancel.get('observaciones').value !== "") {
      let templateObservacion = {
        "textoTipo": "CANCELADO POR:",
        "texto": this.formCancel.get('observaciones').value,
        "nombre": this.nombre,
        "fecha": currentDate,
        "canDelete": false
      }
      payload.orden.observacionesInternas.unshift(templateObservacion);
    }
    payload = this.utils.deleteEmptyObservations(payload);

    this.spinner.show();
    this.dialogRef.addPanelClass('hidePanel');
    this.endpointsService.turnarOrden(payload, 'cancelar').subscribe(
      (response) => {
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');
        this.dialogRef.close();
        this.showModal('generico', 'cancelacionOT', undefined);
      }, (error) => {
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');
        if (error.error.warning != undefined || error.error.warning != null) {
          this.genericModalService.showModal('generico', 'avisoErrorTurnar', { error: error.error.message });
        } else {
          this.genericModalService.showModal('generico', 'errorTurnando', { error: error.error.message });
        }
      });
  }  // end cancel()

  showModal(tipo: string, accion: any, extras2: any): MatDialogRef<unknown, any> {
    return this.genericModalService.showModal(tipo, accion, extras2);
  }

  /**
   * Cerrar el modal
   */
  close() {
    this.dialogRef.close();
  }

  valorStatus(valor): string {
    if (valor) {
      return "cancelada por solicitante";
    } else {
      return "cancelada";
    }

  }
}
