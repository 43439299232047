import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from '@services/loader/loader.service';
import { ModalPersonService } from '@services/modal-person/modal-person.service';
import { ModalService } from '@services/modal/modal.service';
import { ServiciosGnpService } from '@services/servicios-gnp/servicios-gnp.service';
import { UtilsService } from '@services/utils/utils.service';
import { EditPersonComponent } from '../edit-person/edit-person.component';
import { EventosScrollService } from '@services/eventos-scroll/eventos-scroll.service';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent implements OnInit, AfterViewInit {

  modalTitle: string = "Domicilio";
  formAddress: FormGroup;
  flagAddressValid = false;
  addressValues: any;
  selectedValueTipoDom: any;

  chkDireccionPersonalizada: boolean = false;

  catalogosCP = {
    entidadesFederativas: [],
    municipios: [],
    colonias: []
  };

  // Tool tip para el tipo de domicilio
  tooltipTipoDomicilio: string;
  tooltipColonia: string;

  constructor(
    private fb: FormBuilder,
    public readonly modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public inboundData: any,
    public dialogRef: MatDialogRef<AddAddressComponent>,
    private serviciosGnpService: ServiciosGnpService,
    public readonly modalPersonService: ModalPersonService,
    private readonly dialog: MatDialog,
    private readonly spinner: LoaderService,
    public readonly utils: UtilsService,
    private eventosScroll: EventosScrollService
) { }

  ngOnInit(): void {
    this.tooltipTipoDomicilio = '';
    this.buildFrm();
    if(this.inboundData.domicilio) {
      this.tipoDomicilioChange();

      if(this.inboundData.domicilio.chkDireccionPersonalizada.checked) {
        this.tooltipColonia = this.inboundData.domicilio.coloniaCustom;
      }
    }

  }
  ngAfterViewInit(): void {
    this.selectedValueTipoDom = this.inboundData.catalogosIniciales.tiposDomicilio[0].valor;
    this.formAddress.controls['tipoDomicilio'].disable();
  }

  buildFrm(): void {
    // En caso de que es una actualización de un dato existente
    // Se establecen los valores iniciales
    if(this.inboundData.domicilio) {
      this.chkDireccionPersonalizada = this.inboundData.domicilio.chkDireccionPersonalizada.checked;
      this.loadCatalogsByCP(this.inboundData.domicilio.codigoPostal);
    }
    this.formAddress = this.fb.group({
      domicilio: new FormControl(''),
      tipoDomicilio: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.tipoDomicilio : '', Validators.required),
      tipoDomicilioText: new FormControl(''),
      calle: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.calle : '', Validators.required),
      numeroExterior: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.numeroExterior : '', Validators.required),
      numeroInterior: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.numeroInterior : ''),
      referenciaUbicacion: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.referenciaUbicacion : ''),
      codigoPostal: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.codigoPostal : '', Validators.required),
      pais: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.pais : 'MEX', Validators.required),
      entidadFederativa: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.entidadFederativa : '', Validators.required),
      municipio: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.municipio : '', Validators.required),
      colonia: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.colonia : '', Validators.required),
      coloniaCustom: new FormControl(
        this.inboundData.domicilio ? this.inboundData.domicilio.coloniaCustom : '', Validators.required),
      chkDireccionPersonalizada: new FormControl(this.chkDireccionPersonalizada),
      coloniaNombre: new FormControl(''),
    });
    
  }

  /**
   * Carga los catalogos relacionados con un CP.
   */
   loadCatalogsByCP(cp: any) {
    const cpInput: string = cp;
    if (cpInput && cpInput.toString().length !== 5) {
      this.formAddress.get('codigoPostal').setErrors({minlength: true});
      this.catalogosCP = {
        entidadesFederativas: [],
        municipios: [],
        colonias: []
      };
      return;
    }

    if (cpInput && cpInput.toString().length === 5) {
      // TODO: Resetear el campo de colonia y colonia Customizada

    const request = { cp: cpInput };
    this.spinner.showModal();
    this.serviciosGnpService.loadCatalogosByCP(request).subscribe(
      (dataResponse) => {
        this.spinner.hideModal();
          for(const cat of  dataResponse.catalogos) {
            switch (cat.nombre) {
              case 'ESTADO_REPUBLICA':
                  this.catalogosCP.entidadesFederativas = cat.valores;
                  break;
              case 'MUNICIPIO':
                  this.catalogosCP.municipios = cat.valores;
                  break;
              case 'COLONIA':
                  this.catalogosCP.colonias = cat.valores;
                  break;
            }
          }
          // Seleccionar la primera opcion
          this.formAddress.controls['entidadFederativa'].setValue(this.catalogosCP.entidadesFederativas[0].valor);;
          this.formAddress.controls['municipio'].setValue(this.catalogosCP.municipios[0].valor);
          if (this.catalogosCP.colonias.length === 1) {
            this.formAddress.controls['colonia'].setValue(this.catalogosCP.colonias[0].valor);
          }
      }, (error) => {
        this.spinner.hideModal();
        this.formAddress.get('codigoPostal').setErrors({notfound: true});
      }
    );
    }

  }

  // Cerrar el modal,
  closeModal(value: any): void {
    
    if(value) {
      if(this.validateForm() && this.checkAddressFiscal(this.flagAddressValid)) {
        const colonia = this.chkDireccionPersonalizada ?
        this.formAddress.controls['coloniaCustom'].value : this.getDescription(this.formAddress.controls['colonia'].value, this.catalogosCP.colonias);

        let direccion = this.formAddress.controls['calle'].value + ' ';
        direccion += this.formAddress.controls['numeroExterior'].value + ' ';
        direccion += this.formAddress.controls['numeroInterior'].value + ' ';
        direccion += colonia + ' ';
        direccion += this.formAddress.controls['codigoPostal'].value + ' ';
        direccion += this.getDescription(this.formAddress.controls['municipio'].value, this.catalogosCP.municipios) + ' ';
        direccion += this.getDescription(this.formAddress.controls['entidadFederativa'].value, this.catalogosCP.entidadesFederativas) + ', ';
        direccion += this.formAddress.controls['referenciaUbicacion'].value + ' ';

        let filtrarPorValor = function filterItems(tiposDomicilio, query) {
          return tiposDomicilio.filter(function(obj) {
              return obj.valor == query;
          })
        }
        // Obtener la descripcion del tipo de domicilio
        const tipoDomicilioText = filtrarPorValor(this.inboundData.catalogosIniciales.tiposDomicilio, this.formAddress.controls['tipoDomicilio'].value)[0].texto;
        this.formAddress.controls['tipoDomicilioText'].setValue(tipoDomicilioText);
        this.formAddress.controls['domicilio'].setValue(direccion);
        this.formAddress.controls['chkDireccionPersonalizada'].setValue({checked: this.chkDireccionPersonalizada});

        // Guardar la data de este modal en el service de personas
        this.modalPersonService.setAddressData = this.formAddress.getRawValue();
        this.modalPersonService.setAddressFiscalData =  this.addressValues;

        this.close();
      }
    } else {
      this.close();
    }

  }

  close() {
    // Abrimos el otro (Edicion de Persona)
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = '800px';
    this.eventosScroll.eventScroll();
    this.dialog.open(EditPersonComponent, dialogConfig);

    // Cerramos este modal (Captura de direccion)
    this.dialogRef.close();
  }

  validateForm() {
    this.formAddress.get('tipoDomicilio').markAsTouched();
    this.formAddress.get('tipoDomicilio').updateValueAndValidity();

    this.formAddress.get('calle').markAsTouched();
    this.formAddress.get('calle').updateValueAndValidity();

    this.formAddress.get('numeroExterior').markAsTouched();
    this.formAddress.get('numeroExterior').updateValueAndValidity();

    this.formAddress.get('codigoPostal').markAsTouched();
    this.formAddress.get('codigoPostal').updateValueAndValidity();

    this.formAddress.get('pais').markAsTouched();
    this.formAddress.get('pais').updateValueAndValidity();

    this.formAddress.get('entidadFederativa').markAsTouched();
    this.formAddress.get('entidadFederativa').updateValueAndValidity();

    this.formAddress.get('municipio').markAsTouched();
    this.formAddress.get('municipio').updateValueAndValidity();

    if(this.chkDireccionPersonalizada) {
      this.formAddress.get('coloniaCustom').setValidators(Validators.required);
      this.formAddress.get('coloniaCustom').markAsTouched();
      this.formAddress.get('coloniaCustom').updateValueAndValidity();
      this.formAddress.get('colonia').setValidators(null);
      this.formAddress.get('colonia').markAsTouched();
      this.formAddress.get('colonia').updateValueAndValidity();
    } else {
      this.formAddress.get('colonia').setValidators(Validators.required);
      this.formAddress.get('colonia').markAsTouched();
      this.formAddress.get('colonia').updateValueAndValidity();
      this.formAddress.get('coloniaCustom').setValidators(null);
      this.formAddress.get('coloniaCustom').markAsTouched();
      this.formAddress.get('coloniaCustom').updateValueAndValidity();
    }
    
    this.formAddress.updateValueAndValidity();
    const formValid =  this.formAddress.status == 'VALID';
    return formValid;
  }

  getDescription(value: string, catalog: any[]): string {
    let elementDescription = '';
    for(let element of catalog) {
      if(element.valor == value) {
        elementDescription = element.texto;
        break;
      }
    }
    return elementDescription;
  }

  /**
   * Check para colonia personalizada.
   * @param chk Check
   */
  setDireccionPersonalizada(chk) {
    this.chkDireccionPersonalizada = !this.chkDireccionPersonalizada;
    if(this.chkDireccionPersonalizada) {
      this.tooltipColonia = this.formAddress.value.coloniaCustom;
      this.formAddress.controls['chkDireccionPersonalizada'].setValue(this.chkDireccionPersonalizada);
    } else {
      this.tooltipColonia = '';
      this.formAddress.controls['chkDireccionPersonalizada'].setValue(this.chkDireccionPersonalizada);
    }
  }
  
  /**
   * Actualizar tool tip cuando cambie el tipo de domicilio.
   */
  tipoDomicilioChange():void {
    let idTd = this.formAddress.get('tipoDomicilio').value;
    let valFound = this.inboundData.catalogosIniciales.tiposDomicilio.find((o) => o.valor === idTd);
    this.tooltipTipoDomicilio = valFound.texto;
  }

  
  /**
   * Actualizar tool tip cuando cambie la colonia.
   */
   coloniaChange():void {
    let idColonia = this.formAddress.get('colonia').value;
    let valFound = this.catalogosCP.colonias.find((o) => o.valor === idColonia);
    this.tooltipColonia = valFound.texto;
  }

  getFlag(event: boolean){
    this.flagAddressValid =  event;
  }

  getAddressValues(event: any){
    this.addressValues = event;
  }

  setText(valor: any){
    this.formAddress.controls['coloniaNombre'].setValue(valor.source.selected.viewValue);
  }

  checkAddressFiscal(flagAddressValid: boolean): boolean {
    let value = false;
    if(this.addressValues){
      value = flagAddressValid;
    } else {
      value = true;
    }

    return value;

  }

}
