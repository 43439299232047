import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { CatalogosApiService } from '@services/catalogos-api/catalogos-api.service';
import { EndpointsService } from '@services/endpoints/endpoints.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { LoaderService } from '@services/loader/loader.service';
import { OtService } from '@services/ot/ot.service';
import { UtilsService } from '@services/utils/utils.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {

  // Datos de la Orden de trabajo 
  data: GetOTResponseInterface;
  modalTitle: string = "Activar";
  formActivate: FormGroup;
  
  rol: any;
  nombre: string;
  catalogosIniciales: any;
  
  meta: any;
  observacionPortal: any;
  primerMensaje: boolean;

  constructor(
    private utils: UtilsService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public inboundData: any,
    public dialogRef: MatDialogRef<ActivateComponent>,
    private readonly spinner: LoaderService,
    private genericModalService: GenericModalService, 
    private otService: OtService,
    private readonly datePipe: DatePipe,
    private endpointsService: EndpointsService,
    private catalogosApiService: CatalogosApiService
    ) {
      this.catalogosIniciales = {
        tiposActivacion : [],
        guionesActivacion : [],
        causasActivacion : [],
      };
    this.meta = {
        tiposActivacion: [],
        guionesActivacion: [],
        causasActivacion: []
    };
    this.primerMensaje = false;

  }

  ngOnInit(): void {
    this.buildFrm();
    // Cargar datos cuando sea una consulta de OT

    // LLego la data
    this.data = this.otService.ot;
    
    this.rol = this.otService.rol;
    this.nombre = this.otService.nombre;
    
    // Obtener los catalogos del back
    this.getCatalogs();
    
    let currentDate = this.datePipe.transform(
      new Date(), environment.datePatternObs
    );
  
    this.observacionPortal = {
      "textoTipo": "INTRODUCIDO POR",
      "texto": "",
      "nombre": this.nombre,
      "fecha": currentDate,
      "canDelete": false
  };

  }  // end ngOnInit()

  buildFrm(): void {
    this.formActivate = this.fb.group({
      tipoActivacion: new FormControl({value: '', disabled: false}, [Validators.required]),
      guionActivacion: new FormControl({value: '', disabled: false}, [Validators.required]),
      causaActivacion: new FormControl({value: '', disabled: false}, [Validators.required]),
      obsPortal: new FormControl({value: '', disabled: false}, [Validators.required]),
      obsInterna: new FormControl({value: '', disabled: false}, [Validators.required])
    });
  }
  
  getCatalogs(): void {
  
    // Obtener Catalogo de tipos de activacion
    if(this.otService.tiposActivacion) {
      this.catalogosIniciales.tiposActivacion = this.otService.tiposActivacion;
    } else {
          
        var payloadCatActivacion = {
          ramo: this.data.generales.lineaNegocio.toUpperCase(), 
          tipo_solicitud: this.data.generales.tipoSolicitud
       };
       
      this.spinner.showModal();
      this.catalogosApiService.getCatalogosActivacion(this.data.generales.lineaNegocio, payloadCatActivacion).subscribe(
        (resultGetCatalogosActivacion) => {
          this.spinner.hideModal();
          this.catalogosIniciales.tiposActivacion = resultGetCatalogosActivacion.data;
          this.otService.setTiposActivacion = resultGetCatalogosActivacion.data;
      }, (errorResponse) => {
        this.spinner.hideModal();
        let errorData = {
          codigoHttp: errorResponse.status, 
          errorCatalogo: 'tiposActivacion', 
          urlReload: location.href, 
          endpoint: errorResponse.url
        };
        this.genericModalService.showModal('errorConexion', errorData, null);
        this.dialogRef.close();
      });
    }

    // Obtener Catalogo de guiones de activacion
    if(this.otService.guionesActivacion) {
      this.catalogosIniciales.guionesActivacion = this.otService.guionesActivacion;
    } else {
          
        var payloadCatActivacion = {
          ramo: this.data.generales.lineaNegocio.toUpperCase(), 
          tipo_solicitud: this.data.generales.tipoSolicitud
       };
       
      this.spinner.showModal();
      this.catalogosApiService.getGuionesActivacion(this.data.generales.lineaNegocio, {id: 'ALL'}).subscribe(
        (resultGuionesActivacion) => {
          this.spinner.hideModal();
          this.catalogosIniciales.guionesActivacion = resultGuionesActivacion.scripts;
          this.otService.setGuionesActivacion = resultGuionesActivacion.scripts;
      }, (errorResponse) => {
        this.spinner.hideModal();
        let errorData = {
          codigoHttp: errorResponse.status, 
          errorCatalogo: 'guionesActivacion', 
          urlReload: location.href, 
          endpoint: errorResponse.url
        };
        this.genericModalService.showModal('errorConexion', errorData, null);
        this.dialogRef.close();
      });
    }
  }

  /**
   * Obtener la causa de la activacion en base al tipo de activacion.
   * @param tipoActivacion - Tipo de activacion a filtrar.
   */
  getCausas(tipoActivacion: any) {
    this.formActivate.get('guionActivacion').setValue('');
    this.formActivate.get('causaActivacion').setValue('');
    var payloadActivacion = {
      ramo: this.data.generales.lineaNegocio.toUpperCase(), 
      tipo_solicitud: this.data.generales.tipoSolicitud, 
      tipo_activacion: tipoActivacion 
    };
    
    this.spinner.showModal();
    this.catalogosApiService.getCatalogosActivacion(this.data.generales.lineaNegocio, payloadActivacion).subscribe(
      (resultGetCatalogosActivacion) => {
        this.spinner.hideModal();
        this.catalogosIniciales.causasActivacion = resultGetCatalogosActivacion.data;
    }, (errorResponse) => {
      this.spinner.hideModal();
    });

  };

  /**
   * Resetear la causa de activacion.
  */
  resetCausa() {
    this.formActivate.get('causaActivacion').setValue('');
  }

  /**
   * Funcion para el boton agregar, agregar un guion/causa.
   */
   agregar() {
    let  guionAct = this.formActivate.get('guionActivacion').value;
    let tipoAct = this.formActivate.get('tipoActivacion').value;
    let causaAct = this.formActivate.get('causaActivacion').value;

    this.meta.tiposActivacion = [tipoAct];
    this.meta.guionesActivacion = [guionAct];
    this.meta.causasActivacion = [causaAct];

    this.spinner.showModal();
    this.catalogosApiService.getGuionesActivacion(this.data.generales.lineaNegocio, 
        {id: this.data.generales.lineaNegocio.toUpperCase() + guionAct}).subscribe(
        (response) => {
          this.spinner.hideModal();
        if (!this.primerMensaje) {
            this.observacionPortal.texto = tipoAct + "\n" + response.script + "\n" + causaAct;
            this.primerMensaje = true;
        } else {
            if (this.observacionPortal.texto.endsWith("\n")) {
                this.observacionPortal.texto = this.observacionPortal.texto + tipoAct + "\n" + response.script + "\n" + causaAct;
            } else {
              this.observacionPortal.texto = this.observacionPortal.texto + "\n" + tipoAct + "\n" + response.script + "\n" + causaAct;
            }
        }
        this.formActivate.get('obsPortal').setValue(this.observacionPortal.texto);
    }, function(error) {
      this.spinner.hideModal();
    });
  }

  /**
   * Invocar a turnarOrden.
   */
   guardar() {
    this.data = this.utils.fixOT(this.data);

    var payload = {
        num_ot: this.data.num_ot,
        status: 'activada',
        orden: JSON.parse(JSON.stringify(this.data))
    };
    this.observacionPortal.texto = this.formActivate.get('obsPortal').value;
    payload.orden.meta = JSON.parse(JSON.stringify(this.meta));
    payload = this.utils.filterNewObservations(payload);
    payload.orden.observacionesPortal.unshift(this.observacionPortal);
    
    let currentDate = this.datePipe.transform(
      new Date(), environment.datePatternObs
    );
    let  observacionInterna = this.formActivate.get('obsInterna').value;
    if (observacionInterna && observacionInterna !=='') {
        var observacion2 = {
            "textoTipo": "INTRODUCIDO POR",
            "texto": observacionInterna,
            "nombre": this.nombre,
            "fecha": currentDate,
            "canDelete": false
        };
        payload.orden.observacionesInternas.unshift(observacion2);
    }
    if (this.otService.watcherPoliza.changed) {
        payload.orden.observacionesInternas.unshift(this.otService.watcherPoliza.tatuaje())
    }
    payload = this.utils.deleteEmptyObservations(payload);
    this.spinner.show();
    this.dialogRef.addPanelClass('hidePanel');
    this.endpointsService.turnarOrden(payload, 'activar').subscribe(
      (response) => {
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');
        this.otService.setSalir = '';
        this.dialogRef.close();
      }, (error) =>{
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');
        if(error.error.warning != undefined || error.error.warning != null){
          this.genericModalService.showModal('generico', 'avisoErrorTurnar', { error: error.error.message });
        } else {
          this.genericModalService.showModal('generico', 'errorTurnando', { error: error.error.message });
        }
    });

  }
  
  /**
   * Close the modal.
   */
  close() {
    this.dialogRef.close();
  }

  /**
   * Ordenar catalogo.
   * 
   * @param cat - catalogo a ordenar.
   * @param prop - porpiedad por la que se ordenara el catalogo.
   * 
   * @returns - El catalogo ordenado.
   */
   orderBy(cat: string) {
    return this.catalogosIniciales[cat].sort((a, b) => a > b ? 1 : a === b ? 0 : -1);
  }
}
