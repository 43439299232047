import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { QueryParamsInterface } from '@interfaces/general.interface';
import { EndpointsService } from '@services/endpoints/endpoints.service';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { LoaderService } from '@services/loader/loader.service';
import { OtService } from '@services/ot/ot.service';
import { Subscription } from 'rxjs';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDaterangepickerInlineConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import { environment } from '../../../../environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from '@services/utils/utils.service';
import { SpecialCasesCatalogsInterface } from '@interfaces/catalogos-api';
import { CatalogosApiService } from '@services/catalogos-api/catalogos-api.service';

@Component({
  selector: 'app-special-cases',
  templateUrl: './special-cases.component.html',
  styleUrls: ['./special-cases.component.scss']
})
export class SpecialCasesComponent implements OnInit {
  @Input()
  urlParams: QueryParamsInterface;
  @Input()
  funcionarioAutorizador: string;
  @Input()
  cargo: string;

  // Datos de la Orden de trabajo 
  data: GetOTResponseInterface;

  // Suscripcion a OT Inicializada
  subscriptionOT: Subscription;
  
  modalTitle: string = "Solicitud de casos especiales";
  formSpecialCase: FormGroup;

  // Configuracion de Datepicker de bootstrap
  bsConfig: Partial<BsDaterangepickerInlineConfig>;

  // Para establecer la fecha minima permitida al usuario
  minDate: any;

  // select Peticion especial
  catalogos: SpecialCasesCatalogsInterface;

  constructor(
    private utils: UtilsService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public inboundData: any,
    public dialogRef: MatDialogRef<SpecialCasesComponent>,
    private readonly spinner: LoaderService,
    private genericModalService: GenericModalService, 
    private otService: OtService,
    private readonly datePipe: DatePipe,
    private endpointsService: EndpointsService,
    private localeService: BsLocaleService,
    private catalogosApiService: CatalogosApiService
    ) {
      
      esLocale.weekdaysShort = ['dom.','lun', 'mar.', 'mié.', 'jue.', 'vie.', 'sáb.'];
      esLocale.week.dow = 0;
      defineLocale('es', esLocale);
      this.localeService.use('es');
      const bsConfig = { 
        containerClass: 'theme-dark-blue', 
        dateInputFormat: 'DD/MM/YYYY',
        clearPosition: 'center',
        returnFocusToInput: true,
        showWeekNumbers: false
      };
      this.bsConfig = Object.assign({}, bsConfig);
      
      this.catalogos = {
        ConductoCobro: [],
        PeticionEspecial: [],
        TipoCasoEspecial: []
      };
    }


    ngOnInit(): void {
      this.buildFrm();
      // Cargar datos cuando sea una consulta de OT
  
      // LLego la data
      this.data = this.otService.ot;
      this.populateFields(this.data);
  
      // Documentacion del datepicker
      // https:// valor-software.com/ngx-bootstrap/old/6.1.0/#/datepicker

        
      if(this.otService.specialCasesCatalogs) {
        this.catalogos = this.otService.specialCasesCatalogs;
      } else {
        this.spinner.showModal();
        this.catalogosApiService.getCatalogosSolicitudCasosEsp().subscribe(
          (resultCatalogs) => {
            this.spinner.hideModal();
            this.catalogos = resultCatalogs;
            this.otService.setSpecialCasesCatalogs = resultCatalogs;
        }, (errorCatalogs) => {
          this.spinner.hideModal();
        });
      }
        
    }  // end ngOnInit()
  
    /**
     * Construye los campos del formulario.
     */
    buildFrm(): void {
      this.formSpecialCase = this.fb.group({
        peticionEsp: new FormControl({value: '', disabled: false}, [Validators.required]),
        argumento: new FormControl({value: '', disabled: false}, [Validators.required]),
        docsAnexos: new FormControl({value: '', disabled: false}, [Validators.required]),
        peticion: new FormControl({value: '', disabled: false}, [Validators.required]),
        tipoCasoEsp: new FormControl({value: '', disabled: false}, [Validators.required]),
        conducto: new FormControl({value: '', disabled: false}, [Validators.required]),
        fecha: new FormControl({value: '', disabled: false}, [Validators.required]),

      });
    }

  /**
   * Poblar campos del formulario con data del backend.
   * 
   * @param otData - Data del backend.
   * 
  */
  populateFields(otData: GetOTResponseInterface) {
    this.data = otData;
  }

  /**
   * Cierra el modal y regresa al modal de turnar.
   */
  close() {
    this.genericModalService.showModal('changeStatus', 'turnar', undefined);
    this.dialogRef.close();
  }

  /**
   * Guarda la solicitud de caso especial.
   */
  guardar() {
    this.data = this.utils.fixOT(this.data);

    let payload = {
      num_ot: this.data.num_ot,
      status: 'casos_esp',
      orden: JSON.parse(JSON.stringify(this.data))
    };
  
    payload = this.utils.filterNewObservations(payload);
    
    let currentDate = this.datePipe.transform(
      new Date(), environment.datePatternObs
    );
    
    let textoObservacion = '';
    const fechaPagadaHasta = this.formSpecialCase.get('fecha').value;
    let textBits = [{
      "input": "peticionEsp",
      "template": this.capitalizeFirstLetter(this.formSpecialCase.get('peticionEsp').value) + ".\n"
      },
      {
          "input": "tipoCasoEsp",
          "template": this.formSpecialCase.get('tipoCasoEsp').value + ".\n"
      },
      {
          "input": "argumento",
          "template": this.formSpecialCase.get('argumento').value + ".\n"
      },
      {
          "input": "docsAnexos",
          "template": this.formSpecialCase.get('docsAnexos').value + ".\n"
      },
      {
          "input": "peticion",
          "template": this.formSpecialCase.get('peticion').value + ".\n"
      },
      {
          "input": "conducto",
          "template": this.formSpecialCase.get('conducto').value + ".\n"
      },
      {
          "input": "fecha",
          "template": this.datePipe.transform(fechaPagadaHasta, 'YYYY-MM-dd') + ".\n"
      }
    ];
    for (let i = 0; i < textBits.length; i++) {
      if (this.formSpecialCase.controls[textBits[i].input].value) {
        textoObservacion += textBits[i].template;
      }
    }

    let observacion = {
        "textoTipo": "SOLICITO CASO ESPECIAL POR",
        "texto": textoObservacion,
        "nombre": this.otService.nombre,
        "fecha": currentDate,
        "canDelete": false
    };
    payload.orden.observacionesInternas.unshift(observacion);
    
    if (this.inboundData.observacionTurnar !== '') {
      let observacion2 = {
          "textoTipo": "INTRODUCIDO POR",
          "texto": this.inboundData.observacionTurnar,
          "nombre": this.otService.nombre,
          "fecha": currentDate,
          "canDelete": false
      };
      payload.orden.observacionesInternas.unshift(observacion2);
    }

    payload.orden.meta = { tipoCasoEspecial: this.formSpecialCase.get('tipoCasoEsp').value };
    payload = this.utils.deleteEmptyObservations(payload);
    
    this.spinner.show();
    this.dialogRef.addPanelClass('hidePanel');
    this.endpointsService.turnarOrden(payload, 'turnar').subscribe(
      (response) => {
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');
        this.genericModalService.showModal('generico', 'turnarExitoso', null);
        this.dialogRef.close();
      }, (error) =>{
        this.spinner.hide();
        this.dialogRef.removePanelClass('hidePanel');
        if(error.error.warning != undefined || error.error.warning != null){
          this.genericModalService.showModal('generico', 'avisoErrorTurnar', { error: error.error.message });
        } else {
          this.genericModalService.showModal('generico', 'errorTurnando', { error: error.error.message });
        }
    });
  

  }  // end guardar()

  /**
   * Convierte a mayuscula la primera letra de la cadena.
   * 
   * @param string - Cadena a ajustar.
   * 
   * @returns - La cadena ajustada.
   */
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
}
