import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from '@services/modal/modal.service';
import { ModalPersonService } from '@services/modal-person/modal-person.service';
import { EditPersonComponent } from '../edit-person/edit-person.component';
import { UtilsService } from '@services/utils/utils.service';
import { EventosScrollService } from '@services/eventos-scroll/eventos-scroll.service';

@Component({
  selector: 'app-add-contact-method',
  templateUrl: './add-contact-method.component.html',
  styleUrls: ['./add-contact-method.component.scss']
})
export class AddContactMethodComponent implements OnInit {

  modalTitle: string = "Medio de Contacto";
  formPhones: FormGroup;

  constructor(
    private fb: FormBuilder,
    public readonly modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public inboundData: any,
    public dialogRef: MatDialogRef<AddContactMethodComponent>,
    public readonly modalPersonService: ModalPersonService,
    private readonly dialog: MatDialog,
    public readonly utils: UtilsService,
    private eventosScroll: EventosScrollService
) { }

    ngOnInit(): void {
      this.buildFrm();
    }
  
    buildFrm(): void {
      // En caso de que es una actualización de un dato existente
      // const values: ActividadInterface = this.modalService.frmValuesCatalog;
      // Se establecen los valores iniciales
      this.formPhones = this.fb.group({
        tipoTelefono: new FormControl(
          this.inboundData.telefono ? this.inboundData.telefono.tipoTelefono : '', Validators.required),
        tipoTelefonoText: new FormControl('', Validators.required),
        ladaInternacional: new FormControl(
          this.inboundData.telefono ? this.inboundData.telefono.ladaInternacional : '52', Validators.required),
        ladaNacional: new FormControl(
          this.inboundData.telefono ? this.inboundData.telefono.ladaNacional : '', Validators.required),
        telefono: new FormControl(
          this.inboundData.telefono ? this.inboundData.telefono.telefono : '', Validators.required),
        numExtension: new FormControl(
          this.inboundData.telefono ? this.inboundData.telefono.numExtension : '')
      });
    }

  /**
   * Resetear los demas datos de contacto.
   */
  resetDatosContacto() {
    this.formPhones.get("ladaNacional").setValue('');
    this.formPhones.get("telefono").setValue('');
    this.formPhones.get("numExtension").setValue('');
  }

  // Cerrar el modal, 
  closeModal(value: any): void {
    if(value) {
      let filtrarPorValor = function filterItems(tiposTelefono, query) {
        return tiposTelefono.filter(function(obj) {
            return obj.valor === query;
        })
      }
      if(this.formPhones.get("telefono").value) {
        const tipoTelefonoText = filtrarPorValor(this.inboundData.catalogosIniciales.tiposTelefono, this.formPhones.controls['tipoTelefono'].value)[0].texto;
        this.formPhones.get("tipoTelefonoText").setValue(tipoTelefonoText);
      }

      this.formPhones.markAllAsTouched();
      this.formPhones.updateValueAndValidity();
      
      if(!this.formPhones.valid) {
        return;
      }

      const ladaNal = this.formPhones.controls['ladaNacional'].value;
      const tel = this.formPhones.controls['telefono'].value;
      const telCompleto = `${ladaNal}${tel}`;
      if(telCompleto && telCompleto.length < 10) {
        return;
      }
      // Guardar la data de este modal en el service de personas
      this.modalPersonService.setPhonesData = this.formPhones.value;
    }
    // Abrimos el otro (Edicion de Persona)
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = '800px';
    this.eventosScroll.eventScroll();
    this.dialog.open(EditPersonComponent, dialogConfig);
    // Cerramos este modal (Captura de Telefono)
    this.dialogRef.close();
  }
  
}
