import { Injectable } from '@angular/core';
import { HttpService } from '@services/http/http.service';

@Injectable({
    providedIn: 'root'
})
export class TranslateService {
    private defaultLang = 'es';
    private translations: any = null;

    constructor(protected readonly http: HttpService) {}

    loadTranslationFile(language: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.http.get(`assets/i18n/${language}.json`).subscribe(
                (response) => {
                    this.defaultLang = language;
                    this.translations = response;
                    resolve(true);
                },
                (error) => {
                    resolve(false);
                }
            );
        });
    }

    async setLanguage(lang: string): Promise<boolean> {
        return await this.loadTranslationFile(lang);
    }

    getLanguage(): string {
        return this.defaultLang;
    }

    getTranslation(value: string): string {
        const data = value.split('.');
        let labels = this.translations;
        let label = '';

        if (!this.translations) {
            return value;
        }

        for (let index = 0; index < data.length; index++) {
            const key = data[index].toUpperCase();

            if (labels.hasOwnProperty(key)) {
                if (index !== data.length - 1) {
                    labels = labels[key];
                } else {
                    label = labels[key];
                }
            }
        }

        return label;
    }
}
