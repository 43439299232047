import { Injectable } from '@angular/core';
import { ErroresEndpointModel } from '@interfaces/catalogos-api';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessagesService {
  
  constructor() {
    
  }

  getItem(key: string): string | null {
    return sessionStorage.getItem(key);
  }
  
  setItem(key: string, value: string): void {
      return sessionStorage.setItem(key, value);
  }
  
  setMsgsError(errors: ErroresEndpointModel) {
    let _this = this;
    errors.erroresEndpoints.forEach(function(errorObj) {
      _this.setItem("erroresEndpoints." + errorObj.metodo, errorObj.valor);
    });
    errors.erroresCatalogosApi.forEach(function(errorObj) {
      _this.setItem("erroresCatalogosApi." + errorObj.metodo, errorObj.valor);
    });
    errors.erroresLoginApi.forEach(function(errorObj) {
      _this.setItem("erroresLoginApi." + errorObj.metodo, errorObj.valor);
    });
    errors.erroresServiciosGnp.forEach(function(errorObj) {
      _this.setItem("erroresServiciosGnp." + errorObj.metodo, errorObj.valor);
    });
  }

  getMsgError(sessionStorageKey): string {
    const msgData = this.getItem(sessionStorageKey);
    return msgData ? msgData : 'Servicio no disponible.';
  }
}
