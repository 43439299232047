import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit
} from '@angular/core';
import { LoaderService } from '@services/loader/loader.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
    hidden: boolean;
    show: boolean;
    origen: string = environment.menu_portal_empleados ;

    constructor(
        private elRef: ElementRef,
        private readonly loaderService: LoaderService,
        private cd: ChangeDetectorRef,
        private route: ActivatedRoute
    ) {
        this.hidden = true;
        this.loaderService.statusEvent.subscribe((status) => {
            
            this.route.queryParamMap.subscribe((params: Params) => {
                console.log('Origen: '+ params.get('menu'));
                this.origen = params.get('menu');
              });
            
            this.show = status;
            if (this.show) {
                this.hidden = false;
                this.cd.detectChanges();
            }
        });
    }

    ngOnInit(): void {
        this.elRef.nativeElement
            .querySelector('.gnp-loading-body')
            .addEventListener('transitionend', () => {
                this.hidden = !this.show;
            });
    }
}
