import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentoCatalogosApiInterface } from '@interfaces/catalogos-api';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FileteadorService } from "@services/fileteador/fileteador.service";
import { LoaderService } from '@services/loader/loader.service';
import { OtService } from '@services/ot/ot.service';
import { GetOTDocumentoInterface, GetOTResponseInterface } from '@interfaces/endpoints.interface';
import { GenericModalService } from '@services/generic-modal/generic-modal.service';
import { ServiciosGnpService } from '@services/servicios-gnp/servicios-gnp.service';

import { REGLAS_PANTALLA as reglas_pantalla} from '@fixtures/nueva-ot.fixture';
import { QueryParamsInterface } from '@interfaces/general.interface';
import { HttpParams } from '@angular/common/http';
import { UtilsService } from '@services/utils/utils.service';
import { AuthService } from '@services/auth/auth.service';

// Documento por Adjuntar
export interface TipoDocumento {
  tipoDoc: string;
  paginas: string;
  nombre: string;
}

@Component({
  selector: 'app-digital-file',
  templateUrl: './digital-file.component.html',
  styleUrls: ['./digital-file.component.scss']
})
export class DigitalFileComponent implements OnInit {

  formDigitalFile: FormGroup;

  displayedColumnsDocsAdjuntar: Array<string> = ['tipoDoc', 'paginas', 'nombre','operaciones'];
  dataSourceDocsAdjuntar: Array<TipoDocumento>;

  displayedColumnsDocsAdjuntos: Array<string> = ['tipoDoc', 'fecha', 'aprobado','operaciones'];
  dataSourceDocsAdjuntos: Array<GetOTDocumentoInterface> = new Array();

  existOrden: boolean;

  @Input()
  tiposDocumentoList: Array<DocumentoCatalogosApiInterface> = new Array();

  // Datos de la Orden de trabajo
  data: GetOTResponseInterface;

  @Output()
  digitalFileData: EventEmitter<any>;

  // Rol del usuario en sesion.
  @Input()
  rol: string;

  // Suscripcion a lectura del rol del usuario.
  subscriptionRol: Subscription;

  // Parametros de la URL
  @Input()
  urlParams: QueryParamsInterface;

  // ¿El usuario es rensable e la OT?
  responsable: boolean;

  // Suscripcion a cambios en el formulario de expediente digital
  outDataSubscription: Subscription;
  // Suscripcion a OT Inicializada
  subscriptionOT: Subscription;

  // Arreglo para manejar los documentos que estan confirmados
  docsAprobados: Array<FormControl> = new Array();

  // Permite saber si se va a mostrar la seccion de Adjuntar documentos.
  showAdjuntarDocumentos = true;

  seccionarDocumentoSelected: boolean = true;
  seccionarDoc = new FormControl(true);

  flagTrash = true;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private fileteador: FileteadorService,
    private readonly spinner: LoaderService,
    private otService: OtService,
    private genericModalService: GenericModalService,
    private serviciosGnpService: ServiciosGnpService,
    public readonly utils: UtilsService) {
      iconRegistry.addSvgIcon('upload-file-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/img/subir.svg'));
      iconRegistry.addSvgIcon('delete-file-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/img/eliminar.svg'));
      iconRegistry.addSvgIcon('preview-file-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/img/vista-previa.svg'));
      iconRegistry.addSvgIcon('filete-file-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/img/filete.svg'));
      iconRegistry.addSvgIcon('filete-terminado-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/img/filete-terminado.svg'));
      this.dataSourceDocsAdjuntar = [];
      this.dataSourceDocsAdjuntos = [];

      this.digitalFileData = new EventEmitter<any>();
      this.outDataSubscription = new Subscription();
      this.subscriptionRol = new Subscription();
      this.responsable = true;
      this.showCheckboxesConfirm = false;

  }

  ngOnInit(): void {
    this.existOrden = Boolean(this.getParamValueQueryString('orden_id'));
    this.buildFrm(true);

    // Cargar datos cuando sea una consulta de OT
    this.subscriptionOT = this.otService.initialized.subscribe(
      (otData) => {
        // LLego la data
        this.data = otData;

        if (this.data.responsable === this.urlParams.email) {
          this.responsable = true;
        } else {
          this.responsable = false;
          if (this.data.statusOrden === 'registro_da') {
              this.responsable = true;
          }
        }

        this.dataSourceDocsAdjuntos = this.filterDocs();

        for(let d of this.data.docs) {
          d.tmpAprobado = new FormControl(d.aprobado);
        }

        // Suscribir a cambios en el rol
        // Desactivar campos en base a las reglas, rol del usuario y data de la Orden de trabajo
        this.subscriptionRol = this.otService.rolInitialized.subscribe(
          (rol) => {
            this.rol = rol;
            this.disableFields(this.data, rol);
        });

        this.validaReglaTrash();
    });

    this.errorPaginaFinal = false;
  }

  showCheckboxesConfirm: boolean;
  /**
   * Inhabilitar campos del formulario en base a los datos de la OT y el rol del usuario.
   * @param data - Datos de la orden de trabajo.
   * @param rol  - Rol del usuario.
   */
  disableFields(data: any, rol: string) {
    this.rol = rol;
    let userDA = "";

    if (typeof(this.urlParams.da) === "string") {
      userDA = JSON.parse(this.urlParams.da)[0].trim();
    }

    let _scope = {
      reglas_pantalla: reglas_pantalla,
      rol: rol,
      data: this.data,
      responsable: this.responsable,
      da_usuario: userDA,
      stateParams: this.urlParams
    };

    // Validar si es uno de los 3 super roles
    let rolesList: string[] = ['asignador','supervisor_gmm', 'gerente_gmm'];
    let isSuperRol: boolean = rolesList.indexOf(this.rol) >= 0;

    // Validar ocultar seccion de Adjuntar documentos
    const validCoordinarDa = this.rol === 'coordinacion_da' && (_scope.data.status === 'Activada' || _scope.data.status === 'Rechazada' || _scope.data.status === 'Terminada');
    const validAgenteCert = this.rol === 'agente_certificado' && _scope.data.status === 'Rechazada';
    const validAgenteCertTer = this.rol === 'agente_certificado' && (_scope.data.status === 'Terminada' || _scope.data.status === 'Activada');
    const validSuscMedicoEspe = this.rol === 'suscripcion_med_esp' && (_scope.data.status === 'En Proceso');
    
    if(isSuperRol && (this.data.statusOrden == 'figura_servicio' || this.data.statusOrden == 'casos_esp')){
      this.showAdjuntarDocumentos = false;
    } else {
      if (this.otService.isConsulta) {
        if (validCoordinarDa) {
          this.showAdjuntarDocumentos = true;
        } else if (validAgenteCert) {
          this.showAdjuntarDocumentos = false;
        } else if (validAgenteCertTer) {
          this.showAdjuntarDocumentos = true;
        } else if (validSuscMedicoEspe) {
          this.showAdjuntarDocumentos = true;
        } else {
          this.showAdjuntarDocumentos = this.otService.display('adjuntar_documentos', 'show', _scope);
          if(isSuperRol && (this.data.status === 'Terminada' || this.data.status === 'Cancelada' || this.data.status === 'Rechazada' )) {
            this.showAdjuntarDocumentos = false;
          }
  
          if( this.data.status === 'Cancelada Automática' 
            || this.data.status === 'En Registro' 
            || this.data.status === 'Cancelada Solicitante' ){
              if(this.otService.isEmpleado){
                this.showAdjuntarDocumentos = false;
              }
            }
          
        }
        
      } else {
        this.showAdjuntarDocumentos = true;
      }

      if( this.otService.esUsuarioBancaSeguro 
        && !['operador_banca','registro_da'].includes(_scope.data.statusOrden)){
        this.showAdjuntarDocumentos = false;
      }
      
      if( this.otService.esUsuarioBancaSeguro 
        && ['rechazada'].includes(_scope.data.statusOrden)){
        this.showAdjuntarDocumentos = true;
      }
  
  
      // Mostrar / No mostrar el checkbox El empleado confirma que el documento anexo es correcto.
      this.showCheckboxesConfirm = reglas_pantalla.validar_documentos.show.indexOf(rol) >= 0;
  
      // Inhabilitar checkboxes El empleado confirma que el documento anexo es correcto.
      for(let d of this.data.docs) {
        if(!(reglas_pantalla.validar_documentos.enable.indexOf(rol) >= 0)
        || (data.status == 'Terminada' || data.status == 'Rechazada' || data.status == 'Cancelada')) {
          d.tmpAprobado.disable();
        }
        if(!isSuperRol && !this.responsable) {
          d.tmpAprobado.disable();
        }

        if( this.authService.rolGnp != undefined && 
          this.authService.rolGnp.includes('Workflow DA Operador Alta GMM') && _scope.data.status === 'Rechazada'){
          d.tmpAprobado.enable();
        }
      }

    }

  }
  errorPaginaFinal: boolean;
  buildFrm(checked: boolean, tipoDocumento?: string): void {
    let form: any = {
      temporaryDoc: new FormControl(''),
      seccionarDocumento: new FormControl(this.seccionarDocumentoSelected),
      urlDocumento: new FormControl('', []),
      nombreDocumento: new FormControl({value: this.myfilename, disabled: true}),
      tipoDocumento: new FormControl({value: tipoDocumento, disabled: false}, Validators.required)
    };
    if (checked) {
      form = {
        ...form,
        paginaInicial: new FormControl({value: '', disabled: false}, [Validators.required, Validators.min(1), Validators.maxLength(2), Validators.pattern(/^[0-9]\d*$/)]),
        paginaFinal: new FormControl({value: '', disabled: false}, [Validators.required, Validators.min(1), Validators.maxLength(2), Validators.pattern(/^[0-9]\d*$/)])
      }
    }
    this.formDigitalFile = this.fb.group(form);
  }

  eliminarTodosFiletes({checked}: any): void {
    const { tipoDocumento } = this.formDigitalFile.value;
    this.seccionarDocumentoSelected = checked;
    if(checked == false && this.temporaryDoc) {
      this.temporaryDoc.filetes = [];
    }
    this.buildFrm(checked, tipoDocumento);
  }

  /**
   * Si no hay un valor seleccionado, resaltar el combobox con error.
   */
  setValidacion() {
    if(this.formDigitalFile.get('tipoDocumento').value=='') {
      // Revisar si se puede quitar esta funcion.
    }
  }


  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  myfilename = 'Subir Documento';
  formData = new FormData();
  TIPO_PDF = "application/pdf";

  temporaryDoc: any = {
    filetes: []
  };
  prefilete: any = {};

   // Se invoca al seleccionar un archivo
  fileChangeEvent(fileInput: any): void {

    if (fileInput.target.files && fileInput.target.files[0]) {

      var myfile = this.uploadFileInput.nativeElement.files[0];
      var filename = myfile.name;
      var filetype = myfile.type;
      var fileSize = myfile.size;

      if (filetype !== this.TIPO_PDF) {
        this.genericModalService.showModal('generico', "nopdf", null);
        return;
      }
      if (fileSize > environment.digitalFile.maxSizeFile) {
        this.genericModalService.showModal('generico', "toobig2", null);
        return;
      }
      this.myfilename = filename;
      var reader = new FileReader();
      var self = this;
      reader.onload = function(e) {
        self.temporaryDoc.archivo = reader.result;
      };

      reader.readAsDataURL(myfile);

      this.temporaryDoc = {
        nombre: filename,
        archivo: undefined,
        filetes: [],
        file: myfile
      };

      this.formDigitalFile.get('nombreDocumento').setValue(filename);
    } else {
        this.myfilename = 'Subir Documento';
    }
  }

  /**
   * Accion de Icono para quitar el archivo seleccionado.
   */
  deleteFile() {
    this.temporaryDoc = {
      filetes: []
    };
    if(this.formDigitalFile.get('seccionarDocumento').value) {
      delete this.prefilete.paginaInicial;
      delete this.prefilete.paginaFinal;
    }

    this.formDigitalFile.get('nombreDocumento').setValue('Subir Documento');
  }

  /**
   * Agrega un grupo de paginas de un documento seleccionado.
   * Accion para el boton Agregar.
   */
  agregarFilete() {
    let vpg1 = this.validateEmpty('paginaInicial');
    let vpg2 = this.validateEmpty('paginaFinal');
    let vpg3 = this.validateEmpty('tipoDocumento');

    if(!vpg1 || !vpg2 || !vpg3) {
      return;
    }

    this.prefilete = {
      tipoDoc: this.formDigitalFile.get('tipoDocumento').value,
      paginaInicial: Number(this.formDigitalFile.get('paginaInicial').value),
      paginaFinal: Number(this.formDigitalFile.get('paginaFinal').value)
    };

    if(this.formDigitalFile.get('nombreDocumento').value == ''
    || this.formDigitalFile.get('nombreDocumento').value=='Subir Documento') {
      return;
    }

    var docDuplicado = false;
    for (var i = 0; i <this.temporaryDoc.filetes.length; i++) {
      let filete = this.temporaryDoc.filetes[i];
      if(this.prefilete.tipoDoc == filete.tipoDoc) {
        docDuplicado = true;
        break;
      }
    }

    if(docDuplicado) {
      return;
    }

    this.temporaryDoc.filetes.push(this.prefilete);

    this.dataSourceDocsAdjuntar = [];
    for (var i = 0; i <this.temporaryDoc.filetes.length; i++) {
      let filete = this.temporaryDoc.filetes[i];
      this.dataSourceDocsAdjuntar[i] = {
        tipoDoc: filete.tipoDoc,
        paginas: 'Páginas '+ filete.paginaInicial + ' - ' + filete.paginaFinal,
        nombre: this.temporaryDoc.nombre
      };
    }

    this.eliminarDocLocal(false);
  }

  // Accion del icono eliminar en los documentos por adjuntar
  eliminarFilete(elment) {
    this.dataSourceDocsAdjuntar = [];
    var idxDelete = -1;
    for (var i = 0; i <this.temporaryDoc.filetes.length; i++) {
      let filete = this.temporaryDoc.filetes[i];
      if(elment.tipoDoc == filete.tipoDoc) {
        idxDelete = i;
        break;
      }
    }
    this.temporaryDoc.filetes.splice(idxDelete, 1);

    this.dataSourceDocsAdjuntar = [];
    for (var i = 0; i <this.temporaryDoc.filetes.length; i++) {
      let filete = this.temporaryDoc.filetes[i];
      this.dataSourceDocsAdjuntar[i] = {
        tipoDoc: filete.tipoDoc,
        paginas: 'Páginas '+ filete.paginaInicial + ' - ' + filete.paginaFinal,
        nombre: this.temporaryDoc.nombre
      };
    }

  }

  faltaArchivo = false;
  /**
   * Cargar archivo para filetear.
   * Accion para el boton Adjuntar.
   */
  subirArchivo() {
    if (this.seccionarDocumentoSelected) {
      this.prefilete = {
        tipoDoc: this.formDigitalFile.get('tipoDocumento').value,
        paginaInicial: this.formDigitalFile.get('paginaInicial').value,
        paginaFinal: this.formDigitalFile.get('paginaFinal').value
      };

      if (this.temporaryDoc && this.temporaryDoc.filetes.length > 0) {
          this.temporaryDoc.idOrden = this.data.idOrden;
      } else {
          return;
      }
    } else {
      this.prefilete = {
        tipoDoc: this.formDigitalFile.get('tipoDocumento').value
      };

      if (this.temporaryDoc.file.size > environment.digitalFile.maxSizeFilete
        && this.temporaryDoc.file.size < environment.digitalFile.maxSizeFile) {
        this.genericModalService.showModal('generico', "toobig", null);
        return;
      }
      // Revisar si falta seleccionar el archivo
      if (!this.temporaryDoc.archivo) {
          this.faltaArchivo = true;
      }
      if (!this.prefilete.tipoDoc) {
        this.formDigitalFile.get('tipoDocumento').markAsTouched();
        this.formDigitalFile.get('tipoDocumento').updateValueAndValidity();
      }

      if (!this.faltaArchivo ) {
          this.temporaryDoc.filetes.push(this.prefilete);
          this.temporaryDoc.idOrden = this.data.idOrden;
      } else {
          return;
      }
    }  // end else

    var payload = {
      filetes: this.temporaryDoc.filetes,
      idOrden: this.data.idOrden,
      projectID: environment.projectID,
      archivo: this.temporaryDoc.archivo
    };

    this.spinner.show();
    this.fileteador.cargaArchivo(payload).subscribe(
    (dataResponse) => {
      this.spinner.hide();

      this.genericModalService.showModal('generico', "subidaArchivos", null);
      var docs = dataResponse.filetes;
      for (var i = 0; i < docs.length; i++) {
          docs[i].canDelete = true;
      }
      this.data.docs = this.data.docs.concat(dataResponse.filetes);
      this.dataSourceDocsAdjuntos = this.filterDocs();
      this.temporaryDoc.filetes = [];
      this.dataSourceDocsAdjuntar = [];

      // Actualizar OT Compartida
      this.updateOT(this.data);

      this.eliminarDocLocal(true);
      this.formDigitalFile.get('nombreDocumento').setValue('Subir Documento');
      this.uploadFileInput.nativeElement.value = "";
    }, (error) => {
      this.spinner.hide();
      if (error.status === 400) {
        this.genericModalService.showModal('generico', "errorSizeFilete", { error: error.error.message });
      } else if (error.status === 402 || error.status === 403) {
        this.genericModalService.showModal('generico', "errorEncryptedDocument", { error: error.error.message });
      } else {
        this.genericModalService.showModal('generico', "errorFileteador", { error: error.error.message });
      }
    });
  }

  eliminarDocLocal(deleteFiles: boolean): void {
    if (deleteFiles) {
      this.temporaryDoc.filetes = [];
    }

    if (this.seccionarDocumentoSelected) {
      this.prefilete = {};
      this.formDigitalFile.get('paginaInicial').setValue('');
      this.formDigitalFile.get('paginaFinal').setValue('');
    }
    this.formDigitalFile.get('tipoDocumento').setValue(undefined);

    this.formDigitalFile.get('tipoDocumento').markAsUntouched();
    this.formDigitalFile.get('tipoDocumento').markAsPristine();
    this.formDigitalFile.get('tipoDocumento').updateValueAndValidity();

    this.formDigitalFile.get('paginaInicial').markAsUntouched();
    this.formDigitalFile.get('paginaInicial').markAsPristine();
    this.formDigitalFile.get('paginaInicial').updateValueAndValidity();

    this.formDigitalFile.get('paginaFinal').markAsUntouched();
    this.formDigitalFile.get('paginaFinal').markAsPristine();
    this.formDigitalFile.get('paginaFinal').updateValueAndValidity();

  }

  setAprobarDocumento(evt, doc) {
    doc.tmpAprobado = new FormControl(evt.checked);
    for(let d of this.data.docs) {
      if(d.tipoDoc === doc.tipoDoc) {
        d.aprobado = evt.checked;
      }
    }

    let tmpData = JSON.parse(JSON.stringify(this.data));
    let tmpDocs = [...tmpData.docs];

    for(let g of tmpDocs) {
      delete g.tmpAprobado;
    }
   this.updateOT(tmpData);
  }

  eliminarDocStorage(element) {
    this.spinner.show();
    this.fileteador.eliminarArchivo(element.url).subscribe(
    (dataResponse) => {

      this.spinner.hide();
      // Obtener id del documento a eliminar el array de docs de la ot
      var idxDelete = -1;
      for (var i = 0; i <this.data.docs.length; i++) {
        let doc = this.data.docs[i];
        if(element.tipoDoc == doc.tipoDoc && doc.url == element.url) {
          idxDelete = i;
          break;
        }
      }
      this.data.docs.splice(idxDelete, 1);

      this.dataSourceDocsAdjuntos = [];

      // Actualizar el datasource de la tabla de adjuntos
      this.dataSourceDocsAdjuntos = this.dataSourceDocsAdjuntos.concat(this.filterDocs());

      if (this.data.statusOrden === 'registro_da') {
        this.guardar(true);
      } else {
        this.genericModalService.showModal('generico', "borradoExitoso", null);
      }
      
      // Actualizar OT compartida
      this.updateOT(this.data);

    },(errorResponse) => {
        this.spinner.hide();
        this.genericModalService.showModal('generico', "borradoFallido", { error: errorResponse.error.mensaje });
    });
  }

  guardar(flag) {
    // Codigo para guardar la OT
  }

  fncDownloadDoc(element: GetOTDocumentoInterface) {
    if(!element.idFileNet) {
      this.fncDownloadStorage(element);
      return;
    }

    let urlSufix = 'idOrden='+this.data.idOrden+'&idFileNet={' + element.idFileNet.slice(1, element.idFileNet.length - 1) + '}&nombre=' + element.tipoDoc;
    this.spinner.show();
    this.fileteador.descargaDoc(urlSufix).subscribe(
      (dataResponse) => {
        this.spinner.hide();
        var documentContent = `data:application/pdf;base64,${dataResponse.base64}`;
        var documentName = element.tipoDoc+'.pdf';
        var myDocumentLink = document.createElement("a");
        myDocumentLink.href = documentContent;
        myDocumentLink.download = documentName;
        document.body.appendChild(myDocumentLink);
        myDocumentLink.click();
    },(error) => {
      this.spinner.hide();
      this.genericModalService.showModal('generico', "downloadStorageFallido", null);
    });
  }

  fncDownloadStorage(element) {
    this.spinner.show();
    this.fileteador.descargaArchivo(element.url).subscribe(
      (dataResponse) => {
        this.spinner.hide();
        var documentContent = dataResponse.base64;
            var documentName = dataResponse.filename;
            var myDocumentLink = document.createElement("a");
            myDocumentLink.href = documentContent;
            myDocumentLink.download = documentName;
            document.body.appendChild(myDocumentLink);
            myDocumentLink.click();
    },(error) => {
      this.spinner.hide();
      this.genericModalService.showModal('generico', "downloadStorageFallido", null);
    });
  }

  // Mostrar u ocultar controlers html en base a las reglas de negocio
  display(input, action): boolean {
    let userDA = "";

    if (typeof(this.urlParams.da) === "string") {
      userDA = JSON.parse(this.urlParams.da)[0].trim();
    }
    let _scope = {
      reglas_pantalla: reglas_pantalla,
      rol: this.rol,
      data: this.data,
      responsable: this.responsable,
      da_usuario: userDA,
      stateParams: this.urlParams
    };
    return this.otService.display(input, action, _scope);
   }


   noStartWithZero() {
    if (this.data.generales.numeroSolicitantes) {
      this.data.generales.numeroSolicitantes = Number(this.data.generales.numeroSolicitantes);
    }
    if (this.prefilete.paginaInicial) {
        this.prefilete.paginaInicial = this.prefilete.paginaInicial.toString();
        if (this.prefilete.paginaInicial === 0) {
            this.prefilete.paginaInicial = '';
        }
        if ((this.prefilete.paginaInicial[0] === '0' && this.prefilete.paginaInicial.length > 0)) {
            this.prefilete.paginaInicial = this.prefilete.paginaInicial.substring(1);
        }
        this.prefilete.paginaInicial = this.prefilete.paginaInicial ? Number(this.prefilete.paginaInicial) : '';
    }
    if (this.prefilete.paginaFinal) {
        if (this.prefilete.paginaFinal === 0) {
            this.prefilete.paginaFinal = '';
        }
        this.prefilete.paginaFinal = this.prefilete.paginaFinal.toString();
        if (this.prefilete.paginaFinal[0] === '0' && this.prefilete.paginaFinal.length > 0) {
            this.prefilete.paginaFinal = this.prefilete.paginaFinal.substring(1);
        }
        this.prefilete.paginaFinal = this.prefilete.paginaFinal ? Number(this.prefilete.paginaFinal) : '';
    }

    let pfin = Number(this.formDigitalFile.get('paginaFinal').value);
    let pini = Number(this.formDigitalFile.get('paginaInicial').value);
    this.errorPaginaFinal = (pfin > 0) && (pfin < pini);
  };

  /**
   * Actualiza los datos de la ot en el servicio compartido.
   *
   * @param otData - Datos a actualizar.
   */
   updateOT(otData: GetOTResponseInterface) {
    this.otService.setOt = otData;
  }

  /**
   * Filtra el documento de Cedula de autorizacion.
   */
   filterDocs(): GetOTDocumentoInterface[] {
    let tmpDocs: GetOTDocumentoInterface[] = [...this.data.docs];

    // #RF 6, si es el portal de intermediarios, quitar el documento Cedula de Autorizacion
    if(this.urlParams.menu === environment.menu_portal_intermediarios) {
      tmpDocs = this.data.docs.filter(function(doc) {
        return doc.tipoDoc != 'Cedula de Autorizacion';
      });
    }

    tmpDocs = this.data.docs.filter(function(doc) {
      return doc.habilitar_da || [undefined,null].includes(doc.habilitar_da);
    });

    return tmpDocs;
   }

   getParamValueQueryString(paramName: string): string {
    const url: string = window.location.href;
    let paramValue: string;
    if (url.includes('?')) {
        const httpParams = new HttpParams({ fromString: url.split('?')[1] });
        paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }


  /**
   * Devuelve true en caso de que el valor del campo tenga un valor,
   * false en caso contrario.
   *
   * @param field - Campo a validar.
   * @returns true | false
   */
   validateEmpty(field: string): boolean {
    let fieldValue = this.formDigitalFile.get(field).value;
    if(fieldValue) {
      fieldValue = fieldValue.toString().trim();
      if(fieldValue.length <= 0) {
        this.formDigitalFile.get(field).markAsTouched();
        this.formDigitalFile.get(field).updateValueAndValidity();
        return false;
      }
    } else {
      this.formDigitalFile.get(field).markAsTouched();
      this.formDigitalFile.get(field).updateValueAndValidity();
      return false;
    }

    return true;
  }

  validaReglaTrash(): void {
    if(this.data.status == 'En Registro' && this.urlParams.menu == 'workflow'){
      this.flagTrash = false;
    } else {
      this.flagTrash = true;
    }
  }

}
