import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ErrorConexionComponent } from '@components/modals/error-conexion/error-conexion.component';
import { GenericComponent } from '@components/modals/generic/generic.component';
import { GeneratedOrderComponent } from '@components/modals/generated-order/generated-order.component';
import { SpecialAuthorizationComponent } from '@components/modals/special-authorization/special-authorization.component';
import { TurnComponent } from '@components/modals/turn/turn.component';
import { SpecialCasesComponent } from '@components/modals/special-cases/special-cases.component';
import { RefuseConfirmationComponent } from '@components/modals/refuse-confirmation/refuse-confirmation.component';
import { CancelComponent } from '@components/modals/cancel/cancel.component';
import { ActivateComponent } from '@components/modals/activate/activate.component';
import { ChangePolicyComponent } from '@components/modals/change-policy/change-policy.component';
import { CancelDaComponent } from '../../components/modals/cancel-da/cancel-da.component';
import { RecoverComponent } from '@components/modals/recover/recover.component';
import { EventosScrollService } from '@services/eventos-scroll/eventos-scroll.service';
import { BitacoraComponent } from '@components/modals/bitacora/bitacora.component';

@Injectable({
  providedIn: 'root'
})
export class GenericModalService {

  constructor(
    private readonly dialog: MatDialog,
    private eventosScroll: EventosScrollService
) { }

  showModal(id, extras, extras2): MatDialogRef<unknown, any> {
    
    let idSettings: any = {};
    switch (id) {
        // Cambio de status
        case 'changeStatus':
            idSettings = {
                locals: {
                    operacion: extras,
                    extras2: extras2
                },
                controller: TurnComponent
            };
            break;
        case 'cancelar':
            idSettings = {
                locals: {
                },
                controller: CancelComponent
            };
            break;
        case 'cancelar-da':
            idSettings = {
                locals: {
                    flagSolicitante: extras2
                },
                controller: CancelDaComponent
            };
            break;
        case 'activar':
            idSettings = {
                locals: {
                },
                controller: ActivateComponent
            };
            break;
        case 'solicitudCasosEsp':
            idSettings = {
                locals: {
                    observacionTurnar: extras
                },
                controller: SpecialCasesComponent
            };
            break;
        case 'autorizacionEsp':
            idSettings = {
                locals: {
                    urlParams: extras
                },
                controller: SpecialAuthorizationComponent
            };
            break;
        case 'rechazarConfirmacion':
            idSettings = {
                locals: {
                    accion: 'rechazar'
                },
                controller: RefuseConfirmationComponent
            };
            break;
            // Funcionalidad general
        case 'cambioPoliza':
            idSettings = {
                locals: {
                    cambios: extras,
                    casoEspecial: extras2
                },
                controller: ChangePolicyComponent
            };
            break;
        case 'ordenGenerada':
            idSettings = {
                locals: {
                    response: extras.ot,
                    urlParams: extras.urlParams
                },
                controller: GeneratedOrderComponent
            };
            break;
        case 'errorConexion':
            idSettings = {
                locals: {
                    codigoHttp: extras.codigoHttp,
                    errorCatalogo: extras.errorCatalogo,
                    urlReload: extras.urlReload,
                    urlEndpoint: extras.endpoint,
                    operacion: extras.operacion ? extras.operacion : ''
                },
                controller: ErrorConexionComponent
            };
            break;
        case 'recuperar':
            idSettings = {
                locals: {
                },
                controller: RecoverComponent
            };
            break;
        case 'generico':
            idSettings = {
                locals: {
                    tipo: extras,
                    args: extras2
                },
                controller: GenericComponent
            };
            break;
        case 'bitacora':
             idSettings = {
                locals: {},
                controller: BitacoraComponent
             };
            break;
    }
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    // Le pasamos la data al modal de errores
    dialogConfig.data = idSettings.locals;

    this.eventosScroll.eventScroll();
    
    // Abrimos el modal
    const dialogRef = this.dialog.open(idSettings.controller, dialogConfig);
    return dialogRef;
  }
}
