import { Injectable } from '@angular/core';

export enum ToastType {
    success = 'success',
    info = 'info',
    danger = 'danger',
    warning = 'warning'
}

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    show = false;
    message: string;
    title = 'Mensaje';
    type: ToastType;
    setTimeOutToast = null;
    delay: number;

    constructor() {}

    showToast(
        message: string,
        type: ToastType,
        title?: string,
        delay = 3000
    ): void {
        if (title) {
            this.title = title;
        }

        this.message = message;
        this.type = type;
        this.show = true;
        this.delay = delay;
        this.onSetTimer();
    }

    hideToast(): void {
        this.show = false;
        this.message = null;
        this.title = 'Mensaje';
        this.type = null;

        if (this.setTimeOutToast) {
            this.onSetTimer();
        }
    }

    onSetTimer(): void {
        if (this.setTimeOutToast) {
            clearTimeout(this.setTimeOutToast);
            this.setTimeOutToast = null;
        } else {
            this.setTimeOutToast = setTimeout(() => {
                this.hideToast();
            }, this.delay);
        }
    }
}
