import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalPersonService } from '@services/modal-person/modal-person.service';
import { ModalService } from '@services/modal/modal.service';
import { EditPersonComponent } from '../edit-person/edit-person.component';
import { EventosScrollService } from '@services/eventos-scroll/eventos-scroll.service';

@Component({
  selector: 'app-add-email-address',
  templateUrl: './add-email-address.component.html',
  styleUrls: ['./add-email-address.component.scss']
})
export class AddEmailAddressComponent implements OnInit {

  modalTitle: string = "Medio Electrónico";
  formEmails: FormGroup;

  constructor(
    private fb: FormBuilder,
    public readonly modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public inboundData: any,
    public dialogRef: MatDialogRef<AddEmailAddressComponent>,
    public readonly modalPersonService: ModalPersonService,
    private readonly dialog: MatDialog,
    private eventosScroll: EventosScrollService
) { }

  ngOnInit(): void {
    this.buildFrm();
  }

  buildFrm(): void {
    this.formEmails = this.fb.group({
      // Valor a devolver
      tipoMedioElectronico: new FormControl('Correo', [Validators.required]),
      // Valor a pintar
      tipoMedio: new FormControl({value: 'Correo', disabled: true}, [Validators.required]),
      correo: new FormControl(this.inboundData ? this.inboundData.correo : '', Validators.required),
    });
    
    this.formEmails.get('correo').valueChanges.subscribe((myEmail) => {
      if(myEmail) {
        if(!this.validateEmail(myEmail)) {
          this.formEmails.get('correo').setErrors({pattern: true});
        }
      }
    });
    
  }

  /**
   * Validar el correo.
   * 
   * @param email - Cadena a validar.
   * 
   * @returns 
   * false - Si el correo no es correcta.
   * true - Si el correo es correcta.
   */
   validateEmail(email): boolean {
    let reEmail = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/;
    return email.match(reEmail);
  }

  // Cerrar el modal, 
  closeModal(value: any): void {
    if(value) {
      this.formEmails.markAllAsTouched();
      this.formEmails.updateValueAndValidity();
      if(!this.formEmails.valid) {
        return;
      }
      // Guardar la data de este modal en el service de personas
      this.modalPersonService.setEmailsData = this.formEmails.value;  
    }
    // Abrimos el otro (Edicion de Persona)
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = '800px';
    this.eventosScroll.eventScroll();
    this.dialog.open(EditPersonComponent, dialogConfig);
    // Cerramos este modal (Captura de Email)
    this.dialogRef.close();
  }
}
