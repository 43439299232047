import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiUrlService implements HttpInterceptor {

    // URLS que necesitan el dominio base del CRM (Consumo de terceros)
    urlsCRM: string[] = ['personas/buscar','personas/personaBup', 'personas/actualizaPersona', 'personas/altaPersona'];

    // Las URLs que tengan algunas de estas urls parciales del fileteador se deben
    // invocar sin concatenarle la url base del api rest
    fullUrlsFileteador: string[] = ['fileteadorArchivos/v1/descargaArchivo',
    '/expediente-digital-fileteado'];

    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): any {
        let fullEndpoint = req;
        let matched = false;
        if (environment.localEndpoints.indexOf(req.url) === -1) {
            fullEndpoint = req.clone({
                url: `${environment.apiUrl}${req.url}`
            });
            if(this.urlsCRM.includes(req.url)) {
                fullEndpoint = req.clone({
                    url: `${environment.apiCrmUrl}${req.url}`
                });
                matched = true;
            }
            for(let myUrl of this.fullUrlsFileteador) {
                if(matched == false && req.url.search(myUrl) >= 0) {
                    fullEndpoint = req.clone({
                        url: `${req.url}`
                    });
                    break;
                }
            }
        }
        return next.handle(fullEndpoint);
    }
}
