import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { SessionService } from '@services/session/session.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-data-usuario',
    templateUrl: './data-usuario.component.html',
    styleUrls: ['./data-usuario.component.scss']
})
export class DataUsuarioComponent implements OnInit {
    givenname: string;
    idparticipante: string;
    mail: string;
    da: string;
    cua: string;
    menu: string;
    iat: any;
    @Output() drawerToggle = new EventEmitter<void>();
    usuarios: any;
    hiddenBotton = false;

    sessionSubscription: Subscription;

    constructor(
        private readonly storage: LocalStorageService,
        private readonly datePipe: DatePipe,
        private readonly sessionService: SessionService
    ) {
        this.sessionSubscription = new Subscription();
    }

    ngOnInit(): void {
        this.menu = '';
        this.mail = '';
        this.givenname = '';
        this.da = '';
        this.cua = '';
        this.idparticipante = '';
        
        this.sessionSubscription = this.sessionService.sessionStatus.subscribe(
            (status) => {
                //LLego la data
                this.menu = this.storage.getItem('menu');
                this.mail = this.storage.getItem('mail');
                this.givenname = this.storage.getItem('givenname');
                this.da = this.storage.getItem('da');
                this.cua = this.storage.getItem('cua');
                this.idparticipante = this.storage.getItem('idparticipante');
                this.getDate();
        });
    }

    getDate(): void {
        this.iat = this.datePipe.transform(
            Number(this.storage.getItem('iat')) * 1000,
            'dd/MM/YYYY h:mm a'
        );
    }
}
