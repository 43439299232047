import {
    TableActionsInterface,
    TableColumnsInterface
} from '@interfaces/table.interface';

const imgBase = './assets/img/';

export const TableColumnsFixture: TableColumnsInterface[] = [
    {
        key: 'codigo',
        label: 'Código de Cliente',
        filter: false,
        filterOptions: null
    },
    {
        key: 'nombreCompleto',
        label: 'Nombre(s) / Razón Social',
        filter: false,
        filterOptions: null
    },
    {
        key: 'rfc',
        label: 'R.F.C.',
        filter: false,
        filterOptions: null
    },
    {
        key: 'fecha',
        label: 'Fecha de Nacimiento / Constitución',
        filter: false,
        filterOptions: null
    },
    {
        key: 'art492',
        label: 'Art. 492',
        filter: false,
        filterOptions: null
    }
];

export const TableActionsFixture: TableActionsInterface[] = [
    {
        icon: {
            default: `${imgBase}delete/delete_24px.svg`,
            hover: `${imgBase}delete/delete_orange.svg`
        },
        value: 'select',
        tooltip: 'Seleccionar'
    }
];
